import React, { FC, useEffect, useRef } from 'react';
import { View } from 'react-native';
import SignatureScreen from 'react-signature-canvas';
import { useStyle, useTheme } from '../../utilities/styles';
import { CButton, CText } from '../elements';
import { signatureMessages } from './Signature.messages';
import { ISignatureProps } from './Signature';

export const Signature: FC<ISignatureProps> = ({ onOK, text, disabled }) => {
    const style = useStyle();
    const { theme } = useTheme();

    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            if (disabled) {
                ref.current.off();
            } else {
                ref.current.on();
            }
        }
    }, [ref, disabled]);

    return (
        <View>
            <CText>{text}</CText>
            <View
                style={[
                    {
                        marginVertical: 10,
                        borderRadius: 5,
                        overflow: 'hidden',
                        backgroundColor: 'white',
                    },
                    disabled && { backgroundColor: theme.borderColor },
                ]}
            >
                <SignatureScreen
                    ref={(next) => {
                        ref.current = next;
                    }}
                    penColor={'black'}
                    backgroundColor={'transparent'}
                />
            </View>
            <View style={style.horizontalSpaced}>
                <CButton
                    title={signatureMessages.clear}
                    onPress={() => {
                        ref.current.clear();
                    }}
                />
                <CButton
                    cy={'apply-signature'}
                    title={signatureMessages.apply}
                    onPress={async () => {
                        await onOK(ref.current.toDataURL());
                    }}
                    disabled={disabled}
                    disableOnClick
                />
            </View>
        </View>
    );
};
