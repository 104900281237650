import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CImage, CText } from '../../../../../../components';
import { EContractStatus } from '../../../../../../enums/EContractStatus';
import { isSuperUser } from '../../../../../../utilities/auth';
import { useFireBase } from '../../../../../../utilities/firebase';
import { useStyle } from '../../../../../../utilities/styles';
import { ScrollProvider } from '../../../../../../utilities/contexts/Scroll';
import { ContractOverviewBar } from '../../../../components/ContractOverviewBar';
import { DocumentAdminView } from '../DocumentAdminView';
import { ContractNegotiationContext } from '../../ContractNegotiationContext';
import { ECollections } from '../../../../../../enums';
import { MJob, MApplication } from '../../../../../../models';
import { ApplicantOverviewBar } from '../../../../components/ApplicantOverviewBar';
import { JobOverviewBar } from '../../../../components/JobOverviewBar';
import { useSecureNavigate } from '../../../../../../utilities/routing';
import { ContractTitle } from '../../../../components/ContractTitle';
import { ContractCanceled } from '../ContractCanceled';

export const AdminNegotiation: FC = () => {
    const style = useStyle();
    const { secureNavigate } = useSecureNavigate();
    const { userData, getDataById } = useFireBase();
    const { negotiation } = useContext(ContractNegotiationContext);
    const [job, setJob] = useState<MJob>();
    const [application, setApplication] = useState<MApplication>();
    /**
     * effect to load job details for yee to inspect and application for yer to inspect
     */
    useEffect(() => {
        getDataById(ECollections.jobs, negotiation.jobId).then((v) => {
            setJob(new MJob(v));
        });
        getDataById(ECollections.applications, negotiation.applicationId).then(
            (v) => {
                const next = new MApplication(v);
                setApplication(next);
            },
        );
    }, [negotiation]);

    if (isSuperUser(userData)) {
        return (
            <ScrollProvider style={style.paddedScrollableMainView}>
                <View style={[style.card, style.horizontalSplit]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <ContractTitle
                            contract={negotiation}
                            horizontalPadded
                        />
                    </View>
                    <CImage image={negotiation.region} fixedHeight={20} />
                </View>
                <ContractOverviewBar contract={negotiation} />
                {application && (
                    <ApplicantOverviewBar application={application} />
                )}
                {job && <JobOverviewBar job={job} />}
                {negotiation.status !== EContractStatus.creating &&
                    !negotiation.status.includes('review') && (
                        <DocumentAdminView />
                    )}
                {negotiation.status === EContractStatus.canceled && (
                    <ContractCanceled />
                )}
            </ScrollProvider>
        );
    }

    return <CText>ERROR</CText>;
};
