import { defineMessages } from 'react-intl';

const scope = 'con.contract.';
const chatScope = 'chat.contract.';
const reviewScope = 'con.contract.review.';

export const contractMessages = defineMessages({
    negotiationForCustomTitle: {
        id: scope + 'negotiationForCustomTitle',
        defaultMessage: 'Negotiation for "{x}"',
    },
    contractForCustomTitle: {
        id: scope + 'contractForCustomTitle',
        defaultMessage: 'Contract for "{x}"',
    },
    fileList: {
        id: scope + 'fileList',
        defaultMessage: 'Contract File List',
    },
    negotiationForJobInX: {
        id: scope + 'negotiationForJobInX',
        defaultMessage: 'Negotiation for job in {x}',
    },
    contractForJobInX: {
        id: scope + 'contractForJobInX',
        defaultMessage: 'Contract for job in {x}',
    },
    noOpenNegotiations: {
        id: scope + 'noOpenNegotiations',
        defaultMessage:
            'There are currently no open negotiations in which you participate',
    },
    noContracts: {
        id: scope + 'noContracts',
        defaultMessage:
            'There are currently no contracts in which you participate',
    },
    negotiationInit: {
        id: scope + 'negotiationInit',
        defaultMessage: 'Create contract',
    },
    negotiationReview: {
        id: scope + 'negotiationReview',
        defaultMessage: 'Review contract values',
    },
    alreadySigned: {
        id: scope + 'alreadySigned',
        defaultMessage: 'You have already signed',
    },
    scanToSignOnMobile: {
        id: scope + 'scanToSignOnMobile',
        defaultMessage:
            'Scan this QR code with your mobile device to use it as signature pad',
    },
    confirmNoRecentEmployment: {
        id: scope + 'confirmNoRecentEmployment',
        defaultMessage:
            'The hirer undertakes to check before the transfer whether the employee has left an employment relationship with the hirer itself or with a company affiliated with the hirer within the meaning of Section 18 of the German Stock Corporation Act (AktG) in the six months prior to the transfer.',
    },
    confirmNoRecentLeasingByOther: {
        id: scope + 'confirmNoRecentLeasingByOther',
        defaultMessage:
            'The hirer shall inform the lender immediately if an employee is or is to be transferred to the hirer who has already been employed in the company of assignment by another personnel service provider in the last four months prior to the start of the transfer by the lender.',
    },
    beingReviewed: {
        id: scope + 'beingReviewed',
        defaultMessage: 'Currently being reviewed',
    },
    beingCreated: {
        id: scope + 'beingCreated',
        defaultMessage: 'Currently being created',
    },
    usualWage: {
        id: scope + 'usualWage',
        defaultMessage: 'Usual Wage',
    },
    usualWageStatement: {
        id: scope + 'usualWageStatement',
        defaultMessage:
            'I hereby certify that the salary indicated is the same as that of an equivalent employee of our company.',
    },
    usualWageStatementNoWage: {
        id: scope + 'usualWageStatementNoWage',
        defaultMessage:
            'I hereby certify that a leasing employee will not be employed with lesser salary than an equivalent employee of our company.',
    },
    extConsentTitle: {
        id: scope + 'extConsentTitle',
        defaultMessage:
            'Consent to the extension of working hours without compensation working hours due to on-call duty without compensation ("opt-out") pursuant to § 7 para. 2a in conjunction with para. Para. 7 Working Hours Act',
    },
    extConsent0: {
        id: scope + 'extConsent0',
        defaultMessage:
            'I am a part-time employee and declare my consent to the extension of my working hours without compensation of the working day by on-call duty to an average of more than 8 hours per working day per working day or more than 48 hours per week. Due to my part-time employment, the maximum the maximum weekly working hours shall be reduced in principle in accordance with my degree of employment. degree of employment. Pursuant to § 10 Par. 7 of the TV-Ä (VKA), the following exceptions can be made on account of urgent urgent business or operational concerns. The period for calculating the average of the maximum weekly working time is up to 6 months. months.',
    },
    extConsent1: {
        id: scope + 'extConsent1',
        defaultMessage:
            'I am a part-time employee and declare my consent to the extension of my working hours without compensation of the working day by on-call duty to an average of more than 8 hours per working day per working day or more than 48 hours per week. At the same time, I declare that for me, the limits of the maximum working hours full-time employees shall apply to me. According to § 10 Paragraph 5 of the TV-Ä (VKA), the average weekly working time may be up to 56 hours. on average. The period for the calculation of the average of the weekly maximum working hours is up to 6 months.',
    },
    extConsent2: {
        id: scope + 'extConsent2',
        defaultMessage:
            'I am a full-time employee and declare my consent to the extension of my working hours without compensation to of the working day (working time protection period) by on-call duty to an average of an average of more than 8 hours per working day or more than 48 hours per week. Pursuant to § 10 para. 5 TV-Ä (VKA), the weekly working time may be up to 56 hours on average. hours. The period for calculating the average weekly maximum working time is up to six months. maximum working time is up to 6 months.',
    },
    confirmTerms: {
        id: scope + 'confirmTerms',
        defaultMessage: 'Confirm Terms',
    },
    confirmTermsCheckBoxText: {
        id: scope + 'confirmTermsCheckBoxText',
        defaultMessage: 'Confirm Terms and conditions',
    },
    editTerms: {
        id: scope + 'editTerms',
        defaultMessage: 'Edit Terms',
    },
    counteroffer: {
        id: scope + 'counteroffer',
        defaultMessage: 'Counteroffer',
    },
    uploadSignedDocument: {
        id: scope + 'uploadSignedDocument',
        defaultMessage: 'Upload physically signed document',
    },
    multipleWaysToSign: {
        id: scope + 'multipleWaysToSign',
        defaultMessage: 'You can sign in multiple ways',
    },
    signInBrowser: {
        id: scope + 'signInBrowser',
        defaultMessage:
            'After reading the contract, you can digitally sign directly in the signature area below using your cursor',
    },
    signInMobile: {
        id: scope + 'signInMobile',
        defaultMessage:
            'You can digitally sign on your mobile phone using the QR code below.',
    },
    signInReality: {
        id: scope + 'signInReality',
        defaultMessage:
            'You can download the document above and upload a signed copy below.',
    },
    selectFileType: {
        id: scope + 'selectFileType',
        defaultMessage: 'Please select a file type',
    },
    selectFileTypeText: {
        id: scope + 'selectFileTypeText',
        defaultMessage:
            'Please select a file type, so the file you upload can be categorized.',
    },
    fileType: {
        id: scope + 'fileType',
        defaultMessage: 'File type',
    },
    fileTypePlaceholder: {
        id: scope + 'fileTypePlaceholder',
        defaultMessage: 'Select file type',
    },
    offerConditions: {
        id: scope + 'offerConditions',
        defaultMessage: "Click 'confirm' to offer these conditions to user",
    },
    initialChatMessage: {
        id: chatScope + 'initialChatMessage',
        defaultMessage:
            'Welcome to your new contract.\n\n Your employment starts from {from} and continues until {to}.',
    },
    initialChatPayAgreement: {
        id: chatScope + 'initialChatPayAgreement',
        defaultMessage:
            'For the deployment period you have agreed to following hourly rates:',
    },
    initialChatRegularPay: {
        id: chatScope + 'initialChatRegularPay',
        defaultMessage: '- {regularPay} during regular work hours',
    },
    initialChatStandbyPay: {
        id: chatScope + 'initialChatStandbyPay',
        defaultMessage: '- {standbyPay} during standby',
    },
    initialChatOnCallActivePay: {
        id: chatScope + 'initialChatOnCallActivePay',
        defaultMessage: '- {onCallActive} while actively on call',
    },
    initialChatOnCallPassivePay: {
        id: chatScope + 'initialChatOnCallPassivePay',
        defaultMessage: '- {onCallPassive} when on passive call duty',
    },
    initialChatNightRegularPay: {
        id: chatScope + 'night.initialChatRegularPay',
        defaultMessage: '- {regularPay} during night work hours',
    },
    initialChatNightStandbyPay: {
        id: chatScope + 'night.initialChatStandbyPay',
        defaultMessage: '- {standbyPay} during night standby hours',
    },
    initialChatNightOnCallActivePay: {
        id: chatScope + 'night.initialChatOnCallActivePay',
        defaultMessage: '- {onCallActive} during night actively on call',
    },
    initialChatNightOnCallPassivePay: {
        id: chatScope + 'night.initialChatOnCallPassivePay',
        defaultMessage: '- {onCallPassive} when on night passive call duty',
    },
    initialChatSundayRegularPay: {
        id: chatScope + 'sunday.initialChatRegularPay',
        defaultMessage: '- {regularPay} during sunday work hours',
    },
    initialChatSundayStandbyPay: {
        id: chatScope + 'sunday.initialChatStandbyPay',
        defaultMessage: '- {standbyPay} during sunday standby hours',
    },
    initialChatSundayOnCallActivePay: {
        id: chatScope + 'sunday.initialChatOnCallActivePay',
        defaultMessage: '- {onCallActive} during sunday actively on call',
    },
    initialChatSundayOnCallPassivePay: {
        id: chatScope + 'sunday.initialChatOnCallPassivePay',
        defaultMessage: '- {onCallPassive} when on sunday passive call duty',
    },
    initialChatHolidayRegularPay: {
        id: chatScope + 'holiday.initialChatRegularPay',
        defaultMessage: '- {regularPay} during holiday work hours',
    },
    initialChatHolidayStandbyPay: {
        id: chatScope + 'holiday.initialChatStandbyPay',
        defaultMessage: '- {standbyPay} during holiday standby hours',
    },
    initialChatHolidayOnCallActivePay: {
        id: chatScope + 'holiday.initialChatOnCallActivePay',
        defaultMessage: '- {onCallActive} during holiday actively on call',
    },
    initialChatHolidayOnCallPassivePay: {
        id: chatScope + 'holiday.initialChatOnCallPassivePay',
        defaultMessage: '- {onCallPassive} when on holiday passive call duty',
    },
    initialChatMessageEnd: {
        id: chatScope + 'initialChatMessageEnd',
        defaultMessage:
            'Please ensure all documents are sorted out before commencing work at your new workplace.',
    },
    signWithNamirial: {
        id: scope + 'signWithNamirial',
        defaultMessage: 'Sign with Namirial',
    },
    signWithNamirialText: {
        id: scope + 'signWithNamirialText',
        defaultMessage:
            'If you request a signature using Namirial additional costs of 8,50€ (net) will incur.',
    },
    originalValues: {
        id: scope + 'originalValues',
        defaultMessage: 'Original values',
    },
    currentValues: {
        id: scope + 'currentValues',
        defaultMessage: 'Current values',
    },
    newValues: {
        id: scope + 'newValues',
        defaultMessage: 'New values',
    },
    changeAuthoredBy: {
        id: scope + 'changeAuthoredBy',
        defaultMessage: 'Authored by:',
    },
    active: {
        id: scope + 'active',
        defaultMessage: 'Active',
    },
    completed: {
        id: scope + 'completed',
        defaultMessage: 'Completed',
    },
    historyEmpty: {
        id: scope + 'historyEmpty',
        defaultMessage: 'Contract history is empty',
    },
    toReview: {
        id: scope + 'toReview',
        defaultMessage: 'To review',
    },
    inReview: {
        id: scope + 'inReview',
        defaultMessage: 'In review',
    },
    accepted: {
        id: scope + 'accepted',
        defaultMessage: 'Accepted',
    },
    toSign: {
        id: scope + 'toSign',
        defaultMessage: 'To sign',
    },
    paid: {
        id: scope + 'paid',
        defaultMessage: 'Paid',
    },
    inCreation: {
        id: scope + 'inCreation',
        defaultMessage: 'In creation',
    },
    toCreate: {
        id: scope + 'toCreate',
        defaultMessage: 'To create',
    },
    deployed: {
        id: scope + 'deployed',
        defaultMessage: 'In deployment',
    },
    upcoming: {
        id: scope + 'upcoming',
        defaultMessage: 'Upcoming',
    },
    aboutToStart: {
        id: scope + 'aboutToStart',
        defaultMessage: 'About to start',
    },
    toFinish: {
        id: scope + 'toFinish',
        defaultMessage: 'To finish',
    },
    presetRate: {
        id: scope + 'presetRate',
        defaultMessage:
            'The following rate was set during application. You can review it in the next step.',
    },
    presetRateFromJob: {
        id: scope + 'presetRateFromJob',
        defaultMessage:
            'The following rates where set for this job listing during its creation.',
    },
    youIssuedAClose: {
        id: scope + 'youIssuedAClose',
        defaultMessage: 'You are about to cancel this negotiation',
    },
    youIssuedACloseText: {
        id: scope + 'youIssuedACloseText',
        defaultMessage:
            'During negotiation you may cancel a contract without extra costs emerging. If you decide to cancel, there will be no further communication between you and the other party.',
    },
    negotiationCancellationReason: {
        id: scope + 'negotiationCancellationReason',
        defaultMessage:
            'Please tell us why you are canceling this contract negotiation.',
    },
    contractWasCanceled: {
        id: scope + 'contractWasCanceled',
        defaultMessage: 'Contract was canceled',
    },
    contractWasCanceledFrom: {
        id: scope + 'contractWasCanceledFrom',
        defaultMessage: 'Contract was canceled from {cancelator}',
    },
    digitalSignatureAvailable: {
        id: scope + 'digitalSignatureAvailable',
        defaultMessage:
            'Both parties have verified credentials. The digital signature is available',
    },
    digitalSignatureUnavailable: {
        id: scope + 'digitalSignatureUnavailable',
        defaultMessage:
            'Digital Signature is not available. At least one party has not set up credentials.',
    },
    digitalSignatureUnavailableText: {
        id: scope + 'digitalSignatureUnavailableText',
        defaultMessage:
            'The digital signature is not available. Both parties are required to be multi-factor-authenticated and need to have a valid identity document attached to their accounts.',
    },
    timeChanged: {
        id: scope + 'timeChanged',
        defaultMessage: 'The time period changed',
    },
    timeChangedText: {
        id: scope + 'timeChangedText',
        defaultMessage:
            'Your contract partner changed the deployment period. Please make sure you are available during the new time period.',
    },
    confirmAgencyConditions: {
        id: scope + 'confirmAgencyConditions',
        defaultMessage:
            'By checking this box you agree to report worked hours back to us and pay our fee for those worked hours',
    },
    confirmDeletionOfContractFile: {
        id: scope + 'confirmDeletionOfContractFile',
        defaultMessage: 'Are you sure you want to delete this document?',
    },
    confirmDeletionOfContractFileText: {
        id: scope + 'confirmDeletionOfContractFileText',
        defaultMessage: 'This action will be irreversible',
    },
    acceptedOfferWorkplace: {
        id: scope + 'acceptedOfferWorkplace',
        defaultMessage: 'You have accepted the conditions.',
    },
    acceptedOfferWorkplaceText: {
        id: scope + 'acceptedOfferWorkplaceText',
        defaultMessage:
            'Now your contract partner has to finally verify that they are able to fill the position.',
    },
    acceptedOffer: {
        id: scope + 'acceptedOffer',
        defaultMessage: 'You have accepted the conditions.',
    },
    acceptedOfferText: {
        id: scope + 'acceptedOfferText',
        defaultMessage: 'Your contract is now ready.',
    },
    acceptedOfferAgency: {
        id: scope + 'acceptedOfferAgency',
        defaultMessage: 'You have accepted the conditions.',
    },
    acceptedOfferAgencyText: {
        id: scope + 'acceptedOfferAgencyText',
        defaultMessage:
            'You are now connected with your contract partner. Please ensure all documents get exchanged and the work contract signed before your talent starts working.',
    },
    changedOffer: {
        id: scope + 'changedOffer',
        defaultMessage: 'You have changed the conditions.',
    },
    changedOfferText: {
        id: scope + 'changedOfferText',
        defaultMessage:
            'Now your contract partner has to verify that the new conditions are acceptable.',
    },
    signWithCourier: {
        id: scope + 'signWithCourier',
        defaultMessage: 'Request a signed copy of this document.',
    },
    signWithCourierText: {
        id: scope + 'signWithCourierText',
        defaultMessage:
            'Request a signed copy of this document from your contract partner. They shall download the document, print it, sign it and send it to your address.',
    },
    courierRequest: {
        id: scope + 'courierRequest',
        defaultMessage:
            'Your contract partner requested a signature via Courier from you for {x} Documents',
    },
    courierRequestText: {
        id: scope + 'courierRequestText',
        defaultMessage:
            'Please download the documents, apply physical signatures and send all to the following address:\n\n{recipient}\n{street} {number}\n{zipCode} {city}\n{country}\n\nPlease provide a tracking url for your transmission.',
    },
    filesForCourier: {
        id: scope + 'filesForCourier',
        defaultMessage: 'These files should be included:',
    },
    recipient: {
        id: scope + 'recipient',
        defaultMessage: 'Recipient',
    },
    trackingUrl: {
        id: scope + 'trackingUrl',
        defaultMessage: 'Tracking Url',
    },
    closeJob: {
        id: scope + 'closeJob',
        defaultMessage: 'Do you want to close the associated job listing?',
    },
    closeJobText: {
        id: scope + 'closeJobText',
        defaultMessage:
            'You have agreed upon contract details with this applicant. Do you want to close the associated job listing or do you want multiple applicants filling this job?',
    },
    closeJobConfirm: {
        id: scope + 'closeJobConfirm',
        defaultMessage: 'Close job listing and reject all other applicants',
    },
    closeJobReject: {
        id: scope + 'closeJobReject',
        defaultMessage: 'Leave job listing open',
    },
    mainInfoText: {
        id: scope + 'mainInfoText',
        defaultMessage:
            'To complete a legal contract it is required to upload and sign the following documents',
    },
    secondaryInfoText: {
        id: scope + 'secondaryInfoText',
        defaultMessage:
            'Further useful information could be but are not limited to',
    },
    selectSignatureType: {
        id: scope + 'selectSignatureType',
        defaultMessage: 'Please select the Signature provider.',
    },
    selectSignatureTypeText: {
        id: scope + 'selectSignatureTypeText',
        defaultMessage: 'Select what kind of signature you want to use.',
    },
    signUsingCourier: {
        id: scope + 'signUsingCourier',
        defaultMessage: 'Sign using courier',
    },
    signUsingNamirial: {
        id: scope + 'signUsingNamirial',
        defaultMessage: 'E-signature using Namirial',
    },
    openContract: {
        id: scope + 'openContract',
        defaultMessage: 'Open contract',
    },
    openTarif: {
        id: scope + 'openTarif',
        defaultMessage: 'View scale of fees',
    },
    signingWorkplaceUser: {
        id: scope + 'signingWorkplaceUser',
        defaultMessage: 'Signing workplace user',
    },
    youAreNotParticipatingInThisChat: {
        id: scope + 'youAreNotParticipatingInThisChat',
        defaultMessage: 'You are not participating in this chat.',
    },
    joinChat: {
        id: scope + 'joinChat',
        defaultMessage: 'Join chat',
    },
    takeOverNegotiation: {
        id: scope + 'takeOverNegotiation',
        defaultMessage: 'Do you want to manage this contract?',
    },
    takeOverNegotiationText: {
        id: scope + 'takeOverNegotiationText',
        defaultMessage:
            'You are not the user managing this contract. You can become the managing user by selecting ok. The managing user will receive the notifications about updates to this contract.',
    },
    selectFilesForEnvelope: {
        id: scope + 'selectFilesForEnvelope',
        defaultMessage: 'Please select the files you want to get signed.',
    },
    selectFilesForEnvelopeText: {
        id: scope + 'selectFilesForEnvelopeText',
        defaultMessage:
            'Select the files you want to sign. They will count as one signature request.',
    },
    openTimesheet: {
        id: scope + 'openTimesheet',
        defaultMessage: 'Open timesheet',
    },
    requestSignature: {
        id: scope + 'requestSignature',
        defaultMessage: 'Request signature',
    },
    provideReview: {
        id: reviewScope + 'provideReview',
        defaultMessage: 'Provide review',
    },
    reviewEmployee: {
        id: reviewScope + 'reviewEmployee',
        defaultMessage:
            'Please provide a review for the experience with this employee',
    },
    reviewEmployeeInfo: {
        id: reviewScope + 'reviewEmployeeInfo',
        defaultMessage:
            'Providing a review will help us improve our recommendations for employees. It also helps you keep track of employees you had positive experience with.',
    },
    reviewEmployer: {
        id: reviewScope + 'reviewEmployer',
        defaultMessage:
            'Please provide a review for the experience with this employer',
    },
    reviewEmployerInfo: {
        id: reviewScope + 'reviewEmployerInfo',
        defaultMessage:
            'Providing a review will help us improve our platform. It also helps you keep track of employers which you prefer over others.',
    },
    general: {
        id: reviewScope + 'general',
        defaultMessage: 'General',
    },
    punctuality: {
        id: reviewScope + 'punctuality',
        defaultMessage: 'Punctuality',
    },
    reliability: {
        id: reviewScope + 'reliability',
        defaultMessage: 'Reliability',
    },
    professionality: {
        id: reviewScope + 'professionality',
        defaultMessage: 'Professionality',
    },
    competence: {
        id: reviewScope + 'competence',
        defaultMessage: 'Competence',
    },
    sociability: {
        id: reviewScope + 'sociability',
        defaultMessage: 'Sociability',
    },
    introduction: {
        id: reviewScope + 'introduction',
        defaultMessage: 'Introduction',
    },
    taskManagement: {
        id: reviewScope + 'taskManagement',
        defaultMessage: 'Task management',
    },
    hospitality: {
        id: reviewScope + 'hospitality',
        defaultMessage: 'Hospitality',
    },
    communication: {
        id: reviewScope + 'communication',
        defaultMessage: 'Communication',
    },
    agency: {
        id: reviewScope + 'agency',
        defaultMessage: 'Agency',
    },
    employee: {
        id: reviewScope + 'employee',
        defaultMessage: 'Employee',
    },
    xFiles: {
        id: scope + 'xFiles',
        defaultMessage: '{x} Files',
    },
    initialInfo: {
        id: scope + 'initialInfo',
        defaultMessage: 'Welcome to your new contract.',
    },
    initialAgencyInfoText: {
        id: scope + 'initialAgencyInfoText',
        defaultMessage:
            'You have to upload a leasing contract and all related documents now. When you have done so, you can request a signature from your contract partner for this document envelope.',
    },
    initialEmployerInfoText: {
        id: scope + 'initialEmployerInfoText',
        defaultMessage:
            'Your contract partner has to upload a leasing contract and other related documents. When they have done so, you will receive a signature request. In the mean time you can already upload additional helpful documents. For example a location plan or code of conduct.',
    },
    createNewContract: {
        id: scope + 'createNewContract',
        defaultMessage: 'Create new contract',
    },
    createNewContractTitle: {
        id: scope + 'createNewContractTitle',
        defaultMessage: 'Enter the e-mail address of you contract partner',
    },
    createNewContractText: {
        id: scope + 'createNewContractText',
        defaultMessage:
            'You can create a contract for any kind of document transfer. These contracts are free of charge since they are not related to a job listing. You still have to pay the cost per signature. For yourself and your contract partner.',
    },
    mailOfContractPartner: {
        id: scope + 'mailOfContractPartner',
        defaultMessage: 'Mail of contract partner',
    },
    noParticipantsYet: {
        id: scope + 'noParticipantsYet',
        defaultMessage:
            'You are currently the only participant in this contract. You will receive a notification as soon as your invitation gets accepted.',
    },
    mailSend: {
        id: scope + 'mailSend',
        defaultMessage: 'You have sent a mail to {mail}',
    },
    mailSendText: {
        id: scope + 'mailSendText',
        defaultMessage:
            'You have sent a mail to {mail}. As soon as a user connects to your contract you will receive a notification.',
    },
    unmanagedContract: {
        id: scope + 'unmanagedContract',
        defaultMessage: 'Unmanaged Contract',
    },
    createdOn: {
        id: scope + 'createdOn',
        defaultMessage: 'Created on',
    },
    open: {
        id: scope + 'open',
        defaultMessage: 'Open',
    },
    title: {
        id: scope + 'title',
        defaultMessage: 'Custom title',
    },
    checkYourStatus: {
        id: scope + 'checkYourStatus',
        defaultMessage: 'Check your status',
    },
    confirmEnvelope: {
        id: scope + 'confirmEnvelope',
        defaultMessage: 'Please confirm envelope creation.',
    },
    confirmEnvelopeText: {
        id: scope + 'confirmEnvelopeText',
        defaultMessage: 'The envelope will contain the following files:',
    },
    contractDetails: {
        id: scope + 'contractDetails',
        defaultMessage: 'Contract details',
    },
    jobDetails: {
        id: scope + 'jobDetails',
        defaultMessage: 'Job details',
    },
    employeeDetails: {
        id: scope + 'employeeDetails',
        defaultMessage: 'Employee details',
    },
    history: {
        id: scope + 'history',
        defaultMessage: 'History',
    },
    chooseUploadOrCopy: {
        id: scope + 'chooseUploadOrCopy',
        defaultMessage:
            'Do you want to upload a new file or copy one of your files into this contract?',
    },
    chooseUploadOrCopyText: {
        id: scope + 'chooseUploadOrCopyText',
        defaultMessage:
            'You can upload a file from you system or choose one you already uploaded to the profile in the past to copy into this contract.',
    },
    copyAFile: {
        id: scope + 'copyAFile',
        defaultMessage: 'Use an existing file',
    },
    copySelectedFiles: {
        id: scope + 'copySelectedFiles',
        defaultMessage: 'Use selected files',
    },
    uploadAFile: {
        id: scope + 'uploadAFile',
        defaultMessage: 'Upload a new file',
    },
    pickFilesToCopy: {
        id: scope + 'pickFilesToCopy',
        defaultMessage: 'Please select files to copy',
    },
});
