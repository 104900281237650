import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { MUserIdentDoc } from '../../../../models';
import { CButton, CImage } from '../../../../components';
import { useFireBase } from '../../../../utilities/firebase';
import { DocumentValues } from '../../components/DocumentValues';
import { documentScanMessages } from '../../documentScan.messages';
import { useDialog } from '../../../../utilities/dialog';
import { actionMessages } from '../../../../utilities/messages';
import { useLock } from '../../../../utilities/hooks';

export const DocumentScanRow: FC<{
    doc: MUserIdentDoc;
    reload: () => void;
}> = ({ doc, reload }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { lock } = useLock();
    const { getFileDownloadUrl, callFunction } = useFireBase();
    const dialog = useDialog();

    const [frontImage, setFrontImage] = useState<string>();
    const [backImage, setBackImage] = useState<string>();
    const [personImage, setPersonImage] = useState<string>();

    const [rowDims, setRowDims] = useState({ width: 0, height: 0 });

    const columnWidth = useMemo(() => {
        return (rowDims.width - 40) / 3;
    }, [rowDims]);

    useEffect(() => {
        if (doc.front) {
            getFileDownloadUrl(doc.front).then(setFrontImage);
        }
        if (doc.back) {
            getFileDownloadUrl(doc.back).then(setBackImage);
        }
        if (doc.userWithDoc) {
            getFileDownloadUrl(doc.userWithDoc).then(setPersonImage);
        }
    }, [doc]);

    return (
        <View style={style.card}>
            <View
                style={[style.horizontalSplit, style.centeredItems]}
                onLayout={(event) => {
                    const { width, height } = event.nativeEvent.layout;
                    setRowDims({ width, height });
                }}
            >
                <CImage
                    image={'help'}
                    uri={frontImage}
                    maxWidth={columnWidth}
                />
                <CImage image={'help'} uri={backImage} maxWidth={columnWidth} />
                <CImage
                    image={'help'}
                    uri={personImage}
                    maxWidth={columnWidth}
                />
            </View>
            <DocumentValues doc={doc} />
            {!doc.reviewed && (
                <View style={style.horizontalSpaced}>
                    <CButton
                        title={documentScanMessages.reject}
                        onPress={() =>
                            dialog({
                                title: documentScanMessages.confirmReject,
                                message: documentScanMessages.confirmRejectText,
                                buttons: [
                                    {
                                        text: actionMessages.ok,
                                        color: theme.errorColor,
                                    },
                                ],
                                cancelButton: { text: actionMessages.cancel },
                                icon: 'warning',
                            }).then((r) => {
                                if (r) {
                                    console.log('not yet implemented');
                                    const path = `${(doc as any).documentPath}`;
                                    console.log(path);
                                    reload();
                                }
                            })
                        }
                        icon={'close'}
                        danger
                    />
                    <CButton
                        title={documentScanMessages.verify}
                        onPress={() =>
                            dialog({
                                title: documentScanMessages.confirmVerify,
                                message: documentScanMessages.confirmVerifyText,
                                buttons: [{ text: actionMessages.ok }],
                                cancelButton: { text: actionMessages.cancel },
                                icon: 'warning',
                            }).then(async (r) => {
                                if (r) {
                                    const unlock = lock();
                                    const documentPath = `${
                                        (doc as any).documentPath
                                    }`;
                                    await callFunction(
                                        'verifyPersonalDocument',
                                        {
                                            documentPath,
                                        },
                                    );
                                    reload();
                                    unlock();
                                }
                            })
                        }
                        icon={'check'}
                    />
                </View>
            )}
        </View>
    );
};
