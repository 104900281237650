import React, { FC, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import {
    CButton,
    CIcon,
    CImage,
    CText,
    CTextInput,
    CTimePicker,
} from '../../components';
import { useStyle, useTheme } from '../styles';
import { useDimensions } from '../hooks/useDimensions';
import { actionMessages } from '../messages';
import {
    IDialogProps,
    IDialogButton,
    IDialogReviewValue,
    IDialogReviewText,
} from './IDialog';
import { ReviewSections } from './components/ReviewSections';
import { MultiRadioInput } from './components/MultiRadioInput';
import { PickerInputs } from './components/PickerInputs';

export const Dialog: FC<IDialogProps> = ({
    title,
    message,
    subMessages,
    buttons,
    cancelButton,
    icon,
    textInputs,
    timeInputs,
    pickerInputs,
    multiRadioInput,
    reviewSections,
    verticalButtons,
}) => {
    // gloabal state
    const style = useStyle();
    const { theme } = useTheme();
    const { width } = useDimensions();
    // local state
    const [textInputValues, setTextInputValues] = useState<
        { id: string; value: string }[]
    >([]);
    const [timeInputValues, setTimeInputValues] = useState<
        { id: string; value: number }[]
    >([]);
    const [pickerValues, setPickerValues] = useState<
        { id: string; value: string }[]
    >([]);
    const [multiRadioInputValues, setMultiRadioInputValues] = useState<
        { id: string; value: any }[]
    >([]);
    const [reviewValues, setReviewValues] = useState<IDialogReviewValue[]>([]);
    const [reviewTexts, setReviewTexts] = useState<IDialogReviewText[]>([]);
    /**
     * memoized joined input values
     */
    const inputValues = useMemo(() => {
        const next: { id: string; sectionId?: string; value: any }[] = [
            ...textInputValues,
            ...pickerValues,
            ...reviewValues,
            ...timeInputValues,
        ];
        for (const reviewText of reviewTexts) {
            next.push({
                id: 'reviewText',
                sectionId: reviewText.sectionId,
                value: reviewText.text,
            });
        }
        if (multiRadioInput) {
            next.push({
                id: multiRadioInput.id,
                value: multiRadioInputValues.map((v) => v.id),
            });
        }
        return next;
    }, [
        textInputValues,
        pickerValues,
        multiRadioInputValues,
        reviewValues,
        timeInputValues,
        multiRadioInput,
    ]);

    /**
     * memoized icon data
     */
    const iconData = useMemo(() => {
        switch (icon) {
            case 'info':
                return {
                    icon: 'info_outline',
                    tint: theme.accentColor,
                };
            case 'star':
                return {
                    icon: 'star',
                    tint: theme.accentColor,
                };
            case 'error':
                return {
                    icon: 'close',
                    tint: theme.errorColor,
                };
            case 'question':
                return {
                    icon: 'helpCircle_outline',
                    tint: theme.accentColor,
                };
            case 'success':
                return {
                    icon: 'check',
                    tint: theme.successColor,
                };
            case 'warning':
                return {
                    icon: 'alert',
                    tint: theme.warningColor,
                };
            case 'namirial':
                return { icon: 'namirial', tint: theme.accentColor };
            case 'phone':
                return { icon: 'smartphone', tint: theme.accentColor };
            case 'rocketman':
                return { image: 'rocketman' as const };
            case 'signature':
                return { icon: 'signature', tint: theme.accentColor };
            case 'review':
                return { image: 'referral' as const };
            default:
                return undefined;
        }
    }, [icon]);
    /**
     * effect to handle enter key down on web
     */
    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if (e.key === 'Enter') {
                if (buttons && buttons[0].onPress) {
                    buttons[0].onPress();
                }
            }
        };
        // @ts-ignore
        if (Platform.OS === 'web' && document) {
            // @ts-ignore
            document.addEventListener('keydown', handleKeyDown);

            return function cleanup() {
                // @ts-ignore
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    });
    /**
     * effect to fill text inputs with initial values
     */
    useEffect(() => {
        setTextInputValues(
            (textInputs || []).map((v) => {
                if (v.defaultValue) {
                    return { id: v.id, value: v.defaultValue };
                }
                return { id: v.id, value: '' };
            }),
        );
    }, [textInputs]);
    /**
     * effect to fill time inputs with initial values
     */
    useEffect(() => {
        setTimeInputValues(
            (timeInputs || []).map((v) => {
                if (v.defaultValue) {
                    return { id: v.id, value: v.defaultValue };
                }
                return { id: v.id, value: Date.now() };
            }),
        );
    }, [timeInputs]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.card,
                {
                    padding: width < 720 ? 10 : 20,
                    maxWidth: width < 720 ? width : 720,
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            ]}
        >
            <View style={style.verticalPadded}>
                {iconData &&
                    (iconData.icon ? (
                        <CIcon
                            icon={iconData.icon}
                            tint={iconData.tint}
                            size={150}
                        />
                    ) : iconData.image ? (
                        <CImage image={iconData.image} maxWidth={200} />
                    ) : undefined)}
            </View>
            <CText
                style={style.verticalPadded}
                bold
                centered
                headline
                message={title}
            />
            <CText
                style={[style.verticalPadded, { textAlign: 'center' }]}
                message={message}
            />
            {subMessages &&
                subMessages.map((m, i) => (
                    <CText
                        key={i}
                        style={[style.verticalPadded, { textAlign: 'center' }]}
                        message={m}
                    />
                ))}
            <View style={{ width: '100%', zIndex: 1 }}>
                {textInputs &&
                    textInputs.map((tI) => (
                        <CTextInput
                            cy={tI.id}
                            key={tI.id}
                            placeholder={tI.title}
                            value={
                                textInputValues.find((tiv) => tiv.id === tI.id)
                                    ?.value || ''
                            }
                            onChangeText={(text: string) => {
                                setTextInputValues((prev) => {
                                    const p = prev.find(
                                        (tiv) => tiv.id === tI.id,
                                    );
                                    if (p) {
                                        p.value = text;
                                    } else {
                                        prev.push({ id: tI.id, value: text });
                                    }
                                    return Array.from(prev);
                                });
                            }}
                            onKeyPress={async (key) => {
                                if (
                                    tI.entercontinue &&
                                    key.nativeEvent.key === 'Enter'
                                ) {
                                    key.preventDefault();
                                    if (
                                        buttons &&
                                        buttons[0].onPress &&
                                        (!buttons[0].disabled ||
                                            !buttons[0].disabled(inputValues))
                                    ) {
                                        buttons[0].onPress(inputValues);
                                    } else if (
                                        cancelButton &&
                                        cancelButton.onPress
                                    ) {
                                        cancelButton.onPress();
                                    }
                                }
                            }}
                            autoExtend
                        />
                    ))}
                {timeInputs &&
                    timeInputs.map((ti, i) => {
                        const value = timeInputValues.find(
                            (v) => v.id === ti.id,
                        );
                        return (
                            <View
                                key={ti.id}
                                style={[
                                    {
                                        zIndex: timeInputs.length + 1 - i,
                                    },
                                    style.horizontalSplit,
                                    style.centeredItems,
                                ]}
                            >
                                <CText message={ti.title} />
                                <CTimePicker
                                    onChange={(e) =>
                                        setTimeInputValues((prev) => {
                                            const i = prev.findIndex(
                                                (v) => v.id === ti.id,
                                            );
                                            const next = [...prev];
                                            next.splice(i, 1, {
                                                id: ti.id,
                                                value: e.getTime(),
                                            });
                                            return next;
                                        })
                                    }
                                    value={new Date(value?.value || 0)}
                                    minDate={
                                        ti.lowerLimit
                                            ? new Date(ti.lowerLimit).getTime()
                                            : undefined
                                    }
                                    maxDate={
                                        ti.upperLimit
                                            ? new Date(ti.upperLimit).getTime()
                                            : undefined
                                    }
                                />
                            </View>
                        );
                    })}
                {pickerInputs && (
                    <PickerInputs
                        values={pickerValues}
                        onChange={setPickerValues}
                        pickerInputs={pickerInputs}
                    />
                )}
                {multiRadioInput && (
                    <MultiRadioInput
                        values={multiRadioInputValues}
                        onChange={setMultiRadioInputValues}
                        multiRadioInput={multiRadioInput}
                    />
                )}
                {reviewSections && (
                    <ReviewSections
                        onChange={setReviewValues}
                        values={reviewValues}
                        reviewSections={reviewSections}
                        onChangeText={setReviewTexts}
                        texts={reviewTexts}
                    />
                )}
            </View>
            <View
                style={
                    !verticalButtons && width >= 720
                        ? [
                              style.horizontalWrap,
                              style.verticalPadded,
                              style.centeredContent,
                          ]
                        : {
                              display: 'flex',
                              flexDirection: 'column-reverse',
                              alignItems: 'stretch',
                          }
                }
            >
                {cancelButton && cancelButton.onPress && (
                    <CButton
                        cy={'dialog-cancel'}
                        onPress={cancelButton.onPress}
                        title={cancelButton.text}
                        style={{
                            backgroundColor:
                                cancelButton.color || theme.warningColor,
                        }}
                    />
                )}
                {(
                    buttons ||
                    ([{ text: actionMessages.ok }] as IDialogButton[])
                ).map((b, i) => (
                    <CButton
                        cy={'dialog-confirm-' + i}
                        key={i}
                        disabled={b.disabled ? b.disabled(inputValues) : false}
                        onPress={() =>
                            b.onPress
                                ? b.onPress(inputValues)
                                : () => console.error('malformed button')
                        }
                        title={b.text}
                        style={!!b.color && { backgroundColor: b.color }}
                    />
                ))}
            </View>
        </View>
    );
};
