import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate, useSearchParams } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import { MApplication } from '../../../models/MApplication';
import {
    isAgencyUser,
    isManagingUser,
    isPeasant,
} from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { applicationMessages } from '../application.messages';
import { ApplicationRow } from '../components/ApplicationRow';
import { ECollections } from '../../../enums';
import { CImage } from '../../../components/elements/CImage';
import {
    ApplicationFilterBar,
    EApplicationFilterMode,
} from './components/ApplicationFilterBar';
import { actionMessages } from '../../../utilities/messages';
import { IFilter } from '../../../utilities/firebase/store';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { contractMessages } from '../../Contract/contract.messages';
/**
 * status modes that can not coexist
 */
const statusModes = [
    EApplicationFilterMode.accepted,
    EApplicationFilterMode.open,
    EApplicationFilterMode.negotiating,
    EApplicationFilterMode.rejected,
    EApplicationFilterMode.closed,
];
/**
 * list of applications from user (or from all users if super user)
 * @returns
 */
export const ApplicationList: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getDataIndex, user, userData, userAgencies } = useFireBase();
    const [applications, setApplications] = useState<MApplication[]>([]);
    const [loading, setLoading] = useState(true);
    const [lodaingMore, setLoadingMore] = useState(false);
    const [mode, setMode] = useState<EApplicationFilterMode[]>([
        EApplicationFilterMode.open,
    ]);
    const [customStringFilter, setCustomStringFilter] = useState<{
        field: string;
        value: string;
    }>();
    const [decidedOnInitialMode, setDecidedOnInitialMode] = useState(false);
    /**
     * callback to add mode (or remove)
     */
    const addMode = useCallback(
        (nextMode: EApplicationFilterMode) => {
            // check if next is included, then remove it
            const prevIndex = mode.indexOf(nextMode);
            if (prevIndex >= 0) {
                // remove from modes
                setMode((prev) => {
                    prev.splice(prevIndex, 1);
                    return Array.from(prev);
                });
            } else if (statusModes.includes(nextMode)) {
                // remove all incompatible status modes and add
                setMode((prev) => {
                    const next = prev.filter((v) => !statusModes.includes(v));
                    next.push(nextMode);
                    return Array.from(next);
                });
            } else {
                // add
                setMode((prev) => {
                    prev.push(nextMode);
                    return Array.from(prev);
                });
            }
        },
        [mode],
    );
    /**
     * load more with offset (offset is the last createdOn value)
     */
    const loadMore = useCallback(
        (offset?: MApplication) => {
            if (!userData.documentId) {
                return;
            }
            const params = {
                filter: [] as IFilter[],
                orderBy: 'createdOn' as string | undefined,
                partialMatch: undefined as
                    | { field: string; value: string }
                    | undefined,
            };
            if (isManagingUser(userData)) {
                params.filter.push({
                    field: 'job.author',
                    value: user?.uid || '',
                });
            } else if (isAgencyUser(userData)) {
                params.filter.push({
                    field: 'agencyId',
                    operator: 'in',
                    value: userAgencies.map((a) => a.documentId),
                });
            } else if (isPeasant(userData)) {
                params.filter.push({
                    field: 'applicantUid',
                    value: user?.uid || '',
                });
            }
            /**
             * included status mode to filter by
             */
            const includedStatusMode = statusModes.find((sM) =>
                mode.includes(sM),
            );
            if (includedStatusMode) {
                params.filter.push({
                    field: 'status',
                    value: includedStatusMode,
                });
            }
            if (
                customStringFilter &&
                customStringFilter.value &&
                customStringFilter.field
            ) {
                params.partialMatch = customStringFilter;
                params.orderBy = undefined;
            }
            getDataIndex(ECollections.applications, {
                ...params,
                limit: 10,
                startDocumentId: offset ? offset.documentId : undefined,
            })
                .then((result) => {
                    const next = (result as MApplication[]).map(
                        (r) => new MApplication(r),
                    );
                    if (!offset) {
                        setApplications(next);
                        setLoading(false);
                    } else {
                        setApplications((current) => {
                            return [...current, ...next];
                        });
                        setLoadingMore(false);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setLoading(false);
                });
        },
        [userData, mode, customStringFilter],
    );

    useEffect(() => {
        if (decidedOnInitialMode) {
            loadMore();
        }
    }, [loadMore, decidedOnInitialMode]);

    useEffect(() => {
        if (searchParams.get('open')) {
            setMode([EApplicationFilterMode.open]);
        }
    }, [searchParams]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card, { zIndex: 100 }]}>
                <CText bold headline>
                    {format(generalMessages.applications)}
                </CText>
                <ApplicationFilterBar
                    mode={mode}
                    onSet={(p) => {
                        setMode(p.mode);
                    }}
                    addMode={addMode}
                    setCustomStringFilter={setCustomStringFilter}
                    customStringFilter={customStringFilter}
                    decidedOnInitialModeCallback={() =>
                        setDecidedOnInitialMode(true)
                    }
                />
            </View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {applications.map((app) => (
                        <ApplicationRow
                            key={app.documentId}
                            application={app}
                            reload={() => loadMore()}
                        />
                    ))}
                    {lodaingMore ? (
                        <Spinner />
                    ) : (
                        !!applications.length &&
                        ((!mode.length && applications.length % 10 === 0) ||
                            applications.length < 0) && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(
                                            applications[
                                                applications.length - 1
                                            ],
                                        );
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                    {!applications.length && (
                        <View style={style.card}>
                            {mode.includes(
                                EApplicationFilterMode.negotiating,
                            ) ? (
                                <>
                                    <CImage image="negotiations" />
                                    <CText
                                        centered
                                        headline
                                        message={
                                            contractMessages.noOpenNegotiations
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <CImage image={'apply'} />
                                    <CText
                                        centered
                                        headline
                                        message={
                                            applicationMessages.noApplications
                                        }
                                    />
                                </>
                            )}
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    title={format(applicationMessages.goToJobs)}
                                    onPress={() => navigate('/job')}
                                />
                            </View>
                        </View>
                    )}
                </>
            )}
        </ScrollProvider>
    );
};
