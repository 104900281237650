import { defineMessages } from 'react-intl';

const scope = 'con.agency.';

export const agencyMessages = defineMessages({
    owner: {
        id: scope + 'owner',
        defaultMessage: 'Owner',
    },
    iban: {
        id: scope + 'iban',
        defaultMessage: 'IBAN',
    },
    ibanPlaceholder: {
        id: scope + 'ibanPlaceholder',
        defaultMessage: 'IBAN e.g. DE02120300000000202051',
    },
    ibanInvalidError: {
        id: scope + 'ibanInvalidError',
        defaultMessage: 'IBAN seems to be incorrectly formatted',
    },
    bic: {
        id: scope + 'bic',
        defaultMessage: 'BIC',
    },
    bicPlaceholder: {
        id: scope + 'bicPlaceholder',
        defaultMessage: 'BIC e.g. BYLADEM1001',
    },
    bicInvalidError: {
        id: scope + 'bicInvalidError',
        defaultMessage: 'BIC seems to be incorrectly formatted',
    },
    creating: {
        id: scope + 'creating',
        defaultMessage: 'Creating new Agency',
    },
    editing: {
        id: scope + 'editing',
        defaultMessage: 'Editing Agency: {agency}',
    },
    picture: {
        id: scope + 'picture',
        defaultMessage: 'Picture',
    },
    name: {
        id: scope + 'name',
        defaultMessage: 'Agency Name',
    },
    address: {
        id: scope + 'address',
        defaultMessage: 'Address',
    },
    billingAddress: {
        id: scope + 'billingAddress',
        defaultMessage: 'Billing address',
    },
    billingAddressDiffers: {
        id: scope + 'billingAddressDiffers',
        defaultMessage: 'Billing address is different from address',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    editNote: {
        id: scope + 'editNote',
        defaultMessage: 'Edit note',
    },
    finishEditing: {
        id: scope + 'finishEditing',
        defaultMessage: 'Finish editing',
    },
    taxNumber: {
        id: scope + 'taxNumber',
        defaultMessage: 'Tax number',
    },
    registerNumber: {
        id: scope + 'registerNumber',
        defaultMessage: 'Register number',
    },
    licensedUntil: {
        id: scope + 'licensedUntil',
        defaultMessage: 'Licensed until',
    },
    unlicensed: {
        id: scope + 'unlicensed',
        defaultMessage: 'No license',
    },
    createInvite: {
        id: scope + 'createInvite',
        defaultMessage: 'Create new invite',
    },
    users: {
        id: scope + 'users',
        defaultMessage: 'Users',
    },
    invites: {
        id: scope + 'invites',
        defaultMessage: 'Invites',
    },
    agencyId: {
        id: scope + 'agencyId',
        defaultMessage: 'Agency ID',
    },
    workplaces: {
        id: scope + 'workplaces',
        defaultMessage: 'Preferred by these workplaces',
    },
    yourWorkplaces: {
        id: scope + 'yourWorkplaces',
        defaultMessage: 'Select your workplace to send the invite for.',
    },
    confirmRemoval: {
        id: scope + 'confirmRemoval',
        defaultMessage: 'Do you really want to remove this preference?',
    },
    confirmRemovalText: {
        id: scope + 'confirmRemovalText',
        defaultMessage:
            'Please confirm that you want to remove {agency} from {workplace} preferred agencies.',
    },
    timeTracking: {
        id: scope + 'timeTracking',
        defaultMessage: 'Internal time tracking',
    },
    enableTimeTracking: {
        id: scope + 'enableTimeTracking',
        defaultMessage: 'Enable internal time tracking',
    },
    shiftLength: {
        id: scope + 'shiftLength',
        defaultMessage: 'Shift length in minutes',
    },
    breakLength: {
        id: scope + 'breakLength',
        defaultMessage: 'Break length in minutes',
    },
    viewTimes: {
        id: scope + 'viewTimes',
        defaultMessage: 'View times',
    },
});
