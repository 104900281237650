import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CText } from '../../../../components';
import { CImage } from '../../../../components/elements/CImage';
import { MEducation, MEmployment } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { EducationView } from './EducationView';
import { EmploymentView } from './EmploymentView';
import { ProfileContext } from '../../../Profile/Profile.context';

export const ProfessionalHistory: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    const [freshEduIndex, setFreshEduIndex] = useState(-1);
    const [freshEmpIndex, setFreshEmpIndex] = useState(-1);
    /**
     * unset validity
     */
    useEffect(() => {
        onValidityChange('', 'history');
    }, [curData]);

    const onChangeEmployment = (next: Partial<MEmployment>, index: number) => {
        const nextEmployments = [...curData.employmentHistory];
        nextEmployments[index] = { ...nextEmployments[index], ...next };
        onChange({ employmentHistory: nextEmployments });
    };
    const onChangeEducation = (next: Partial<MEducation>, index: number) => {
        const nextEducations = [...curData.educationHistory];
        nextEducations[index] = { ...nextEducations[index], ...next };
        onChange({ educationHistory: nextEducations });
    };
    return (
        <>
            <CText headline message={profileMessages.professionalHistory} />
            <CImage image="professionalHistory" />
            <CText secondaryHeadline>
                {format(profileMessages.employmentHistory)}
            </CText>
            {curData.employmentHistory.map((e, i, arr) => (
                <EmploymentView
                    key={i}
                    zIndex={arr.length - i + 50}
                    employment={e}
                    onChange={(next) => onChangeEmployment(next, i)}
                    removeSelf={() => {
                        const nextEmployments =
                            curData.employmentHistory.filter(
                                (e, index) => i !== index,
                            );
                        onChange({ employmentHistory: nextEmployments });
                    }}
                    fresh={i === freshEmpIndex}
                />
            ))}
            {!curData.employmentHistory.length && (
                <View style={style.verticalPadded}>
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText>
                            {format(profileMessages.fillInPreviousEmployments)}
                        </CText>
                    </View>
                </View>
            )}
            <View style={[style.horizontalSpaced]}>
                <CButton
                    icon="plus"
                    title={format(profileMessages.addEmployment)}
                    minor
                    onPress={() => {
                        const newEmployments = [...curData.employmentHistory];
                        newEmployments.push(new MEmployment());
                        onChange({ employmentHistory: newEmployments });
                        setFreshEmpIndex(newEmployments.length - 1);
                    }}
                />
            </View>
            <CText secondaryHeadline>
                {format(profileMessages.educationHistory)}
            </CText>
            {curData.educationHistory.map((e, i, arr) => (
                <EducationView
                    key={i}
                    zIndex={arr.length - i + 25}
                    education={e}
                    onChange={(next) => onChangeEducation(next, i)}
                    removeSelf={() => {
                        const nextEdus = curData.educationHistory.filter(
                            (e, index) => i !== index,
                        );
                        onChange({ educationHistory: nextEdus });
                    }}
                    fresh={i === freshEduIndex}
                />
            ))}
            {!curData.educationHistory.length && (
                <View style={style.verticalPadded}>
                    {/* <Image
                        // @ts-ignore
                        source={assets.professionalHistory}
                        style={{
                            ...imageSize(width, 0.7, 0.514, 500),
                            marginHorizontal: 'auto',
                        }}
                    /> */}
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText>
                            {format(profileMessages.fillInEducations)}
                        </CText>
                    </View>
                </View>
            )}
            <View style={[style.horizontalSpaced]}>
                <CButton
                    icon="plus"
                    minor
                    title={format(profileMessages.addEducation)}
                    onPress={() => {
                        const newEducations = [...curData.educationHistory];
                        newEducations.push(new MEducation());
                        onChange({ educationHistory: newEducations });
                        setFreshEduIndex(newEducations.length - 1);
                    }}
                />
            </View>
        </>
    );
};
