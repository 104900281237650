import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { CImage, CLabel, CText } from '../../../../../components';
import { CButton, CMultiButton } from '../../../../../components/elements';
import { useNavigate } from '../../../../../utilities/routing';
import { ExpandableCard } from '../../../../../components/ExpandableCard';
import { useFormat } from '../../../../../utilities/intl';
import { jobMessages } from '../../../Job.messages';
import { actionMessages } from '../../../../../utilities/messages';
import { useFireBase } from '../../../../../utilities/firebase';
import { isSuperUser } from '../../../../../utilities/auth';
import { EApplicationStatus } from '../../../../../enums';
import { JobRowBody } from './components/JobRowBody';
import { JobTitle } from '../../../components/JobTitle';
import { JobRowSubHead } from './components/JobRowSubHead';
import { useJobRowState } from './useJobRowState';
import { MJob } from '../../../../../models';
import { useDimensions } from '../../../../../utilities/hooks';
import { JobRowSlim } from './JobRowSlim';

export interface IJobRow {
    job: MJob;
    noButtons?: boolean;
    noSubHead?: boolean;
    inCard?: boolean;
    smallButtons?: boolean;
    pinned?: boolean;
    forceOpen?: boolean;
    onChangePinned?: () => void;
    highlight?: boolean;
    customOnClick?: () => void;
}

export const JobRow: FC<IJobRow> = ({
    job,
    noButtons,
    noSubHead,
    inCard,
    forceOpen,
    smallButtons,
    highlight,
    customOnClick,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const { userData, isLoggedIn } = useFireBase();
    const { width } = useDimensions();
    // local state
    const { handleApply, handleQuickPublish, isOwner, application } =
        useJobRowState({
            job,
        });
    /**
     * memoized multi button
     */
    const memoizedMultiButton = useMemo(
        () => (
            <CMultiButton
                buttons={[
                    {
                        icon: 'cog',
                        onPress: () =>
                            navigate('/japplication/view/' + job.documentId),
                        title: format(jobMessages.viewJob),
                    },
                ]}
            />
        ),
        [job],
    );
    if (width < 720) {
        return (
            <JobRowSlim
                {...{
                    job,
                    noButtons,
                    noSubHead,
                    inCard,
                    forceOpen,
                    smallButtons,
                    highlight,
                    customOnClick,
                    handleApply,
                    handleQuickPublish,
                    isOwner,
                    application,
                    memoizedMultiButton,
                }}
            />
        );
    }
    /**
     * render
     */
    return (
        <>
            <ExpandableCard
                style={
                    highlight ? [style.card, style.accentBorder] : inCard && {}
                }
                head={
                    <View
                        style={[
                            style.flex1,
                            style.horizontalSplit,
                            style.centeredItems,
                            style.horizontalWrap,
                        ]}
                    >
                        <JobTitle job={job} />
                        {!noButtons && (
                            <View
                                style={[
                                    style.horizontal,
                                    {
                                        alignSelf: 'flex-end',
                                        alignContent: 'flex-end',
                                    },
                                ]}
                            >
                                {isSuperUser(userData) && (
                                    <View
                                        style={[
                                            style.verticalPadded,
                                            style.horizontalPadded,
                                        ]}
                                    >
                                        <CImage
                                            image={job.region}
                                            fixedHeight={20}
                                        />
                                    </View>
                                )}
                                <View>
                                    {isOwner || isSuperUser(userData) ? (
                                        <View
                                            style={[
                                                style.horizontal,
                                                style.centeredItems,
                                            ]}
                                        >
                                            <CButton
                                                minor
                                                small
                                                title={format(
                                                    jobMessages[job.status],
                                                )}
                                                onPress={handleQuickPublish}
                                            />
                                            {memoizedMultiButton}
                                        </View>
                                    ) : !application ||
                                      application.status ===
                                          EApplicationStatus.closed ? (
                                        <CButton
                                            cy={`apply-for-${job.documentId}`}
                                            title={format(actionMessages.apply)}
                                            onPress={handleApply}
                                            small={smallButtons}
                                        />
                                    ) : (
                                        <CLabel
                                            title={
                                                application.status !==
                                                EApplicationStatus.rejected
                                                    ? jobMessages.applied
                                                    : jobMessages.applicationRejected
                                            }
                                            color={
                                                application.status !==
                                                EApplicationStatus.rejected
                                                    ? theme.successColor
                                                    : theme.errorColor
                                            }
                                            startIcon={
                                                application.status !==
                                                EApplicationStatus.rejected
                                                    ? 'check'
                                                    : 'close'
                                            }
                                            small={smallButtons}
                                        />
                                    )}
                                    {job.id && (
                                        <CText
                                            style={[
                                                {
                                                    textAlign: 'right',
                                                    paddingTop: 10,
                                                },
                                                style.horizontalPadded,
                                            ]}
                                        >
                                            {`${format(jobMessages.id)}: ${
                                                job.id
                                            }`}
                                        </CText>
                                    )}
                                </View>
                            </View>
                        )}
                    </View>
                }
                subHead={!noSubHead && <JobRowSubHead job={job} showDistance />}
                body={<JobRowBody job={job} />}
                customExpand={forceOpen}
                customOnClick={
                    (!isLoggedIn &&
                        (() => navigate('/job/view/' + job.documentId))) ||
                    customOnClick
                }
            />
            {inCard && <View style={style.paddedThinSeparator} />}
        </>
    );
};
