import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { DocumentLocationPicker } from '../../components/DocumentLocationPicker/DocumentLocationPicker';
import { useNavigate, useSearchParams } from '../../utilities/routing';
import { CButton, CCard, CText, InfoBox, Spinner } from '../../components';
import { MContractFile, MFile, MSignaturePosition } from '../../models';
import { useStyle } from '../../utilities/styles';
import { requestSignatureMessages } from './requestSignature.messages';
import { ScrollProvider } from '../../utilities/contexts';
import { ECollections, EUserType } from '../../enums';
import { useFireBase } from '../../utilities/firebase';
import { isEmployer } from '../../utilities/auth';
import { PositionRow } from './components/PositionRow';

export const RequestSignature: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const { userData, getDataById } = useFireBase();
    const [searchParams] = useSearchParams();

    const [files, setFiles] = useState<MContractFile[]>([]);
    const [file, setFile] = useState<MFile>();

    const [positions, setPositions] = useState<MSignaturePosition[]>([
        new MSignaturePosition({ type: EUserType.employer }),
        new MSignaturePosition({ type: EUserType.user }),
    ]);

    const userPositions = useMemo(() => {
        const filteredPositions = positions.filter(
            (p) => p.type === EUserType.user,
        );

        return filteredPositions.map((p) => {
            return { position: p, index: positions.indexOf(p) };
        });
    }, [positions]);

    const employerPositions = useMemo(() => {
        const filteredPositions = positions.filter(
            (p) => p.type === EUserType.employer,
        );

        return filteredPositions.map((p) => {
            return { position: p, index: positions.indexOf(p) };
        });
    }, [positions]);

    const hasOnePositionForBoth = useMemo(
        () =>
            userPositions.filter((p) => p.position.x || p.position.y).length &&
            employerPositions.filter((p) => p.position.x || p.position.y)
                .length,
        [employerPositions, userPositions],
    );

    const [selectedIndex, setSelectedIndex] = useState(0);

    const currentPosition = useMemo(() => {
        const next = positions[selectedIndex];
        if (next) {
            return next;
        }
        return undefined;
    }, [positions, selectedIndex]);

    const removePosition = useCallback(
        (index: number) => {
            setPositions((prev) => {
                const next = [...prev];
                next.splice(selectedIndex, 1);
                return next;
            });
            if (selectedIndex === index) {
                setSelectedIndex(0);
            }
        },
        [selectedIndex],
    );

    const addPosition = useCallback((type: EUserType) => {
        setPositions((prev) => {
            const next = [
                ...prev,
                new MSignaturePosition({
                    type,
                }),
            ];
            setSelectedIndex(next.length - 1);
            return next;
        });
    }, []);

    useEffect(() => {
        const nextCID = searchParams.get('contractId');
        const fid = searchParams.get('fid');
        if (fid && nextCID) {
            Promise.all(
                fid
                    .split(',')
                    .map(
                        async (id) =>
                            await getDataById(
                                `${ECollections.contracts}/${nextCID}/${ECollections.files}`,
                                id,
                            ),
                    ),
            ).then((results) => {
                setFiles(results.map((r) => new MContractFile(r)));
                setFile(new MContractFile(results[0]));
            });
        }
    }, [searchParams]);

    if (!file) {
        return <Spinner />;
    }

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            navigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={style.horizontalPadded}
                        headline
                        message={
                            requestSignatureMessages.selectPositionsOnDocument
                        }
                    />
                </View>
            </View>
            {files.length > 1 && (
                <View style={style.card}>
                    {files.map((f) => (
                        <CButton
                            key={f.documentId}
                            title={f.path}
                            onPress={() => {
                                setFile(f);
                            }}
                        />
                    ))}
                </View>
            )}
            {!hasOnePositionForBoth && (
                <View style={style.card}>
                    <InfoBox
                        message={
                            requestSignatureMessages.selectPositionsOnDocumentHint
                        }
                        type={'warning'}
                    />
                </View>
            )}
            <View style={[style.verticalPadded, style.horizontalSpaced]}>
                <View style={{ flexGrow: 1 }}>
                    <CCard>
                        <CText
                            message={requestSignatureMessages.yourPositions}
                            secondaryHeadline
                        />
                    </CCard>
                    {(isEmployer(userData)
                        ? employerPositions
                        : userPositions
                    ).map(({ position, index }) => (
                        <PositionRow
                            key={index}
                            active={index === selectedIndex}
                            position={position}
                            onPress={() => setSelectedIndex(index)}
                            onRemove={() => removePosition(index)}
                        />
                    ))}
                    <CButton
                        onPress={() =>
                            addPosition(
                                isEmployer(userData)
                                    ? EUserType.employer
                                    : EUserType.user,
                            )
                        }
                        icon="plus"
                    />
                </View>
                <View style={style.card}>
                    <DocumentLocationPicker
                        filename={file.path}
                        position={currentPosition}
                        onPosition={(pos) =>
                            setPositions((prev) => {
                                const next = [...prev];
                                next.splice(
                                    selectedIndex,
                                    1,
                                    new MSignaturePosition({
                                        ...currentPosition,
                                        ...pos,
                                        fileId: file.documentId,
                                        filename: file.path,
                                    }),
                                );
                                return next;
                            })
                        }
                    />
                </View>
                <View style={{ flexGrow: 1 }}>
                    <CCard>
                        <CText
                            message={requestSignatureMessages.otherPositions}
                            secondaryHeadline
                        />
                    </CCard>
                    {(isEmployer(userData)
                        ? userPositions
                        : employerPositions
                    ).map(({ position, index }) => (
                        <PositionRow
                            key={index}
                            active={index === selectedIndex}
                            position={position}
                            onPress={() => setSelectedIndex(index)}
                            onRemove={() => removePosition(index)}
                        />
                    ))}
                    <CButton
                        onPress={() =>
                            addPosition(
                                isEmployer(userData)
                                    ? EUserType.user
                                    : EUserType.employer,
                            )
                        }
                        icon="plus"
                    />
                </View>
            </View>
            <View style={[style.horizontalSpaced, style.verticalPadded]}>
                <CButton
                    title={requestSignatureMessages.confirmSelection}
                    onPress={() => {
                        const prev = searchParams.get('prev');
                        const pstring = positions
                            .map((p) =>
                                JSON.stringify({
                                    x: p.x,
                                    y: p.y,
                                    page: p.page,
                                    type: p.type,
                                    fileId: p.fileId,
                                }),
                            )
                            .join(',');
                        navigate(
                            `${prev}?&posdata=[${pstring}]&fid=${files
                                .map((f) => f.documentId)
                                .join(',')}`,
                        );
                    }}
                    disabled={!hasOnePositionForBoth}
                />
            </View>
        </ScrollProvider>
    );
};
