import React, { FC } from 'react';
import { Pressable, Image } from 'react-native';
import { getAsset } from '../../../assets';
import { CText } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { EFBSignInTypes } from '../../../utilities/firebase/auth/EFBSignInTypes';
import { useFormat } from '../../../utilities/intl';
import { useTheme } from '../../../utilities/styles';
import { startMessages } from '../start.messages';

export const GoogleButton: FC = () => {
    const firebase = useFireBase();
    const format = useFormat();
    const { isDark, theme } = useTheme();
    return (
        <Pressable
            onPress={() => {
                firebase.logIn({
                    type: EFBSignInTypes.google,
                });
            }}
            style={{
                borderRadius: 10,
                paddingHorizontal: 25,
                margin: 5,
                minHeight: 40,
                justifyContent: 'center',
                zIndex: 2,
                shadowColor: theme.shadowColor,
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.18,
                shadowRadius: 1.0,
                elevation: 1,
                backgroundColor: isDark ? '#4285F4' : '#ffffff',
                borderColor: isDark ? '' : '#757575',
                borderWidth: 0,
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Image
                style={{ width: 24, height: 24 }}
                source={getAsset('gLogo')}
            />
            <CText
                style={{
                    color: isDark ? '#ffffff' : '#757575',
                    fontFamily: 'Roboto',
                    marginLeft: 10,
                }}
            >
                {format(startMessages.signInWithGoogle)}
            </CText>
        </Pressable>
    );
};
