import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CText, Spinner } from '../../components';
import { useFormat } from '../../utilities/intl';
import { useStyle } from '../../utilities/styles';
import { generalMessages } from '../../utilities/messages';
import { JobApplicationRow } from './components/JobApplicationRow';
import { jobMessages } from '../Job/Job.messages';
import { useJobApplicationsState } from './useJobApplicationState';
import { CImage } from '../../components/elements/CImage';
import { WorkplaceZipCodeTypeahead } from '../../components/Typeahead/WorkplaceZipCodeTypeahead';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { JobsContext } from '../Job/List/JobList';
import { EJobStatus } from '../../enums';
import { jobApplicationMessages } from './JobApplication.messages';
import { JobApplicationFilterButton } from './components/JobApplicationFilterButton';
import { isSuperUser } from '../../utilities/auth';
import { WorkplaceMultiPicker } from './components/WorkplaceMultiPicker';
import { useFireBase } from '../../utilities/firebase';

export const JobApplications: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { userData } = useFireBase();

    const {
        loading,
        jobs,
        curWorkplaces,
        setCurWorkplaces,
        issueReload,
        setStatusFilter,
        statusFilter,
        handleCreate,
    } = useJobApplicationsState();

    if (loading) {
        return <Spinner />;
    }

    return (
        <JobsContext.Provider
            value={{ reload: issueReload, loadOn: Date.now() }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <View style={[style.card, { zIndex: 100 }]}>
                    <View style={[style.horizontalSplit, style.horizontalWrap]}>
                        <CText
                            headline
                            message={generalMessages.jobApplications}
                        />
                        <CButton
                            cy={'create-job'}
                            icon={'plus'}
                            title={format(jobMessages.createNew)}
                            onPress={handleCreate}
                        />
                    </View>
                    {isSuperUser(userData) ? (
                        <WorkplaceZipCodeTypeahead
                            currentWorkplace={curWorkplaces[0]}
                            onChangeWorkplace={(wp) => setCurWorkplaces([wp])}
                        />
                    ) : (
                        <WorkplaceMultiPicker
                            currentWorkplaces={curWorkplaces}
                            onChangeCurrentWorkplaces={setCurWorkplaces}
                        />
                    )}
                    <View style={style.horizontalWrap}>
                        {Object.values(EJobStatus)
                            .filter((status) => status !== EJobStatus.deleted)
                            .map((status) => (
                                <JobApplicationFilterButton
                                    key={status}
                                    workplaces={curWorkplaces}
                                    status={status}
                                    statusFilter={statusFilter}
                                    onPress={setStatusFilter}
                                />
                            ))}
                    </View>
                </View>
                {jobs.map((job) => (
                    <JobApplicationRow key={job.documentId} job={job} />
                ))}
                {!jobs.length && (
                    <View style={style.card}>
                        <CImage image="search" />
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CText bold centered headline>
                                {format(jobApplicationMessages.currentlyNoJobs)}
                            </CText>
                        </View>
                    </View>
                )}
            </ScrollProvider>
        </JobsContext.Provider>
    );
};
