import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CText } from '../../../components';
import { ECollections, EJobStatus } from '../../../enums';
import { isEmployer } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { dashboardMessages } from '../Dashboard.messages';
/**
 * displace todays new application counter
 * @returns
 */
export const ActiveJobs: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { userData, getDataIndex, userWorkplaces } = useFireBase();

    const [count, setCount] = useState(0);
    /**
     * load count
     */
    useEffect(() => {
        if (isEmployer(userData)) {
            getDataIndex(ECollections.jobs, {
                filter: [
                    {
                        field: 'workPlaceId',
                        operator: 'in',
                        value: userWorkplaces.map((wp) => wp.documentId),
                    },
                    {
                        field: 'status',
                        operator: 'in',
                        value: [EJobStatus.public, EJobStatus.private],
                    },
                ],
                getLength: true,
            }).then((res) => {
                if (typeof res === 'number') {
                    setCount(res as number);
                }
            });
        }
    }, [userData, userWorkplaces]);
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="jobs-card">
            <View
                style={[
                    style.centeredItems,
                    { justifyContent: 'space-between', maxWidth: '100%' },
                ]}
            >
                <CText bold headline wrap>
                    {format(dashboardMessages.activeJobs)}
                </CText>
                <CText style={[style.importantValue]} bold>
                    {count}
                </CText>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() => navigate('/japplications')}
                    transparent
                    minor
                />
            </View>
        </View>
    );
};
