export enum EEmploymentType {
    fullTime = 'fullTime',
    partTime = 'partTime',
    selfEmployed = 'selfEmployed',
    freelance = 'freelance',
    contract = 'contract',
    personnelLeasing = 'personnelLeasing',
    internship = 'internship',
    apprenticeship = 'apprenticeship',
    civilServiceInternship = 'civilServiceInternship',
    lifetimeCivilServant = 'lifetimeCivilServant',
    coOp = 'coOp',
    workStudy = 'workStudy',
}
