import {
    EApplicationStatus,
    EContractFiles,
    EEnvironment,
    EGeneralFiles,
    EProfession,
    EPureContractFiles,
    ERegion,
    EUserFiles,
    EUserType,
} from '../enums';
import { lightTheme } from './styles/themes';
/**
 * coalition of users that have great to full access to all kind of data
 */
export const SUPERUSERS = [
    EUserType.admin,
    EUserType.moderator,
    EUserType.agent,
];
/**
 * coalition of users that are able to manage content
 */
export const MANAGINGUSERS = [EUserType.employer];
export const AGENCYUSERS = [EUserType.agency];
/**
 * coalition of lowest user types. usable to hide data or apply restrictions
 */
export const LOWUSERS = [EUserType.user, EUserType.agency];

export const NURSECOLOR = '#5a81fa';
export const USERCOLOR = '#5a81fa';
export const AGENCYCOLOR = '#62c1c5';
export const EMPLOYERCOLOR = '#39d08b';
export const MODERATORCOLOR = '#2c3d8f';
export const AGENTCOLOR = '#2c3d8f';
export const ADMINCOLOR = '#e85b55';
/**
 * get color based on usertype
 */
export const getUserTypeColor = (type: EUserType) => {
    switch (type) {
        case EUserType.admin:
            return ADMINCOLOR;
        case EUserType.moderator:
            return MODERATORCOLOR;
        case EUserType.employer:
            return EMPLOYERCOLOR;
        case EUserType.user:
            return USERCOLOR;
        case EUserType.agency:
            return AGENCYCOLOR;
        case EUserType.agent:
            return AGENTCOLOR;
        default:
            return lightTheme.accentColor;
    }
};

export const OPENCOLOR = lightTheme.accentColor;
export const CLOSEDCOLOR = lightTheme.warningColor;
export const ACCEPTEDCOLOR = lightTheme.successColor;
export const REJECTEDCOLOR = lightTheme.errorColor;
/**
 * get color based on applicationstatus
 */
export const getApplicationStatusColor = (type: EApplicationStatus) => {
    switch (type) {
        case EApplicationStatus.open:
            return OPENCOLOR;
        case EApplicationStatus.closed:
            return CLOSEDCOLOR;
        case EApplicationStatus.accepted:
            return ACCEPTEDCOLOR;
        case EApplicationStatus.rejected:
            return REJECTEDCOLOR;
        default:
            return lightTheme.accentColor;
    }
};

export const EMPLOYMENT_CONTRACT_COLOR = '#FF5733'; // Vibrant orange
export const LEASING_CONTRACT_COLOR = '#49E5E2'; // Teal
export const OTHER_FILE_COLOR = '#FFC300'; // Yellow
/**
 * get color based on file type
 * @param type
 * @returns
 */
export const getContractFileColor = (type: EContractFiles | EUserFiles) => {
    switch (type) {
        case EPureContractFiles.employmentContract:
            return EMPLOYMENT_CONTRACT_COLOR;
        case EPureContractFiles.leasingContract:
            return LEASING_CONTRACT_COLOR;
        case EGeneralFiles.other:
            return OTHER_FILE_COLOR;
        default:
            return OTHER_FILE_COLOR;
    }
};
/**
 *  funciton to get the base url for the different environments
 * @param environment environment to get baseurl for
 * @returns base url
 */
export const getEnvironmentUrl = (environment?: EEnvironment) => {
    switch (environment) {
        case EEnvironment.SITUS:
            return 'app.situsdocs';
        case EEnvironment.TimePloyees:
            return 'app.timeployees';
        default:
            return 'localhost:3000';
    }
};
/**
 * function to get ull url for selected region and environment
 * @param region region for url
 * @param environment environment for baseurl
 * @returns
 */
export const getRegionUrl = (region: ERegion, environment: EEnvironment) => {
    switch (region) {
        case ERegion.de:
            return getEnvironmentUrl(environment) + '.de';
        case ERegion.za:
            return getEnvironmentUrl(environment) + '.co.za';
        default:
            return 'localhost:3000';
    }
};
/**
 * check if enviorment is web && localhost
 * @returns true if localhost
 */
export const isLocalhost = (): boolean =>
    // @ts-ignore
    window?.location?.hostname === 'localhost';
/**
 * check if should use emulators
 * @returns true if should use emulators
 */
export const shouldUseEmulators = (): boolean =>
    !!(isLocalhost() && process.env.REACT_APP_USE_EMULATORS);
/**
 * width of interest to change between menu styles
 */
export const MOBILEWIDTH = 880;
/**
 * height of header on top @40px
 */
export const HEADERHEIGHT = 40;
/**
 * function to determin if a profession is allowed to use the 'any' education
 * @param p
 * @returns
 */
export const professionIsAllowedToUseAny = (p: EProfession) =>
    [EProfession.teacher].includes(p);
