import React, { FC, useMemo } from 'react';
import { CTable } from '../../../../components';
import { MProfessionalInfo } from '../../../../models';
import {
    genderMessages,
    doctorTitleMessages,
    doctorPositionMessages,
    educationMessages,
    specializationMessages,
} from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useFormat } from '../../../../utilities/intl';
import { jobMessages } from '../../../Job/Job.messages';
/**
 * table that overviews professional relevant user infos
 * @param param0
 * @returns
 */
export const ProfessionalUserTable: FC<{
    profInfos: MProfessionalInfo;
}> = ({ profInfos }) => {
    // globale state
    const format = useFormat();
    // local state
    /**
     * key/values for current user upper table (professional infos)
     */
    const upperTableRows = useMemo(() => {
        const rows: { key: string; value: string }[] = [];
        rows.push({
            key: format(profileMessages.gender),
            value: profInfos.gender
                ? format(genderMessages[profInfos.gender])
                : '',
        });
        rows.push({
            key: format(profileMessages.selectTitle),
            value: profInfos.title
                ? format(doctorTitleMessages[profInfos.title])
                : '',
        });
        const doctorPosition =
            doctorPositionMessages[
                profInfos.position as keyof typeof doctorPositionMessages
            ];
        if (doctorPosition) {
            rows.push({
                key: format(jobMessages.employeePosition),
                value: format(doctorPosition),
            });
        }
        const educations =
            profInfos.educations &&
            profInfos.educations
                .map((education) =>
                    format(
                        educationMessages[
                            education as keyof typeof educationMessages
                        ],
                    ),
                )
                .join(', ');

        rows.push({
            key: format(profileMessages.education),
            value: educations,
        });
        const specs =
            profInfos.specializations &&
            profInfos.specializations
                .map((specialization) =>
                    format(
                        specializationMessages[
                            specialization as keyof typeof specializationMessages
                        ],
                    ),
                )
                .join(', ');
        rows.push({
            key: format(profileMessages.specialization),
            value: specs,
        });
        rows.push({
            key: format(profileMessages.yoe),
            value: `${profInfos.yearsOfExperience}` || '',
        });
        return rows;
    }, [profInfos]);
    /**
     * key/values for more informations that can be expandet
     */
    // const moreTableRows = useMemo(() => {
    //     const rows: { key: string; value: string }[] = [];
    //     return rows;
    // }, [profInfos]);
    /**
     * render
     */
    return (
        <CTable
            headers={[{ key: 'key' }, { key: 'value' }]}
            hideHeaders
            rows={[...upperTableRows].map((entry) => {
                return {
                    values: [
                        {
                            key: 'key',
                            bold: true,
                            value: entry.key,
                        },
                        {
                            key: 'value',
                            value: entry.value,
                            flex: 3,
                        },
                    ],
                };
            })}
            // moreRows={[...moreTableRows].map((entry) => {
            //     return {
            //         values: [
            //             {
            //                 key: 'key',
            //                 bold: true,
            //                 value: entry.key,
            //             },
            //             {
            //                 key: 'value',
            //                 value: entry.value,
            //                 flex: 3,
            //             },
            //         ],
            //     };
            // })}
        />
    );
};
