import { defineMessages } from 'react-intl';

const scope = 'con.availability.';

export const availabilityMessages = defineMessages({
    repeats: {
        id: scope + 'repeats',
        defaultMessage: 'Repeats {x} weeks',
    },
    availableFromWeek: {
        id: scope + 'availableFromWeek',
        defaultMessage: 'Availability starts week {weekNumber}',
    },
    availableFromTo: {
        id: scope + 'availableFromTo',
        defaultMessage: 'Available from {fromDate} until {toDate}',
    },
    request: {
        id: scope + 'request',
        defaultMessage: 'Request',
    },
});
