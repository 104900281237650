import React, { FC } from 'react';
import { Linking, View } from 'react-native';
import { CCard, CIcon, CImage, CText, WageView } from '../../../../components';
import { MContract } from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { jobMessages } from '../../../Job/Job.messages';
import { isSuperUser } from '../../../../utilities/auth';
import { useFireBase } from '../../../../utilities/firebase';
import { ContractTitle } from '../../components/ContractTitle';
import { ContractState } from '../../components/ContractState';

interface IContractHistoryRowProps {
    contract: MContract;
    embedded?: boolean;
    small?: boolean;
    highlight?: boolean;
}

export const ContractHistoryRow: FC<IContractHistoryRowProps> = ({
    contract,
    embedded,
    small,
    highlight,
}) => {
    const style = useStyle();
    const { userData, callFunction, getFileDownloadUrl } = useFireBase();
    const { theme } = useTheme();

    return (
        <CCard
            cy={'contract-' + contract.jobId}
            style={highlight && style.accentBorder}
            onPress={() => {
                callFunction('getContractZip', {
                    contractId: contract.documentId,
                }).then((path) =>
                    getFileDownloadUrl(path).then((url) =>
                        Linking.openURL(url),
                    ),
                );
            }}
            embedded={embedded}
            hoverEffects
        >
            <View style={style.horizontalSplit}>
                <View
                    style={[style.horizontal, style.centeredItems, style.flex1]}
                >
                    <View style={style.flex1}>
                        <ContractTitle contract={contract} />
                        <View style={[style.horizontal, style.centeredItems]}>
                            <CIcon
                                icon="calendar"
                                tint={theme.textSecondaryColor}
                                size={14}
                            />
                            <CText message={jobMessages.fromTo} />
                            <CText style={style.leftPadded}>
                                {/* {new Date(
                                    contract.createdOn,
                                ).toLocaleDateString('de')} */}
                                {`${new Date(contract.from).toLocaleDateString(
                                    'de',
                                )} - ${new Date(contract.to).toLocaleDateString(
                                    'de',
                                )}`}
                            </CText>
                        </View>
                    </View>
                </View>
                <View style={style.horizontal}>
                    {isSuperUser(userData) && (
                        <View
                            style={[
                                style.verticalPadded,
                                style.horizontalPadded,
                            ]}
                        >
                            <CImage image={contract.region} fixedHeight={20} />
                        </View>
                    )}
                    <ContractState contract={contract} />
                </View>
            </View>
            {!small && (
                <View
                    style={[
                        style.verticalPadded,
                        style.horizontalSplit,
                        style.centeredItems,
                    ]}
                >
                    <View style={[style.horizontal]}>
                        <WageView value={contract.wage} horizontal fragment />
                    </View>
                    {/* <View>
                        <CText bold>{format(jobMessages.fromTo)}</CText>
                        <CText>
                            {`${new Date(contract.from).toLocaleDateString(
                                'de',
                            )} - ${new Date(contract.to).toLocaleDateString(
                                'de',
                            )}`}
                        </CText>
                    </View> */}
                </View>
            )}
        </CCard>
    );
};
