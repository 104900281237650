import React, { FC, useMemo } from 'react';
import {
    Bubble,
    Composer,
    GiftedChat,
    InputToolbar,
    LoadEarlier,
    Message,
    Send,
} from 'react-native-gifted-chat';
import { useStyle, useTheme } from '../../../utilities/styles';
import { KeyboardAvoidingView, Linking, Platform, View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { CButton, CText, FilePicker, Spinner } from '../../../components';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { isSuperUser } from '../../../utilities/auth';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { chatMessages } from '../Chat.messages';
import { useFormat } from '../../../utilities/intl';
import { makeCButtonStyles } from '../../../components/elements/CButton/styles';
import { useSecureNavigate } from '../../../utilities/routing';
import { useChatViewState } from './useChatViewState';
import { MChat } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useEnvironment } from '../../../utilities/contexts';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { SupportChatFileList } from './components/SupportChatFileList';
import { ContractTitle } from '../../Contract/components/ContractTitle';
import { EUserType } from '../../../enums';

export interface IChatViewProps {
    chat?: MChat;
    handleFile?: (newFn: string, file: Uint8Array, preFileResult: any) => void;
    preFile?: () => any;
    embedded?: boolean;
}

export const ChatView: FC<IChatViewProps> = (props) => {
    // global state
    const format = useFormat();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const style = useStyle();
    const { width } = useDimensions();
    const { secureNavigate } = useSecureNavigate();
    const dialog = useDialog();
    const { environment } = useEnvironment();
    const cbStyle = useMemo(() => makeCButtonStyles(theme), []);
    // local state
    const {
        chat,
        loading,
        messagesToDisplay,
        onSend,
        participants,
        loadMore,
        messages,
        hasEarlier,
        handleFile,
        preFile,
        enableFileUpload,
        closeSupportRequest,
        supportRequest,
        contract,
        mainCorrespondent,
    } = useChatViewState(props);

    const filePickerComponent = useMemo(
        () =>
            enableFileUpload && handleFile ? (
                <View style={[{ height: 50 }, style.centeredContent]}>
                    <FilePicker onFile={handleFile} preFile={preFile} />
                </View>
            ) : (
                <View />
            ),
        [enableFileUpload, handleFile, preFile],
    );
    /**
     * return spinner during load
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <View
            style={{
                backgroundColor: theme.backgroundSecondaryColor,
                borderRadius: 10,
                flex: 1,
            }}
        >
            {width < 1000 && !props.handleFile && (
                <View style={style.headlineCard}>
                    <View style={style.horizontal}>
                        <CButton
                            onPress={() => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <CText headline style={style.horizontalPadded}>
                            {participants
                                .map((p) => `${p.firstName} ${p.lastName}`)
                                .join(', ')}
                        </CText>
                    </View>
                </View>
            )}
            {mainCorrespondent?.type === EUserType.talent &&
                mainCorrespondent.talentId && (
                    <View style={[style.card, style.horizontalSpaced]}>
                        <CButton
                            small
                            minor
                            title={chatMessages.openProfile}
                            onPress={() =>
                                secureNavigate(
                                    `/profUser/${mainCorrespondent.talentId}`,
                                )
                            }
                        />
                    </View>
                )}
            {chat && contract && !props.embedded && (
                <View style={style.card}>
                    <ContractTitle contract={contract} secondary />
                </View>
            )}
            {chat && !!chat.supportRequestId && !props.embedded && (
                <View style={style.card}>
                    {supportRequest && (
                        <CText secondaryHeadline numberOfLines={1}>
                            {format(chatMessages.supportRequest)}
                            {': '}
                            {supportRequest.reason}
                        </CText>
                    )}
                    <View style={[style.horizontalSplit, style.centeredItems]}>
                        {isSuperUser(userData) && !chat.closedOn ? (
                            <CButton
                                small
                                title={chatMessages.closeSupportRequest}
                                onPress={closeSupportRequest}
                            />
                        ) : (
                            <View />
                        )}
                        <SupportChatFileList
                            supportRequestId={chat.supportRequestId}
                            filePickerComponent={filePickerComponent}
                        />
                    </View>
                </View>
            )}
            <KeyboardAvoidingView
                style={{ flex: 1 }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                keyboardVerticalOffset={150}
            >
                <GiftedChat
                    messages={messagesToDisplay}
                    onSend={(messages) => onSend(messages)}
                    user={{
                        _id: userData.documentId,
                    }}
                    timeFormat={'HH:MM'}
                    isKeyboardInternallyHandled={false}
                    renderBubble={(props) => (
                        <Bubble
                            {...props}
                            textStyle={{
                                right: {
                                    color: theme.textAccentColor,
                                    fontFamily: theme.mainFontFamily,
                                },
                                left: {
                                    color: theme.textMainColor,
                                    fontFamily: theme.mainFontFamily,
                                },
                            }}
                            wrapperStyle={{
                                left: {
                                    backgroundColor: theme.backgroundMainColor,
                                },
                                right: {
                                    backgroundColor: theme.accentColor,
                                },
                            }}
                        />
                    )}
                    renderSend={(props) => (
                        <Send
                            {...props}
                            textStyle={[
                                props.textStyle,
                                style.centeredItems,
                                style.centeredContent,
                                {
                                    color: theme.accentColor,
                                    fontFamily: theme.mainFontFamily,
                                },
                            ]}
                            sendButtonProps={
                                {
                                    ...props.sendButtonProps,
                                    dataSet: { cy: 'send-button' },
                                } as any
                            }
                            containerStyle={[
                                props.containerStyle,
                                style.centeredItems,
                                style.centeredContent,
                                {
                                    backgroundColor: theme.backgroundMainColor,
                                    borderWidth: 0,
                                },
                            ]}
                            label={props.text && format(chatMessages.send)}
                        />
                    )}
                    renderComposer={(props: any) => (
                        <Composer
                            {...props}
                            textInputProps={{
                                ...props.textInputProps,
                                onKeyPress: (key) => {
                                    if (
                                        props.text &&
                                        key.nativeEvent.key === 'Enter'
                                    ) {
                                        props.onSend(
                                            { text: props.text.trim() },
                                            true,
                                        );
                                    }
                                },
                                dataSet: { cy: 'composer' },
                            }}
                            textInputStyle={{
                                fontFamily: theme.mainFontFamily,
                                color: theme.textMainColor,
                                margin: 12,
                                marginBottom: 0,
                                padding: 6,
                                fontSize: 15,
                                height: 36,
                            }}
                            placeholder={
                                props.placeholder && !!chat?.closedOn
                                    ? format(chatMessages.chatClosed)
                                    : format(chatMessages.composerPlaceholder)
                            }
                            disableComposer={!!chat?.closedOn}
                        />
                    )}
                    renderInputToolbar={(props) => (
                        <InputToolbar
                            {...props}
                            containerStyle={{
                                backgroundColor: theme.backgroundMainColor,
                                margin: 0,
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10,
                                borderColor: theme.borderColor,
                                borderWidth: 1,
                            }}
                        />
                    )}
                    renderAvatar={(message) => {
                        const participant = participants.find(
                            (p) =>
                                p.documentId ===
                                message.currentMessage?.user._id,
                        );
                        if (participant) {
                            return (
                                <ProfilePicture
                                    data={participant}
                                    smaller
                                    onPress={
                                        isSuperUser(userData)
                                            ? () =>
                                                  secureNavigate(
                                                      `/user/${participant.documentId}`,
                                                  )
                                            : undefined
                                    }
                                />
                            );
                        }
                    }}
                    loadEarlier={hasEarlier}
                    onLoadEarlier={() =>
                        loadMore(messages[messages.length - 1].documentId)
                    }
                    renderLoadEarlier={(props) => (
                        <LoadEarlier
                            {...props}
                            wrapperStyle={cbStyle.minorButton}
                            textStyle={cbStyle.minorButtonText}
                            label={format(chatMessages.loadEarlierMessages)}
                        />
                    )}
                    renderActions={(renderActionProps) => filePickerComponent}
                    renderTime={(props) => {
                        const date = new Date(
                            props.currentMessage?.createdAt || 0,
                        );
                        return (
                            <View>
                                <CText
                                    style={{
                                        marginHorizontal: 10,
                                        marginBottom: 5,
                                        color:
                                            props.position === 'left'
                                                ? 'gray'
                                                : 'white',
                                    }}
                                >
                                    {`${date.toLocaleTimeString('de', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}`}
                                </CText>
                            </View>
                        );
                    }}
                    renderMessage={(props) => {
                        return (
                            <Message
                                {...props}
                                // @ts-ignore
                                linkStyle={{
                                    right: {
                                        color: theme.textAccentColor,
                                    },
                                    left: {
                                        color: theme.textMainColor,
                                    },
                                }}
                            />
                        );
                    }}
                    parsePatterns={(item) => [
                        {
                            type: 'url',
                            style: {
                                textDecorationLine: 'underline',
                            },
                            onPress: async (url: string) => {
                                if (
                                    await dialog({
                                        icon: 'warning',
                                        title: format(generalMessages.openUrl, {
                                            platform: environment,
                                        }),
                                        message: generalMessages.openUrlText,
                                        buttons: [
                                            { text: actionMessages.open },
                                        ],
                                        cancelButton: {
                                            text: actionMessages.cancel,
                                        },
                                    })
                                ) {
                                    if (url.includes('http')) {
                                        Linking.openURL(url);
                                    } else {
                                        Linking.openURL('https://' + url);
                                    }
                                }
                            },
                        },
                    ]}
                />
            </KeyboardAvoidingView>
        </View>
    );
};
