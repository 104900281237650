import { useCallback } from 'react';
import { actionMessages, generalMessages } from '../messages';
import { useDialog } from './useDialog';
import { useTheme } from '../styles';

export const useUnsavedChangesDialog = () => {
    const dialog = useDialog();
    const { theme } = useTheme();
    const adminDialog = useCallback(async () => {
        return dialog({
            title: generalMessages.unsavedChanges,
            message: generalMessages.ifContinueTheyDiscard,
            cancelButton: {
                text: actionMessages.back,
            },
            buttons: [
                {
                    text: actionMessages.continueAndDiscard,
                    color: theme.errorColor,
                },
            ],
            icon: 'warning',
        });
    }, [dialog]);

    return adminDialog;
};
