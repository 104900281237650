import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CPicker, CText } from '../../../../components';
import { FilePicker } from '../../../../components/FilePicker';
import {
    EProfession,
    EDoctorFiles,
    EGeneralFiles,
    EUserFiles,
    ECollections,
    EEmployeeFiles,
} from '../../../../enums';
import { useFireBase } from '../../../../utilities/firebase';
import { useFormat } from '../../../../utilities/intl';
import {
    filenameMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { ProfileContext } from '../../../Profile/Profile.context';
import { MUserFile } from '../../../../models';
import { useDialog } from '../../../../utilities/dialog';
import { useLock } from '../../../../utilities/hooks';
/**
 * view to display documents and upload
 * @param param0
 * @returns
 */
export const UnsaveProfessionalDocuments: FC<{
    agencyId: string;
}> = ({ agencyId }) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { uploadToStorage, callFunction } = useFireBase();
    const { curData, onChange } = useContext(ProfileContext);
    const dialog = useDialog();
    const { lock } = useLock();
    // local state
    const [currentUploadable, setCurrentUploadable] = useState<EUserFiles>(
        EEmployeeFiles.resume,
    );
    /**
     * memoized list of accessible documents
     */
    const docs = useMemo(() => {
        const generalFiles: string[] = Object.values(EGeneralFiles);
        const employeeFiles: string[] = Object.values(EEmployeeFiles);
        const next = [...generalFiles, ...employeeFiles];
        if (curData.profession === EProfession.doctor) {
            next.push(...Object.values(EDoctorFiles));
        } else if (curData.profession === EProfession.nurse) {
            // * No nurse files
        }
        return next;
    }, [curData]);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            const unlock = lock();
            const fnS = newFn.split('.');
            const ending = fnS.pop();
            if (ending !== 'pdf') {
                throw 'Only PDF upload allowed';
            }
            // TODO: better name generation
            const fakeId = Date.now().toString(36);
            const basename = fakeId + fnS.join('.');
            try {
                const preassignMeta = await uploadToStorage(
                    `${ECollections.agencies}/${agencyId}/profiles/${curData.documentId}/tmp/unsaveupload.${ending}`,
                    file,
                );

                const fullPath = await callFunction('assignProfProfileFile', {
                    preassignMeta,
                    basename: basename,
                });
                onChange({
                    files: [
                        ...curData.files,
                        new MUserFile({
                            documentId: fakeId,
                            type: currentUploadable,
                            name: basename,
                            path: fullPath,
                        }),
                    ],
                });
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [currentUploadable, curData, agencyId],
    );
    /**
     * render
     */
    return (
        <>
            <View style={[style.horizontalWrap, style.centeredItems]}>
                <View style={[style.flex1]}>
                    <CPicker
                        onChange={setCurrentUploadable}
                        values={docs
                            .map((d) => {
                                return {
                                    label: format(
                                        filenameMessages[d as EUserFiles],
                                    ),
                                    value: d,
                                };
                            })
                            .sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={currentUploadable}
                        title={format(profileMessages.documentToUpload)}
                    />
                </View>
                <View
                    // adjustment to be inline with picker
                    style={{
                        justifyContent: 'flex-end',
                        paddingBottom: 5,
                        alignSelf: 'stretch',
                    }}
                >
                    <FilePicker onFile={handleFile} />
                </View>
            </View>
            {(curData.files || []).map((f, i) => {
                if (!docs.includes(f.type)) {
                    return;
                }
                return (
                    <View
                        key={i}
                        style={[style.horizontal, style.centeredItems]}
                    >
                        <View style={style.horizontalPadded} />
                        <CText numberOfLines={1} bold>
                            {format(filenameMessages[f.type])}
                        </CText>
                    </View>
                );
            })}
        </>
    );
};
