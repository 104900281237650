import { useCallback } from 'react';
import { useDialog } from '../../../../utilities/dialog';
import { useFireBase } from '../../../../utilities/firebase';
import { contractMessages } from '../../contract.messages';
import { useLock } from '../../../../utilities/hooks';
import { MContract, MFile, MProfessionalProfile } from '../../../../models';
import { isAgencyUser, isPeasant } from '../../../../utilities/auth';
import { ECollections } from '../../../../enums';
import {
    actionMessages,
    filenameMessages,
} from '../../../../utilities/messages';
import { useFormat } from '../../../../utilities/intl';
/**
 * shows dialog for all known file
 * after dialog initiates copy
 * @param dialog
 * @param userData
 */
export const useCopyFileToContract = (
    contractId: string | undefined,
    setReload: (next: number) => void,
) => {
    const { callFunction, userData, getDataById } = useFireBase();
    const dialog = useDialog();
    const format = useFormat();
    const { lock } = useLock();

    return useCallback(async () => {
        if (!contractId) return false;
        const unlock = lock();
        const files: MFile[] = [];

        files.push(...userData.files);

        const contract = new MContract(
            await getDataById(ECollections.contracts, contractId),
        );

        if (isAgencyUser(userData) && contract.profileId) {
            const profile = new MProfessionalProfile(
                await getDataById(
                    ECollections.profProfiles,
                    contract.profileId,
                ),
            );

            files.push(...profile.files);
        }
        unlock();
        if (!files.length) return false;
        if (
            !isPeasant(userData) ||
            !(await dialog({
                icon: 'question',
                title: contractMessages.chooseUploadOrCopy,
                message: contractMessages.chooseUploadOrCopyText,
                buttons: [{ text: contractMessages.copyAFile }],
                cancelButton: { text: contractMessages.uploadAFile },
                verticalButtons: true,
            }))
        )
            return false;
        const filesToCopy: MFile[] = [];

        await dialog({
            title: contractMessages.pickFilesToCopy,
            multiRadioInput: {
                id: 'filesToCopy',
                title: contractMessages.pickFilesToCopy,
                values: files.map((f) => ({
                    value: f.documentId,
                    label: `${f.name} - ${format(filenameMessages[f.type])}`,
                    id: f.documentId,
                })),
            },
            buttons: [
                {
                    text: contractMessages.copySelectedFiles,
                    onPress: (inputs) => {
                        const efi = inputs?.find((v) => v.id === 'filesToCopy');
                        if (efi) {
                            for (const fid of efi.value as string[]) {
                                const next = files.find(
                                    (f) => f.documentId === fid,
                                );
                                if (next) {
                                    filesToCopy.push(next);
                                }
                            }
                        }
                    },
                    disabled: (inputs) => {
                        const efi = inputs?.find((v) => v.id === 'filesToCopy');
                        return !efi?.value || !(efi?.value as string[]).length;
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });

        if (filesToCopy.length) {
            const unlock2 = lock();
            await callFunction('copyFilesToContract', {
                contractId,
                filesToCopy,
            });
            unlock2();
            setReload(Date.now());
        }
        return true;
    }, [dialog, callFunction, userData, contractId, setReload]);
};
