import React, { FC } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CDatePicker,
    CPicker,
    CTextInput,
} from '../../../../components';
import { EEmploymentType } from '../../../../enums/EEmploymentTypes';
import { useFormat } from '../../../../utilities/intl';
import { employmentTypeMessages } from '../../../../utilities/messages/employmentType.messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { IEmploymentProps } from './EmploymentView';

interface IEmploymentEditProps extends IEmploymentProps {
    endEdit: () => void;
}

export const EmploymentEdit: FC<IEmploymentEditProps> = ({
    onChange,
    employment,
    endEdit,
    zIndex,
}) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <View
            style={{
                zIndex,
                borderWidth: 1,
                borderColor: theme.borderColor,
                borderRadius: 5,
                padding: 10,
                marginVertical: 10,
            }}
        >
            <CTextInput
                value={employment.title}
                onChangeText={(title) =>
                    onChange({
                        title,
                    })
                }
                placeholder={format(profileMessages.employmentTitlePlaceholder)}
                label={format(profileMessages.employmentTitle)}
                autoExtend
            />
            <CPicker
                title={format(profileMessages.employmentType)}
                values={[
                    {
                        label: format(
                            profileMessages.employmentTypePlaceholder,
                        ),
                        value: undefined,
                        hidden: true,
                    },
                    ...Object.values(EEmploymentType).map((v) => {
                        return {
                            label: format(employmentTypeMessages[v]),
                            value: v,
                        };
                    }),
                ]}
                value={employment.type}
                onChange={(type) => {
                    onChange({ type });
                }}
            />
            <CTextInput
                value={employment.company}
                onChangeText={(company) =>
                    onChange({
                        company,
                    })
                }
                placeholder={format(profileMessages.companyNamePlaceholder)}
                label={format(profileMessages.companyName)}
                autoExtend
            />
            <CTextInput
                value={employment.location}
                onChangeText={(location) =>
                    onChange({
                        location,
                    })
                }
                placeholder={format(profileMessages.locationPlaceholder)}
                label={format(profileMessages.location)}
                autoExtend
            />

            <View style={[style.horizontal, { zIndex: 23 }]}>
                <CDatePicker
                    title={format(profileMessages.from)}
                    value={
                        employment.from ? new Date(employment.from) : undefined
                    }
                    onChange={(next) => onChange({ from: next.getTime() })}
                    zIndex={22}
                />
                <CDatePicker
                    title={format(profileMessages.to)}
                    value={employment.to ? new Date(employment.to) : undefined}
                    onChange={(next) => onChange({ to: next.getTime() })}
                    minDate={employment.from}
                />
            </View>
            <View style={style.horizontalSpaced}>
                <CButton
                    iconColor={theme.successColor}
                    title={format(profileMessages.finishEditing)}
                    transparent
                    icon={'check'}
                    onPress={endEdit}
                />
            </View>
        </View>
    );
};
