import { FullMetadata } from 'firebase/storage';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import QRCode from 'react-qr-code';
import { CButton, CText, FilePicker, Spinner } from '../../../../../components';
import PDFViewer from '../../../../../components/PDFViewer';
import { Signature } from '../../../../../components/Signature';
import { ECollections, EUserType } from '../../../../../enums';
import { isPeasant } from '../../../../../utilities/auth';
import { isLocalhost } from '../../../../../utilities/constants';
import { useEnvironment } from '../../../../../utilities/contexts';
import { useFireBase } from '../../../../../utilities/firebase';
import { capitalize, useFormat } from '../../../../../utilities/intl';
import { actionMessages } from '../../../../../utilities/messages';
import { useStyle } from '../../../../../utilities/styles';
import { contractMessages } from '../../../contract.messages';
import { MContract, MSignaturePosition } from '../../../../../models';
import { ContractNegotiationContext } from '../ContractNegotiationContext';
import { useFileUpload } from '../../../../../utilities/hooks/useFileUpload';
/**
 * sign the contract from both sides
 * @param param0 props
 * @returns
 */
export const SignContract: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { environment } = useEnvironment();
    const { callFunction, getFileMetadata, userData, getDataById } =
        useFireBase();
    const { negotiation, handleClose, onChange } = useContext(
        ContractNegotiationContext,
    );
    const fileUpload = useFileUpload();
    // local state
    const [didRead, setDidRead] = useState(false);
    const [contractFilename, setContractFilename] = useState<string>();
    const [contractMeta, setContractMeta] = useState<FullMetadata>();
    /**
     * memoized sign url for qr code
     */
    const signUrl = useMemo(() => {
        if (contractFilename) {
            const url =
                (isLocalhost()
                    ? 'localhost:3000'
                    : environment === 'SITUS'
                    ? 'app.situsdocs.de'
                    : '') +
                `/sign/contract/${negotiation.documentId}/${
                    contractFilename.split('.')[0].split('_')[1]
                }`;
            console.log(url);
            return url;
        } else {
            return '';
        }
    }, [environment, contractFilename]);
    /**
     * memoized loading value. returns true while filename or meta is unset
     */
    const loading = useMemo(
        () => !contractFilename || !contractMeta,
        [contractFilename, contractMeta],
    );
    /**
     * memoized value if user has signed by metaData (set by sign fcf)
     */
    const hasSigned = useMemo(() => {
        if (contractMeta) {
            const cM = contractMeta.customMetadata;
            if (cM) {
                const signaturePositions: MSignaturePosition[] = JSON.parse(
                    cM.signaturePositions,
                );
                return !!signaturePositions.find(
                    (sP) =>
                        (userData.type === EUserType.agency
                            ? sP.type === EUserType.user
                            : sP.type === userData.type) && sP.signed,
                );
            }
        }
        return false;
    }, [contractMeta, userData]);
    /**
     * handle filename
     */
    const handleFilename = useCallback((fn: string) => {
        setContractFilename(`${fn}`);
        getFileMetadata(fn).then((metaRes) => {
            setContractMeta(metaRes);
        });
    }, []);
    /**
     * handle signature
     */
    const handleSignature = useCallback(
        async (signature: string) => {
            if (contractFilename) {
                await callFunction('signContract', {
                    contractFilename,
                    signature,
                    contractId: negotiation.documentId,
                });
                handleFilename(contractFilename);
                const next = await getDataById(
                    ECollections.contracts,
                    negotiation.documentId,
                );
                onChange(new MContract(next));
            }
        },
        [negotiation, handleFilename, contractFilename],
    );
    /**
     * callback to handle a new file upload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            fileUpload(
                `contracts/${negotiation.documentId}/${userData.documentId}`,
                newFn,
                file,
            );
        },
        [negotiation, userData, fileUpload],
    );
    /**
     * effect to create contract / get read access and get metadata
     */
    useEffect(() => {
        callFunction('getContractDocument', {
            contractId: negotiation.documentId,
            negotiation,
        }).then((res) => {
            console.log(res);
            if (res) {
                handleFilename(res);
            }
        });
    }, []);
    /**
     * while document not present return spinner
     */
    if (loading) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <View>
            {contractFilename && (
                <View style={style.card}>
                    <PDFViewer
                        filename={contractFilename}
                        onReachedLastPage={() => setDidRead(true)}
                    />
                </View>
            )}
            {contractFilename &&
            (userData.type === EUserType.employer || isPeasant(userData)) &&
            !hasSigned ? (
                <>
                    <View style={style.card}>
                        <CText secondaryHeadline>
                            {format(contractMessages.multipleWaysToSign)}
                        </CText>
                        <CText>
                            - {format(contractMessages.signInBrowser)}
                        </CText>
                        <CText>- {format(contractMessages.signInMobile)}</CText>
                        <CText>
                            - {format(contractMessages.signInReality)}
                        </CText>
                    </View>
                    <View
                        style={[
                            style.card,
                            style.horizontal,
                            style.centeredContent,
                            style.centeredItems,
                        ]}
                    >
                        <CText>
                            {format(contractMessages.uploadSignedDocument)}
                        </CText>
                        <FilePicker onFile={handleFile} />
                    </View>
                    <View style={style.horizontal}>
                        <View style={[style.card, { flex: 1 }]}>
                            <Signature
                                text={capitalize(format(actionMessages.sign))}
                                onOK={handleSignature}
                                disabled={!didRead}
                            />
                        </View>
                        <View
                            style={[
                                style.card,
                                {
                                    minWidth: 0,
                                    maxWidth: 250,
                                    alignItems: 'center',
                                },
                            ]}
                        >
                            <CText style={style.verticalPadded} centered>
                                {format(contractMessages.scanToSignOnMobile)}
                            </CText>
                            <QRCode value={signUrl} size={150} />
                        </View>
                    </View>
                </>
            ) : (
                <View style={[style.centeredItems, style.verticalPadded]}>
                    <CText>{format(contractMessages.alreadySigned)}</CText>
                </View>
            )}
            <View style={style.horizontalSpaced}>
                <CButton
                    title={format(actionMessages.cancel)}
                    onPress={handleClose}
                    warning
                />
            </View>
        </View>
    );
};
