import React, {
    FC,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { View } from 'react-native';
import RCalendar from 'react-calendar';

import './Calendar.css';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import { ICCalendarProps } from './ICCalendarProps';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../../../utilities/functions';
import { useStyle, useTheme } from '../../../utilities/styles';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { CText } from '../CText';
import { calendarMessages } from './calendar.messages';
import { useFireBase } from '../../../utilities/firebase';
import { isAgencyUser, isEmployer } from '../../../utilities/auth';
import { EUserType } from '../../../enums';

const radius = 8;
const offset = 24;
const bubbleStyle = {
    width: radius,
    height: radius,
    borderRadius: radius / 2,
    marginHorizontal: 2,
};
/**
 * web calendar
 * @param param0
 * @returns
 */
export const CCalendar: FC<ICCalendarProps> = ({
    curDate,
    setCurDate,
    availabilities,
    negotiations,
    contracts,
    range,
    jobs,
}) => {
    // global state
    const { language } = useContext(LanguageContext);
    const style = useStyle();
    const { theme } = useTheme();
    const { width } = useDimensions();
    const { userData } = useFireBase();
    /**
     * stolen code for activeStart date magic
     */
    const [startDate, setStartDate] = useState<Date>();
    const refCalendarContainer = useRef<View>(null);
    const disableActiveStartDate = () => {
        setTimeout(() => setStartDate(undefined));
    };
    /**
     * stolen effect to reset start date if cur date changes
     */
    useEffect(() => {
        if (curDate instanceof Date) {
            setStartDate(curDate);
        }
    }, [curDate]);
    /**
     * stolen effect to hook onto disabling activeStartDate
     */
    useEffect(() => {
        // @ts-ignore
        const refNode = refCalendarContainer.current as HTMLDivElement;
        // @ts-ignore
        const navigationButtons = refNode.querySelectorAll(
            '.react-calendar__navigation__arrow',
        );

        navigationButtons.forEach((button: any) => {
            button.addEventListener('click', disableActiveStartDate);
        });

        return () => {
            if (refNode) {
                navigationButtons.forEach((button: any) => {
                    button.removeEventListener('click', disableActiveStartDate);
                });
            }
        };
    }, []);
    /**
     * memoize days in negotiation
     */
    const negotiationDays = useMemo(() => {
        const t = (negotiations || []).reduce((curr, negotiation) => {
            const daysBetween = [] as Date[];
            let temp = negotiation.from + day + 1;
            while (temp < negotiation.to) {
                daysBetween.push(new Date(temp));
                temp += day;
            }
            const from = new Date(negotiation.from);
            const to = new Date(negotiation.to);
            return [
                ...curr,
                ...[...daysBetween, from, to].map((d) =>
                    d.toLocaleDateString('de'),
                ),
            ];
        }, [] as string[]);
        return t;
    }, [negotiations]);
    /**
     * memoized contract days
     */
    const contractDays = useMemo(() => {
        const t = (contracts || []).reduce((curr, contract) => {
            const daysBetween = [] as Date[];
            let temp = contract.from + 1;
            while (temp < contract.to) {
                daysBetween.push(new Date(temp));
                temp += day;
            }
            const from = new Date(contract.from);
            const to = new Date(contract.to);
            return [
                ...curr,
                ...[...daysBetween, from, to].map((d) =>
                    d.toLocaleDateString('de'),
                ),
            ];
        }, [] as string[]);
        return t;
    }, [contracts]);
    /**
     * memoized contract days
     */
    const jobDays = useMemo(() => {
        if (!jobs) {
            return [];
        }
        return jobs
            .filter(
                (j) => !(contracts || []).find((c) => c.jobId === j.documentId),
            )
            .filter(
                (j) =>
                    !(negotiations || []).find((c) => c.jobId === j.documentId),
            )
            .reduce((curr, job) => {
                return Array.from(new Set([...curr, ...[...job.activeDates]]));
            }, [] as string[]);
    }, [jobs]);
    /**
     * memoice available days
     */
    const availableDays = useMemo(() => {
        const t = availabilities
            .map((a) => {
                const toReturn: Date[] = [];
                const startDate = convertWeekAndYearToDate(a.year, a.start);
                a.days.forEach((d) => {
                    const dayOffset = (d.day - 1) * day;
                    let weekStartDate = new Date(startDate.getTime());
                    [
                        0,
                        ...Array.from(Array(a.repeatCount)).map(
                            (_, i) => i + 1,
                        ),
                    ].forEach(() => {
                        const wn = getWeekNumber(weekStartDate);
                        const wyear = weekStartDate.getFullYear();
                        const tempDayDate = new Date(
                            convertWeekAndYearToDate(wyear, wn).getTime() +
                                dayOffset,
                        );

                        toReturn.push(tempDayDate);

                        weekStartDate = new Date(
                            convertWeekAndYearToDate(wyear, wn).getTime() +
                                week * 1.5,
                        );
                    });
                });

                return toReturn;
            })
            .reduce((acc, next) => {
                acc.push(...next);
                return acc;
            }, [])
            .map((d) => d.toLocaleDateString('de'));
        return t;
    }, [availabilities]);
    /**
     * render
     */
    return (
        <View ref={refCalendarContainer}>
            <RCalendar
                locale={language}
                value={curDate}
                onClickDay={setCurDate}
                activeStartDate={startDate}
                showWeekNumbers={width > 750}
                tileContent={(props) => {
                    const available = availableDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const negotiating = negotiationDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const active = contractDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    const openJob = jobDays.includes(
                        props.date.toLocaleDateString('de'),
                    );
                    return (
                        <View
                            style={{
                                marginTop: -offset,
                                marginBottom: offset - radius,
                                height: radius,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            {available && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.accentColor,
                                    }}
                                />
                            )}
                            {negotiating && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.warningColor,
                                    }}
                                />
                            )}
                            {active && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.successColor,
                                    }}
                                />
                            )}
                            {openJob && (
                                <View
                                    style={{
                                        ...bubbleStyle,
                                        backgroundColor: theme.borderColor,
                                    }}
                                />
                            )}
                        </View>
                    );
                }}
                tileClassName={(props) => {
                    const currentRangeIndex = range
                        ? range.findIndex(
                              (d) => props.date.toLocaleDateString('de') === d,
                          )
                        : -1;
                    const currentRangeLength = range ? range.length : 0;

                    return `withDot ${
                        currentRangeIndex > -1
                            ? currentRangeIndex === currentRangeLength - 1
                                ? 'endPiece'
                                : !currentRangeIndex
                                ? 'startPiece'
                                : 'centerPiece'
                            : ''
                    }`;
                }}
            />
            {userData.type === EUserType.user && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View
                        style={{
                            ...bubbleStyle,
                            backgroundColor: theme.accentColor,
                        }}
                    />
                    <CText message={calendarMessages.available} />
                </View>
            )}
            {isAgencyUser(userData) && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View
                        style={{
                            ...bubbleStyle,
                            backgroundColor: theme.accentColor,
                        }}
                    />
                    <CText message={calendarMessages.talentAvailable} />
                </View>
            )}
            {isEmployer(userData) && (
                <View style={[style.horizontal, style.centeredItems]}>
                    <View
                        style={{
                            ...bubbleStyle,
                            backgroundColor: theme.borderColor,
                        }}
                    />
                    <CText message={calendarMessages.openJob} />
                </View>
            )}
            {(isEmployer(userData) || userData.type === EUserType.user) && (
                <>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.warningColor,
                            }}
                        />
                        <CText message={calendarMessages.activeNegotiation} />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.successColor,
                            }}
                        />
                        <CText message={calendarMessages.activeContract} />
                    </View>
                </>
            )}
            {isAgencyUser(userData) && (
                <>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.warningColor,
                            }}
                        />
                        <CText
                            message={calendarMessages.talentActiveNegotiation}
                        />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View
                            style={{
                                ...bubbleStyle,
                                backgroundColor: theme.successColor,
                            }}
                        />
                        <CText
                            message={calendarMessages.talentActiveContract}
                        />
                    </View>
                </>
            )}
        </View>
    );
};
