export enum EDoctorFiles {
    approbation = 'approbation',
}

export enum ESocialWorkerFiles {
    kidsCertificate = 'kidsCertificate',
    infectionCertificate = 'infectionCertificate',
    measleCertificate = 'measleCertificate',
    firstAidKid = 'firstAidKid',
}

export enum EAdminFiles {
    id = 'id',
}

export enum EEmployeeFiles {
    police = 'police',
    educert = 'educert',
    resume = 'resume',
}

export enum EGeneralFiles {
    other = 'other',
}

export enum EAgencyFiles {
    license = 'license',
}

export enum EPureContractFiles {
    leasingContract = 'leasingContract',
    equalPayDeclaration = 'equalPayDeclaration',
    educationCertificate = 'educationCertificate',
    houseRules = 'houseRules',
    locationPlan = 'locationPlan',
    contactDetails = 'contactDetails',
    driversLicense = 'driversLicense',
    timesheetTemplate = 'timesheetTemplate',
    timesheet = 'timesheet',
    employmentContract = 'employmentContract',
}

export type EContractFiles = EGeneralFiles | EPureContractFiles;

export type EUserFiles =
    | EDoctorFiles
    | ESocialWorkerFiles
    | EAdminFiles
    | EGeneralFiles
    | EEmployeeFiles;

export type EFile =
    | EAgencyFiles
    | EGeneralFiles
    | EAdminFiles
    | EDoctorFiles
    | ESocialWorkerFiles
    | EEmployeeFiles
    | EPureContractFiles;
