import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { CText, CButton, CPicker, Spinner } from '../../../components';
import {
    ECollections,
    ECountries,
    EEnvironment,
    EField,
    EInviteType,
    EProfession,
    ERegion,
    EUserType,
} from '../../../enums';
import { MInvite, MUserData } from '../../../models';
import { isLocalhost } from '../../../utilities/constants';
import { useEnvironment } from '../../../utilities/contexts';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { year } from '../../../utilities/functions';
import { EOnboardingSteps } from '../Onboarding';
import { onboardingMessages } from '../Onboarding.messages';
import { OnboardingPreselectButton } from './OnboardingPreselectButton';
import { RegionSelect } from './RegionSelect';
import { useDialog } from '../../../utilities/dialog';
import { useSearchParams } from '../../../utilities/routing';
import { useLock } from '../../../utilities/hooks';

export interface IOnboardingComponent {
    onChange: (next: MUserData) => void;
    setSteps: (next: EOnboardingSteps[]) => void;
    invite?: MInvite;
}

export const Preselect: FC<IOnboardingComponent> = ({
    onChange,
    setSteps,
    invite,
}) => {
    // global state
    const {
        logOut,
        user,
        userData,
        callFunction,
        getDataById,
        reloadUserData,
    } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    const dialog = useDialog();
    const [searchParams] = useSearchParams();
    const { lock } = useLock();
    const { environment, setEnvironment, region, setRegion } = useEnvironment();
    // local state
    const [resolvingInvite, setResolvingInvite] = useState(false);
    const [regionSet, setRegionSet] = useState(false);
    /**
     * memoized check if this is dev or not
     */
    const dev = useMemo(isLocalhost, []);
    /**
     * handle response from state 0 with basic data filled in
     */
    const handleInit = useCallback(
        (
            type:
                | EUserType.agency
                | EUserType.employer
                | EUserType.basic
                | EUserType.talent
                | EProfession,
            options?: {
                prevOverwritable?: MUserData;
                prev?: MUserData;
                invited?: boolean;
            },
        ) => {
            /**
             * default assigned values for all userdata entries
             */
            const next =
                options?.prev ||
                new MUserData({
                    ...options?.prevOverwritable,
                    documentId: user?.uid || '',
                    dateOfBirth: Date.now() - year * 18,
                    createdOn: Date.now(),
                    mail: user?.email || undefined,
                    nationality:
                        region === ERegion.za
                            ? ECountries.southAfrica
                            : ECountries.germany,
                });
            if (EUserType.agency === type) {
                onChange(new MUserData({ ...next, type: type as EUserType }));
                if (!options?.invited) {
                    setSteps([
                        EOnboardingSteps.agencyInfo,
                        EOnboardingSteps.basicInfo,
                        EOnboardingSteps.fieldInfo,
                        EOnboardingSteps.agencyProfileCreate,
                        EOnboardingSteps.termsAndConditions,
                    ]);
                } else {
                    setSteps([
                        EOnboardingSteps.basicInfo,
                        EOnboardingSteps.termsAndConditions,
                    ]);
                }
            } else if (EUserType.employer === type) {
                onChange(new MUserData({ ...next, type: type as EUserType }));
                if (!options?.invited) {
                    setSteps([
                        EOnboardingSteps.workplaceInfo,
                        EOnboardingSteps.fieldInfo,
                        EOnboardingSteps.basicInfo,
                        EOnboardingSteps.termsAndConditions,
                    ]);
                } else {
                    setSteps([
                        EOnboardingSteps.basicInfo,
                        EOnboardingSteps.termsAndConditions,
                    ]);
                }
            } else if (EUserType.basic === type) {
                onChange(new MUserData({ ...next, type: EUserType.basic }));
                setSteps([
                    EOnboardingSteps.basicInfo,
                    EOnboardingSteps.termsAndConditions,
                ]);
            } else if (EUserType.talent === type) {
                onChange(new MUserData({ ...next, type: EUserType.talent }));
                if (next.firstName && next.lastName) {
                    setSteps([EOnboardingSteps.termsAndConditions]);
                } else {
                    setSteps([
                        EOnboardingSteps.basicInfo,
                        EOnboardingSteps.termsAndConditions,
                    ]);
                }
            } else {
                /**
                 * make user to regular user with profession
                 * TODO: not hardcoded field selection
                 */
                onChange(
                    new MUserData({
                        ...next,
                        type: EUserType.user,
                        profession: type as EProfession,
                        field: EField.medic,
                    }),
                );
                setSteps([
                    EOnboardingSteps.basicInfo,
                    EOnboardingSteps.addressInfo,
                    EOnboardingSteps.paymentInfo,
                    EOnboardingSteps.termsAndConditions,
                    EOnboardingSteps.marketingInfo,
                ]);
            }
        },
        [user, setSteps, onChange, region],
    );
    /**
     * effect to skip preselect if user is already assigned
     */
    useEffect(() => {
        if (
            [
                EUserType.agency,
                EUserType.employer,
                EUserType.talent,
                ...Object.values(EProfession),
            ].includes(userData.type)
        ) {
            const pid = searchParams.get('profile');
            if (!pid) {
                dialog({
                    icon: 'info',
                    title: generalMessages.completeYourOnboarding,
                    message: generalMessages.completeYourOnboardingMessage,
                    buttons: [{ text: actionMessages.ok }],
                });
            }
            handleInit(userData.type as any, { prev: userData });
        }
    }, [userData]);
    /**
     * effect to resolve invite
     */
    useEffect(() => {
        if (invite && user) {
            setResolvingInvite(true);
            callFunction('resolveInvite', {
                inviteId: invite.documentId,
                targedId: invite.targetId,
                type: invite.type,
            }).then(async (res) => {
                console.log(res);
                if (!res.includes('success')) {
                    return;
                }
                const prevOverwritable = res.includes('verified')
                    ? await getDataById(ECollections.users, user.uid)
                    : undefined;
                if (invite.type === EInviteType.agency) {
                    handleInit(EUserType.agency, {
                        invited: true,
                        prevOverwritable,
                    });
                } else if (invite.type === EInviteType.workplace) {
                    handleInit(EUserType.employer, {
                        invited: true,
                        prevOverwritable,
                    });
                } else if (invite.type === EInviteType.agencyReferral) {
                    handleInit(EUserType.agency, { prevOverwritable });
                }
                setResolvingInvite(false);
            });
        }
    }, [invite, user]);
    /**
     * effect to immediatly become a talent if prof profile supplied
     */
    useEffect(() => {
        const pid = searchParams.get('profile');

        if (userData.type === EUserType.default && pid) {
            setResolvingInvite(true);
            try {
                const unlock = lock();
                callFunction('becomeATalent', { talentId: pid }).then(() => {
                    reloadUserData();
                    unlock();
                    setResolvingInvite(false);
                });
            } catch (e) {
                console.error(e);
                setResolvingInvite(false);
            }
        }
    }, [searchParams, userData, lock]);

    if (resolvingInvite) {
        return <Spinner />;
    }

    if (Platform.OS !== 'web' && !regionSet) {
        return (
            <RegionSelect
                onRegion={(r) => {
                    setRegionSet(true);
                    setRegion(r);
                }}
            />
        );
    }
    /**
     * render
     */
    return (
        <>
            {dev && (
                <>
                    <CPicker
                        cy={'environment-picker'}
                        values={[
                            { label: 'SITUS', value: EEnvironment.SITUS },
                            {
                                label: 'TimePloyees',
                                value: EEnvironment.TimePloyees,
                            },
                        ]}
                        onChange={(v) => {
                            setEnvironment(v);
                        }}
                        value={environment}
                    />
                    <CPicker
                        cy={'region-picker'}
                        values={[
                            { label: 'DE', value: ERegion.de },
                            { label: 'ZA', value: ERegion.za },
                        ]}
                        onChange={(v) => {
                            setRegion(v);
                        }}
                        value={region}
                    />
                </>
            )}
            <CText bold headline style={style.verticalPadded}>
                {format(onboardingMessages.youAre)}
            </CText>
            {environment === 'SITUS' ? (
                <View style={style.verticalPadded}>
                    <OnboardingPreselectButton
                        cy={'become-a-doctor'}
                        onPress={() => handleInit(EProfession.doctor)}
                        image={'placeholderM'}
                        title={onboardingMessages.aJobAsDoctor}
                        description={onboardingMessages.aJobAsDoctorDescription}
                    />
                    <OnboardingPreselectButton
                        cy={'become-a-nurse'}
                        onPress={() => handleInit(EProfession.nurse)}
                        image={'placeholderFB'}
                        title={onboardingMessages.aJobAsNurse}
                        description={onboardingMessages.aJobAsNurseDescription}
                    />
                </View>
            ) : (
                <View style={style.verticalPadded}>
                    <OnboardingPreselectButton
                        cy={'become-a-agency'}
                        onPress={() => handleInit(EUserType.agency)}
                        image={'agencyMPlaceholder'}
                        title={onboardingMessages.aAgency}
                        description={onboardingMessages.aAgencyDescription}
                    />
                </View>
            )}
            <View style={style.verticalPadded}>
                <OnboardingPreselectButton
                    cy={'become-a-employer'}
                    onPress={() => handleInit(EUserType.employer)}
                    image={'placeholderH'}
                    title={
                        environment === 'SITUS'
                            ? onboardingMessages.aHospital
                            : onboardingMessages.aWorkplace
                    }
                    description={
                        environment === 'SITUS'
                            ? onboardingMessages.aHospitalDescription
                            : onboardingMessages.aWorkplaceDescription
                    }
                />
            </View>
            {invite?.type === EInviteType.contract && (
                <View style={style.verticalPadded}>
                    <OnboardingPreselectButton
                        cy={'become-a-basic-user'}
                        onPress={() => handleInit(EUserType.basic)}
                        image={'placeholderD'}
                        title={onboardingMessages.aBasic}
                        description={onboardingMessages.aBasicDescription}
                    />
                </View>
            )}
            <View style={style.verticalPadded}>
                <CButton
                    title={format(actionMessages.logout)}
                    onPress={logOut}
                    transparent
                    minor
                />
            </View>
        </>
    );
};
