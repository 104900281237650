import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, Spinner } from '../../../components';
import { ECollections } from '../../../enums';
import { MWorkplace } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { ProfileContext } from '../Profile.context';
import { WorkplaceRow } from '../../Workplace/List/components/WorkplaceRow';
/**
 * profile section workplace. contains a list of all workplaces for a user.
 * the list should usually be one long but could extend
 * @returns
 */
export const ProfileSectionWorkplace: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { curData } = useContext(ProfileContext);
    const { getDataIndex } = useFireBase();
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [endReached, setEndReached] = useState(false);
    /**
     * callback to load entries. should reset on curdata change
     */
    const loadMore = useCallback(
        async (offset?: MWorkplace) => {
            if (!offset) {
                setEndReached(false);
            }
            const filter: IFilter[] = [];
            filter.push({
                field: 'users',
                operator: 'array-contains',
                value: curData.documentId,
            });
            const params: any = {
                filter,
                limit: 10,
            };
            if (offset) {
                params.startDocumentId = offset.documentId;
            }
            getDataIndex(ECollections.workplaces, params).then((result) => {
                const next = (result as MWorkplace[]).map((r) => {
                    if (r.address) {
                        return new MWorkplace({
                            ...r,
                            address: {
                                ...r.address,
                                zipCode: `${r.address.zipCode}`,
                            },
                        });
                    } else {
                        return new MWorkplace(r);
                    }
                });
                if (next.length !== 10) {
                    setEndReached(true);
                }
                if (!offset) {
                    setWorkplaces(next);
                    setLoading(false);
                } else {
                    setWorkplaces((current) => {
                        return [...current, ...next];
                    });
                    setLoadingMore(false);
                }
            });
        },
        [curData],
    );
    /**
     * effect to trigger reload on load more change
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);
    /**
     * render
     */
    return (
        <View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {workplaces.map((workplace) => (
                        <WorkplaceRow
                            key={workplace.documentId}
                            workplace={workplace}
                        />
                    ))}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!workplaces.length &&
                        workplaces.length % 10 === 0 &&
                        !endReached && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(
                                            workplaces[workplaces.length - 1],
                                        );
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                </>
            )}
        </View>
    );
};
