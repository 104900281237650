import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { CPicker, DocumentPreviewRow } from '../../../components';
import { FilePicker } from '../../../components/FilePicker';
import {
    EProfession,
    EAdminFiles,
    EDoctorFiles,
    EGeneralFiles,
    EUserFiles,
    ECollections,
    EEmployeeFiles,
    ESocialWorkerFiles,
} from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { filenameMessages, generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { profileMessages } from '../Profile.messages';
import { ProfileContext } from '../Profile.context';
import { MUserFile } from '../../../models';
import { useLock } from '../../../utilities/hooks';
import { useDialog } from '../../../utilities/dialog';
import { useFileUpload } from '../../../utilities/hooks/useFileUpload';

const files = [
    {
        fn: 'approbation',
        type: EDoctorFiles.approbation,
    },
    {
        fn: 'recentcv',
        type: EEmployeeFiles.resume,
    },
    {
        fn: 'educert',
        type: EEmployeeFiles.educert,
    },
    {
        fn: 'additionalcert',
        type: EEmployeeFiles.educert,
    },
    {
        fn: 'police',
        type: EEmployeeFiles.police,
    },
    {
        fn: 'id',
        type: EAdminFiles.id,
    },
];
/**
 * view to display documents and upload
 * @param param0
 * @returns
 */
export const ProfileSectionDocuments: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { getFileMetadata, callFunction } = useFireBase();
    const { lock } = useLock();
    const dialog = useDialog();
    const fileUpload = useFileUpload();
    // parent state
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    // local state
    const [reloadUserFileList, setReloadUserFileList] = useState(true);
    const [currentUploadable, setCurrentUploadable] = useState<EUserFiles>(
        EEmployeeFiles.resume,
    );
    /**
     * memoized list of accessible documents
     */
    const docs = useMemo(() => {
        const generalFiles: string[] = Object.values(EGeneralFiles);
        const employeeFiles: string[] = Object.values(EEmployeeFiles);
        const next = [...generalFiles, ...employeeFiles];
        if (curData.profession === EProfession.doctor) {
            next.push(...Object.values(EDoctorFiles));
        } else if (curData.profession === EProfession.nurse) {
            // * No nurse files
        } else if (curData.profession === EProfession.socialWorker) {
            next.push(...Object.values(ESocialWorkerFiles));
        }
        return next;
    }, [curData]);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            const unlock = lock();
            try {
                const baseFile = await fileUpload(
                    `${ECollections.users}/${curData.documentId}/temp/`,
                    newFn,
                    file,
                );
                const trueFN = await callFunction('setUserFile', {
                    filename: baseFile.name,
                    path: baseFile.path,
                    fileType: currentUploadable,
                });
                onChange({
                    files: [
                        ...curData.files,
                        new MUserFile({
                            type: currentUploadable,
                            name: baseFile.name,
                            path: trueFN,
                        }),
                    ],
                });
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [currentUploadable, curData],
    );
    /**
     * effect to load filelist
     */
    useEffect(() => {
        onValidityChange('');
        if (curData.documentId && reloadUserFileList) {
            setReloadUserFileList(false);
            callFunction('getUserFileList', {
                directory: curData.documentId,
            }).then((res) => {
                if (!res) {
                    console.error(
                        'something went wrong. You could be unauthorized',
                    );
                }
                const fl = res as string[];
                Promise.all(
                    files.map(async ({ fn, type }) => {
                        const recentFile = fl.find((filename) =>
                            filename.includes(fn),
                        );
                        if (recentFile) {
                            const fullMeta = await getFileMetadata(
                                `${recentFile}`,
                            );

                            return { type, name: fullMeta.fullPath };
                        }
                    }),
                ).then((res) => {
                    /**
                     * filter for existing x unknown
                     */
                    const next = res.filter(
                        (r) =>
                            !!r &&
                            !curData.files.find(({ path }) => r.name === path),
                    );
                    const nextParsed = next.map(
                        (v) => new MUserFile({ ...v, path: v?.name }),
                    );
                    if (next.length) {
                        onChange({
                            files: [...curData.files, ...nextParsed],
                        });
                    }
                });
            });
        }
    }, [curData, reloadUserFileList, fileUpload]);
    /**
     * render
     */
    return (
        <>
            <View style={[style.horizontal, style.centeredItems]}>
                <View style={[style.flex1]}>
                    <CPicker
                        onChange={setCurrentUploadable}
                        values={docs
                            .map((d) => {
                                return {
                                    label: format(
                                        filenameMessages[d as EUserFiles],
                                    ),
                                    value: d,
                                };
                            })
                            .sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={currentUploadable}
                        title={format(profileMessages.documentToUpload)}
                    />
                </View>
                <View
                    // adjustment to be inline with picker
                    style={{
                        justifyContent: 'flex-end',
                        alignSelf: 'stretch',
                        paddingBottom: 5,
                    }}
                >
                    <FilePicker onFile={handleFile} />
                </View>
            </View>
            {(curData.files || []).map((f, i) => {
                if (!docs.includes(f.type)) {
                    return;
                }
                return (
                    <DocumentPreviewRow
                        key={f.documentId}
                        file={f}
                        onDelete={() => {
                            const next = Array.from(curData.files);
                            next.splice(i, 1);
                            onChange({ files: next });
                        }}
                    />
                );
            })}
        </>
    );
};
