import React, { FC } from 'react';
import { View } from 'react-native';
import { CText, WageView } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/Job.messages';
import { MContract } from '../../../models';
import { RatesView } from '../../../components/Wage/RatesView';

export const ContractOverviewBar: FC<{ contract: MContract }> = ({
    contract,
}) => {
    const style = useStyle();
    const format = useFormat();
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="contract-details">
            <View
                style={[
                    style.horizontalSplit,
                    style.horizontalPadded,
                    style.horizontalWrap,
                ]}
            >
                <View style={{ flexGrow: 1 }}>
                    <RatesView
                        curData={contract.wage}
                        horizontal
                        injectedFields={[
                            <View key={'fromto'}>
                                <CText bold>{format(jobMessages.fromTo)}</CText>
                                <CText>
                                    {`${new Date(
                                        contract.from,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        contract.to,
                                    ).toLocaleDateString('de')}`}
                                </CText>
                            </View>,
                        ]}
                    />
                </View>
            </View>
        </View>
    );
};
