import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CText } from '../../../components';
import { ECollections, EContractStatus, EUserType } from '../../../enums';
import { isEmployer, isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { dashboardMessages } from '../Dashboard.messages';
import { IFilter } from '../../../utilities/firebase/store';
/**
 * displace todays new application counter
 * @returns
 */
export const ActiveNegotiations: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { userData, getDataIndex, userWorkplaces } = useFireBase();

    const [count, setCount] = useState(0);
    /**
     * load count
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((w) => w.documentId),
                    operator: 'in',
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        getDataIndex(ECollections.contracts, {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    operator: 'in',
                    value: [
                        EContractStatus.creating,
                        EContractStatus.review_yee,
                        EContractStatus.review_yer,
                        EContractStatus.accepted,
                    ],
                },
            ],
            getLength: true,
        }).then((res) => {
            if (typeof res === 'number') {
                setCount(res);
            }
        });
    }, [userData, userWorkplaces]);
    /**
     * render
     */
    return (
        <View style={style.card} nativeID="negotiation-card">
            <View
                style={[
                    style.centeredItems,
                    { justifyContent: 'space-between', maxWidth: '100%' },
                ]}
            >
                <CText bold headline wrap>
                    {format(dashboardMessages.activeNegotiations)}
                </CText>
                <CText style={[style.importantValue]} bold>
                    {count}
                </CText>
                <CButton
                    title={format(generalMessages.showMore)}
                    onPress={() => navigate('/negotiations')}
                    transparent
                    minor
                />
            </View>
        </View>
    );
};
