import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MAvailability } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections, EField, EProfession } from '../../../enums';
import { IFilter } from '../../../utilities/firebase/store';
import { ScrollProvider, useEnvironment } from '../../../utilities/contexts';
import { useStyle } from '../../../utilities/styles';
import {
    CPicker,
    CText,
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../components';
import { generalMessages, monthMessages } from '../../../utilities/messages';
import { calendarMessages } from '../../Calendar/Calendar.messages';
import { isSuperUser } from '../../../utilities/auth';
import { AvailableUserRow } from './components/AvailableUserRow';

export const AvailableUsersList: FC = () => {
    const { getDataIndex, userData } = useFireBase();
    const style = useStyle();
    const { region } = useEnvironment();
    const [availabilities, setAvailabilities] = useState<MAvailability[]>([]);
    const [month, setMonth] = useState(new Date().getMonth());
    const [field, setField] = useState<EField>();
    const [profession, setProfession] = useState<EProfession>();
    const [education, setEducation] = useState<string>();

    /**
     * effect to load availabilities
     */
    useEffect(() => {
        const filter: IFilter[] = [];
        if (month) {
            filter.push({
                field: Object.keys(monthMessages).filter(
                    (m) => !m.includes('Short'),
                )[month],
                value: true,
            });
        }
        if (education) {
            console.log(education);
            filter.push({
                field: 'educations',
                operator: 'array-contains',
                value: education,
            });
        } else if (profession) {
            filter.push({
                field: 'profession',
                value: profession,
            });
        } else if (field) {
            filter.push({
                field: 'field',
                value: field,
            });
        }
        if (!isSuperUser(userData)) {
            filter.push({ field: 'region', value: region });
        }
        getDataIndex(ECollections.availabilities, { filter }).then((result) => {
            setAvailabilities(
                (result as MAvailability[]).map((r) => new MAvailability(r)),
            );
        });
    }, [month, field, profession, education, region, userData]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card]}>
                <CText
                    style={style.verticalPadded}
                    bold
                    headline
                    message={generalMessages.users}
                />
                <View style={style.horizontalWrap}>
                    <CPicker
                        title={calendarMessages.month}
                        value={month + 1}
                        values={Object.keys(monthMessages)
                            .filter((m) => !m.includes('Short'))
                            .map((m, i) => ({
                                label: monthMessages[
                                    m as keyof typeof monthMessages
                                ],
                                value: i + 1,
                            }))}
                        onChange={(v) => setMonth(v - 1)}
                        horizontal
                    />
                    <FieldPicker
                        onChange={(f) => {
                            setField(f);
                            setProfession(undefined);
                            setEducation(undefined);
                        }}
                        value={field}
                        horizontal
                        allowEmpty
                    />
                    <ProfessionPicker
                        onChange={(p) => {
                            setProfession(p);
                            setEducation(undefined);
                        }}
                        value={profession}
                        field={field}
                        horizontal
                        allowEmpty
                    />
                    {profession && (
                        <EducationPicker
                            onChange={(e) => setEducation(e as string)}
                            value={education}
                            profession={profession}
                            field={field}
                            horizontal
                            allowEmpty
                        />
                    )}
                </View>
            </View>
            {availabilities.map((a) => (
                <AvailableUserRow availability={a} key={a.documentId} />
            ))}
        </ScrollProvider>
    );
};
