import React, { FC, useCallback, useContext, useEffect } from 'react';
import { CTextInput, CPicker } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { denominationMessages } from '../../../utilities/messages';
import { profileMessages } from '../Profile.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { EUserType } from '../../../enums';
import { ProfileContext } from '../Profile.context';

export const ProfileSectionBank: FC = () => {
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    /**
     * iban validity callback
     */
    const ibanValidity = useCallback((IBAN: string) => {
        function smellsLikeIban(str: string) {
            return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
                str,
            );
        }
        const ibanStripped = IBAN.replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
            .toUpperCase(); //calculation expects upper-case
        if (IBAN && !smellsLikeIban(ibanStripped)) {
            return format(profileMessages.ibanInvalidError);
        }
    }, []);
    /**
     * bic validity callback
     */
    const bicValidity = useCallback((BIC: string) => {
        if (BIC && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(BIC)) {
            return format(profileMessages.bicInvalidError);
        }
    }, []);
    /**
     * effect to handle validity of curdata in banking context
     */
    useEffect(() => {
        const validity =
            ibanValidity(curData.bankDetails.IBAN) ||
            bicValidity(curData.bankDetails.BIC);
        onValidityChange(
            validity ? format(generalMessages.somethingWrong) : '',
        );
    }, [curData]);
    /**
     * render
     */
    return (
        <>
            <CTextInput
                value={curData.bankDetails.accountHolderName}
                onChangeText={(accountHolderName) =>
                    onChange({
                        bankDetails: {
                            ...curData.bankDetails,
                            accountHolderName,
                        },
                    })
                }
                placeholder={format(profileMessages.accountHolderPlaceholder)}
                label={format(profileMessages.accountHolder)}
                autoExtend
            />
            <CTextInput
                value={curData.bankDetails.IBAN}
                onChangeText={(IBAN) =>
                    onChange({
                        bankDetails: {
                            ...curData.bankDetails,
                            IBAN,
                        },
                    })
                }
                placeholder={format(profileMessages.ibanPlaceholder)}
                label={format(profileMessages.iban)}
                customCheckInvalidity={ibanValidity}
                autoExtend
            />
            <CTextInput
                value={curData.bankDetails.BIC}
                onChangeText={(BIC) =>
                    onChange({
                        bankDetails: {
                            ...curData.bankDetails,
                            BIC,
                        },
                    })
                }
                placeholder={format(profileMessages.bicPlaceholder)}
                label={format(profileMessages.bic)}
                customCheckInvalidity={bicValidity}
                autoExtend
            />
            {curData.type === EUserType.user && (
                <CTextInput
                    value={curData.healthInsurance}
                    onChangeText={(healthInsurance) =>
                        onChange({ healthInsurance })
                    }
                    placeholder={format(
                        profileMessages.hisInsuranceMembershipPlaceholder,
                    )}
                    label={format(profileMessages.hisInsuranceMembership)}
                    autoExtend
                />
            )}
            <CTextInput
                value={curData.taxNumber}
                onChangeText={(taxNumber) => onChange({ taxNumber })}
                placeholder={format(profileMessages.taxNumberPlaceholder)}
                label={format(profileMessages.taxNumber)}
                autoExtend
            />
            {curData.type === EUserType.agency && (
                <CTextInput
                    value={curData.registerNumber}
                    onChangeText={(registerNumber) =>
                        onChange({ registerNumber })
                    }
                    placeholder={format(
                        profileMessages.registerNumberPlaceholder,
                    )}
                    label={format(profileMessages.registerNumber)}
                    autoExtend
                />
            )}
            {curData.type === EUserType.user && (
                <>
                    <CTextInput
                        value={curData.socialSecurityNumber}
                        onChangeText={(socialSecurityNumber) =>
                            onChange({ socialSecurityNumber })
                        }
                        placeholder={format(
                            profileMessages.socialSecurityNumberPlaceholder,
                        )}
                        label={format(profileMessages.socialSecurityNumber)}
                        autoExtend
                    />
                    <CTextInput
                        value={curData.childAllowance}
                        onChangeText={(childAllowance) =>
                            onChange({ childAllowance })
                        }
                        placeholder={format(
                            profileMessages.childAllowancePlaceholder,
                        )}
                        label={format(profileMessages.childAllowance)}
                        autoExtend
                    />
                    <CPicker
                        title={format(profileMessages.denomination)}
                        onChange={(denomination) => onChange({ denomination })}
                        values={[
                            {
                                value: undefined,
                                label: `-${format(
                                    profileMessages.denominationPlaceholder,
                                )}-`,
                                hidden: true,
                            },
                            ...(
                                Object.keys(
                                    denominationMessages,
                                ) as (keyof typeof denominationMessages)[]
                            ).map((v) => ({
                                label: format(denominationMessages[v]),
                                value: v,
                            })),
                        ]}
                    />
                </>
            )}
        </>
    );
};
