import React, { FC, useCallback, useMemo } from 'react';
import { MContract, MFile, MSignaturePosition } from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { Linking, View } from 'react-native';
import { CIcon, CButton, CText } from '../../../../components';
import { useFireBase } from '../../../../utilities/firebase';
import { EUserType } from '../../../../enums';
import { isPeasant, isEmployer } from '../../../../utilities/auth';
import { useShowCourierDialog } from './functions/useShowCourierDialog';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { contractMessages } from '../../contract.messages';

export const Envelope: FC<{
    envelope: { envelopeId: string; files: MFile[] };
    contract: MContract;
    issueReload: () => void;
}> = ({ envelope, contract, issueReload }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { userData } = useFireBase();
    const format = useFormat();
    /**
     * memoized check if this is a namirial envelope or courier
     * - namirial envelopes have porper string ids from namirial
     * - courier envelopes only have Date.now ids
     */
    const isNamirial = useMemo(() => isNaN(+envelope.envelopeId), [envelope]);
    /**
     * myNamirial Signature positions
     */
    const myNamirialPositions = useMemo(
        () =>
            envelope.files.reduce((acc, f) => {
                const next = f.signaturePositions.filter((sp) =>
                    sp.userId
                        ? userData.documentId === sp.userId
                        : isPeasant(userData)
                        ? sp.type === EUserType.user
                        : isEmployer(userData)
                        ? sp.type === EUserType.employer
                        : false,
                );
                acc.push(...next);
                return acc;
            }, [] as MSignaturePosition[]),
        [envelope],
    );
    /**
     * memoized check for namirial if you signed
     */
    const singed = useMemo(() => {
        const unsignedPositions = myNamirialPositions.filter((p) => !p.signed);

        return !unsignedPositions.length;
    }, [myNamirialPositions]);
    /**
     * memoized check for namirial everything is signed
     */
    const completelySinged = useMemo(() => {
        const unsignedPositions = envelope.files
            .reduce((acc, f) => {
                const next = f.signaturePositions;
                acc.push(...next);
                return acc;
            }, [] as MSignaturePosition[])
            .filter((p) => !p.signed);

        return !unsignedPositions.length;
    }, [envelope]);
    /**
     * url to namirial signature position
     */
    const mySignaturePositionUrl = useMemo(() => {
        if (myNamirialPositions.length) {
            return myNamirialPositions[0].url;
        }
    }, [myNamirialPositions]);
    /**
     * url to courier tracking
     */
    const trackingUrl = useMemo(() => {
        const fileWithTracking = envelope.files.find(
            (f) => f.signatureRequest?.trackingUrl,
        );

        return fileWithTracking?.signatureRequest?.trackingUrl;
    }, [envelope]);
    /**
     * memoized check if this user has to sign
     */
    const hasToSign = useMemo(() => {
        const namirialUnsigned = isNamirial && !singed;
        const courierNotAuthorNoTracking =
            !isNamirial &&
            userData.documentId !== envelope.files[0].author &&
            !trackingUrl;
        const next = namirialUnsigned || courierNotAuthorNoTracking;

        return next;
    }, [isNamirial, singed, userData, envelope]);
    /**
     * callback to show courier dialog request
     */
    const showCourierDialog = useShowCourierDialog(issueReload);
    /**
     * callback to show signature request
     */
    const showSignatureRequest = useCallback(
        (file: MFile) => {
            showCourierDialog(file, contract);
        },
        [showCourierDialog, contract],
    );
    /**
     * render
     */
    return (
        <View>
            <View style={[style.horizontalSplit, style.centeredItems]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CIcon icon={isNamirial ? 'namirial' : 'plane'} />
                    <View style={style.leftPadded}>
                        <CText
                            message={generalMessages.envelope}
                            secondaryHeadline
                        />
                        <CText
                            message={format(contractMessages.xFiles, {
                                x: envelope.files.length,
                            })}
                        />
                    </View>
                </View>
                {/* SINGLE CHECK IF ENVELOPE IS SIGNED BY THIS USER */}
                {isNamirial && singed && !completelySinged && (
                    <View
                        style={[
                            style.centeredItems,
                            style.centeredContent,
                            { marginHorizontal: 25, width: 0 },
                        ]}
                    >
                        <CIcon icon="check" />
                    </View>
                )}
                {/* SHOW DOUBLE CHECK IF ENVELOPE IS SIGNED */}
                {isNamirial && completelySinged && (
                    <View
                        style={[
                            style.centeredItems,
                            style.centeredContent,
                            { marginHorizontal: 25, width: 0 },
                        ]}
                    >
                        <CIcon icon="checkDouble" tint={theme.successColor} />
                    </View>
                )}
                {/* Button to go complete signature */}
                {hasToSign && (
                    <CButton
                        onPress={() => {
                            if (isNamirial) {
                                if (mySignaturePositionUrl) {
                                    Linking.openURL(mySignaturePositionUrl);
                                }
                            } else {
                                showSignatureRequest(envelope.files[0]);
                            }
                        }}
                        icon="signature"
                        minor
                        transparent
                        style={{
                            marginHorizontal: 0,
                            padding: 5,
                            width: 'auto',
                        }}
                    />
                )}
            </View>
            {!!trackingUrl && <CText message={trackingUrl} />}
        </View>
    );
};
