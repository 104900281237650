import {
    EAgencyFiles,
    EContractFiles,
    EEmployeeFiles,
    EFile,
    EGeneralFiles,
    EUserFiles,
} from '../enums';
import { MCourierRequest } from './MCourierRequest';
import { MSignaturePosition } from './MSignaturePosition';
/**
 * model for file references
 */
export class MFile {
    public documentId = '';
    public name = '';
    public path = '';
    public author = '';
    public type: EFile = EGeneralFiles.other;

    public createdOn = Date.now();

    public envelopeId = '';
    public namirialFileId = '';

    public signatureRequest?: MCourierRequest;
    public signaturePositions: MSignaturePosition[] = [];

    constructor(params: Partial<MFile>) {
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }

    static fixConstructor = (that: any, params: Partial<MFile>) => {
        if (params.signaturePositions) {
            that.signaturePositions = params.signaturePositions.map(
                (sP) => new MSignaturePosition(sP),
            );
        }
    };
}
/**
 * model for userfile references.
 * use enum for filetypes
 */
export class MUserFile extends MFile {
    public type: EUserFiles = EEmployeeFiles.resume;

    constructor(params: Partial<MUserFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
/**
 * model for agencyfile references
 * use enum for filetypes
 */
export class MAgencyFile extends MFile {
    public type: EAgencyFiles = EAgencyFiles.license;

    constructor(params: Partial<MAgencyFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
/**
 * model for contractfile references.
 * use enum for filetypes
 */
export class MContractFile extends MFile {
    public type: EContractFiles | EUserFiles = EGeneralFiles.other;
    /**
     * used for filtering
     */
    public for: 'yer' | 'yee' | 'null' = 'null';

    constructor(params: Partial<MContractFile>) {
        super(params);
        Object.assign(this, params);
        MFile.fixConstructor(this, params);
    }
}
