import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Clipboard, Platform, View } from 'react-native';
import {
    CButton,
    CCard,
    CCheckBox,
    CPicker,
    CText,
    Spinner,
} from '../../../components';
import { ExpandableCard } from '../../../components/ExpandableCard';
import PDFViewer from '../../../components/PDFViewer';
import { ECollections, EEnvironment } from '../../../enums';
import {
    MAgency,
    MProfessionalInfo,
    MProfessionalProfile,
    MUserData,
} from '../../../models';
import { isSuperUser } from '../../../utilities/auth';
import { useEnvironment } from '../../../utilities/contexts';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import {
    useAdminDialog,
    useDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import {
    actionMessages,
    educationMessages,
    generalMessages,
} from '../../../utilities/messages';
import {
    useNavigate,
    useParams,
    useSecureNavigate,
} from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ProfileContext } from '../../Profile/Profile.context';
import { profProfileMessages } from '../ProfProfile.messages';
import {
    ProfessionalDocuments,
    UnsaveProfessionalDocuments,
} from './components';
import { ProfessionalBasic } from './components/ProfessionalBasic';
import { ProfessionalHistory } from './components/ProfessionalHistory';
import { ProfessionalInformation } from './components/ProfessionalInformation';
import { ProfessionalLanguage } from './components/ProfessionalLanguage';
import { ProfessionalWage } from './components/ProfessionalWage';
import { useLock } from '../../../utilities/hooks';
import { isLocalhost } from '../../../utilities/constants';
import { ProfessionalAvailabilities } from './components/ProfessionalAvailabilities';
import { ProfessionalEmployment } from './components/ProfessionalEmployment';
/**
 * ProfProfileEdit
 * used by agencies and admins to view & edit profiles
 *
 * ! Opens user context with excessive values to prevent new context type
 * @returns
 */
export const ProfProfileEdit: FC<{
    nested?: boolean;
    saveOverride?: (next: MProfessionalProfile) => void;
    saveAndRegisterNewUser?: (next: MProfessionalProfile) => void;
    predefinedData?: MProfessionalProfile;
    showUnsaveDocSection?: boolean;
}> = ({
    nested,
    saveOverride,
    saveAndRegisterNewUser,
    predefinedData,
    showUnsaveDocSection,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { environment, region } = useEnvironment();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const navigate = useNavigate();
    const format = useFormat();
    const dialog = useDialog();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const adminDialog = useAdminDialog();
    const { lock } = useLock();
    const {
        getDataById,
        userData,
        userAgencies,
        post,
        put,
        remove,
        callFunction,
        getDataIndex,
        isLoggedIn,
    } = useFireBase();
    const { id } = useParams<{ id: string }>();
    const { language } = useContext(LanguageContext);
    // local state
    const [agencies, setAgencies] = useState<MAgency[]>([]);
    const [resumeFilename, setFilename] = useState<{
        fn: string;
        issued: number;
    }>({ fn: '', issued: Date.now() });
    const [profInfos, setProfInfos] = useState<MProfessionalProfile>();
    const [prevProfInfos, setPrevProfInfos] = useState<MProfessionalProfile>();
    const [curValidity, setCurValidity] = useState<any>({});
    const [previewAnonym, setPreviewAnonym] = useState(false);
    /**
     * memoized profile name for headline
     */
    const prevProfileName = useMemo(() => {
        if (!prevProfInfos) {
            return '';
        }
        const edukey = prevProfInfos
            .educations[0] as keyof typeof educationMessages;
        if (prevProfInfos.lastName) {
            return `${prevProfInfos.lastName} ${
                educationMessages[edukey]
                    ? format(educationMessages[edukey])
                    : ''
            }`;
        } else {
            return educationMessages[edukey]
                ? format(educationMessages[edukey])
                : '';
        }
    }, [prevProfInfos]);
    /**
     * memoized connected agency
     */
    const agency = useMemo(() => {
        return agencies.find((a) => a.documentId === prevProfInfos?.agencyId);
    }, [agencies, prevProfInfos]);
    /**
     * memoized current data
     */
    const curData = useMemo(
        () =>
            new MProfessionalProfile({
                ...profInfos,
                region: agency?.region || region,
            }),
        [profInfos, agency, region],
    );
    /**
     * memoized prev data
     */
    const prevData = useMemo(
        () =>
            new MProfessionalProfile({
                ...prevProfInfos,
                region: agency?.region || region,
            }),
        [prevProfInfos, agency, region],
    );
    /**
     * memoized data as userdata for context
     */
    const parsedCurDataForContext = useMemo(
        () => new MUserData(curData as MProfessionalInfo),
        [curData],
    );
    const parsedPrevDataForContext = useMemo(
        () => new MUserData(prevData as MProfessionalInfo),
        [prevData],
    );
    /**
     * memoized value if any content differs from content initial loadet
     */
    const unsavedChanges = useMemo(
        () => JSON.stringify(profInfos) !== JSON.stringify(prevProfInfos),
        [profInfos, prevProfInfos],
    );
    /**
     * memoized invite url if profile is open invite
     */
    const inviteUrl = useMemo(() => {
        if (!profInfos || !profInfos.invite || !profInfos.open) return;
        const basepath =
            Platform.OS === 'web'
                ? // @ts-ignore
                  window?.location?.hostname
                : 'app.timeployees.de';
        const isLocalhost = basepath === 'localhost';
        const inviteURL = `${isLocalhost ? '' : 'https://'}${basepath}${
            isLocalhost ? ':3000' : ''
        }/fill?profile=${profInfos.documentId}`;
        return inviteURL;
    }, [profInfos]);
    /**
     * memoized iterable validities
     */
    const validities = useMemo(() => {
        const keys = Object.keys(curValidity) as (keyof typeof curValidity)[];

        return keys
            .filter((k) => !!curValidity[k])
            .map((k) => {
                return { key: k, value: curValidity[k] };
            });
    }, [curValidity]);
    /**
     * callback to handle changes based on next data and index
     */
    const onChange = useCallback((change: Partial<MProfessionalProfile>) => {
        setProfInfos((prev) => {
            return new MProfessionalProfile({ ...(prev || {}), ...change });
        });
    }, []);
    /**
     * callback to handle save
     */
    const handleSave = useCallback(async () => {
        if (isSuperUser(userData) && !(await adminDialog())) return;
        const next = new MProfessionalProfile({
            ...profInfos,
        });
        if (saveOverride) {
            return saveOverride(next);
        }
        const unlock = lock();
        if (id === 'new') {
            next.moderators = [userData.documentId];
            await post(ECollections.profProfiles, next);
        } else if (id) {
            await put(ECollections.profProfiles, id, next);
        }
        unlock();
        secureNavigate(-1, { force: true });
    }, [profInfos, userData, id, post, put, saveOverride]);
    /**
     * callback to resend form to fill in
     */
    const handleReinvite = useCallback(async () => {
        let mail = '';
        const ok = await dialog({
            icon: 'info',
            title: profProfileMessages.invite,
            message: profProfileMessages.inviteText,
            textInputs: [
                {
                    title: profProfileMessages.mail,
                    defaultValue: profInfos?.mail,
                    id: 'mail',
                },
            ],
            buttons: [
                {
                    text: profProfileMessages.createInvite,
                },
                {
                    text: profProfileMessages.createInviteAndSendMail,
                    onPress: (inputs) => {
                        mail =
                            inputs?.find((i) => i.id === 'mail')?.value || '';
                    },
                    disabled: (inputs) =>
                        !inputs?.find((i) => i.id === 'mail')?.value,
                },
            ],
            cancelButton: { text: actionMessages.cancel },
            verticalButtons: true,
        });

        if (!ok) return;

        const unlock = lock();

        const next = new MProfessionalProfile({
            ...profInfos,
            open: true,
            invite: true,
            mail: mail,
        });
        await put(ECollections.profProfiles, next.documentId, next);
        setPrevProfInfos(next);
        setProfInfos(next);
        if (mail) {
            const mailresult = await callFunction('sendProfProfileInvite', {
                profileId: next.documentId,
            });
            console.log(mailresult);
            unlock();
            if (mailresult === 'Ok') {
                await dialog({
                    icon: 'success',
                    title: format(profProfileMessages.mailSend, { mail }),
                    message: format(profProfileMessages.mailSendText, {
                        mail,
                    }),
                });
            }
        } else {
            unlock();
        }
    }, [profInfos]);
    /**
     * callback to close invite
     */
    const handleInviteCancel = useCallback(async () => {
        if (
            await dialog({
                title: profProfileMessages.confirmFormulaClose,
                message: profProfileMessages.confirmFormulaCloseText,
                buttons: [
                    {
                        text: actionMessages.ok,
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            })
        )
            onChange({ open: false });
    }, []);
    /**
     * callback to handle delete
     */
    const handleDelete = useCallback(() => {
        if (profInfos)
            dialog({
                title: profProfileMessages.confirmDelete,
                message: profProfileMessages.confirmDeleteMessage,
                buttons: [
                    {
                        text: actionMessages.delete,
                        onPress: () =>
                            remove(
                                ECollections.profProfiles,
                                profInfos.documentId,
                            ).then(() => navigate(-1)),
                        color: theme.errorColor,
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            });
    }, [profInfos]);
    /**
     * navigation lock. ask for discard before navigation
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        if (locking) {
            return true;
        } else {
            return false;
        }
    }, [unsavedChanges]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => {
        setNavigationLock(navigationLock);
    }, [navigationLock]);
    /**
     * effecct to load agencies for super users. and assign userAgencies for regulars
     */
    useEffect(() => {
        const filter: IFilter[] = [];
        if (!isLoggedIn) {
            return;
        }
        if (!isSuperUser(userData)) {
            filter.push({
                field: 'users',
                operator: 'array-contains',
                value: userData.documentId,
            });

            getDataIndex(ECollections.agencies, { filter }).then((res) =>
                setAgencies((res as any[]).map((a) => new MAgency(a))),
            );
        } else {
            setAgencies(userAgencies);
        }
    }, [userData, userAgencies]);
    /**
     * effect to load prof profile
     */
    useEffect(() => {
        if (predefinedData) {
            setProfInfos(predefinedData);
        } else if (id && id !== 'new') {
            getDataById(ECollections.profProfiles, id).then((res) => {
                const next = new MProfessionalProfile(res);
                const nextPrev = new MProfessionalProfile(res);
                if (next.filledOn) {
                    delete next.filledOn;
                }
                setProfInfos(next);
                setPrevProfInfos(nextPrev);
            });
        } else if (userAgencies.length === 1) {
            const next = new MProfessionalProfile({
                agencyId: userAgencies[0].documentId,
            });
            setProfInfos(next);
        } else {
            setProfInfos(new MProfessionalProfile());
        }
    }, [id, userData, userAgencies, predefinedData]);
    /**
     * effect to load resume
     */
    useEffect(() => {
        if (!prevProfInfos) {
            return;
        }
        if (id !== 'new') {
            // TODO: fix frequency of calls.
            console.log(
                'loading resume',
                prevProfInfos,
                previewAnonym,
                language,
            );
            callFunction('getResume', {
                profProfileId: id,
                timeployees: environment === EEnvironment.TimePloyees,
                lang: language,
            })
                .then((nextFN) =>
                    setFilename({ fn: nextFN, issued: Date.now() }),
                )
                .catch((e) => console.error(e));
        }
    }, [prevProfInfos, previewAnonym, language]);
    /**
     * display spinner during load
     */
    if (!profInfos) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData: parsedCurDataForContext,
                prevData: parsedPrevDataForContext,
                onChange: onChange,
                onValidityChange: (v, key) =>
                    setCurValidity((prev: any) => {
                        if (key) {
                            const next = {} as any;
                            next[key] = v;
                            return { ...prev, ...next };
                        }
                        return { ...prev, basic: v };
                    }),
            }}
        >
            <ScrollProvider
                style={style.paddedScrollableMainView}
                nested={nested}
            >
                {!nested && (
                    <View
                        style={[
                            style.headlineCard,
                            style.centeredItems,
                            style.horizontalSplit,
                        ]}
                    >
                        <View style={style.horizontal}>
                            <CButton
                                cy={'back'}
                                onPress={async () => {
                                    secureNavigate(-1);
                                }}
                                icon={'chevronLeft'}
                                small
                            />
                            <CText
                                message={`${format(
                                    generalMessages.professionalProfile,
                                )} - ${prevProfileName}`}
                                bold
                                headline
                                style={style.horizontalPadded}
                            />
                        </View>
                        <View style={style.horizontal}>
                            {!!(
                                profInfos.mail &&
                                profInfos.firstName &&
                                profInfos.lastName
                            ) &&
                                !profInfos.open && (
                                    <CButton
                                        onPress={handleReinvite}
                                        transparent
                                        small
                                        icon={'plane'}
                                    />
                                )}
                            {!!curData.documentId && (
                                <CButton
                                    onPress={() => {
                                        secureNavigate(
                                            '/calendar?profileId=' +
                                                curData.documentId,
                                        );
                                    }}
                                    icon={'calendar'}
                                    transparent
                                    small
                                />
                            )}
                        </View>
                    </View>
                )}
                {!nested && !!inviteUrl && (
                    <CCard>
                        <View
                            style={[style.horizontalSplit, style.centeredItems]}
                        >
                            <View>
                                <CText message={inviteUrl} />
                            </View>
                            <View style={style.horizontal}>
                                <CButton
                                    icon={'close'}
                                    onPress={handleInviteCancel}
                                    mouseOverTitle={actionMessages.close}
                                    minor
                                    iconColor={theme.errorColor}
                                    transparent
                                    fontStyles={{ color: theme.errorColor }}
                                />
                                <CButton
                                    icon={'clipboardMulti_outline'}
                                    onPress={() => {
                                        Clipboard.setString(inviteUrl);
                                    }}
                                    minor
                                    transparent
                                />
                            </View>
                        </View>
                    </CCard>
                )}
                {agencies.length > 1 && (
                    <View style={style.card}>
                        <CPicker
                            values={agencies.map((agency) => {
                                return {
                                    value: agency.documentId,
                                    label: agency.name,
                                };
                            })}
                            onChange={(v) => {
                                onChange({ agencyId: v });
                            }}
                            value={agency?.documentId}
                        />
                        {agency && <CText>{agency.name}</CText>}
                    </View>
                )}
                {/* basic info edit for professional profiles */}
                <View style={[style.card, { zIndex: 1 }]}>
                    <ProfessionalBasic />
                </View>
                <View
                    style={[
                        style.card,
                        !curData.educations.length && style.errorBorder,
                    ]}
                >
                    <ProfessionalInformation />
                </View>
                <View
                    style={[style.card, curValidity.lang && style.errorBorder]}
                >
                    <ProfessionalLanguage />
                </View>
                <View
                    style={[style.card, curValidity.lang && style.errorBorder]}
                >
                    <ProfessionalEmployment />
                </View>
                <View style={[style.card, { zIndex: 1 }]}>
                    <ProfessionalHistory />
                </View>
                {/* collapsable resume preview for created professional profiles */}
                {id !== 'new' && !nested && (
                    <ExpandableCard
                        head={
                            <CText
                                bold
                                message={format(
                                    profProfileMessages.previewResume,
                                )}
                            />
                        }
                        body={
                            <>
                                {resumeFilename.fn ? (
                                    <PDFViewer filename={resumeFilename.fn} />
                                ) : (
                                    <View>
                                        <Spinner />
                                        <CText centered>
                                            {format(
                                                generalMessages.loadingResume,
                                            )}
                                        </CText>
                                    </View>
                                )}
                            </>
                        }
                        preventHover
                    />
                )}
                {id !== 'new' && agency && (
                    <View style={style.card}>
                        <ProfessionalDocuments agency={agency} />
                    </View>
                )}
                {!!curData.documentId && showUnsaveDocSection && (
                    <View style={style.card}>
                        <UnsaveProfessionalDocuments
                            agencyId={curData.agencyId}
                        />
                    </View>
                )}
                {/* wage rates for professional profiles */}
                {!nested && (
                    <View style={style.card}>
                        <ProfessionalWage
                            curData={profInfos}
                            prevData={prevProfInfos}
                            onChange={onChange}
                            onValidityChange={(v, key) =>
                                setCurValidity((prev: any) => {
                                    if (key) {
                                        const next = {} as any;
                                        next[key] = v;
                                        return { ...prev, ...next };
                                    }
                                    return { ...prev, basic: v };
                                })
                            }
                        />
                    </View>
                )}
                {!!curData.documentId && (
                    <CCard style={{ zIndex: 1 }} outsideStyle={{ zIndex: 1 }}>
                        <ProfessionalAvailabilities agency={agency} />
                    </CCard>
                )}
                {/* watch for jobs check for professional profiles */}
                {!nested && (
                    <View style={style.card}>
                        <CText
                            secondaryHeadline
                            message={
                                profProfileMessages.watchForNewJobsForThisProfile
                            }
                        />
                        <CText
                            message={
                                profProfileMessages.watchForNewJobsForThisProfileDescription
                            }
                        />
                        <CCheckBox
                            title={profProfileMessages.watchForNewJobs}
                            checked={profInfos.isWatching}
                            onCheckedChanged={(isWatching) =>
                                onChange({ isWatching })
                            }
                        />
                    </View>
                )}
                <View style={style.verticalPadded}>
                    <View style={style.horizontalSplit}>
                        <View />
                        <View style={{ alignItems: 'flex-end' }}>
                            {!!validities.length && (
                                <View style={{ marginHorizontal: 'auto' }}>
                                    {validities.map((v) => (
                                        <CText
                                            key={v.key as string}
                                            style={style.error}
                                        >
                                            {v.value}
                                        </CText>
                                    ))}
                                </View>
                            )}
                            {unsavedChanges && (
                                <CText style={style.warning}>
                                    {format(generalMessages.unsavedChanges)}
                                </CText>
                            )}
                            <View style={style.horizontal}>
                                {!nested && !!profInfos.documentId && (
                                    <CButton
                                        onPress={handleDelete}
                                        title={format(actionMessages.delete)}
                                        danger
                                    />
                                )}
                                <CButton
                                    disabled={!!validities.length}
                                    onPress={handleSave}
                                    title={format(
                                        nested
                                            ? actionMessages.submitForm
                                            : actionMessages.save,
                                    )}
                                />
                                {saveAndRegisterNewUser && isLocalhost() && (
                                    <CButton
                                        disabled={!!validities.length}
                                        onPress={() =>
                                            saveAndRegisterNewUser(
                                                new MProfessionalProfile({
                                                    ...profInfos,
                                                }),
                                            )
                                        }
                                        title={format(
                                            profProfileMessages.saveAndCreateAccount,
                                        )}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
