import React, { FC } from 'react';
import { CTable } from '../../../components';
import { MApplication } from '../../../models/MApplication';
import { useFormat } from '../../../utilities/intl';
import {
    generalMessages,
    languageMessages,
    educationMessages,
    specializationMessages,
    positionMessages,
} from '../../../utilities/messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { applicationMessages } from '../application.messages';
import { profProfileMessages } from '../../ProfProfile/ProfProfile.messages';

interface IApplicantData {
    application: MApplication;
}

export const ApplicantData: FC<IApplicantData> = ({ application }) => {
    const format = useFormat();
    return (
        <CTable
            headers={[{ key: 'key' }, { key: 'value' }]}
            hideHeaders
            rows={[
                {
                    key: format(applicationMessages.employeeProfession),
                    value: format(generalMessages[application.profession]),
                },
                {
                    key: format(profileMessages.education),
                    value: application.educations
                        .map((e) =>
                            format(
                                educationMessages[
                                    e as keyof typeof educationMessages
                                ],
                            ),
                        )
                        .join(', '),
                },
                {
                    key: format(profileMessages.specialization),
                    value: application.specializations
                        .map((specialization) => {
                            const typedSpec =
                                specialization as keyof typeof specializationMessages;
                            const message = specializationMessages[typedSpec];
                            return message ? format(message) : typedSpec;
                        })
                        .join(', '),
                },
                {
                    key: format(profileMessages.yoe),
                    value: `${application.yearsOfExperience}`,
                },
                {
                    key: format(profileMessages.addExp),
                    value: application.additionalExperiecne || '-',
                },
                {
                    key: format(profileMessages.position),
                    value:
                        !!application.position &&
                        // @ts-ignore
                        !!positionMessages[application.position]
                            ? format(
                                  // @ts-ignore
                                  positionMessages[application.position],
                              )
                            : '-',
                },
                application.gerApprobation && {
                    key: format(profileMessages.gerApprobation),
                    value: application.gerApprobation
                        ? format(generalMessages.true)
                        : format(generalMessages.false),
                },
                application.noCrossGenderWork && {
                    key: format(profileMessages.noCrossGenderWork),
                    value: application.noCrossGenderWork
                        ? format(generalMessages.true)
                        : format(generalMessages.false),
                },
                application.languages.length && {
                    key: format(profProfileMessages.languages),
                    value: application.languages.reduce((str, l) => {
                        // @ts-ignore
                        if (languageMessages[l.lang]) {
                            if (str) {
                                str = `${str}, ${format(
                                    // @ts-ignore
                                    languageMessages[l.lang],
                                )} - ${l.level}`;
                            } else {
                                str = `${format(
                                    // @ts-ignore
                                    languageMessages[l.lang],
                                )} - ${l.level}`;
                            }
                        }
                        return str;
                    }, ''),
                },
            ].map((entry) => {
                if (entry) {
                    return {
                        values: [
                            {
                                key: 'key',
                                bold: true,
                                value: entry.key,
                            },
                            {
                                key: 'value',
                                value: entry.value,
                                flex: 3,
                            },
                        ],
                    };
                }
            })}
        />
    );
};
