import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from '../../../utilities/routing';
import { CMultiButton } from '../../../components';
import { MApplication, MContract, MJob, MWorkplace } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { useTheme } from '../../../utilities/styles';
import { IFilter } from '../../../utilities/firebase/store';
import { actionMessages } from '../../../utilities/messages';
import {
    EApplicationStatus,
    ECollections,
    EContractStatus,
    EEnvironment,
    EJobStatus,
} from '../../../enums';
import { jobApplicationMessages } from '../JobApplication.messages';
import { jobMessages } from '../../Job/Job.messages';
import { useEnvironment } from '../../../utilities/contexts';
import { useDialog } from '../../../utilities/dialog';
import { IKeyButton } from '../../../components/elements/CMultiButton/CMultiButton';

export const useJobApplicationRowState = (job: MJob) => {
    // global state
    const format = useFormat();
    const navigate = useNavigate();
    const { environment } = useEnvironment();
    const {
        callFunction,
        getDataIndex,
        getDataById,
        userData,
        userWorkplaces,
    } = useFireBase();
    const { theme } = useTheme();
    const dialog = useDialog();
    // local state
    const [expand, setExpand] = useState(false);
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [applications, setApplications] = useState<MApplication[]>([]);
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [loading, setLoading] = useState(true);
    const [filenames, setFileNames] = useState<string[]>([]);
    /**
     * multi button, memoized to avoid infinite loopings
     */
    const memoizedMultiButton = useMemo(() => {
        /**
         * array to be filled with buttons
         * allows for dynamically adding or skipping adds of buttons
         */
        const buttons: IKeyButton[] = [];
        /**
         * push edit button if no applications
         */
        if (
            !applications.length &&
            ![
                EJobStatus.filled,
                EJobStatus.archived,
                EJobStatus.disabled,
            ].includes(job.status)
        ) {
            buttons.push({
                icon: 'cog',
                onPress: () => navigate('/job/' + job.documentId),
                title: format(actionMessages.edit),
                cy: 'edit-' + job.documentId,
            });
        }
        /**
         * push draft button on all non draft jobs
         */
        if (job.status !== EJobStatus.draft) {
            buttons.push({
                icon: 'duplicate',
                onPress: () => {
                    navigate(`/job/draft/${job.documentId}`, {
                        replace: true,
                    });
                },
                title: format(jobMessages.createDraft),
            });
        }
        /**
         * push publish button on draft jobs
         */
        if (job.status === EJobStatus.draft) {
            buttons.push({
                icon: 'eye',
                onPress: () => {
                    navigate(`/job/publish/${job.documentId}`, {
                        replace: true,
                    });
                },
                title: format(jobMessages.publish),
            });
        }
        /**
         * add duplicate button
         */
        buttons.push({
            icon: 'plus',
            onPress: () => {
                navigate(`/job/duplicate/${job.documentId}`, {
                    replace: true,
                });
            },
            title: format(jobMessages.duplicate),
        });
        /**
         * return multi button component for render
         */
        return (
            <CMultiButton cy={'multi-' + job.documentId} buttons={buttons} />
        );
    }, [job, applications]);
    /**
     * effect to load applications
     */
    useEffect(() => {
        if (!userData.documentId) {
            return;
        }
        if (
            job.status === EJobStatus.public ||
            job.status === EJobStatus.private
        ) {
            const applicationFilter: IFilter[] = [];
            applicationFilter.push(
                { field: 'jobId', value: job.documentId },
                {
                    field: 'status',
                    operator: 'in',
                    value: [EApplicationStatus.open],
                },
                { field: 'jobWorkplace', value: job.workPlaceId },
            );
            getDataIndex(ECollections.applications, {
                filter: applicationFilter,
            })
                .then((result) => {
                    setApplications(
                        (result as MApplication[]).map(
                            (r) => new MApplication(r),
                        ),
                    );
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
        } else if (job.status === EJobStatus.filled) {
            const contractFilter: IFilter[] = [];
            contractFilter.push(
                { field: 'jobId', value: job.documentId },
                { field: 'status', value: EContractStatus.signed },
                { field: 'workplaceId', value: job.workPlaceId },
            );
            getDataIndex(ECollections.contracts, {
                filter: contractFilter,
            })
                .then((result) => {
                    setContracts(
                        (result as any[]).map((r) => new MContract(r)),
                    );
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [userData, job]);
    /**
     * effect to get workpllace (from user workplaces in case of employer user)
     */
    useEffect(() => {
        if (userWorkplaces.length) {
            setWorkplace(
                userWorkplaces.find((wp) => wp.documentId === job.workPlaceId),
            );
        } else {
            getDataById(ECollections.workplaces, job.workPlaceId).then((wp) => {
                setWorkplace(wp);
            });
        }
    }, [job, userWorkplaces]);
    /**
     * effect to load filenames and verify access to files and state of files
     */
    useEffect(() => {
        if (applications.length !== filenames.length && expand) {
            const promises = applications.map(
                async (app) =>
                    await callFunction('getResume', {
                        applicationId: app.documentId,
                        timeployees: environment === EEnvironment.TimePloyees,
                    }),
            );
            Promise.all(promises).then(setFileNames);
        }
    }, [applications, filenames, expand]);
    /**
     * handle accept
     * @param application
     * @param status
     */
    const handleAccept = useCallback(
        async (application: MApplication, nextStatus: EApplicationStatus) => {
            let completeJob = false;
            if (
                await dialog({
                    title: jobApplicationMessages.completeJob,
                    message: jobApplicationMessages.completeJobDescription,
                    buttons: [
                        {
                            text: jobApplicationMessages.justAccept,
                            color: theme.accentColor,
                        },
                        {
                            text: jobApplicationMessages.acceptAndClose,
                            color: theme.accentColor,
                            onPress: () => {
                                completeJob = true;
                            },
                        },
                    ],
                    cancelButton: {
                        text: actionMessages.cancel,
                    },
                })
            ) {
                callFunction('changeApplicationStatus', {
                    applicationId: application.documentId,
                    nextStatus,
                    completeJob,
                }).then((response) => {
                    if (response === 'invalid') {
                        console.error('Something unexpected happened');
                    } else if (nextStatus === EApplicationStatus.rejected) {
                        setApplications((v) => {
                            const i = v.indexOf(application);
                            const next = [...v];
                            next.splice(i, 1);
                            return next;
                        });
                    } else if (nextStatus === EApplicationStatus.accepted) {
                        if (response) {
                            navigate('/contract/negotiation/' + response);
                        }
                    }
                });
            }
        },
        [callFunction, navigate],
    );
    return {
        memoizedMultiButton,
        filenames,
        loading,
        applications,
        expand,
        setExpand,
        handleAccept,
        contracts,
        workplace,
    };
};
