import { defineMessages } from 'react-intl';

const scope = 'con.chat.';

export const chatMessages = defineMessages({
    noChatSelected: {
        id: scope + 'noChatSelected',
        defaultMessage: 'Select a chat to display messages',
    },
    noOpenChats: {
        id: scope + 'noOpenChats',
        defaultMessage:
            'You are currently not participating in a chat. If you establish a connection with an employer you will be able to communicate with them here',
    },
    send: {
        id: scope + 'send',
        defaultMessage: 'Send',
    },
    composerPlaceholder: {
        id: scope + 'composerPlaceholder',
        defaultMessage: 'Type a message...',
    },
    loadEarlierMessages: {
        id: scope + 'loadEarlierMessages',
        defaultMessage: 'Load earlier messages',
    },
    noMessages: {
        id: scope + 'noMessages',
        defaultMessage: '[No messages]',
    },
    closeSupportRequest: {
        id: scope + 'closeSupportRequest',
        defaultMessage: 'Close support request',
    },
    closeSupportRequestText: {
        id: scope + 'closeSupportRequestText',
        defaultMessage:
            'Please confirm that you want to close this support request.',
    },
    chatClosed: {
        id: scope + 'chatClosed',
        defaultMessage: 'This chat is closed.',
    },
    showClosedChats: {
        id: scope + 'showClosedChats',
        defaultMessage: 'Show closed chats',
    },
    showOpenChats: {
        id: scope + 'showOpenChats',
        defaultMessage: 'Show open chats',
    },
    supportRequest: {
        id: scope + 'supportRequest',
        defaultMessage: 'Support request',
    },
    openProfile: {
        id: scope + 'openProfile',
        defaultMessage: 'Open profile',
    },
});
