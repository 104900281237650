import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CText, CTimePicker } from '../../../components';
import { TouchableView } from '../../../components/TouchableView';
import { IDayAvailability } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { convertWeekAndYearToDate } from '../../../utilities/functions';
import { IDayAvailabilityProps } from './DayAvailability';
import { calendarMessages } from '../Calendar.messages';

/**
 * day availability row for availability edit
 */
export const DayAvailability2: FC<IDayAvailabilityProps> = ({
    day,
    dayNumber,
    availability,
    onChange,
    currentDate,
    startHour,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    /**
     * change day availability entry
     */
    const changeEntry = useCallback(
        (next: Partial<IDayAvailability>) => {
            onChange((prev) => {
                const indexToEdit = prev.days.findIndex(
                    (dA) => dA.day === dayNumber,
                );
                const prevVal = prev.days[indexToEdit];
                prev.days[indexToEdit] = { ...prevVal, ...next };

                return { ...prev, days: [...prev.days] };
            });
        },
        [dayNumber, onChange],
    );
    /**
     * toggle active by removing entry from list or adding it
     */
    const toggleActive = useCallback(
        (setToActive: boolean) => {
            onChange((prev) => {
                if (setToActive) {
                    const [avgFrom, avgTo] = prev.days.reduce(
                        (acc, d) => {
                            acc[0] += d.from;
                            acc[1] += d.to;
                            return acc;
                        },
                        [0, 0],
                    );
                    const nextDay = {
                        day: dayNumber,
                        from: prev.days.length
                            ? Math.round(avgFrom / prev.days.length)
                            : startHour
                            ? startHour
                            : 0,
                        to: prev.days.length
                            ? Math.round(avgTo / prev.days.length)
                            : startHour
                            ? startHour + 1
                            : 24,
                        inheriting: false,
                    };

                    return {
                        ...prev,
                        days: [...prev.days, nextDay],
                    };
                } else {
                    const indexToRemove = prev.days.findIndex(
                        (dA) => dA.day === dayNumber,
                    );
                    prev.days.splice(indexToRemove, 1);
                    return {
                        ...prev,
                    };
                }
            });
        },
        [onChange],
    );
    /**
     * from to from self or inherited
     */
    const { from, to } = useMemo(() => {
        const s = availability.days.find((dA) => dA.day === dayNumber);
        if (!s || s.inheriting) {
            return availability;
        } else if (s) {
            return s;
        } else {
            return { from: 0, to: 24 };
        }
    }, [dayNumber, availability]);
    /**
     * day availability reference
     */
    const avail = useMemo(() => {
        return availability.days.find((dA) => dA.day === dayNumber);
    }, [availability, dayNumber]);
    /**
     * memoized date
     */
    const date = useMemo(() => {
        const start = new Date(
            convertWeekAndYearToDate(availability.year, availability.start),
        );
        const selectedDate = start.toLocaleDateString('de');

        const day = selectedDate.split('.')[0];

        return new Date(
            start.getFullYear(),
            start.getMonth(),
            +day + dayNumber - 1,
        );
    }, [availability, dayNumber]);
    /**
     * memoize if this day is currently selected
     */
    const isSelected = useMemo(() => {
        const selectedDate = currentDate.toLocaleDateString('de');
        const selfDate = date.toLocaleDateString('de');
        return selectedDate === selfDate;
    }, [currentDate, availability]);
    /**
     * return base date
     */
    const baseDate = useMemo(
        () =>
            new Date(
                convertWeekAndYearToDate(availability.year, availability.start),
            ),
        [availability],
    );
    /**
     * memoized time picker readable from
     */
    const fromDate = useMemo(() => {
        const baseDate = new Date(
            convertWeekAndYearToDate(availability.year, availability.start),
        );
        const time = new Date(
            baseDate.getFullYear(),
            baseDate.getMonth(),
            baseDate.getDate(),
            from,
        );
        return time;
    }, [baseDate, from]);
    /**
     * memoized time picker readable from
     */
    const toDate = useMemo(() => {
        const time = new Date(
            baseDate.getFullYear(),
            baseDate.getMonth(),
            baseDate.getDate(),
            to === 24 ? 23 : to,
        );
        return time;
    }, [baseDate, to]);
    /**
     * set from callback
     */
    const setFrom = useCallback(
        (from: number) => changeEntry({ from: +from, inheriting: false }),
        [avail],
    );
    /**
     * set to callback
     */
    const setTo = useCallback(
        (to: number) => changeEntry({ to: +to, inheriting: false }),
        [avail],
    );
    /**
     * render
     */
    return (
        <View
            style={[
                {
                    borderWidth: 1,
                    borderColor: 'transparent',
                    borderRadius: 10,
                    zIndex: 1,
                },
                isSelected && { borderColor: theme.accentColor },
            ]}
        >
            <View
                style={[
                    style.horizontal,
                    style.centeredItems,
                    { paddingVertical: 10 },
                ]}
            >
                <TouchableView
                    style={[style.horizontal, style.centeredItems]}
                    onPress={() => toggleActive(!avail)}
                >
                    <CCheckBox
                        checked={!!avail}
                        onCheckedChanged={toggleActive}
                    />
                    <CText
                        style={style.leftPadded}
                        secondaryHeadline
                        message={`${date.toLocaleDateString('de')} ${day}`}
                    />
                </TouchableView>
            </View>
            {avail && (
                <View
                    style={[
                        {
                            zIndex: 1,
                            borderWidth: 1,
                            borderColor: theme.borderColor,
                            borderRadius: 10,
                            backgroundColor: theme.backgroundSecondaryColor,
                            marginVertical: 10,
                        },
                    ]}
                >
                    <View
                        style={[
                            style.horizontalSplit,
                            style.horizontalHeavyPadded,
                            style.centeredItems,
                            { zIndex: 2 },
                        ]}
                    >
                        <CText message={calendarMessages.from} />
                        <CTimePicker
                            value={fromDate}
                            onChange={(next) => setFrom(next.getHours())}
                            timeIntervals={60}
                        />
                    </View>
                    <View style={style.thinSeparator} />
                    <View
                        style={[
                            style.horizontalSplit,
                            style.horizontalHeavyPadded,
                            style.centeredItems,
                            { zIndex: 1 },
                        ]}
                    >
                        <CText message={calendarMessages.to} />
                        <CTimePicker
                            value={toDate}
                            onChange={(next) => setTo(next.getHours())}
                            timeIntervals={60}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};
