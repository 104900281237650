import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import {
    ExpandableCard,
    CText,
    CPicker,
    DocumentPreviewRow,
    FilePicker,
} from '../../../components';
import {
    EUserFiles,
    EEmployeeFiles,
    EGeneralFiles,
    EProfession,
    EDoctorFiles,
    ESocialWorkerFiles,
    ECollections,
} from '../../../enums';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useLock } from '../../../utilities/hooks';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { profileMessages } from '../Profile.messages';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import { ProfileContext } from '../Profile.context';
import { filenameMessages, generalMessages } from '../../../utilities/messages';
import { MProfessionalProfile, MUserFile } from '../../../models';

export const PSTalentDocuments: FC = () => {
    // global state
    const style = useStyle();
    const { subMenu } = useParams<{ subMenu: string }>();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const { uploadToStorage, getDataById, put } = useFireBase();
    const { curData } = useContext(ProfileContext);
    const dialog = useDialog();
    const { lock } = useLock();
    // local state
    const [talent, setTalent] = useState<MProfessionalProfile>();
    const [currentUploadable, setCurrentUploadable] = useState<EUserFiles>(
        EEmployeeFiles.resume,
    );
    /**
     * memoized list of accessible documents
     */
    const docs = useMemo(() => {
        const generalFiles: string[] = Object.values(EGeneralFiles);
        const employeeFiles: string[] = Object.values(EEmployeeFiles);
        const next = [...generalFiles, ...employeeFiles];
        if (curData.profession === EProfession.doctor) {
            next.push(...Object.values(EDoctorFiles));
        } else if (curData.profession === EProfession.nurse) {
            // * No nurse files
        } else if (curData.profession === EProfession.socialWorker) {
            next.push(...Object.values(ESocialWorkerFiles));
        }
        return next;
    }, [curData]);
    /**
     * callback to handle changes
     */
    const onChange = useCallback((next: Partial<MProfessionalProfile>) => {
        setTalent((prev) => {
            const update = new MProfessionalProfile({ ...prev, ...next });
            if (prev?.documentId) {
                put(ECollections.profProfiles, prev.documentId, update);
            }
            return update;
        });
    }, []);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            if (!talent) return;
            const unlock = lock();
            const fnS = newFn.split('.');
            const ending = fnS.pop();
            const name = fnS.shift();
            const fakeId = Date.now().toString(36);
            const basename = fakeId + name;
            try {
                const nextFM = await uploadToStorage(
                    `${ECollections.agencies}/${talent.agencyId}/profiles/${talent.documentId}/${basename}.${ending}`,
                    file,
                );
                onChange({
                    files: [
                        ...talent.files,
                        new MUserFile({
                            documentId: fakeId,
                            type: currentUploadable,
                            name: `${name}.${ending}`,
                            path: nextFM.fullPath,
                        }),
                    ],
                });
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [currentUploadable, talent],
    );
    /**
     * effect to load talentprofile
     */
    useEffect(() => {
        if (!curData.talentId) return;

        getDataById(ECollections.profProfiles, curData.talentId).then(
            (result) => {
                setTalent(new MProfessionalProfile(result));
            },
        );
    }, [curData]);
    /**
     * render
     */
    return (
        <ExpandableCard
            head={
                <View style={[style.horizontalSplit, { flex: 1 }]}>
                    <CText bold headline>
                        {format(profileMessages.documents)}
                    </CText>
                </View>
            }
            body={
                <>
                    <View style={[style.horizontalWrap, style.centeredItems]}>
                        <View style={[style.flex1]}>
                            <CPicker
                                onChange={setCurrentUploadable}
                                values={docs
                                    .map((d) => {
                                        return {
                                            label: format(
                                                filenameMessages[
                                                    d as EUserFiles
                                                ],
                                            ),
                                            value: d,
                                        };
                                    })
                                    .sort((a, b) =>
                                        a.label > b.label ? 1 : -1,
                                    )}
                                value={currentUploadable}
                                title={format(profileMessages.documentToUpload)}
                            />
                        </View>
                        <View
                            // adjustment to be inline with picker
                            style={{
                                justifyContent: 'flex-end',
                                paddingBottom: 5,
                                alignSelf: 'stretch',
                            }}
                        >
                            <FilePicker onFile={handleFile} />
                        </View>
                    </View>
                    {(talent?.files || []).map((f, i) => {
                        if (!docs.includes(f.type)) {
                            return;
                        }
                        return (
                            <DocumentPreviewRow
                                key={f.documentId}
                                file={f}
                                onDelete={() => {
                                    const next = Array.from(
                                        talent?.files || [],
                                    );
                                    next.splice(i, 1);
                                    onChange({ files: next });
                                }}
                            />
                        );
                    })}
                </>
            }
            customExpand={subMenu === 'documents'}
            customOnClick={async (is) => {
                secureNavigate(is ? '/profile' : '/profile/documents');
            }}
            preventHover
        />
    );
};
