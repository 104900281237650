import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MAgency,
    MAvailability,
    MProfessionalInfo,
    MProfessionalProfile,
} from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections } from '../../../../enums';
import { useStyle, useTheme } from '../../../../utilities/styles';
import {
    CButton,
    CCard,
    CIcon,
    CText,
    ProfilePicture,
} from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import {
    educationMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { profProfileMessages } from '../../../ProfProfile/ProfProfile.messages';
import {
    convertNumberToCurrencyString,
    convertWeekAndYearToDate,
    day,
    week,
} from '../../../../utilities/functions';
import { useEnvironment } from '../../../../utilities/contexts';
import { availabilityMessages } from '../../availabilityMessages';

export const AvailableUserRow: FC<{ availability: MAvailability }> = ({
    availability,
}) => {
    const { getDataById } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { region } = useEnvironment();

    const [resource, setResource] = useState<MProfessionalInfo>();
    const [agency, setAgency] = useState<MAgency>();
    /**
     * style for views
     */
    const viewStyle = useMemo(() => {
        return { flex: 1, justifyContent: 'center' as const };
    }, []);
    /**
     * days translated
     */
    const days = useMemo(() => {
        return [
            format(generalMessages.monday),
            format(generalMessages.tuesday),
            format(generalMessages.wednesday),
            format(generalMessages.thursday),
            format(generalMessages.friday),
            format(generalMessages.saturday),
            format(generalMessages.sunday),
        ];
    }, []);
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        const talentProfile = resource as MProfessionalProfile;
        if (!talentProfile || !talentProfile.wage) return 0;
        const next = talentProfile.wage.wage + talentProfile.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, [resource]);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from = convertWeekAndYearToDate(
            availability.year,
            availability.start,
        );
        const to = new Date(
            from.getTime() + (availability.repeatCount + 1) * week - day,
        );

        return { from, to };
    }, [availability]);
    /**
     * effect to load resource
     */
    useEffect(() => {
        if (availability.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                availability.profileId,
            ).then((r) => setResource(new MProfessionalInfo(r)));
        } else {
            getDataById(ECollections.publicUsers, availability.uid).then((r) =>
                setResource(new MProfessionalInfo(r)),
            );
        }
        if (availability.agencyId) {
            getDataById(
                ECollections.publicAgencies,
                availability.agencyId,
            ).then((r) => setAgency(new MAgency(r)));
        }
    }, []);

    return (
        <CCard
            hoverEffects
            cy={'open-user-' + resource?.lastName}
            style={[style.horizontal]}
            onPress={() => {
                // TODO:
            }}
        >
            {!!resource && <ProfilePicture data={resource} small />}
            <View style={style.flex1}>
                {agency && (
                    <CText secondaryHeadline style={{ marginHorizontal: 5 }}>
                        {agency.name}
                    </CText>
                )}
                {!!resource?.educations.length && (
                    <View style={[style.horizontalWrap, style.centeredItems]}>
                        <CIcon
                            icon="school"
                            size={16}
                            tint={theme.textSecondaryColor}
                        />
                        <CText style={style.leftPadded}>
                            {`${resource.educations
                                .map((edu) =>
                                    format(
                                        educationMessages[
                                            edu as keyof typeof educationMessages
                                        ],
                                    ),
                                )
                                .join(', ')}`}
                        </CText>
                    </View>
                )}
                {!!resource?.yearsOfExperience && (
                    <CText>
                        {`${resource.yearsOfExperience} ${format(
                            profileMessages.yoe,
                        )}`}
                    </CText>
                )}
                {hourlyRate ? (
                    <CText>
                        {`${format(
                            profProfileMessages.rate,
                        )} ${convertNumberToCurrencyString(
                            hourlyRate,
                            region,
                        )}`}
                    </CText>
                ) : (
                    <CText message={profProfileMessages.noRate} />
                )}
                <View style={[style.horizontalSplit]}>
                    <View style={viewStyle}>
                        <CText>
                            {format(availabilityMessages.availableFromTo, {
                                fromDate: from.toLocaleDateString('de'),
                                toDate: to.toLocaleDateString('de'),
                            })}
                        </CText>
                        {availability.repeat && (
                            <View style={viewStyle}>
                                <CText
                                    message={format(
                                        availabilityMessages.repeats,
                                        {
                                            x: availability.repeatCount,
                                        },
                                    )}
                                />
                            </View>
                        )}
                    </View>
                    {/* <View style={viewStyle}>
                        <CText>{availability.year} </CText>
                    </View> */}
                    {/* <View style={viewStyle}>
                        <CText>{availability.note}</CText>
                    </View> */}
                    <View style={viewStyle}>
                        {availability.days.map((d, i) => (
                            <View key={i} style={style.horizontal}>
                                <CText style={style.flex1}>{`${
                                    days[d.day - 1]
                                }`}</CText>
                                <CText style={style.flex1}>{`${
                                    d.inheriting
                                        ? `${availability.from} - ${availability.to}`
                                        : `${d.from} - ${d.to}`
                                }`}</CText>
                            </View>
                        ))}
                    </View>
                </View>
            </View>
            <CButton
                onPress={() => {
                    // TODO:
                }}
                title={availabilityMessages.request}
            />
        </CCard>
    );
};
