import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ECollections, EJobStatus, EUserType } from '../../../../../enums';
import { MApplication, MJob } from '../../../../../models';
import { isPeasant, isSuperUser } from '../../../../../utilities/auth';
import {
    ProfileCompletionContext,
    useEnvironment,
} from '../../../../../utilities/contexts';
import { useDialog } from '../../../../../utilities/dialog';
import { useFireBase } from '../../../../../utilities/firebase';
import { useFormat } from '../../../../../utilities/intl';
import {
    actionMessages,
    generalMessages,
} from '../../../../../utilities/messages';
import { useNavigate } from '../../../../../utilities/routing';
import { applicationMessages } from '../../../../Application/application.messages';
import { jobMessages } from '../../../Job.messages';
import { JobsContext } from '../../JobList';

export const useJobRowState = ({ job }: { job: MJob }) => {
    const { reload } = useContext(JobsContext);
    const { licensedAgency } = useContext(ProfileCompletionContext);
    const dialog = useDialog();
    const format = useFormat();
    const navigate = useNavigate();
    const { environment } = useEnvironment();
    const { userData, getDataIndex, put, userAgencies, isLoggedIn } =
        useFireBase();
    // local state
    const [application, setApplication] = useState<MApplication>();
    const isOwner = useMemo(
        () => job.author === userData.documentId,
        [userData, job],
    );
    /**
     * effect to check if peasent has application to this job listing
     */
    useEffect(() => {
        if (isLoggedIn && isPeasant(userData)) {
            const filter = [
                { field: 'applicantUid', value: userData.documentId },
                { field: 'jobId', value: job.documentId },
            ];
            getDataIndex(ECollections.applications, {
                filter,
            }).then((res) => {
                if ((res as MApplication[]).length) {
                    setApplication(
                        new MApplication(
                            (res as MApplication[]).sort(
                                (a, b) => b.createdOn - a.createdOn,
                            )[0],
                        ),
                    );
                }
            });
        }
    }, [userData, isLoggedIn, job]);
    /**
     * handle quick publish from superUsers || owners
     */
    const handleQuickPublish = useCallback(() => {
        if (
            job.status !== EJobStatus.draft ||
            (!isOwner && !isSuperUser(userData))
        ) {
            return;
        }
        dialog({
            title: format(jobMessages.publish),
            message: format(jobMessages.publishHint),
            buttons: [
                {
                    onPress: () => {
                        put(ECollections.jobs, job.documentId, {
                            ...job,
                            status: EJobStatus.public,
                        }).then(reload);
                    },
                    text: format(jobMessages.publish),
                },
                {
                    text: format(actionMessages.cancel),
                },
            ],
        });
    }, []);
    /**
     * handle apply or throw dialog if user is not ready
     */
    const handleApply = useCallback(() => {
        if (!userData.verified) {
            dialog({
                title: format(applicationMessages.youAreNotVerified),
                message: format(applicationMessages.verifyYourself, {
                    platform: environment,
                }),
                buttons: [
                    {
                        text: format(actionMessages.ok),
                        onPress: () => {
                            navigate(
                                '/helpcenter/SxEn23PTl0nTNBs98B3g/Kusb54mKu0p5QOO98fWp',
                            );
                        },
                    },
                ],
                icon: 'error',
            });
        } else {
            if (userData.type === EUserType.agency && !licensedAgency) {
                dialog({
                    title: applicationMessages.noEmploymentLeasingLicense,
                    message: applicationMessages.noEmploymentLeasingLicenseText,
                    buttons: [
                        {
                            text: actionMessages.ok,
                        },
                        {
                            text: format(applicationMessages.openAgency),
                            onPress: () => {
                                navigate(
                                    '/agency/edit/' +
                                        userAgencies[0].documentId +
                                        '?documents=1',
                                );
                            },
                        },
                    ],
                    verticalButtons: true,
                    icon: 'error',
                });
            } else if (
                userData.profession !== job.employeeProfession &&
                userData.type !== EUserType.agency
            ) {
                dialog({
                    title: format(applicationMessages.wrongType, {
                        typeA: format(generalMessages[job.employeeProfession]),
                        typeB: format(generalMessages[userData.profession]),
                    }),
                    message: format(applicationMessages.wrongTypeDesc, {
                        typeA: format(generalMessages[job.employeeProfession]),
                        typeB: format(generalMessages[userData.profession]),
                    }),
                    buttons: [
                        {
                            text: format(actionMessages.continue),
                            onPress: () => {
                                navigate('/apply/' + job.documentId);
                            },
                        },
                        {
                            text: format(actionMessages.back),
                        },
                    ],
                    icon: 'error',
                });
            } else {
                navigate('/apply/' + job.documentId);
            }
        }
    }, [userData, navigate, job, environment]);
    /**
     * render
     */
    return { handleApply, handleQuickPublish, isOwner, application };
};
