import { createContext } from 'react';
import { MContract } from '../../../../models';

export const ContractNegotiationContext = createContext(
    {} as {
        negotiation: MContract;
        originalNegotiation: MContract;
        onChange: (next: Partial<MContract>) => void;
        handleClose: () => void;
    },
);
