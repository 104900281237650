/**
 * dynamicalli calculated image widht and height based on current screen width: ratio from 1 to 0
 * @param screenWidth
 * @param ratio
 * @param heightRatio
 * @param maxWidth
 * @returns
 */
export const imageSize = (
    screenWidth: number,
    ratio: number,
    heightRatio: number,
    maxWidth: number,
) => {
    let width = screenWidth * ratio;
    if (maxWidth && width > maxWidth) {
        width = maxWidth;
    }

    return {
        width,
        height: width * heightRatio,
    };
};
