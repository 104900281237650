import React, { FC } from 'react';
import { MShift } from '../../../models';
import { CText, TouchableView } from '../../../components';
import { LayoutChangeEvent, View } from 'react-native';
import { useStyle } from '../../../utilities/styles';
import { timeString } from '../../../utilities/functions';

export interface IShiftRowProps {
    toggleEdit?: () => void;
    shift: MShift;
    onChange?: (next: Partial<MShift>) => void;
    onRemove?: () => void;
    onLayout?: (e: LayoutChangeEvent) => void;
    cellWidth: number;
}

export const ShiftRow: FC<IShiftRowProps> = ({
    shift,
    toggleEdit,
    onLayout,
    cellWidth,
}) => {
    const style = useStyle();
    return (
        <TouchableView onLayout={onLayout} onPress={toggleEdit}>
            <View
                style={[
                    style.horizontalSplit,
                    style.centeredItems,
                    { height: 50 },
                ]}
            >
                <View style={[style.centeredItems, { width: cellWidth }]}>
                    <CText
                        message={`${shift.date}.${shift.month}.${shift.year}`}
                    />
                </View>
                <View style={[style.centeredItems, { width: cellWidth }]}>
                    <CText
                        message={timeString(shift.fromHour, shift.fromMinute)}
                    />
                </View>
                <View style={[style.centeredItems, { width: cellWidth }]}>
                    <CText message={timeString(shift.toHour, shift.toMinute)} />
                </View>
                <View style={[style.centeredItems, { width: cellWidth }]}>
                    {shift.breaks.map((b, i) => (
                        <View key={i}>
                            <CText
                                message={`${timeString(
                                    b.fromHour,
                                    b.fromMinute,
                                )} - ${timeString(b.toHour, b.toMinute)}`}
                            />
                        </View>
                    ))}
                </View>
            </View>
        </TouchableView>
    );
};
