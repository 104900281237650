import { defineMessages } from 'react-intl';

const scope = 'con.workplace.';

export const workplaceMessages = defineMessages({
    creating: {
        id: scope + 'creating',
        defaultMessage: 'Creating new Workplace',
    },
    editing: {
        id: scope + 'editing',
        defaultMessage: 'Editing Workplace: {workplace}',
    },
    address: {
        id: scope + 'address',
        defaultMessage: 'Address',
    },
    billingAddress: {
        id: scope + 'billingAddress',
        defaultMessage: 'Billing address',
    },
    billingAddressDiffers: {
        id: scope + 'billingAddressDiffers',
        defaultMessage: 'Billing address is different from address',
    },
    name: {
        id: scope + 'name',
        defaultMessage: 'Workplace name',
    },
    phone: {
        id: scope + 'phone',
        defaultMessage: 'Phone',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'Mail',
    },
    iban: {
        id: scope + 'iban',
        defaultMessage: 'IBAN',
    },
    ibanPlaceholder: {
        id: scope + 'ibanPlaceholder',
        defaultMessage: 'IBAN e.g. DE02120300000000202051',
    },
    ibanInvalidError: {
        id: scope + 'ibanInvalidError',
        defaultMessage: 'IBAN seems to be incorrectly formatted',
    },
    bic: {
        id: scope + 'bic',
        defaultMessage: 'BIC',
    },
    bicPlaceholder: {
        id: scope + 'bicPlaceholder',
        defaultMessage: 'BIC e.g. BYLADEM1001',
    },
    bicInvalidError: {
        id: scope + 'bicInvalidError',
        defaultMessage: 'BIC seems to be incorrectly formatted',
    },
    picture: {
        id: scope + 'picture',
        defaultMessage: 'Picture',
    },
    owner: {
        id: scope + 'owner',
        defaultMessage: 'Owner',
    },
    numberOfEmployees: {
        id: scope + 'numberOfEmployees',
        defaultMessage: 'Number of employees',
    },
    website: {
        id: scope + 'website',
        defaultMessage: 'Website',
    },
    bedPlaceholder: {
        id: scope + 'bedPlaceholder',
        defaultMessage: 'Select number of employees',
    },
    verified: {
        id: scope + 'verified',
        defaultMessage: 'Assigned',
    },
    unverified: {
        id: scope + 'unverified',
        defaultMessage: 'Not assigned',
    },
    zipCode: {
        id: scope + 'zipCode',
        defaultMessage: 'ZIP code',
    },
    editNote: {
        id: scope + 'editNote',
        defaultMessage: 'Edit note',
    },
    finishEditing: {
        id: scope + 'finishEditing',
        defaultMessage: 'Finish editing',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
    createInvite: {
        id: scope + 'createInvite',
        defaultMessage: 'Create new invite',
    },
    users: {
        id: scope + 'users',
        defaultMessage: 'Users',
    },
    invites: {
        id: scope + 'invites',
        defaultMessage: 'Invites',
    },
    multiUserInformation: {
        id: scope + 'multiUserInformation',
        defaultMessage:
            'Please make sure every user in this list is associated with this workplace. Mark users with permission to sign by clicking the signature icon.',
    },
    agencies: {
        id: scope + 'agencies',
        defaultMessage: 'Preferred agencies',
    },
    inviteAgency: {
        id: scope + 'inviteAgency',
        defaultMessage:
            'If a agency you employ is not using the {platform} platform, you can offer them to join by inviting them. If they join, they will automatically be added to your list of preferred agencies.',
    },
    createAgencyInvite: {
        id: scope + 'createAgencyInvite',
        defaultMessage: 'Invite new agency',
    },
    createAgencyInviteText: {
        id: scope + 'createAgencyInviteText',
        defaultMessage:
            'Please enter the e-mail address of the agency or agencies you want to invite. Your name and workplace information will be used in the invite text.',
    },
    recipientPlaceholder: {
        id: scope + 'recipientPlaceholder',
        defaultMessage: 'e.g john@doe.com; max@musterman.de',
    },
    addAgency: {
        id: scope + 'addAgency',
        defaultMessage: 'Add agency',
    },
    idOfAgencyToAdd: {
        id: scope + 'idOfAgencyToAdd',
        defaultMessage: 'ID of agency to add',
    },
    idOfAgencyToAddPlaceholder: {
        id: scope + 'idOfAgencyToAddPlaceholder',
        defaultMessage:
            'Ask the agency for their agency ID and paste it here to add them to your preferred agencies.',
    },
    preferredAgenciesInfo: {
        id: scope + 'preferredAgenciesInfo',
        defaultMessage:
            'Preferred agencies will be separately notified of job listings you create and have access to your private listings. Their applications will be highlighted over others.',
    },
    agencyInviteSent: {
        id: scope + 'agencyInviteSent',
        defaultMessage: 'Successfully sent invite',
    },
    agencyInviteSentText: {
        id: scope + 'agencyInviteSentText',
        defaultMessage: 'You have sent an invite to {recipients}.',
    },
    didNotFindAgency: {
        id: scope + 'didNotFindAgency',
        defaultMessage: 'Unable to add agency',
    },
    didNotFindAgencyText: {
        id: scope + 'didNotFindAgencyText',
        defaultMessage:
            'We could not find the agency you wanted to add in our database.',
    },
    openAgencyInvites: {
        id: scope + 'openAgencyInvites',
        defaultMessage: 'Open invites',
    },
});
