import { defineMessages } from 'react-intl';

const scope = 'fn.';
/**
 * filenames
 */
export const filenameMessages = defineMessages({
    approbation: {
        id: scope + 'approbation',
        defaultMessage: 'Medical license',
    },
    id: {
        id: scope + 'id',
        defaultMessage: 'Id / Passport / Drivers License',
    },
    police: {
        id: scope + 'police',
        defaultMessage: 'Police clearance certificate',
    },
    educert: {
        id: scope + 'educert',
        defaultMessage: 'Educational certificate',
    },
    resume: { id: scope + 'resume', defaultMessage: 'Personal resume' },
    license: { id: scope + 'license', defaultMessage: 'License' },
    leasingContract: {
        id: scope + 'leasingContract',
        defaultMessage: 'Leasing contract',
    },
    equalPayDeclaration: {
        id: scope + 'equalPayDeclaration',
        defaultMessage: 'Equal pay declaration',
    },
    houseRules: {
        id: scope + 'houseRules',
        defaultMessage: 'House rules',
    },
    employmentContract: {
        id: scope + 'employmentContract',
        defaultMessage: 'Employment contract',
    },
    locationPlan: {
        id: scope + 'locationPlan',
        defaultMessage: 'Location Plan',
    },
    contactDetails: {
        id: scope + 'contactDetails',
        defaultMessage: 'Contact details',
    },
    educationCertificate: {
        id: scope + 'educationCertificate',
        defaultMessage: 'Education certificate',
    },
    driversLicense: {
        id: scope + 'driversLicense',
        defaultMessage: 'Drivers license',
    },
    timesheetTemplate: {
        id: scope + 'timesheetTemplate',
        defaultMessage: 'Timesheet template',
    },
    timesheet: {
        id: scope + 'timesheet',
        defaultMessage: 'Timesheet',
    },
    other: {
        id: scope + 'other',
        defaultMessage: 'Other',
    },
    kidsCertificate: {
        id: scope + 'kidsCertificate',
        defaultMessage:
            'Extended police clearance for good conduct with children',
    },
    infectionCertificate: {
        id: scope + 'infectionCertificate',
        defaultMessage: 'Infection protection certificate',
    },
    measleCertificate: {
        id: scope + 'measleCertificate',
        defaultMessage: 'Proof of protection against measles',
    },
    firstAidKid: {
        id: scope + 'firstAidKid',
        defaultMessage: 'First aid for kids',
    },
});
