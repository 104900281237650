import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { CButton, CCalendar, CCard, CPicker, CText } from '../../components';
import { ECollections, EJobStatus, EUserType } from '../../enums';
import { EContractStatus } from '../../enums/EContractStatus';
import {
    IDayAvailability,
    MAvailability,
    MContract,
    MJob,
    MProfessionalProfile,
} from '../../models';
import { isAgencyUser, isEmployer, isPeasant } from '../../utilities/auth';
import { ScrollContext, ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFireBase } from '../../utilities/firebase';
import { IFilter } from '../../utilities/firebase/store';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../../utilities/functions';
import { useFormat } from '../../utilities/intl';
import { generalMessages } from '../../utilities/messages/general.messages';
import { monthMessages } from '../../utilities/messages/month.messages';
import { useNavigate, useSearchParams } from '../../utilities/routing';
import { useStyle } from '../../utilities/styles';
import { ContractSideCard } from '../Contract/components/ContractSideCard';
import { jobMessages } from '../Job/Job.messages';
import { JobSideCard } from '../JobApplications/View/components/JobSideCard';
import { calendarMessages } from './Calendar.messages';
import { ICalendarProps } from './ICalendarProps';
import { DayHours } from './components/DayHours';
import { TodaysContracts } from './components/TodaysContracts';
import { TodaysJobs } from './components/TodaysJobs';
import { EditAvailability2 } from './components/EditAvailability.v2';
import { educationMessages } from '../../utilities/messages';
/**
 * day messages to map getDay value to
 */
const days = [
    generalMessages.sunday,
    generalMessages.monday,
    generalMessages.tuesday,
    generalMessages.wednesday,
    generalMessages.thursday,
    generalMessages.friday,
    generalMessages.saturday,
];
/**
 * web calendar
 * @param param0
 * @returns
 */
const UnwrappedCalendar: FC<ICalendarProps> = ({ embedded }) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { getDataIndex, userData, userWorkplaces, userAgencies } =
        useFireBase();
    const { setY, maxScrollY } = useContext(ScrollContext);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    // local state
    const [availabilities, setAvailabilities] = useState<MAvailability[]>([]);
    const [negotiations, setNegotiations] = useState<MContract[]>([]);
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [curDate, setCurDate] = useState(new Date());
    const [curMonth, setCurMonth] = useState(new Date().getMonth());
    const [edit, setEdit] = useState(false);
    const [prev, setPrev] = useState<MAvailability>();
    const [reload, setReload] = useState(Date.now());
    const [selectedHour, setSelectedHour] = useState<number>();
    const [returnToProfile, setReturnToProfile] = useState(false);
    const [range, setRange] = useState<string[]>([]);
    const [thisMonthsJobs, setThisMonthsJobs] = useState<MJob[]>([]);
    const [selectedJob, setSelectedJob] = useState<MJob>();
    const [selectedContract, setSelectedContract] = useState<MContract>();
    const [profiles, setProfiles] = useState<MProfessionalProfile[]>([]);
    const [manualySelectedProfile, setSelectedProfile] =
        useState<MProfessionalProfile>();

    const selectedProfile = useMemo(() => {
        if (manualySelectedProfile) {
            return manualySelectedProfile;
        }
        const urlId = params.get('profileId');
        const urlSelectedProfile = profiles.find((p) => p.documentId === urlId);
        if (urlId && urlSelectedProfile) {
            return urlSelectedProfile;
        }
    }, [manualySelectedProfile, profiles, params]);

    const enableEdit = useCallback(
        (next: boolean) => {
            if (
                userData.type === EUserType.user ||
                userData.type === EUserType.agency
            ) {
                setEdit(next);
            } else {
                navigate('/job/new?from=' + curDate.getTime());
            }
        },
        [curDate],
    );

    const closeSelection = useCallback(() => {
        setSelectedJob(() => undefined);
        setSelectedContract(() => undefined);
        setRange([]);
    }, []);
    /**
     * effect to load availabilities for current user
     */
    useEffect(() => {
        if (!selectedProfile && userData.type === EUserType.agency) return;
        const filter: IFilter[] = [];
        if (selectedProfile) {
            filter.push({
                field: 'profileId',
                value: selectedProfile.documentId,
            });
        } else {
            filter.push({ field: 'uid', value: userData.documentId });
        }
        if (userAgencies.length) {
            filter.push({
                field: 'agencyId',
                value: userAgencies[0].documentId,
            });
        }
        getDataIndex(ECollections.availabilities, {
            filter,
        }).then((results) => {
            const parsedResult = (results as MAvailability[]).map(
                (r) => new MAvailability(r),
            );
            setAvailabilities(parsedResult);
            const aId = params.get('aId');
            if (aId && !returnToProfile) {
                const aoi = parsedResult.find((a) => a.documentId === aId);
                if (aoi) {
                    setEdit(true);
                    setPrev(aoi);
                    setReturnToProfile(true);
                    setCurDate(convertWeekAndYearToDate(aoi.year, aoi.start));
                }
            }
        });
    }, [reload, params, selectedProfile, userAgencies]);
    /**
     * effect to load profiles for current user
     */
    useEffect(() => {
        if (userAgencies.length) {
            getDataIndex(ECollections.profProfiles, {
                filter: [
                    { field: 'agencyId', value: userAgencies[0].documentId },
                ],
            }).then((result) => {
                setProfiles(
                    (result as MProfessionalProfile[]).map(
                        (r) => new MProfessionalProfile(r),
                    ),
                );
            });
        }
    }, [userAgencies]);
    /**
     * effect to load negotiations for current user if typeof user
     */
    useEffect(() => {
        if (isPeasant(userData) || isEmployer(userData)) {
            const filter = [] as IFilter[];

            if (userData.type === EUserType.user) {
                filter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            } else if (isAgencyUser(userData)) {
                filter.push({
                    field: 'agencyId',
                    value: userAgencies.map((a) => a.documentId),
                    operator: 'in',
                });
            } else if (isEmployer(userData)) {
                filter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((wp) => wp.documentId),
                    operator: 'in',
                });
            }
            getDataIndex(ECollections.contracts, {
                filter: [
                    ...filter,
                    {
                        field: 'status',
                        value: [
                            EContractStatus.accepted,
                            EContractStatus.review_yee,
                            EContractStatus.review_yer,
                        ],
                        operator: 'in',
                    },
                ],
            }).then((res) => {
                setNegotiations(
                    (res as MContract[]).map((n) => new MContract(n)),
                );
            });
            getDataIndex(ECollections.contracts, {
                filter: [
                    ...filter,
                    {
                        field: 'status',
                        value: [EContractStatus.signed],
                        operator: 'in',
                    },
                ],
            }).then((res) => {
                setContracts((res as MContract[]).map((c) => new MContract(c)));
            });
        }
    }, [userData, userWorkplaces, userAgencies]);
    /**
     * effect to load jobs for currentworkplace  user if typeof employer
     */
    useEffect(() => {
        if (userData.type === EUserType.employer) {
            getDataIndex(ECollections.jobs, {
                filter: [
                    {
                        field: 'workPlaceId',
                        operator: 'in',
                        value: userWorkplaces.map((wp) => wp.documentId),
                    },
                    {
                        field: 'activeMonths',
                        operator: 'array-contains',
                        value: curMonth,
                    },
                    {
                        field: 'status',
                        operator: 'in',
                        value: [EJobStatus.private, EJobStatus.public],
                    },
                ],
            }).then((res) =>
                setThisMonthsJobs((res as MJob[]).map((n) => new MJob(n))),
            );
        }
    }, [userWorkplaces, curMonth]);
    /**
     * effect to scroll to bottom on edit change if edit active
     */
    useEffect(() => {
        if (edit) {
            setY(maxScrollY);
        }
    }, [edit, maxScrollY]);
    /**
     * matched availabilities to current day
     */
    const matchedA = useMemo(() => {
        return availabilities.reduce((acc, a) => {
            const { start, year } = a;
            const startDate = convertWeekAndYearToDate(year, start);

            const [dayOfInterest, monthOfInterest] = curDate
                .toLocaleDateString('de')
                .split('.');

            const filtered = a.days.filter((d) => {
                const dayOffset = (d.day - 1) * day;
                let midWeekDate = new Date(startDate.getTime() + week / 2);
                const match = [
                    0,
                    ...Array.from(Array(a.repeatCount)).map((_, i) => i + 1),
                ].find(() => {
                    const wn = getWeekNumber(midWeekDate);
                    const wyear = midWeekDate.getFullYear();
                    const tempDayDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            dayOffset,
                    );
                    const selectedDate = tempDayDate.toLocaleDateString('de');
                    const [curDay, curMonth] = selectedDate.split('.');

                    midWeekDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            week * 1.5,
                    );

                    return (
                        +curDay === +dayOfInterest &&
                        curMonth === monthOfInterest
                    );
                });
                return match !== undefined;
            });
            filtered.forEach((dayAv) => {
                if (dayAv.inheriting) {
                    acc.push({
                        today: { ...dayAv, from: a.from, to: a.to },
                        parent: a,
                    });
                } else {
                    acc.push({ today: dayAv, parent: a });
                }
            });

            return acc;
        }, [] as { today: IDayAvailability; parent: MAvailability }[]);
    }, [availabilities, curDate]);
    /**
     * handle modal close callback
     */
    const handleModalClose = useCallback(() => {
        setEdit(false);
        setPrev(undefined);
        setReload(Date.now());
        setSelectedHour(undefined);
    }, [setEdit, setPrev, setReload]);
    /**
     * render
     */
    return (
        <>
            {!embedded && (
                <View style={[style.card]}>
                    <View style={[style.horizontalWrap, style.horizontalSplit]}>
                        <View style={style.verticalPadded}>
                            <CText bold headline>
                                {`${format(
                                    generalMessages.calendar,
                                )} - ${format(
                                    days[curDate.getDay()],
                                )} ${curDate.toLocaleDateString('de')}`}
                            </CText>
                        </View>
                        {!edit && (
                            <View style={style.centeredContent}>
                                {isPeasant(userData) ? (
                                    <CButton
                                        icon={'plus'}
                                        title={format(
                                            calendarMessages.addAvailability,
                                        )}
                                        onPress={() => enableEdit(true)}
                                    />
                                ) : (
                                    <CButton
                                        icon={'plus'}
                                        title={format(jobMessages.createNew)}
                                        onPress={() =>
                                            navigate(
                                                '/job/new?from=' +
                                                    curDate.getTime(),
                                            )
                                        }
                                    />
                                )}
                            </View>
                        )}
                    </View>
                </View>
            )}
            {!!profiles.length && !params.get('profileId') && (
                <CCard>
                    <CText
                        secondaryHeadline
                        message={generalMessages.professionalProfile}
                    />
                    <CPicker
                        onChange={(value: number) => {
                            setSelectedProfile(profiles[value - 1]);
                        }}
                        values={profiles
                            .filter((pp) => pp.educations.length)
                            .map((pp, i) => {
                                const key = pp
                                    .educations[0] as keyof typeof educationMessages;
                                return {
                                    value: i + 1,
                                    label: `${pp.lastName} ${
                                        educationMessages[key]
                                            ? format(educationMessages[key])
                                            : ''
                                    }`,
                                };
                            })}
                        value={
                            selectedProfile
                                ? profiles.findIndex(
                                      (p) =>
                                          p.documentId ===
                                          selectedProfile.documentId,
                                  ) + 1
                                : 0
                        }
                        placeholder={generalMessages.professionalProfile}
                        horizontal
                    />
                </CCard>
            )}
            <View style={[style.horizontal, { flex: 1, width: '100%' }]}>
                <View style={[style.card, { flex: 1.5 }]}>
                    <View
                        style={[
                            style.centeredItems,
                            style.horizontalSpaced,
                            style.horizontalWrap,
                        ]}
                    >
                        <View style={[{ flex: 1 }, style.centeredItems]}>
                            <CPicker
                                title={calendarMessages.month}
                                value={curDate.getMonth() + 1}
                                values={Object.keys(monthMessages)
                                    .filter((m) => !m.includes('Short'))
                                    .map((m, i) => ({
                                        label: monthMessages[
                                            m as keyof typeof monthMessages
                                        ],
                                        value: i + 1,
                                    }))}
                                onChange={(next) =>
                                    setCurDate((prev) => {
                                        const nextD = new Date(prev);
                                        nextD.setMonth(next - 1);
                                        if (
                                            prev.getMonth() !== nextD.getMonth()
                                        ) {
                                            setCurMonth(nextD.getMonth());
                                        }
                                        return nextD;
                                    })
                                }
                                horizontal
                            />
                        </View>
                        <CButton
                            small
                            title={format(generalMessages.selectToday)}
                            onPress={() =>
                                setCurDate((prev) => {
                                    if (
                                        prev.getMonth() !==
                                        new Date().getMonth()
                                    ) {
                                        setCurMonth(new Date().getMonth());
                                    }
                                    return new Date();
                                })
                            }
                        />
                        <View style={[{ flex: 1 }, style.centeredItems]}>
                            <CPicker
                                horizontal
                                title={calendarMessages.year}
                                value={curDate.getFullYear()}
                                values={Array.from(
                                    new Set([
                                        ...Array(10).keys(),
                                        curDate.getFullYear() -
                                            (new Date().getFullYear() - 2),
                                    ]),
                                ).map((y, i) => ({
                                    label: `${
                                        y + new Date().getFullYear() - 2
                                    }`,
                                    value: y + new Date().getFullYear() - 2,
                                }))}
                                onChange={(next) =>
                                    setCurDate((prev) => {
                                        const nextD = new Date(prev);
                                        nextD.setFullYear(next);
                                        if (
                                            prev.getMonth() !== nextD.getMonth()
                                        ) {
                                            setCurMonth(nextD.getMonth());
                                        }
                                        return nextD;
                                    })
                                }
                            />
                        </View>
                    </View>
                    <CCalendar
                        setCurDate={(next) =>
                            setCurDate((prev) => {
                                if (prev.getMonth() !== next.getMonth()) {
                                    setCurMonth(next.getMonth());
                                }
                                return next;
                            })
                        }
                        curDate={curDate}
                        availabilities={availabilities}
                        range={range.length ? range : undefined}
                        negotiations={negotiations}
                        contracts={contracts}
                        jobs={thisMonthsJobs}
                    />
                </View>
                {selectedJob && (
                    <JobSideCard
                        job={selectedJob}
                        notInJobApplicationsView
                        onClose={closeSelection}
                    />
                )}
                {selectedContract && (
                    <ContractSideCard
                        contract={selectedContract}
                        onClose={closeSelection}
                    />
                )}
            </View>
            {isEmployer(userData) ? (
                <>
                    <TodaysContracts
                        curDate={curDate}
                        setRange={setRange}
                        selectedContract={selectedContract}
                        setSelectedContract={(next) => {
                            setSelectedContract(next);
                            setSelectedJob(undefined);
                        }}
                        contracts={contracts}
                    />
                    <TodaysJobs
                        curDate={curDate}
                        setRange={setRange}
                        selectedJob={selectedJob}
                        setSelectedJob={(next) => {
                            setSelectedContract(undefined);
                            setSelectedJob(next);
                        }}
                        thisMonthsJobs={thisMonthsJobs}
                    />
                </>
            ) : !edit ? (
                <DayHours
                    todaysAvailabilities={matchedA}
                    setSelectedHour={setSelectedHour}
                    setEdit={enableEdit}
                    setPrev={setPrev}
                    returnToProfile={returnToProfile}
                />
            ) : (
                <EditAvailability2
                    close={handleModalClose}
                    startDate={curDate}
                    startHour={selectedHour}
                    prev={prev}
                    returnToProfile={returnToProfile}
                    selectedProfile={selectedProfile}
                />
            )}
        </>
    );
};

export const Calendar: FC<ICalendarProps> = ({ embedded }) => {
    const style = useStyle();
    return (
        <ScrollProvider style={!embedded && style.paddedScrollableMainView}>
            <UnwrappedCalendar />
        </ScrollProvider>
    );
};
