import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { CButton, CCheckBox, CText, Spinner } from '../../components';
import PDFViewer from '../../components/PDFViewer';
import { ECollections, EEnvironment } from '../../enums';
import { MProfessionalProfile, MUserData } from '../../models';
import { useEnvironment } from '../../utilities/contexts';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useDialog, useUnsavedChangesDialog } from '../../utilities/dialog';
import { useFireBase } from '../../utilities/firebase';
import { LanguageContext, useFormat } from '../../utilities/intl';
import { actionMessages, generalMessages } from '../../utilities/messages';
import {
    useNavigate,
    useParams,
    useSecureNavigate,
} from '../../utilities/routing';
import { useStyle, useTheme } from '../../utilities/styles';
import { profProfileMessages } from '..//ProfProfile/ProfProfile.messages';
import { ProfessionalHistory } from '../ProfProfile/Edit/components/ProfessionalHistory';
import { ProfessionalInformation } from '../ProfProfile/Edit/components/ProfessionalInformation';
import { ProfessionalLanguage } from '../ProfProfile/Edit/components/ProfessionalLanguage';
import { ProfileContext } from '../Profile/Profile.context';
/**
 * Resume
 * used by agencies and admins to view & edit profiles
 *
 * ! Opens user context with excessive values to prevent new context type
 * @returns
 */
export const Resume: FC = () => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { environment } = useEnvironment();
    const { setNavigationLock } = useSecureNavigate();
    const navigate = useNavigate();
    const format = useFormat();
    const dialog = useDialog();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const { userData, post, put, remove, callFunction, reloadUserData } =
        useFireBase();
    const { id } = useParams<{ id: string }>();
    const { language } = useContext(LanguageContext);
    // local state
    const [resumeFilename, setFilename] = useState<{
        fn: string;
        issued: number;
    }>({ fn: '', issued: Date.now() });
    const [profInfos, setProfInfos] = useState<MProfessionalProfile>();
    const [prevProfInfos, setPrevProfInfos] = useState<MProfessionalProfile>();
    const [curValidity, setCurValidity] = useState<any>({});
    const [previewAnonym, setPreviewAnonym] = useState(false);
    /**
     * memoized current data as userdata for context
     */
    const { curData, prevData } = useMemo(() => {
        return {
            curData: new MUserData(profInfos),
            prevData: new MUserData(prevProfInfos),
        };
    }, [profInfos, prevProfInfos]);
    /**
     * memoized value if any content differs from content initial loadet
     */
    const unsavedChanges = useMemo(
        () => JSON.stringify(profInfos) !== JSON.stringify(prevProfInfos),
        [profInfos, prevProfInfos],
    );
    /**
     * memoized iterable validities
     */
    const validities = useMemo(() => {
        const keys = Object.keys(curValidity) as (keyof typeof curValidity)[];

        return keys
            .filter((k) => !!curValidity[k])
            .map((k) => {
                return { key: k, value: curValidity[k] };
            });
    }, [curValidity]);
    /**
     * callback to handle changes based on next data and index
     */
    const handleChange = useCallback(
        (change: Partial<MProfessionalProfile>) => {
            setProfInfos((prev) => {
                return new MProfessionalProfile({ ...(prev || {}), ...change });
            });
        },
        [],
    );
    /**
     * callback to handle save
     */
    const handleSave = useCallback(() => {
        put(ECollections.users, userData.documentId, {
            ...userData,
            ...profInfos,
        }).then(() => {
            setTimeout(reloadUserData, 1000);
        });
    }, [profInfos, userData, id, post, put]);
    /**
     * callback to handle delete
     */
    const handleDelete = useCallback(() => {
        if (profInfos)
            dialog({
                title: profProfileMessages.confirmDelete,
                message: profProfileMessages.confirmDeleteMessage,
                buttons: [
                    {
                        text: actionMessages.delete,
                        onPress: () =>
                            remove(
                                ECollections.profProfiles,
                                profInfos.documentId,
                            ).then(() => navigate(-1)),
                        color: theme.errorColor,
                    },
                ],
                cancelButton: {
                    text: actionMessages.cancel,
                },
            });
    }, [profInfos]);
    /**
     * navigation lock. ask for discard before navigation
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        if (locking) {
            return true;
        } else {
            return false;
        }
    }, [unsavedChanges]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => {
        setNavigationLock(navigationLock);
    }, [navigationLock]);
    /**
     * effect to load user
     */
    useEffect(() => {
        const next = new MProfessionalProfile(userData);

        setProfInfos(next);
        setPrevProfInfos(next);
    }, [userData]);
    /**
     * effect to load resume
     */
    useEffect(() => {
        if (!prevProfInfos) {
            return;
        }

        callFunction('getResume', {
            anonym: previewAnonym,
            timeployees: environment === EEnvironment.TimePloyees,
            lang: language,
            timestamp: Date.now(),
        }).then((nextFN) => setFilename({ fn: nextFN, issued: Date.now() }));
    }, [prevProfInfos, previewAnonym, language]);
    /**
     * display spinner during load
     */
    if (!profInfos) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData,
                prevData,
                onChange: handleChange,
                onValidityChange: (v, key) =>
                    setCurValidity((prev: any) => {
                        if (key) {
                            const next = {} as any;
                            next[key] = v;
                            return { ...prev, ...next };
                        }
                        return { ...prev, basic: v };
                    }),
            }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <View style={[style.headlineCard, style.centeredItems]}>
                    <View style={style.horizontal}>
                        <CText
                            message={generalMessages.resume}
                            bold
                            headline
                            style={style.horizontalPadded}
                        />
                    </View>
                </View>

                {/* resume preview for users */}
                <View style={style.horizontalWrap}>
                    <View style={[style.card]}>
                        <CText bold style={style.verticalPadded}>
                            {format(profProfileMessages.previewResume)}
                        </CText>
                        {resumeFilename.fn ? (
                            <PDFViewer
                                filename={resumeFilename.fn}
                                issuedOn={resumeFilename.issued}
                            />
                        ) : (
                            <View>
                                <Spinner />
                                <CText centered>
                                    {format(generalMessages.loadingResume)}
                                </CText>
                            </View>
                        )}
                    </View>
                    <View style={[style.card, { flex: 1, minWidth: 200 }]}>
                        <CText bold>{format(generalMessages.options)}</CText>
                        <CCheckBox
                            checked={previewAnonym}
                            onCheckedChanged={setPreviewAnonym}
                            title={format(profProfileMessages.previewAnonym)}
                        />
                    </View>
                </View>
                <View
                    style={[
                        style.card,
                        !curData.educations.length && style.errorBorder,
                    ]}
                >
                    <ProfessionalInformation />
                </View>
                <View
                    style={[style.card, curValidity.lang && style.errorBorder]}
                >
                    <ProfessionalLanguage />
                </View>
                <View style={style.card}>
                    <ProfessionalHistory />
                </View>
                <View style={style.verticalPadded}>
                    <View style={style.horizontalSplit}>
                        <View></View>
                        <View style={{ alignItems: 'flex-end' }}>
                            {!!validities.length && (
                                <View style={{ marginHorizontal: 'auto' }}>
                                    {validities.map((v) => (
                                        <CText
                                            key={v.key as string}
                                            style={style.error}
                                        >
                                            {v.value}
                                        </CText>
                                    ))}
                                </View>
                            )}
                            {unsavedChanges && (
                                <CText style={style.warning}>
                                    {format(generalMessages.unsavedChanges)}
                                </CText>
                            )}
                            <View style={style.horizontal}>
                                {!!profInfos.documentId && (
                                    <CButton
                                        onPress={handleDelete}
                                        title={format(actionMessages.delete)}
                                        danger
                                    />
                                )}
                                <CButton
                                    disabled={!!validities.length}
                                    onPress={handleSave}
                                    title={format(actionMessages.save)}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
