import React, { FC } from 'react';
import { Pressable, View } from 'react-native';

import { useStyle, useTheme } from '../../../utilities/styles';
import { CButton, CIcon, CText } from '../../../components';
import { TouchableView } from '../../../components/TouchableView';
import { IDayAvailability, MAvailability } from '../../../models';
import { useSecureNavigate } from '../../../utilities/routing';
import { calendarMessages } from '../Calendar.messages';
import { useFormat } from '../../../utilities/intl';

interface IDayHoursProps {
    returnToProfile: boolean;
    todaysAvailabilities: { today: IDayAvailability; parent: MAvailability }[];
    setSelectedHour: (next?: number) => void;
    setEdit: (next: boolean) => void;
    setPrev: (next?: MAvailability) => void;
}

export const DayHours: FC<IDayHoursProps> = ({
    todaysAvailabilities,
    setSelectedHour,
    setEdit,
    setPrev,
    returnToProfile,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();

    return (
        <View style={[style.card]}>
            {returnToProfile && (
                <View style={style.horizontal}>
                    <CButton
                        small
                        icon={'chevronLeft'}
                        title={format(calendarMessages.returnToProfile)}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                    />
                </View>
            )}
            <View style={[style.horizontal, { paddingTop: 10 + 25 / 2 }]}>
                <View style={{ marginTop: -25 / 2 }}>
                    {Array.from(new Array(25)).map((v, i) => {
                        const digits = `${i}:00`.split('');
                        if (digits.length < 5) {
                            digits.unshift('0');
                        }
                        return (
                            <View
                                key={i}
                                style={{
                                    width: 40,
                                    height: 25,
                                    alignItems: 'flex-end',
                                    marginRight: 5,
                                }}
                            >
                                <View style={style.horizontal}>
                                    {digits.map((c, i) => (
                                        <View
                                            key={i}
                                            style={{
                                                width: 8,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CText>{c}</CText>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        );
                    })}
                </View>
                {!todaysAvailabilities.length && (
                    <View style={{ flex: 1 }}>
                        {Array.from(new Array(24)).map((v, i) => (
                            <View key={i} style={style.horizontal}>
                                <Pressable
                                    style={[
                                        style.horizontal,
                                        {
                                            height: 25,
                                            flex: 1,
                                            borderWidth: 1,
                                            borderColor: 'transparent',
                                            borderBottomColor:
                                                theme.borderColor,
                                        },
                                        i === 0 && {
                                            borderTopColor: theme.borderColor,
                                        },
                                        i === 23 && {
                                            borderBottomColor:
                                                theme.borderColor,
                                        },
                                    ]}
                                    onPress={() => {
                                        setEdit(true);
                                        setSelectedHour(i);
                                    }}
                                ></Pressable>
                            </View>
                        ))}
                    </View>
                )}
                {todaysAvailabilities.map(({ today, parent }) => (
                    <View key={parent.documentId} style={{ flex: 1 }}>
                        {Array.from(new Array(24)).map((v, i) => {
                            const adjustedFrom = today.from;
                            const marked = adjustedFrom <= i && today.to > i;
                            const first = adjustedFrom === i;
                            const last = today.to === i + 1;
                            const i0 = i === 0;
                            const i24 = i === 23;

                            return (
                                <View key={i} style={style.horizontal}>
                                    <TouchableView
                                        style={[
                                            style.horizontal,
                                            {
                                                height: 25,
                                                flex: 1,
                                                borderWidth: 1,
                                                borderColor: 'transparent',
                                                borderBottomColor:
                                                    theme.borderColor,
                                                backgroundColor: marked
                                                    ? theme.successColor
                                                    : undefined,
                                            },
                                            i0 && {
                                                borderTopColor:
                                                    theme.borderColor,
                                            },
                                            i24 && {
                                                borderBottomColor:
                                                    theme.borderColor,
                                            },
                                            marked && {
                                                borderColor: 'transparent',
                                                borderBottomColor:
                                                    'transparent',
                                            },
                                            first && {
                                                borderTopLeftRadius: 5,
                                                borderTopRightRadius: 5,
                                            },
                                            last && {
                                                borderBottomLeftRadius: 5,
                                                borderBottomRightRadius: 5,
                                            },
                                        ]}
                                        onPress={() => {
                                            setEdit(true);

                                            if (marked) {
                                                setPrev(parent);
                                            } else {
                                                setSelectedHour(i);
                                            }
                                        }}
                                    >
                                        {first && (
                                            <View
                                                style={[
                                                    style.horizontal,
                                                    style.centeredItems,
                                                ]}
                                            >
                                                {parent.repeat &&
                                                    parent.repeatCount > 0 && (
                                                        <View
                                                            style={[
                                                                style.horizontal,
                                                                style.centeredItems,
                                                                style.horizontalPadded,
                                                            ]}
                                                        >
                                                            <CIcon
                                                                icon="repeat"
                                                                tint={
                                                                    theme.textSecondaryColor
                                                                }
                                                            />
                                                            <CText>
                                                                {parent.repeatCount +
                                                                    1}
                                                            </CText>
                                                        </View>
                                                    )}
                                                <CText>{parent.note}</CText>
                                            </View>
                                        )}
                                    </TouchableView>
                                </View>
                            );
                        })}
                    </View>
                ))}
            </View>
        </View>
    );
};
