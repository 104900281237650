import { EEmploymentType, EField, EProfession, ERegion } from '../enums';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../utilities/functions';

export interface IDayAvailability {
    from: number;
    to: number;
    inheriting: boolean;
    day: number;
}

export class MAvailability {
    public documentId = '';
    public uid = '';
    public agencyId?: string;
    public profileId?: string;
    public employmentType = EEmploymentType.personnelLeasing;
    public title = '';
    /**
     * 0 - 24
     */
    public from = 0;
    /**
     * from - from + 24
     */
    public to = 24;
    /**
     * 1 - 7
     */
    public days: IDayAvailability[] = [];
    /**
     * kw
     */
    public start = 0;
    public year = 2022;

    public repeat = false;
    public repeatCount = 0;

    public note = '';

    public field = EField.undefined;
    public profession = EProfession.undefined;
    public educations: string[] = [];
    public region = ERegion.de;

    public activeDates: string[] = [];
    public activeMonths: number[] = [];
    public january?: boolean;
    public february?: boolean;
    public march?: boolean;
    public april?: boolean;
    public may?: boolean;
    public june?: boolean;
    public july?: boolean;
    public august?: boolean;
    public september?: boolean;
    public october?: boolean;
    public november?: boolean;
    public december?: boolean;

    public editedOn = Date.now();
    public createdOn = Date.now();

    constructor(params: Partial<MAvailability> = {}) {
        Object.assign(this, params);

        MAvailability.fixConstructor(this, params);
    }

    static fixConstructor(that: MAvailability, params: Partial<MAvailability>) {
        if (
            params.days &&
            params.start &&
            params.year &&
            (!params.activeDates ||
                !params.activeDates.length ||
                !params.activeMonths ||
                !params.activeMonths.length)
        ) {
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            const startDate = new Date(
                convertWeekAndYearToDate(params.year, params.start),
            );
            for (const d of params.days) {
                const dayOffset = d.day * day;
                let weekStartDate = new Date(startDate.getTime());
                [
                    0,
                    ...Array.from(Array(params.repeatCount)).map(
                        (_, i) => i + 1,
                    ),
                ].forEach(() => {
                    const wn = getWeekNumber(new Date(weekStartDate));
                    const wyear = new Date(weekStartDate).getFullYear();
                    const tempDayDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            dayOffset,
                    );
                    nextActiveDates.push(tempDayDate.toLocaleDateString('de'));
                    const curMonth = tempDayDate.getMonth();

                    if (!nextActiveMonths.includes(curMonth)) {
                        nextActiveMonths.push(curMonth);
                    }

                    weekStartDate = new Date(
                        convertWeekAndYearToDate(wyear, wn).getTime() +
                            week * 1.5,
                    );
                });
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;
        }
        that.january = that.activeMonths.includes(0);
        that.february = that.activeMonths.includes(1);
        that.march = that.activeMonths.includes(2);
        that.april = that.activeMonths.includes(3);
        that.may = that.activeMonths.includes(4);
        that.june = that.activeMonths.includes(5);
        that.july = that.activeMonths.includes(6);
        that.august = that.activeMonths.includes(7);
        that.september = that.activeMonths.includes(8);
        that.october = that.activeMonths.includes(9);
        that.november = that.activeMonths.includes(10);
        that.december = that.activeMonths.includes(11);
    }
}
