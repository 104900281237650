import { defineMessages } from 'react-intl';

const scope = 'lang.';

export const languageMessages = defineMessages({
    german: {
        id: scope + 'german',
        defaultMessage: 'German',
    },
    english: {
        id: scope + 'english',
        defaultMessage: 'English',
    },
    spanish: {
        id: scope + 'spanish',
        defaultMessage: 'Spanish',
    },
    french: {
        id: scope + 'french',
        defaultMessage: 'French',
    },
    italian: {
        id: scope + 'italian',
        defaultMessage: 'Italian',
    },
    arabic: {
        id: scope + 'arabic',
        defaultMessage: 'Arabic',
    },
    turkish: {
        id: scope + 'turkish',
        defaultMessage: 'Turkish',
    },
    russian: {
        id: scope + 'russian',
        defaultMessage: 'Russian',
    },
    polish: {
        id: scope + 'polish',
        defaultMessage: 'Polish',
    },
});
