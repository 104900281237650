import React, { FC, useCallback, useState } from 'react';
import { View } from 'react-native';
import { CButton, CText } from '../../../components';
import { ECollections, EEnvironment, EUserType } from '../../../enums';
import {
    MAgency,
    MApplication,
    MChat,
    MHelp,
    MJob,
    MProfessionalProfile,
    MUserData,
    MWorkplace,
} from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useStyle, useTheme } from '../../../utilities/styles';
import { month, week } from '../../../utilities/functions';

export const AdminActions: FC = () => {
    const {
        getDataIndex,
        getDataById,
        put,
        post,
        getFile,
        getFileMetadata,
        getFileDownloadUrl,
        uploadToStorage,
        removeFile,
        remove,
    } = useFireBase();
    const { theme } = useTheme();
    const style = useStyle();

    const [touchingUsers, setTouchingUsers] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchUsers = useCallback(() => {
        setTouchingUsers(true);
        getDataIndex(ECollections.users).then((users) => {
            Promise.all(
                (users as MUserData[]).map((user) => {
                    const next = new MUserData(user);
                    return put(ECollections.users, user.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingUsers(false));
        });
    }, [getDataIndex, put]);

    const [refershingPubUsers, setRefreshingPubUsers] = useState(false);
    /**
     * callback to touch all userData
     */
    const refreshPubUsers = useCallback(() => {
        setRefreshingPubUsers(true);
        getDataIndex(ECollections.publicUsers).then((users) => {
            Promise.all(
                (users as MUserData[]).map((user) => {
                    const next = new MUserData(user);
                    return remove(ECollections.publicUsers, user.documentId);
                }),
            ).then(() => {
                setRefreshingPubUsers(false);
                touchUsers();
            });
        });
    }, [touchUsers]);

    const [touchingProfiles, setTouchingProfiles] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchProfiles = useCallback(() => {
        setTouchingProfiles(true);
        getDataIndex(ECollections.profProfiles).then((users) => {
            Promise.all(
                (users as any[]).map((user) => {
                    const next = new MProfessionalProfile(user);
                    return put(ECollections.profProfiles, user.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingProfiles(false));
        });
    }, [getDataIndex, put]);

    const [touchingApplications, setTouchingApplications] = useState(false);
    /**
     * callback to touch all userData
     */
    const touchApplications = useCallback(() => {
        setTouchingApplications(true);
        getDataIndex(ECollections.applications).then((users) => {
            Promise.all(
                (users as MApplication[]).map((app) => {
                    const next = new MApplication(app);
                    return put(ECollections.applications, app.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingApplications(false));
        });
    }, [getDataIndex, put]);

    const [touchingJobs, setTouchingJobs] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchJobs = useCallback(() => {
        setTouchingJobs(true);
        getDataIndex(ECollections.jobs).then((jobs) => {
            Promise.all(
                (jobs as MJob[]).map((job) => {
                    const next = new MJob(job);
                    return put(ECollections.jobs, job.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingJobs(false));
        });
    }, [getDataIndex, put]);

    const [fixingJobIds, setFixingJobIds] = useState(false);
    /**
     * callback to touch all jobs
     */
    const fixJobIds = useCallback(() => {
        setFixingJobIds(true);
        getDataIndex(ECollections.jobs, {
            orderBy: 'createdOn',
            asc: true,
        }).then(async (jobs) => {
            let pivot = 1;
            for (const job of jobs as MJob[]) {
                const next = new MJob(job);
                await put(ECollections.jobs, job.documentId, {
                    ...next,
                    id: pivot,
                    touched: Date.now(),
                });
                pivot++;
            }
            setFixingJobIds(false);
        });
    }, [getDataIndex, put]);

    const [randomizingJobs, setRandomizingJobs] = useState(false);
    /**
     * callback to touch all jobs
     */
    const randomizeJobs = useCallback(() => {
        setRandomizingJobs(true);
        const to = Date.now() - week;
        const from = to - month;
        getDataIndex(ECollections.jobs).then((jobs) => {
            Promise.all(
                (jobs as MJob[]).map(async (job) => {
                    if (job.workPlaceId) {
                        const workplace = new MWorkplace(
                            await getDataById(
                                ECollections.workplaces,
                                job.workPlaceId,
                            ),
                        );
                        if (!workplace.name.includes('BSP:')) {
                            return;
                        }
                        const next = new MJob({
                            ...job,
                            createdOn: Math.round(
                                Math.random() * (to - from) + from,
                            ),
                        });
                        return put(ECollections.jobs, job.documentId, {
                            ...next,
                            touched: Date.now(),
                        });
                    }
                }),
            ).then(() => setRandomizingJobs(false));
        });
    }, [getDataIndex, put]);

    const [touchingChats, setTouchingChats] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchChats = useCallback(() => {
        setTouchingChats(true);
        getDataIndex(ECollections.chats).then((chats) => {
            Promise.all(
                (chats as MChat[]).map((chat) => {
                    const next = new MChat(chat);
                    return put(ECollections.chats, chat.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingChats(false));
        });
    }, [getDataIndex, put]);

    const [touchingHelps, setTouchingHelps] = useState(false);
    /**
     * callback to touch all jobs
     */
    const touchHelps = useCallback(() => {
        setTouchingHelps(true);
        getDataIndex(ECollections.helps).then((helps) => {
            Promise.all(
                (helps as MHelp[]).map((help) => {
                    const next = new MHelp(help);
                    // * fix for old helps
                    // if (!next.groups.length) {
                    //     if (next.environment === EEnvironment.SITUS) {
                    //         next.groups = [EUserType.user];
                    //     } else {
                    //         next.groups = [EUserType.agency];
                    //     }
                    // }
                    return put(ECollections.helps, help.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => setTouchingHelps(false));
        });
    }, [getDataIndex, put]);

    const [duplicatingHelpsToTP, setDuplicatingHelpsToTP] = useState(false);
    /**
     * callback to touch all jobs
     */
    const duplicateHelpsToTP = useCallback(() => {
        setDuplicatingHelpsToTP(true);
        getDataIndex(ECollections.helps).then((helps) => {
            const helpsWithoutParent = (helps as MHelp[]).filter(
                (v) => !v.parent,
            );
            Promise.all(
                helpsWithoutParent.map(async (help) => {
                    const next = new MHelp(help);
                    next.documentId = '';
                    // return put(ECollections.helps, help.documentId, {
                    //     ...next,
                    //     environment: EEnvironment.TimePloyees,
                    //     touched: Date.now(),
                    // });
                    const response = await post(ECollections.helps, {
                        ...next,
                        environment: EEnvironment.TimePloyees,
                        touched: Date.now(),
                    });
                    return {
                        previousId: help.documentId,
                        nextId: response?.id,
                    };
                }),
            ).then((mappings) => {
                const helpsWithMappedParents = (helps as MHelp[]).filter((v) =>
                    mappings.find((m) => v.parent === m.previousId),
                );
                Promise.all(
                    helpsWithMappedParents.map(async (help) => {
                        const next = new MHelp(help);
                        const mapping = mappings.find(
                            (m) => next.parent === m.previousId,
                        );
                        next.documentId = '';
                        next.parent = mapping?.nextId || '';
                        const response = await post(ECollections.helps, {
                            ...next,
                            environment: EEnvironment.TimePloyees,
                            touched: Date.now(),
                        });
                        return {
                            previousId: help.documentId,
                            nextId: response?.id,
                        };
                    }),
                ).then((mappings2) => {
                    const helpsWithMappedParents2 = (helps as MHelp[]).filter(
                        (v) => mappings2.find((m) => v.parent === m.previousId),
                    );
                    Promise.all(
                        helpsWithMappedParents2.map(async (help) => {
                            const next = new MHelp(help);
                            const mapping = mappings2.find(
                                (m) => next.parent === m.previousId,
                            );
                            next.documentId = '';
                            next.parent = mapping?.nextId || '';
                            const response = await post(ECollections.helps, {
                                ...next,
                                environment: EEnvironment.TimePloyees,
                                touched: Date.now(),
                            });
                            return {
                                previousId: help.documentId,
                                nextId: response?.id,
                            };
                        }),
                    ).then(() => {
                        setDuplicatingHelpsToTP(false);
                    });
                });
            });
        });
    }, [getDataIndex, put]);

    const [touchingWorkplaces, setTouchingWorkplaces] = useState(false);
    /**
     * callback to move all workplaces to workplaces collection
     */
    const touchWorkplaces = useCallback(() => {
        setTouchingWorkplaces(true);
        getDataIndex(ECollections.workplaces as any).then((workplaces) => {
            Promise.all(
                (workplaces as MWorkplace[]).map((workplace) => {
                    const next = new MWorkplace(workplace);
                    return put(ECollections.workplaces, workplace.documentId, {
                        ...next,
                        touched: Date.now(),
                    });
                }),
            ).then(() => {
                setTouchingWorkplaces(false);
            });
        });
    }, [getDataIndex, put]);

    // const [creatingAgencies, setCreatingAgencies] = useState(false);
    // /**
    //  * callback to create agencies from agency users
    //  */
    // const createAgencies = useCallback(() => {
    //     setCreatingAgencies(true);
    //     getDataIndex(ECollections.users, {
    //         filter: [
    //             {
    //                 field: 'type',
    //                 value: EUserType.agency,
    //             },
    //         ],
    //     }).then((users) => {
    //         Promise.all(
    //             (users as any[]).map(async (rawUser) => {
    //                 const user = new MUserData(rawUser);
    //                 const postResponse = await post(ECollections.agencies, {
    //                     ...new MAgency({
    //                         owner: user.documentId,
    //                         name: user.agency,
    //                         taxNumber: user.taxNumber,
    //                         registerNumber: user.registerNumber,
    //                         bankDetails: user.bankDetails,
    //                         address: user.address,
    //                         billingAddress: user.billingAddress,
    //                         users: [user.documentId],
    //                     }),
    //                 });
    //                 if (postResponse) {
    //                     const rawProfiles = await getDataIndex(
    //                         ECollections.profProfiles,
    //                         {
    //                             filter: [
    //                                 {
    //                                     field: 'moderators',
    //                                     value: user.documentId,
    //                                     operator: 'array-contains',
    //                                 },
    //                             ],
    //                         },
    //                     );
    //                     for (const rawProfile of rawProfiles as any[]) {
    //                         await put(
    //                             ECollections.profProfiles,
    //                             rawProfile.documentId,
    //                             { ...rawProfile, agencyId: postResponse?.id },
    //                         );
    //                     }
    //                 }
    //             }),
    //         ).then(() => setCreatingAgencies(false));
    //     });
    // }, [getDataIndex, put]);

    const [fixingFiles, setFixingFiles] = useState(false);
    /**
     * callback to create agencies from agency users
     */
    const fixFiles = useCallback(() => {
        setFixingFiles(true);
        getDataIndex(ECollections.users, {
            filter: [],
        }).then((users) => {
            Promise.all(
                (users as any[]).map(async (rawUser) => {
                    const user = new MUserData(rawUser);
                    if (user.picture) {
                        const meta = await getFileMetadata(user.picture);
                        const nextFn = `pictures/${
                            user.documentId
                        }/${Date.now().toString(36)}.${
                            meta.name.split('.')[1]
                        }`;
                        const prevFileGetResponse = await getFile(user.picture);
                        await uploadToStorage(
                            nextFn,
                            new Uint8Array(prevFileGetResponse),
                        );
                        await removeFile(user.picture);
                        user.picture = nextFn;
                    }
                    for (const prevFile of user.files) {
                        const prevPathSplit = prevFile.name.split('/');
                        const nextFn = `user/${user.documentId}/${
                            prevPathSplit[prevPathSplit.length - 1]
                        }`;
                        if (nextFn !== prevFile.name) {
                            const prevFileGetResponse = await getFile(
                                prevFile.name,
                            );
                            await uploadToStorage(
                                nextFn,
                                new Uint8Array(prevFileGetResponse),
                            );
                            await removeFile(prevFile.name);
                            prevFile.name = nextFn;
                        }
                    }
                    return await put(ECollections.users, user.documentId, {
                        ...user,
                    });
                }),
            ).then(() => setFixingFiles(false));
        });
    }, [getDataIndex, put]);
    /**
     * render
     */
    return (
        <View>
            <CText headline bold style={[{ color: theme.errorColor }]}>
                Danger Zone
            </CText>
            <View style={style.verticalPadded}>
                <CButton
                    onPress={touchUsers}
                    title="Touch all users"
                    warning
                    disabled={refershingPubUsers || touchingUsers}
                />
                <CButton
                    onPress={refreshPubUsers}
                    title="Refresh Pub users"
                    warning
                    disabled={refershingPubUsers || touchingUsers}
                />
                <CButton
                    onPress={touchProfiles}
                    title="Touch all profiles"
                    warning
                    disabled={touchingProfiles}
                />
                <CButton
                    onPress={touchApplications}
                    title="Touch all applications"
                    warning
                    disabled={touchingApplications}
                />
                <CButton
                    onPress={touchJobs}
                    title="Touch all Jobs"
                    warning
                    disabled={touchingJobs}
                />
                <CButton
                    onPress={touchChats}
                    title="Touch all Chats"
                    warning
                    disabled={touchingChats}
                />
                <CButton
                    onPress={touchHelps}
                    title="Touch all Helps"
                    warning
                    disabled={touchingHelps}
                />
                <CButton
                    onPress={touchWorkplaces}
                    title="Touch workplaces"
                    warning
                    disabled={touchingWorkplaces}
                />
                <CText headline bold style={[{ color: theme.errorColor }]}>
                    Extra Danger Zone
                </CText>
                <CButton
                    onPress={fixJobIds}
                    title="Fix job ids"
                    disabled={fixingJobIds}
                    danger
                />
                <CButton
                    onPress={randomizeJobs}
                    title="Randomize Jobs created on"
                    disabled={randomizingJobs}
                    danger
                />
                {/* <CButton
                    onPress={duplicateHelpsToTP}
                    title="Duplicate helps to tp"
                    warning
                    disabled={duplicatingHelpsToTP}
                /> */}
                {/* <CButton
                    onPress={createAgencies}
                    title="Create agencies for existing users"
                    danger
                    disabled={creatingAgencies}
                /> */}
                <CButton
                    onPress={fixFiles}
                    title="Fix user file structure"
                    danger
                    disabled={fixingFiles}
                />
            </View>
        </View>
    );
};
