import React, { FC, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CPicker } from '../../../../components';
import { MJob } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { jobMessages } from '../../Job.messages';
import { ProfessionPicker } from '../../../../components/Pickers/ProfessionPicker';
import { EducationPicker } from '../../../../components/Pickers/EducationPicker';
import { SpecializationPicker } from '../../../../components/Pickers/SpecializationPicker';
import { FieldPicker, PositionPicker } from '../../../../components/Pickers';
import { EField, EProfession } from '../../../../enums';
import { professionIsAllowedToUseAny } from '../../../../utilities/constants';

interface IEmployeeDataProps {
    onChange: (next: Partial<MJob>) => void;
    curData: MJob;
    onValidityChange: (next: any) => void;
    showAcademicTitleRequired?: boolean;
}

export const EmployeeData: FC<IEmployeeDataProps> = ({
    onChange,
    curData,
    onValidityChange,
    showAcademicTitleRequired,
}) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    /**
     * edu validity memoized
     */
    const eduValidity = useMemo(
        () =>
            curData.employeeEducations.length < 1
                ? format(jobMessages.educationMissingError)
                : undefined,
        [curData],
    );
    /**
     * pos validity memoized. position is mandatory for doctors
     */
    const posValidity = useMemo(
        () =>
            curData.employeeProfession === 'doctor' && !curData.employeePosition
                ? format(jobMessages.positionMissingError)
                : undefined,
        [curData],
    );
    /**
     * effect to update validity
     */
    useEffect(() => {
        const next = {
            posV: posValidity,
            eduV: eduValidity,
        };
        onValidityChange(next);
    }, [posValidity, eduValidity, onValidityChange]);
    /**
     * render
     */
    return (
        <>
            <FieldPicker
                onChange={(employeeField) =>
                    // clears other selections
                    onChange({
                        employeeField,
                        employeeProfession: undefined,
                        employeeSpecializations: [],
                        employeeEducations: [],
                        employeePosition: undefined,
                    })
                }
                value={curData.employeeField}
            />
            {curData.employeeField &&
                curData.employeeField !== EField.undefined && (
                    <ProfessionPicker
                        field={curData.employeeField}
                        onChange={(employeeProfession) =>
                            // clears other selections
                            onChange({
                                employeeProfession,
                                employeeSpecializations: [],
                                employeeEducations: [],
                                employeePosition: '',
                            })
                        }
                        value={curData.employeeProfession}
                    />
                )}
            {showAcademicTitleRequired && (
                <View style={style.verticalPadded}>
                    <CCheckBox
                        cy={'academic-title-required'}
                        title={format(jobMessages.employeeAcademicTitle)}
                        checked={curData.employeeAcademicTitle}
                        onCheckedChanged={(employeeAcademicTitle) =>
                            onChange({ employeeAcademicTitle })
                        }
                    />
                </View>
            )}
            {curData.employeeProfession &&
                (curData.employeeProfession !== EProfession.undefined ||
                    curData.employeeField === EField.general) && (
                    <>
                        <PositionPicker
                            profession={curData.employeeProfession}
                            value={curData.employeePosition}
                            onChange={(employeePosition) =>
                                onChange({ employeePosition })
                            }
                            validity={posValidity}
                        />
                        <EducationPicker
                            multi
                            field={curData.employeeField}
                            values={curData.employeeEducations}
                            onChange={(next) => {
                                onChange({
                                    employeeEducations: next as string[],
                                });
                            }}
                            validity={eduValidity}
                            profession={curData.employeeProfession}
                            allowAny={professionIsAllowedToUseAny(
                                curData.employeeProfession,
                            )}
                        />
                        <View style={style.verticalPadded}>
                            <CPicker
                                title={format(jobMessages.yoe)}
                                cy={'employee-yoe'}
                                value={curData.employeeYoe}
                                values={[
                                    {
                                        label: `-${format(
                                            jobMessages.yoePlaceholder,
                                        )}-`,
                                        value: undefined,
                                        hidden: true,
                                    },
                                    ...[...Array(69).keys()].map((v) => {
                                        return {
                                            label: `${v}`,
                                            value: v,
                                        };
                                    }),
                                ]}
                                onChange={(employeeYoe) =>
                                    onChange({ employeeYoe })
                                }
                            />
                        </View>
                        <View style={style.verticalPadded}>
                            <SpecializationPicker
                                multi
                                profession={curData.employeeProfession}
                                values={curData.employeeSpecializations}
                                onChange={(next) =>
                                    onChange({
                                        employeeSpecializations:
                                            next as string[],
                                    })
                                }
                            />
                        </View>
                    </>
                )}
        </>
    );
};
