import { StyleSheet } from 'react-native';
import { ITheme } from '../../utilities/styles/ITheme';

export const makeMenuStyles = (theme: ITheme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'row',
        },
        sidebar: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.backgroundMainColor,
            zIndex: 9000,
            paddingBottom: 20,
            paddingTop: 10,
            paddingHorizontal: 10,
        },
        sidebarDetail: {
            minWidth: 250,
            paddingHorizontal: 20,
            paddingBottom: 20,
        },
        navButton: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: theme.backgroundMainColor,
            marginHorizontal: 5,
            paddingHorizontal: 0,
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0,
            shadowRadius: 0,
            elevation: 0,
        },
        navButtonText: {
            color: theme.textMainColor,
            alignItems: 'center',
        },
        exNavButton: {
            justifyContent: 'flex-start',
            backgroundColor: theme.backgroundMainColor,
        },
        headerContainer: {
            flexDirection: 'row',
            paddingBottom: 20,
            alignItems: 'center',
        },
        sidebarButton: {
            backgroundColor: theme.backgroundMainColor,
            borderWidth: 0,
            height: 40,
            width: 40,
            marginTop: 10,
        },
        notificationBubble: {
            zIndex: 50,
            position: 'absolute',
            backgroundColor: theme.accentColor,
            borderRadius: 5,
            width: 10,
            height: 10,
            top: 16,
            right: 0,
        },
    });
