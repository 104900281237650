import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { CCard, CIcon, CLabel, CText } from '../../../../components';
import { ProfilePicture } from '../../../../components/ProfilePicture';
import { useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useSecureNavigate } from '../../../../utilities/routing';
import { educationMessages } from '../../../../utilities/messages';
import { MProfessionalProfile } from '../../../../models';
import { profProfileMessages } from '../../ProfProfile.messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { convertNumberToCurrencyString } from '../../../../utilities/functions';
import { UserTypeLabel } from '../../../../components/UserTypeLabel';
import { useEnvironment } from '../../../../utilities/contexts';

interface IUserRow {
    professionalUser: MProfessionalProfile;
    handleChange: (next: MProfessionalProfile) => void;
}
/**
 * professionalUser row
 * @param param0
 * @returns
 */
export const ProfessionalUserRow: FC<IUserRow> = ({ professionalUser }) => {
    const format = useFormat();
    const style = useStyle();
    const { theme } = useTheme();
    const { region } = useEnvironment();
    const { secureNavigate } = useSecureNavigate();
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        const next =
            professionalUser.wage.wage + professionalUser.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, []);
    /**
     * memoized talentprofile name
     */
    const name = useMemo(() => {
        if (professionalUser.firstName && professionalUser.lastName) {
            return `${professionalUser.firstName} ${professionalUser.lastName}`;
        } else if (professionalUser.lastName) {
            return professionalUser.lastName;
        } else {
            return '';
        }
    }, [professionalUser]);
    /**
     * render
     */
    return (
        <CCard
            style={[
                !!professionalUser.filledOn && style.accentBorder,
                style.horizontalSplit,
            ]}
            onPress={() =>
                secureNavigate(`/profUser/${professionalUser.documentId}`)
            }
            hoverEffects
        >
            <View
                style={[
                    style.horizontalSplit,
                    style.flex1,
                    style.horizontalWrap,
                ]}
            >
                <View style={[style.horizontalWrap]}>
                    <ProfilePicture data={professionalUser} isProfProfile />
                    <View style={style.horizontalPadded}>
                        <View style={style.horizontal}>
                            <UserTypeLabel data={professionalUser} />
                            {!!professionalUser.isWatching && (
                                <CLabel
                                    checked={true}
                                    title={profProfileMessages.watching}
                                    color={theme.errorColor}
                                    smaller
                                />
                            )}
                        </View>
                        {!!name && <CText secondaryHeadline>{name}</CText>}
                        {!!professionalUser.educations.length && (
                            <View
                                style={[
                                    style.horizontalWrap,
                                    style.centeredItems,
                                ]}
                            >
                                <CIcon
                                    icon="school"
                                    size={16}
                                    tint={theme.textSecondaryColor}
                                />
                                <CText style={style.leftPadded}>
                                    {`${professionalUser.educations
                                        .map((edu) =>
                                            format(
                                                educationMessages[
                                                    edu as keyof typeof educationMessages
                                                ],
                                            ),
                                        )
                                        .join(', ')}`}
                                </CText>
                            </View>
                        )}
                    </View>
                </View>
                <View style={{ alignItems: 'flex-end' }}>
                    {!!professionalUser.yearsOfExperience && (
                        <CText>
                            {`${professionalUser.yearsOfExperience} ${format(
                                profileMessages.yoe,
                            )}`}
                        </CText>
                    )}
                    {hourlyRate ? (
                        <CText>
                            {`${format(
                                profProfileMessages.rate,
                            )} ${convertNumberToCurrencyString(
                                hourlyRate,
                                region,
                            )}`}
                        </CText>
                    ) : (
                        <CText message={profProfileMessages.noRate} />
                    )}
                </View>
            </View>
            <View style={{ justifyContent: 'center' }}>
                <CIcon icon="chevronRight" />
            </View>
        </CCard>
    );
};
