import React, { FC, useMemo } from 'react';
import { CText } from '../../../components';
import { EProfession } from '../../../enums';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
    positionMessages,
} from '../../../utilities/messages';
import { useFormat } from '../../../utilities/intl';
import { View } from 'react-native';
import { MJob } from '../../../models';
import { isPeasant } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { jobMessages } from '../Job.messages';
/**
 * job title display for use at multiple occasions
 * @param param0
 * @returns
 */
export const JobTitle: FC<{ job: MJob; showId?: boolean }> = ({
    job,
    showId,
}) => {
    const format = useFormat();
    const { userData } = useFireBase();
    /**
     * type messages nurse doctor ....
     */
    const typeMessage = useMemo(
        () => generalMessages[job.employeeProfession],
        [job],
    );
    /**
     * position message (e.g.: Facharzt, Oberarzt)
     */
    const posMessage = useMemo(
        () =>
            positionMessages[
                job.employeePosition as keyof typeof positionMessages
            ],
        [job],
    );
    return (
        <View>
            <CText
                message={
                    job.employeeProfession === EProfession.undefined
                        ? fieldMessages[job.employeeField]
                        : job.employeeProfession === EProfession.doctor &&
                          posMessage
                        ? `${format(typeMessage)} - ${format(posMessage)}`
                        : typeMessage
                }
            />
            {!isPeasant(userData) && !!job.title && (
                <CText headline message={`${job.title}`} />
            )}
            <CText headline={isPeasant(userData) || !job.title}>
                {job.employeeEducations
                    .map((edu) => {
                        const key = edu as keyof typeof educationMessages;
                        if (educationMessages[key]) {
                            return format(educationMessages[key]);
                        }
                    })
                    .join(', ')}
            </CText>
            {showId && <CText>{`${format(jobMessages.id)}: ${job.id}`}</CText>}
        </View>
    );
};
