import React, { FC, useEffect, useMemo, useState } from 'react';
import { MInvite, MUserData, MWorkplace } from '../../../../models';
import { View } from 'react-native';
import { CText, InfoBox, InviteRow, CButton } from '../../../../components';
import { EInviteType, ECollections, EInviteStatus } from '../../../../enums';
import { isSuperUser } from '../../../../utilities/auth';
import { UserRow } from '../../../User/List/components/UserRow';
import { workplaceMessages } from '../../workplace.messages';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';

export const WorkplaceUsers: FC<{
    workplace: MWorkplace;
    onChange: (next: Partial<MWorkplace>) => void;
}> = ({ workplace, onChange }) => {
    const style = useStyle();
    const { post, getDataById, userData, getDataIndex } = useFireBase();
    // local state
    const [invites, setInvites] = useState<MInvite[]>([]);
    const [users, setUsers] = useState<MUserData[]>([]);
    /**
     * filtered invites which are user only
     */
    const userInvites = useMemo(() => {
        return invites.filter((i) => i.type === EInviteType.workplace);
    }, [invites]);
    /**
     * effect to load invites
     */
    useEffect(() => {
        getDataIndex(
            `${ECollections.workplaces}/${workplace.documentId}/${ECollections.invites}`,
            { filter: [{ field: 'status', value: EInviteStatus.open }] },
        ).then((result) => {
            setInvites((result as any[]).map((r) => new MInvite(r)));
        });
    }, [workplace]);
    /**
     * effect to load users
     */
    useEffect(() => {
        Promise.all(
            workplace.users.map((uid) =>
                getDataById(
                    isSuperUser(userData)
                        ? ECollections.users
                        : ECollections.publicUsers,
                    uid,
                ),
            ),
        ).then((nextUsers) => {
            setUsers(nextUsers.map((u) => new MUserData(u)));
        });
    }, [workplace, userData]);
    return (
        <>
            <CText secondaryHeadline message={workplaceMessages.users} />
            <View style={style.verticalPadded}>
                <InfoBox
                    type={'warning'}
                    message={workplaceMessages.multiUserInformation}
                />
            </View>
            {users.map((user) => {
                const canSign =
                    !!workplace.signatureUsers.find(
                        (uid) => uid === user.documentId,
                    ) || user.documentId === workplace.owner;
                return (
                    <UserRow
                        key={user.documentId}
                        user={user}
                        handleChange={() => {
                            console.log('no');
                        }}
                        minimal={!isSuperUser(userData)}
                        canSign={canSign}
                        onChangeCanSign={
                            userData.documentId === workplace.owner ||
                            isSuperUser(userData)
                                ? () => {
                                      if (canSign) {
                                          onChange({
                                              signatureUsers:
                                                  workplace.signatureUsers.filter(
                                                      (uid) =>
                                                          uid !==
                                                          user.documentId,
                                                  ),
                                          });
                                      } else {
                                          onChange({
                                              signatureUsers: [
                                                  ...workplace.signatureUsers,
                                                  user.documentId,
                                              ],
                                          });
                                      }
                                  }
                                : undefined
                        }
                        isDefaultSigner={
                            user.documentId === workplace.defaultSignatureUser
                        }
                        onChangeIsDefaultSigner={() =>
                            onChange({
                                defaultSignatureUser: user.documentId,
                            })
                        }
                    />
                );
            })}
            {(userData.documentId === workplace.owner ||
                isSuperUser(userData)) && (
                <>
                    {!!userInvites.length && (
                        <CText
                            secondaryHeadline
                            message={workplaceMessages.invites}
                        />
                    )}
                    {userInvites.map((invite) => (
                        <InviteRow key={invite.documentId} invite={invite} />
                    ))}
                    <View style={style.horizontalSpaced}>
                        <CButton
                            title={workplaceMessages.createInvite}
                            onPress={() => {
                                const next = new MInvite({
                                    targetId: workplace.documentId,
                                    type: EInviteType.workplace,
                                    authorId: userData.documentId,
                                });
                                post(
                                    `${ECollections.workplaces}/${workplace.documentId}/${ECollections.invites}`,
                                    next,
                                ).then((postResult) => {
                                    if (postResult) {
                                        next.documentId = postResult.id;
                                        setInvites([...invites, next]);
                                    }
                                });
                            }}
                        />
                    </View>
                </>
            )}
        </>
    );
};
