import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CText,
    RichTextView,
    Spinner,
    StarDisplay,
    TouchableView,
} from '../../../../../../components';
import { MJob, MWorkplace } from '../../../../../../models';
import { useFormat } from '../../../../../../utilities/intl';
import {
    doctorPositionMessages,
    educationMessages,
    fieldMessages,
    generalMessages,
    specializationMessages,
} from '../../../../../../utilities/messages';
import { useStyle } from '../../../../../../utilities/styles';
import { jobMessages } from '../../../../Job.messages';
import { JobTable } from '../../../../components/JobTable';
import { ECollections, EProfession } from '../../../../../../enums';
import { useFireBase } from '../../../../../../utilities/firebase';
import { useNavigate } from '../../../../../../utilities/routing';

export const JobRowBody: FC<{ job: MJob }> = ({ job }) => {
    const format = useFormat();
    const { getDataById } = useFireBase();
    const style = useStyle();
    const navigate = useNavigate();
    // local state
    const [workplace, setWorkplace] = useState<MWorkplace>();
    /**
     * type messages nurse doctor ....
     */
    const typeMessage = useMemo(
        () => generalMessages[job.employeeProfession],
        [job],
    );
    /**
     * position message (e.g.: Facharzt, Oberarzt)
     */
    const posMessage = useMemo(
        () =>
            doctorPositionMessages[
                job.employeePosition as keyof typeof doctorPositionMessages
            ],
        [job],
    );
    /**
     * effect to load pub workplace
     */
    useEffect(() => {
        getDataById(ECollections.publicWorkplaces, job.workPlaceId).then((v) =>
            setWorkplace(new MWorkplace(v)),
        );
    }, [job]);
    /**
     * render
     */
    return (
        <View style={{ width: '100%', overflow: 'hidden' }}>
            <CText bold headline message={jobMessages.aboutEmployee} />
            <CText
                message={format(jobMessages.jobEmployeeEdu, {
                    employeeProfession:
                        job.employeeProfession === EProfession.undefined
                            ? format(fieldMessages[job.employeeField])
                            : job.employeeProfession === EProfession.doctor &&
                              posMessage
                            ? `${format(typeMessage)} - ${format(posMessage)}`
                            : format(typeMessage),
                    employeeEdu: job.employeeEducations
                        .map((education) =>
                            format(
                                educationMessages[
                                    education as keyof typeof educationMessages
                                ],
                            ),
                        )
                        .join(', '),
                    station: posMessage && format(posMessage),
                })}
            />
            <CText>
                {format(jobMessages.jobEmployeeYoe, {
                    yoe: job.employeeYoe,
                })}
            </CText>
            {!!job.employeeSpecializations.length && (
                <CText>
                    {format(jobMessages.jobEmployeeSpecs, {
                        employeeSpecs: job.employeeSpecializations
                            .map((spec) => {
                                const specMessage =
                                    specializationMessages[
                                        spec as keyof typeof specializationMessages
                                    ];
                                return specMessage
                                    ? format(specMessage)
                                    : undefined;
                            })
                            .join(', '),
                    })}
                </CText>
            )}
            {!!job.freeText && (
                <View style={style.verticalPadded}>
                    <CText message={jobMessages.freeTextDisplay} />
                    <RichTextView html={job.freeText} />
                </View>
            )}
            <View style={style.thinSeparator} />
            <View style={style.verticalPadded} />
            <CText bold headline>
                {format(jobMessages.aboutJob)}
            </CText>
            <View style={style.verticalPadded}>
                <JobTable job={job} pure />
            </View>
            <CText bold headline>
                {format(jobMessages.aboutEmployer)}
            </CText>
            <CText message={jobMessages.employerRating} />
            {workplace ? (
                !!workplace.reviewCount && (
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.verticalPadded,
                            style.centeredItems,
                        ]}
                        onPress={() =>
                            navigate(
                                `/reviews/${ECollections.workplaces}/${workplace.documentId}`,
                            )
                        }
                    >
                        <StarDisplay stars={workplace.averageRating} />
                        <CText message={`(${workplace.reviewCount})`} />
                    </TouchableView>
                )
            ) : (
                <Spinner />
            )}
            <View style={style.verticalPadded}>
                <CText>
                    {format(jobMessages.jobLocation, {
                        location: job.fullAddress
                            ? job.fullAddress.zipCode
                            : job.location,
                    })}
                </CText>
            </View>
            <CText>{format(jobMessages.jobIntorductionPositivity)}</CText>
        </View>
    );
};
