import { EUserType } from '../../../../enums';
import {
    contractViewRide,
    dashboardRide,
    dashboardEmployerRide,
} from '../rides';

export const selectRide = (
    pathname: string,
    opts: {
        userType: EUserType;
    },
) => {
    if (pathname === '/') {
        if (opts.userType === EUserType.employer) {
            return dashboardEmployerRide;
        }
        return dashboardRide;
    }
    const pathSplit = pathname.split('/');
    if (
        pathSplit.length === 3 &&
        pathSplit[1] === 'contract' &&
        opts.userType !== EUserType.talent &&
        opts.userType !== EUserType.basic
    ) {
        return contractViewRide;
    }
    return [];
};
