import { ITheme } from '../ITheme';

export const darkTheme: ITheme = {
    backgroundMainColor: '#282c34',
    backgroundSecondaryColor: '#21252b',
    backgroundTertiaryColor: '#474646',
    borderColor: '#404042',
    textMainColor: '#d4d2d2',
    textSecondaryColor: '#a1a7c7',
    textTertiaryColor: '#656c70',
    accentColor: '#5a81fa',
    darkAccentColor: '#2c3d8f',
    textAccentColor: '#d4d2d2',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#e85b55',
    warningColor: '#feae7f',
    successColor: '#39d08b',
    mainFontFamily: 'Poppins',
    headlineFontFamily: 'DM Sans',
    shadowColor: '#000',
};
