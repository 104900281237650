import { defineMessages } from 'react-intl';

const scope = 'con.requestSignature.';

export const requestSignatureMessages = defineMessages({
    selectPositionsOnDocument: {
        id: scope + 'selectPositionsOnDocument',
        defaultMessage: 'Select positions for signatures',
    },
    selectPositionsOnDocumentHint: {
        id: scope + 'selectPositionsOnDocumentHint',
        defaultMessage:
            'Please select at least one position for each party where the digital signature should be displayed on the document',
    },
    yourPositions: {
        id: scope + 'yourPositions',
        defaultMessage: 'Your positions',
    },
    otherPositions: {
        id: scope + 'otherPositions',
        defaultMessage: 'Other positions',
    },
    confirmSelection: {
        id: scope + 'confirmSelection',
        defaultMessage: 'Confirm selection',
    },
});
