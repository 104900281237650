import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CText, Spinner } from '../../../components';
import { useSecureNavigate } from '../../../utilities/routing';
import { useStyle } from '../../../utilities/styles';
import { ChatView } from '../../Chat';
import { ContractTitle } from '../components/ContractTitle';
import { contractMessages } from '../contract.messages';
import { ContractFiles } from './components/ContractFiles';
import { useContractViewState } from './useContractViewState';
import { ScrollProvider } from '../../../utilities/contexts';
import { useDimensions } from '../../../utilities/hooks';
/**
 * Contract view
 * - overview over contract values
 * - all documents related to contract
 * - options to upload documents and request a signature
 * - display the chat related to this contract
 * @returns
 */
export const BasicContractView: FC<ReturnType<typeof useContractViewState>> = ({
    contract,
    chat,
    preFile,
    handleFile,
    joinChat,
}) => {
    const style = useStyle();
    const { secureNavigate } = useSecureNavigate();
    const { mainHeight } = useDimensions();
    const [containerHeight, setContainerHeight] = useState(0);
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }
    /**
     * return placeholder if no participants yet
     */
    if (contract.participants.length < 2) {
        return (
            <View style={style.paddedMainView}>
                <CCard>
                    <CText message={contractMessages.noParticipantsYet} />
                </CCard>
            </View>
        );
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View
                style={{
                    minHeight: mainHeight - 40,
                }}
            >
                <View style={style.headlineCard}>
                    <View
                        style={[style.horizontal, style.centeredItems]}
                        {...{
                            dataSet: { cy: 'contract-view-headline-container' },
                        }}
                    >
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <ContractTitle contract={contract} horizontalPadded />
                    </View>
                </View>
                <View
                    style={[style.horizontal, style.flex1]}
                    onLayout={(e) =>
                        setContainerHeight(e.nativeEvent.layout.height)
                    }
                >
                    <View style={[style.flex1]}>
                        <View
                            style={{
                                height: containerHeight,
                            }}
                            nativeID="contract-documents"
                        >
                            <ContractFiles
                                contract={contract}
                                maxHeight={containerHeight}
                            />
                        </View>
                    </View>
                    {!!chat ? (
                        <View
                            style={[style.card, style.flex1]}
                            nativeID="contract-chat"
                        >
                            <ChatView
                                chat={chat}
                                handleFile={handleFile}
                                preFile={preFile}
                            />
                        </View>
                    ) : (
                        <View style={[style.card, style.flex1]}>
                            <CText
                                message={
                                    contractMessages.youAreNotParticipatingInThisChat
                                }
                                centered
                            />
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.horizontalSpaced,
                                ]}
                            >
                                <CButton
                                    onPress={joinChat}
                                    title={contractMessages.joinChat}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </ScrollProvider>
    );
};
