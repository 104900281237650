import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { User } from 'firebase/auth';
import { IDefaultProps } from '../IDefaultProps';
import { FireAppProvider } from './app/FireAppProvider';
import { FireAuthProvider } from './auth/FireAuthProvider';
import { FireFunctionProvider } from './function/FireFunctionProvider';
import { FireStorageProvider } from './storage/FireStorageProvider';
import { FirestoreProvider } from './store/FirestoreProvider';

import { ECollections, EEnvironment, EUserType } from '../../enums';
import { MAgency, MUserData, MWorkplace } from '../../models';
import { getRegionUrl, isLocalhost } from '../constants';
import { useEnvironment } from '../contexts';
import { useDialog } from '../dialog';
import { useFormat } from '../intl';
import { actionMessages, generalMessages } from '../messages';
import { useSecureNavigate } from '../routing';
import { FireBaseContext } from './FireBaseContext';
import { FireAuthContext } from './auth';
import { FireFunctionContext } from './function';
import { FirestoreContext } from './store';
import { Platform } from 'react-native';
import { isSuperUser } from '../auth';
/**
 * The complete firebaseContext stack
 * @param param0
 * @returns
 */
export const FireBaseProvider: FC<IDefaultProps> = ({ children }) => {
    /**
     * provide context
     */
    return (
        <FireAppProvider>
            <FireAuthProvider>
                <FirestoreProvider>
                    <FireStorageProvider>
                        <FireFunctionProvider>
                            <FireStateProvider>{children}</FireStateProvider>
                        </FireFunctionProvider>
                    </FireStorageProvider>
                </FirestoreProvider>
            </FireAuthProvider>
        </FireAppProvider>
    );
};
/**
 * internal firebase data state
 * manages userdata and confirms availability of services.
 *
 * ! has to be nested in all other firebase providers !
 * @param param0
 * @returns
 */
const FireStateProvider: FC<IDefaultProps> = ({ children }) => {
    // functions from contexts
    const { getDataById, getDataIndex } = useContext(FirestoreContext);
    const { onAuthStateChanged, logOut } = useContext(FireAuthContext);
    const { callFunction } = useContext(FireFunctionContext);
    const dialog = useDialog();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    const { setEnvironment, setRegion, region, environment } = useEnvironment();
    // local states
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User>();
    const [userData, setUserData] = useState(new MUserData());
    const [userWorkplaces, setUserWorkplaces] = useState<MWorkplace[]>([]);
    const [userAgencies, setUserAgencies] = useState<MAgency[]>([]);
    const [shouldLoadUserData, setShouldLoadUserData] = useState(false);
    const [noData, setNoData] = useState(false);
    const [disabledAttempt, setDisabledAttempt] = useState(0);
    /**
     * memoized value if user is logged in or not
     */
    const isLoggedIn = useMemo(
        () => !!(user?.uid && (noData || userData.documentId)),
        [user, userData, noData],
    );
    /**
     * memoized value if user has no data and requires onboarding
     */
    const requiresOnboarding = useMemo(
        () =>
            isLoggedIn &&
            (userData.type === EUserType.default ||
                !userData.agreeDataShare ||
                !userData.readAGB),
        [isLoggedIn, userData, noData],
    );
    /**
     * memoized value if user has 2fa
     */
    const mfaInfo = useMemo(() => {
        if (!user) {
            return;
        }
        const nextInfo = (user as any)?.reloadUserInfo?.mfaInfo as {
            displayName: string;
            enrolledAt: string;
            mfaEnrollmentId: string;
            phoneInfo: string;
        }[];
        if (nextInfo) {
            return !!(nextInfo.length ? nextInfo[0] : undefined);
        }
        const next = (user as any)?.multiFactor as {
            displayName: string; // 'Personal phone number';
            enrollmentTime: string; //'55886-02-02T03:50:00+0100';
            factorId: string; //'phone';
            uid: string; //'f95c128f-8a76-401b-a452-3a8f0da76bb8';
        };

        return !!next;
    }, [user]);
    /**
     * effect to initialize auth state handling
     */
    useEffect(() => {
        onAuthStateChanged((res) => {
            if (res) {
                // login
                setUser(res);
                setShouldLoadUserData(true);
            } else {
                // logout
                setUser(undefined);
                setUserWorkplaces([]);
                setUserAgencies([]);
                setUserData(new MUserData());
                if (loading) {
                    setLoading(false);
                }
                setNoData(false);
            }
        });
    }, []);
    /**
     * effect to load user data when everything is ready
     */
    useEffect(() => {
        if (shouldLoadUserData && user) {
            /**
             * set sould load user data ahead of time and no matter what happens to false to prevent duplicate
             */
            setShouldLoadUserData(false);
            /**
             * get id token to refresh it in case it is expired
             */
            user.getIdToken();
            /**
             * get fresh userData
             */
            getDataById(ECollections.users, user.uid)
                .then(async (d) => {
                    // set user data or set tried
                    if (d && Object.keys(d).length > 1) {
                        const next = new MUserData({
                            ...d,
                            mail: user.email || '',
                        });
                        if (next.disabled) {
                            setDisabledAttempt((prev) => prev + 1);
                            logOut();
                        } else if (
                            next.region !== region &&
                            Platform.OS === 'web' &&
                            !isLocalhost()
                        ) {
                            dialog({
                                icon: 'error',
                                title: generalMessages.wrongRegionLogin,
                                message: format(
                                    generalMessages.wrongRegionLoginText,
                                    {
                                        yourRegion: next.region,
                                        correctUrl: getRegionUrl(
                                            next.region,
                                            environment,
                                        ),
                                    },
                                ),
                            }).then(logOut);
                        } else if (
                            next.type !== EUserType.default &&
                            !isLocalhost() &&
                            !isSuperUser(next) &&
                            (next.type !== EUserType.user
                                ? environment === EEnvironment.SITUS
                                : environment === EEnvironment.TimePloyees)
                        ) {
                            const correctEnvironment = Object.values(
                                EEnvironment,
                            ).filter((v) => v !== environment)[0];
                            const variables = {
                                platformA: environment,
                                platformB: correctEnvironment,
                                correctUrl: getRegionUrl(
                                    next.region,
                                    correctEnvironment,
                                ),
                            };
                            console.log('issuing dialog');
                            dialog({
                                icon: 'error',
                                title: format(
                                    generalMessages.wrongPlatformLogin,
                                    variables,
                                ),
                                message: format(
                                    generalMessages.wrongPlatformLoginText,
                                    variables,
                                ),
                            }).then(logOut);
                        } else {
                            setUserData(next);
                            // @ts-ignore // ! this is webshit
                            const pathname = window?.location?.hostname;
                            if (pathname && pathname.includes('localhost')) {
                                if (next.type === EUserType.user) {
                                    setEnvironment(EEnvironment.SITUS);
                                } else if (next.type !== EUserType.default) {
                                    setEnvironment(EEnvironment.TimePloyees);
                                }
                                setRegion(next.region);
                            }
                            if (Platform.OS !== 'web') {
                                setRegion(next.region);
                            }
                            /**
                             * prevent popups for reopened still onboardin users
                             */
                            if (!(next.agreeDataShare && next.readAGB)) {
                                // DO NOTHING SINCE ONBOARDING IS ONGOING
                            } else if (!user.emailVerified) {
                                dialog({
                                    icon: 'info',
                                    title: generalMessages.eMailNotVerified,
                                    message: format(
                                        generalMessages.eMailNotVerifiedMessage,
                                        { mail: user.email },
                                    ),
                                    buttons: [
                                        {
                                            text: format(
                                                generalMessages.sendVerification,
                                            ),
                                            onPress: () => {
                                                callFunction('emailVerify', {
                                                    mail: user.email,
                                                });
                                            },
                                        },
                                    ],
                                    cancelButton: {
                                        text: format(actionMessages.later),
                                    },
                                });
                            } else if (
                                [EUserType.user, EUserType.agency].includes(
                                    next.type,
                                ) &&
                                !next.verified
                            ) {
                                dialog({
                                    icon: 'info',
                                    title: format(
                                        generalMessages.userNotVerified,
                                    ),
                                    message: format(
                                        generalMessages.requestVerificationFromAgent,
                                    ),
                                    buttons: [
                                        {
                                            text: format(
                                                actionMessages.bookNow,
                                            ),
                                            onPress: () => {
                                                secureNavigate('/latepoint');
                                            },
                                        },
                                    ],
                                    cancelButton: {
                                        text: format(actionMessages.later),
                                    },
                                });
                            }
                        }
                    } else {
                        console.log('got nothing');
                        setUserData(new MUserData({ documentId: user.uid }));
                        setNoData(true);
                    }
                    if (loading) {
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    dialog({
                        icon: 'error',
                        title: generalMessages.errorOccured,
                        message: `${e}`,
                    });
                    logOut();
                });
        }
    }, [shouldLoadUserData, user, region, environment]);
    /**
     * effect to load agencies / workplaces on userdata
     * for employer & agency users
     */
    useEffect(() => {
        if (userData.type === EUserType.employer) {
            getDataIndex(ECollections.workplaces, {
                filter: [
                    {
                        field: 'users',
                        operator: 'array-contains',
                        value: userData.documentId,
                    },
                ],
            }).then((res) =>
                setUserWorkplaces((res as any[]).map((r) => new MWorkplace(r))),
            );
        } else if (userData.type === EUserType.agency) {
            getDataIndex(ECollections.agencies, {
                filter: [
                    {
                        field: 'users',
                        operator: 'array-contains',
                        value: userData.documentId,
                    },
                ],
            }).then((res) =>
                setUserAgencies((res as any[]).map((r) => new MAgency(r))),
            );
        }
    }, [userData]);
    /**
     * provide context
     */
    return (
        <FireBaseContext.Provider
            value={{
                user,
                userData,
                userWorkplaces,
                userAgencies,
                isLoggedIn,
                requiresOnboarding,
                loading,
                reloadUserData: () => setShouldLoadUserData(true),
                disabledAttempt,
                mfaInfo,
            }}
        >
            {children}
        </FireBaseContext.Provider>
    );
};
