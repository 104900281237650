import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useParams, useSecureNavigate } from '../../utilities/routing';
import { MContract, MShift, MTimesheet } from '../../models';
import { CButton, CCard, CText, Spinner } from '../../components';
import { useFireBase } from '../../utilities/firebase';
import { ECollections } from '../../enums';
import { useStyle } from '../../utilities/styles';
import { actionMessages } from '../../utilities/messages';
import { ShiftRow } from './components/ShiftRow';
import { ShiftRowEdit } from './components/ShiftRowEdit';
import { timeTrackingMessages } from './timetracking.messages';
import { useDialog, useUnsavedChangesDialog } from '../../utilities/dialog';
import { useLock } from '../../utilities/hooks';
import { day } from '../../utilities/functions';
import { ContractTitle } from '../Contract/components/ContractTitle';
import { ScrollProvider } from '../../utilities/contexts';
/**
 * Time tracking view
 * > https://trello.com/c/3ulgKNrj/289-timetracking-app-bauen-stundenzettel-designen-und-zu-contract-hochladen
 */
export const TimeTracking: FC = () => {
    // global state
    const { lock } = useLock();
    const style = useStyle();
    const dialog = useDialog();
    const unsavedChangesDialog = useUnsavedChangesDialog();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { contractId } = useParams();
    const { getDataById, getDataIndex, callFunction } = useFireBase();
    // local state
    const [contract, setContract] = useState<MContract>();
    const [timesheet, setTimesheet] = useState<MTimesheet>();
    const [shifts, setShifts] = useState<MShift[]>([]);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [cellWidth, setCellWidth] = useState(0);
    /**
     * callback to handle timesheet change
     */
    const onChange = useCallback(
        (next: Partial<MTimesheet>) =>
            setTimesheet((prev) => new MTimesheet({ ...prev, ...next })),
        [],
    );
    /**
     * callback to handle shhift change
     */
    const onChangeShift = useCallback(
        (index: number, next: Partial<MShift>) =>
            setShifts((prev) => {
                if (prev.length < index) return prev;
                /**
                 * new shift object with updated value
                 */
                const shoi = new MShift({ ...prev[index], ...next });
                /**
                 * recalculate from
                 */
                shoi.from = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.fromHour,
                    shoi.fromMinute,
                ).getTime();
                /**
                 * recalculate to
                 */
                shoi.to = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.toHour,
                    shoi.toMinute,
                ).getTime();
                /**
                 * create new array
                 */
                const toReturn = [...prev];
                /**
                 * set index to new shift object
                 */
                toReturn[index] = shoi;

                return toReturn;
            }),
        [],
    );
    /**
     * callback to add a new shift
     */
    const addShift = useCallback(() => {
        setShifts((prev) => {
            const nextShiftDate = new Date(prev[prev.length - 1].from + day);
            const date = nextShiftDate.getDate();
            const month = nextShiftDate.getMonth();
            const year = nextShiftDate.getFullYear();
            return [
                ...prev,
                new MShift({
                    date: `${date}`,
                    month: `${month + 1}`,
                    year: `${year}`,
                    fromHour: 9,
                    fromMinute: 0,
                    from: new Date(year, month, date, 9, 0).getTime(),
                    toHour: 17,
                    toMinute: 0,
                    to: new Date(year, month, date, 17, 0).getTime(),
                    complete: true,
                }),
            ];
        });
    }, []);
    /**
     * callback to remove a shift from index
     */
    const removeShift = useCallback((index: number) => {
        setShifts((prev) => {
            const next = [...prev];
            next.splice(index, 1);
            return next;
        });
    }, []);
    /**
     * callback to handle timesheet save action
     */
    const handleSave = useCallback(async () => {
        if (!timesheet || !contract) return;
        let complete = false;
        await dialog({
            icon: 'question',
            title: timeTrackingMessages.saveAndComplete,
            message: timeTrackingMessages.saveAndComplete,
            buttons: [
                {
                    text: timeTrackingMessages.saveAndComplete,
                    onPress: () => (complete = true),
                },
                { text: actionMessages.save },
            ],
            verticalButtons: true,
        });
        if (
            complete &&
            !(await dialog({
                icon: 'warning',
                title: timeTrackingMessages.confirmComplete,
                message: timeTrackingMessages.confirmCompleteText,
                buttons: [{ text: actionMessages.confirm }],
                cancelButton: { text: actionMessages.cancel },
            }))
        )
            return;
        const unlock = lock();
        await callFunction('saveTimesheet', { ...timesheet, shifts });
        unlock();
        secureNavigate(-1, { force: true });
    }, [timesheet, shifts]);
    /**
     * effect to load the contract the time sheet is for
     */
    useEffect(() => {
        if (!contractId) return;

        getDataById(ECollections.contracts, contractId).then((c) =>
            setContract(c),
        );
    }, [contractId]);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!contract) return;
        const timesheetCollection = `${ECollections.contracts}/${contract.documentId}/${ECollections.timesheets}`;
        getDataIndex(timesheetCollection).then((ts) => {
            const results = (ts as any[]).map((t) => new MTimesheet(t));

            if (results.length) {
                const next = new MTimesheet(results[0]);
                setTimesheet(next);
                getDataIndex(
                    `${timesheetCollection}/${next.documentId}/${ECollections.shifts}`,
                ).then((ss) =>
                    setShifts((ss as MShift[]).map((s) => new MShift(s))),
                );
            } else {
                setShifts(
                    contract.activeDates.map((s) => {
                        const ss = s.split('.');
                        return new MShift({
                            date: ss[0],
                            month: ss[1],
                            year: ss[2],
                            fromHour: 9,
                            fromMinute: 0,
                            from: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                9,
                                0,
                            ).getTime(),
                            toHour: 17,
                            toMinute: 0,
                            to: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                17,
                                0,
                            ).getTime(),
                            complete: true,
                        });
                    }),
                );
                setTimesheet(
                    new MTimesheet({
                        contractId: contract.documentId,
                        agencyId: contract.agencyId,
                        employeeId: contract.employeeId,
                        workplaceId: contract.workplaceId,
                    }),
                );
            }
        });
    }, [contract]);
    /**
     * set navigation lock
     */
    useEffect(
        () => setNavigationLock(async () => !(await unsavedChangesDialog())),
        [],
    );
    /**
     * return spinner during load
     */
    if (!timesheet || !contract) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.headlineCard}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        style={style.leftPadded}
                        message={timeTrackingMessages.timesheet}
                        headline
                    >
                        :
                    </CText>
                    <ContractTitle contract={contract} horizontalPadded />
                </View>
            </View>
            <CCard>
                <View style={style.horizontalSplit}>
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            secondaryHeadline
                            message={timeTrackingMessages.date}
                        />
                    </View>
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            secondaryHeadline
                            message={timeTrackingMessages.from}
                        />
                    </View>
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            secondaryHeadline
                            message={timeTrackingMessages.to}
                        />
                    </View>
                    <View style={[style.centeredItems, { width: cellWidth }]}>
                        <CText
                            secondaryHeadline
                            message={timeTrackingMessages.breaks}
                        />
                    </View>
                </View>
            </CCard>
            {shifts.map((shift, i) => (
                <CCard
                    key={i}
                    style={{ zIndex: 1 + shifts.length - i }}
                    outsideStyle={{ zIndex: 1 + shifts.length - i }}
                >
                    <View style={[style.centeredContent, { zIndex: 1 }]}>
                        {editingIndex !== i ? (
                            <ShiftRow
                                shift={shift}
                                toggleEdit={() => setEditingIndex(i)}
                                onLayout={(e) => {
                                    setCellWidth(
                                        e.nativeEvent.layout.width / 4,
                                    );
                                }}
                                cellWidth={cellWidth}
                            />
                        ) : (
                            <ShiftRowEdit
                                onChange={(next) => onChangeShift(i, next)}
                                onRemove={() => removeShift(i)}
                                shift={shift}
                                cellWidth={cellWidth}
                            />
                        )}
                    </View>
                </CCard>
            ))}
            <View style={[style.verticalPadded, { marginHorizontal: 'auto' }]}>
                <CButton
                    onPress={addShift}
                    title={timeTrackingMessages.addShift}
                    icon="plus"
                />
                <CButton onPress={handleSave} title={actionMessages.save} />
            </View>
        </ScrollProvider>
    );
};
