import { defineMessages } from 'react-intl';

const scope = 'component.calendar.';

export const calendarMessages = defineMessages({
    openJob: {
        id: scope + 'openJob',
        defaultMessage: 'A unfilled job listing exists for that day',
    },
    activeNegotiation: {
        id: scope + 'activeNegotiation',
        defaultMessage: 'A negotiation is in progress for that day',
    },
    activeContract: {
        id: scope + 'activeContract',
        defaultMessage: 'A contract is active for that day',
    },
    available: {
        id: scope + 'available',
        defaultMessage: 'You are set to be available during that day',
    },
    talentAvailable: {
        id: scope + 'talentAvailable',
        defaultMessage:
            'The selected talent is marked as available during that day',
    },
    talentActiveNegotiation: {
        id: scope + 'talentActiveNegotiation',
        defaultMessage:
            'The selected talent is involved in a negotiation which contains a period spanning ove that day',
    },
    talentActiveContract: {
        id: scope + 'talentActiveContract',
        defaultMessage:
            'The selected talent is involved in a contract during that day',
    },
});
