import React, { FC } from 'react';
import { Linking, View } from 'react-native';
import { MFile } from '../../models';
import { useFireBase } from '../../utilities/firebase';
import { useFormat } from '../../utilities/intl';
import { filenameMessages } from '../../utilities/messages';
import { useStyle, useTheme } from '../../utilities/styles';
import { DocumentPreview } from '../DocumentPreview';
import { CText, CButton } from '../elements';

export const DocumentPreviewRow: FC<{
    file: MFile;
    onDelete?: () => void;
}> = ({ file, onDelete }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { getFileDownloadUrl } = useFireBase();

    return (
        <View>
            <View style={style.horizontalSplit}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <View style={style.horizontalPadded} />
                    <CText numberOfLines={1} bold>
                        {format(filenameMessages[file.type])}
                    </CText>
                </View>
                <View style={style.horizontal}>
                    <CButton
                        icon="download"
                        onPress={() => {
                            getFileDownloadUrl(file.path).then((u) => {
                                Linking.openURL(u);
                            });
                        }}
                        minor
                    />
                    {onDelete && (
                        <CButton
                            icon={'minus'}
                            iconColor={theme.errorColor}
                            onPress={onDelete}
                            minor
                        />
                    )}
                </View>
            </View>
            <DocumentPreview filename={file.path} />
        </View>
    );
};
