import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CDatePicker,
    CText,
    InfoBox,
    WageInput,
    WageView,
} from '../../../../../components';
import { useFormat } from '../../../../../utilities/intl';
import { actionMessages } from '../../../../../utilities/messages';
import { useStyle } from '../../../../../utilities/styles';
import { jobMessages } from '../../../../Job/Job.messages';
import { contractMessages } from '../../../contract.messages';
import { ContractNegotiationContext } from '../ContractNegotiationContext';
import { ERegion } from '../../../../../enums';
import { useFireBase } from '../../../../../utilities/firebase';
import { MRates } from '../../../../../models';
import { Rates } from '../../../../../components/Wage/Rates';
/**
 * set the contract values as workplace
 * @param param0 props
 * @returns
 */
export const InitContract: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { callFunction } = useFireBase();

    const { negotiation, handleClose, onChange } = useContext(
        ContractNegotiationContext,
    );
    const [wageLock, setWageLock] = useState(false);
    const [confirmedWage, setConfirmedWage] = useState(false);

    const initContract = useCallback(async () => {
        await callFunction('initContract', {
            contractId: negotiation.documentId,
            ...negotiation.wage,
            from: negotiation.from,
            to: negotiation.to,
            usualWage: negotiation.usualWage,
            wasInitialized: wageLock,
        });
    }, [negotiation, wageLock]);

    useEffect(() => {
        if (negotiation.originalParameters.wage.wage) {
            setWageLock(true);
        }
    }, [negotiation]);

    return (
        <>
            <View style={style.card}>
                <CText headline>
                    {format(contractMessages.negotiationInit)}
                </CText>
                {negotiation.region === ERegion.de && (
                    <>
                        {/* <CTextInput
                            disabled={confirmedWage}
                            cy={'usualWage'}
                            label={format(contractMessages.usualWage)}
                            value={convertNumberToCurrencyString(
                                negotiation.usualWage,
                                negotiation.region
                            )}
                            onChangeText={(s) => {
                                onChange({
                                    usualWage: convertCurrencyStringToNumber(s),
                                });
                            }}
                        /> */}
                        <CCheckBox
                            cy={'confirmUsualWage'}
                            // disabled={!negotiation.usualWage}
                            checked={confirmedWage}
                            onCheckedChanged={setConfirmedWage}
                            title={contractMessages.usualWageStatementNoWage}
                        />
                    </>
                )}
            </View>
            {(confirmedWage || negotiation.region !== ERegion.de) && (
                <View style={style.card}>
                    {wageLock ? (
                        negotiation.fixedJobWage ? (
                            <>
                                <View style={style.verticalPadded}>
                                    <InfoBox
                                        message={
                                            contractMessages.presetRateFromJob
                                        }
                                        type={'info'}
                                    />
                                </View>
                                <WageView value={negotiation.wage} />
                            </>
                        ) : (
                            <>
                                <View style={style.verticalPadded}>
                                    <InfoBox
                                        message={contractMessages.presetRate}
                                        type={'info'}
                                    />
                                </View>
                                <WageView value={negotiation.wage} />
                            </>
                        )
                    ) : (
                        <Rates
                            curData={negotiation.wage}
                            onChange={(wage) =>
                                onChange({
                                    wage: new MRates({
                                        ...negotiation.wage,
                                        ...wage,
                                    }),
                                })
                            }
                        />
                    )}
                    <View style={[style.horizontal, { zIndex: 1 }]}>
                        <CDatePicker
                            title={format(jobMessages.from)}
                            value={new Date(negotiation.from)}
                            onChange={(next) =>
                                onChange({
                                    from: next.getTime(),
                                })
                            }
                        />
                        <CDatePicker
                            title={format(jobMessages.to)}
                            value={new Date(negotiation.to)}
                            onChange={(next) =>
                                onChange({
                                    to: next.getTime(),
                                })
                            }
                        />
                    </View>
                    {!!negotiation.wage.wage && (
                        <>
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.centeredItems,
                                ]}
                            >
                                <CText
                                    secondaryHeadline
                                    message={contractMessages.offerConditions}
                                />
                            </View>
                            <View style={style.horizontalSpaced}>
                                <CButton
                                    cy={'cancel'}
                                    title={actionMessages.cancel}
                                    onPress={handleClose}
                                    warning
                                />
                                <CButton
                                    cy={'continue'}
                                    title={actionMessages.continue}
                                    onPress={initContract}
                                    disableOnClick
                                />
                            </View>
                        </>
                    )}
                </View>
            )}
        </>
    );
};
