import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MContract } from '../../../models';
import { CText } from '../../../components';
import { calendarMessages } from '../Calendar.messages';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { useNavigate } from '../../../utilities/routing';
import { ContractRow } from '../../Contract/List/components/ContractRow';
/**
 * todays contracts component for employers below calendar
 * @param param0
 * @returns
 */
export const TodaysContracts: FC<{
    curDate: Date;
    setRange: (r: string[]) => void;
    contracts: MContract[];
    selectedContract?: MContract;
    setSelectedContract: (next: MContract) => void;
}> = ({
    curDate,
    setRange,
    contracts,
    setSelectedContract,
    selectedContract,
}) => {
    // global state
    const format = useFormat();
    const navigate = useNavigate();
    const style = useStyle();
    // local state
    const [todaysContracts, setTodaysContracts] = useState<MContract[]>([]);
    /**
     * effect to set contracts filtered based on cur date
     */
    useEffect(() => {
        setTodaysContracts(
            contracts.filter((j) =>
                j.activeDates.includes(curDate.toLocaleDateString('de')),
            ),
        );
    }, [curDate, contracts]);
    /**
     * render
     */
    return (
        <View>
            {/* {selectedContract && (
                <>
                    <View style={style.card}>
                        <CText
                            secondaryHeadline
                            message={calendarMessages.selectedContract}
                        />
                    </View>
                    <ContractRow contract={selectedContract} highlight />
                </>
            )} */}
            {!!todaysContracts.length && (
                <View style={style.card}>
                    <CText
                        secondaryHeadline
                        message={format(calendarMessages.todaysContracts, {
                            date: curDate.toLocaleDateString('de'),
                        })}
                    />
                </View>
            )}
            {todaysContracts.map((contract) => (
                <React.Fragment key={contract.documentId}>
                    <ContractRow
                        negotiation={contract}
                        highlight={
                            contract.documentId === selectedContract?.documentId
                        }
                        onPress={() => {
                            setRange(contract.activeDates);
                            setSelectedContract(contract);
                        }}
                        // noSubHead
                    />
                </React.Fragment>
            ))}
            {!todaysContracts.length && (
                <View style={[style.centeredItems, style.card]}>
                    <CText
                        secondaryHeadline
                        message={calendarMessages.noContractsForThisDay}
                        style={style.verticalPadded}
                    />
                </View>
            )}
        </View>
    );
};
