import { ITheme } from '../ITheme';

export const lightThemeTimePloyees: ITheme = {
    backgroundMainColor: '#FFF',
    backgroundSecondaryColor: '#F4F2EE',
    backgroundTertiaryColor: '#f5f5f5',
    borderColor: '#cdced5',
    textMainColor: '#222b54',
    textSecondaryColor: '#0a3c5f',
    textTertiaryColor: '#a7aeb3',
    accentColor: '#62c1c5',
    darkAccentColor: '#00666b',
    textAccentColor: '#fafcfb',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#f44336',
    warningColor: '#b98a20',
    successColor: '#6AC69E',
    mainFontFamily: 'Lota Grotesque',
    headlineFontFamily: 'Lota Grotesque',
    shadowColor: '#000000',
};
