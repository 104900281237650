import React, { FC, useMemo } from 'react';
import { MProfessionalInfo, MUserData } from '../../models';
import { CLabel } from '../elements/CLabel';
import { fieldMessages, generalMessages } from '../../utilities/messages';
import { getUserTypeColor } from '../../utilities/constants';
import { EProfession, EUserType } from '../../enums';
import { useFormat } from '../../utilities/intl';
/**
 * User Type label. Displays Profession for EUserType.User and ProfProfiles
 */
export const UserTypeLabel: FC<{
    data: MProfessionalInfo;
    small?: boolean;
}> = ({ data, small }) => {
    const format = useFormat();
    /**
     * memoized text to display usually usertype or profession
     */
    const textToDisplay = useMemo(() => {
        const userData = data as MUserData;

        if (userData.type && userData.type !== EUserType.user) {
            if (userData.type === EUserType.talent) {
                return [
                    format(generalMessages[userData.type]),
                    format(
                        userData.profession === EProfession.undefined
                            ? fieldMessages[data.field]
                            : generalMessages[data.profession],
                    ),
                ].join(': ');
            }
            return generalMessages[userData.type];
        } else {
            return data.profession === EProfession.undefined
                ? fieldMessages[data.field]
                : generalMessages[data.profession];
        }
    }, [data]);
    /**
     * memoized color of label
     */
    const colorToDisplay = useMemo(() => {
        const userData = data as MUserData;

        if (userData.type) {
            return getUserTypeColor(userData.type);
        } else {
            return getUserTypeColor(EUserType.user);
        }
    }, [data]);
    /**
     * render
     */
    return (
        <CLabel
            title={textToDisplay}
            color={colorToDisplay}
            smaller
            checked
            style={small && { margin: 0 }}
        />
    );
};
