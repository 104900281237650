import React, { FC, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import { CButton, CCard, CDataTable, CText, Spinner } from '../../components';
import { CImage } from '../../components/elements/CImage';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFormat } from '../../utilities/intl';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
} from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';
import { billingCenterMessages } from './billingCenter.messages';
import { useFireBase } from '../../utilities/firebase';
import {
    EUserType,
    ECollections,
    EField,
    EProfession,
    EContractStatus,
} from '../../enums';
import { MContract, MWorkplace } from '../../models';
import { isSuperUser } from '../../utilities/auth';
import { IFilter } from '../../utilities/firebase/store';
import { ContractState } from '../Contract/components/ContractState';
/**
 * Billing center to list contract history and track payment status
 * @returns
 */
export const BillingCenter: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const {
        getDataIndex,
        getDataById,
        userData,
        userWorkplaces,
        callFunction,
        getFileDownloadUrl,
    } = useFireBase();
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [loadingContracts, setLoadingContracts] = useState(true);
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const params = {
            filter: [] as IFilter[],
        };
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                params.filter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((w) => w.documentId),
                    operator: 'in',
                });
            } else {
                params.filter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        params.filter.push({
            field: 'status',
            operator: 'in',
            value: [EContractStatus.completed, EContractStatus.paid],
        });
        getDataIndex(ECollections.contracts, params).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setContracts(nextContracts);
            setLoadingContracts(false);
        });
    }, [userData, userWorkplaces]);
    /**
     * effect to load workplaces
     */
    useEffect(() => {
        const wpids = Array.from(new Set(contracts.map((c) => c.workplaceId)));

        Promise.all(
            wpids.map((wpid) =>
                getDataById(ECollections.publicWorkplaces, wpid),
            ),
        ).then((wps) => setWorkplaces(wps.map((wp) => new MWorkplace(wp))));
    }, [contracts]);
    /**
     * render spinner during load
     */
    if (loadingContracts) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider
            style={!contracts.length && style.paddedScrollableMainView}
        >
            <View style={style.headlineCard}>
                <CText bold headline message={generalMessages.billingCenter} />
            </View>
            {!contracts.length ? (
                <CCard>
                    <CImage image={'payment'} relativeWidth={0.7} />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            headline
                            message={billingCenterMessages.empty}
                        />
                    </View>
                </CCard>
            ) : (
                <CCard>
                    <CDataTable
                        headers={[
                            { headerKey: 'documentId', label: 'ID' },
                            {
                                headerKey: 'status',
                                label: billingCenterMessages.status,
                            },
                            {
                                headerKey: 'from',
                                label: billingCenterMessages.from,
                            },
                            {
                                headerKey: 'to',
                                label: billingCenterMessages.to,
                            },
                            {
                                headerKey: 'workplace',
                                label: billingCenterMessages.workplace,
                            },
                            {
                                headerKey: 'field',
                                label: billingCenterMessages.field,
                            },
                            {
                                headerKey: 'profession',
                                label: billingCenterMessages.profession,
                            },
                            {
                                headerKey: 'education',
                                label: billingCenterMessages.education,
                            },
                            {
                                headerKey: 'createdOn',
                                label: billingCenterMessages.createdOn,
                            },
                            {
                                headerKey: 'files',
                                label: billingCenterMessages.files,
                            },
                        ]}
                        rows={contracts.map((c) => ({
                            values: [
                                {
                                    headerKey: 'documentId',
                                    value: c.documentId,
                                },
                                {
                                    headerKey: 'workplace',
                                    value:
                                        workplaces.find(
                                            (wp) =>
                                                wp.documentId === c.workplaceId,
                                        )?.name || c.workplaceId,
                                },
                                {
                                    headerKey: 'createdOn',
                                    value: new Date(
                                        c.createdOn,
                                    ).toLocaleDateString('de'),
                                },
                                {
                                    headerKey: 'from',
                                    value: new Date(c.from).toLocaleDateString(
                                        'de',
                                    ),
                                },
                                {
                                    headerKey: 'to',
                                    value: new Date(c.to).toLocaleDateString(
                                        'de',
                                    ),
                                },
                                {
                                    headerKey: 'status',
                                    value: '',
                                    component: (
                                        <View style={style.horizontal}>
                                            <ContractState contract={c} />
                                        </View>
                                    ),
                                },
                                {
                                    headerKey: 'field',
                                    value: format(
                                        fieldMessages[
                                            c.field || EField.undefined
                                        ],
                                    ),
                                },
                                {
                                    headerKey: 'profession',
                                    value: format(
                                        generalMessages[
                                            c.profession ||
                                                EProfession.undefined
                                        ],
                                    ),
                                },
                                {
                                    headerKey: 'education',
                                    value: c.education
                                        ? format(
                                              educationMessages[
                                                  c.education as keyof typeof educationMessages
                                              ],
                                          )
                                        : '?',
                                },
                                {
                                    headerKey: 'files',
                                    value: '',
                                    component: (
                                        <CButton
                                            onPress={() =>
                                                callFunction('getContractZip', {
                                                    contractId: c.documentId,
                                                }).then((path) =>
                                                    getFileDownloadUrl(
                                                        path,
                                                    ).then((url) =>
                                                        Linking.openURL(url),
                                                    ),
                                                )
                                            }
                                            smallest
                                            icon={'download'}
                                        />
                                    ),
                                },
                            ],
                        }))}
                    />
                </CCard>
            )}
        </ScrollProvider>
    );
};
