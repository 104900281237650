import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useStyle } from '../../../../utilities/styles';
import {
    CPicker,
    FilePicker,
    DocumentPreviewRow,
} from '../../../../components';
import { EAgencyFiles, ECollections } from '../../../../enums';
import {
    filenameMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { useFormat } from '../../../../utilities/intl';
import { MAgency, MAgencyFile } from '../../../../models';
import { useLock } from '../../../../utilities/hooks';
import { useDialog } from '../../../../utilities/dialog';
import { useFileUpload } from '../../../../utilities/hooks/useFileUpload';
/**
 * section for documents of agency
 * - more or less equivalent to user docs
 */
export const AgencyDocuments: FC<{
    agency: MAgency;
    onChange: (next: Partial<MAgency>) => void;
}> = ({ agency, onChange }) => {
    const style = useStyle();
    const format = useFormat();
    const { lock } = useLock();
    const dialog = useDialog();
    const fileUpload = useFileUpload();
    // local state
    const [currentUploadable, setCurrentUploadable] = useState(
        EAgencyFiles.license,
    );
    const docs = useMemo(() => {
        const next = Object.values(EAgencyFiles);
        return next;
    }, []);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (newFn: string, file: Uint8Array) => {
            const unlock = lock();
            const fakeId = Date.now().toString(36);
            try {
                const baseFile = await fileUpload(
                    `${ECollections.agencies}/${agency.documentId}`,
                    newFn,
                    file,
                );
                onChange({
                    files: [
                        ...agency.files,
                        new MAgencyFile({
                            ...baseFile,
                            type: currentUploadable,
                            documentId: fakeId,
                        }),
                    ],
                });
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [currentUploadable, agency, fileUpload],
    );
    /**
     * render
     */
    return (
        <View>
            <View style={[style.horizontal, style.centeredItems]}>
                <View style={[style.flex1]}>
                    <CPicker
                        onChange={setCurrentUploadable}
                        values={docs
                            .map((d) => {
                                return {
                                    label: format(
                                        filenameMessages[d as EAgencyFiles],
                                    ),
                                    value: d,
                                };
                            })
                            .sort((a, b) => (a.label > b.label ? 1 : -1))}
                        value={currentUploadable}
                        title={format(profileMessages.documentToUpload)}
                    />
                </View>
                <View
                    // adjustment to be inline with picker
                    style={{
                        justifyContent: 'flex-end',
                        alignSelf: 'stretch',
                        paddingBottom: 5,
                    }}
                >
                    <FilePicker onFile={handleFile} />
                </View>
            </View>
            {(agency.files || []).map((file, i) => {
                if (!docs.includes(file.type)) {
                    return;
                }
                return (
                    <DocumentPreviewRow
                        key={file.path}
                        file={file}
                        onDelete={() => {
                            const next = Array.from(agency.files);
                            next.splice(i, 1);
                            onChange({ files: next });
                        }}
                    />
                );
            })}
        </View>
    );
};
