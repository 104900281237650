import React, { FC, useContext, useEffect, useMemo } from 'react';
import { CPicker, CText, CTextInput, Radio } from '../../../../components';
import { EEmploymentType } from '../../../../enums';
import { useFormat } from '../../../../utilities/intl';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profProfileMessages } from '../../ProfProfile.messages';
import { employmentTypeMessages } from '../../../../utilities/messages';
import { View } from 'react-native';
import { useStyle } from '../../../../utilities/styles';

export const ProfessionalEmployment: FC = () => {
    const format = useFormat();
    const style = useStyle();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    /**
     * memoized travel level
     */
    const w = useMemo(() => {
        if (curData.willingToTravel && !curData.notWillingToTravel) {
            return 3;
        } else if (!curData.willingToTravel && !curData.notWillingToTravel) {
            return 2;
        } else if (!curData.willingToTravel && curData.notWillingToTravel) {
            return 1;
        }
    }, [curData]);
    /**
     * effect to comunicate validity to context
     */
    useEffect(() => {
        onValidityChange('', 'employment');
    }, [curData]);

    return (
        <>
            <CPicker
                cy={'employment-type'}
                title={profProfileMessages.employType}
                values={[
                    // ...Object.values(EEmploymentType).map((v) => {
                    ...[
                        EEmploymentType.personnelLeasing,
                        EEmploymentType.fullTime,
                    ].map((v) => {
                        return {
                            label: format(employmentTypeMessages[v]),
                            value: v,
                        };
                    }),
                ]}
                onChange={(type) => {
                    // Do nothing
                }}
                multiValues={curData.employmentType}
                onChangeMulti={(next) => {
                    onChange({ employmentType: [...next] });
                }}
                keepOpen
            />
            <View style={style.verticalPadded}>
                <CText message={profProfileMessages.travelAvailability} />
                <Radio
                    cy={'travel-radio'}
                    values={[
                        {
                            label: format(profProfileMessages.willingToTravel),
                            value: 3,
                        },
                        {
                            label: format(profProfileMessages.neutralToTravel),
                            value: 2,
                        },
                        {
                            label: format(
                                profProfileMessages.notWillingToTravel,
                            ),
                            value: 1,
                        },
                    ]}
                    value={w}
                    onChange={(next) => {
                        if (next === 3) {
                            onChange({
                                willingToTravel: true,
                                notWillingToTravel: false,
                            });
                        } else if (next === 2) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: false,
                            });
                        } else if (next) {
                            onChange({
                                willingToTravel: false,
                                notWillingToTravel: true,
                            });
                        }
                    }}
                />
            </View>
            <View style={style.verticalPadded}>
                {!curData.willingToTravel && !curData.notWillingToTravel && (
                    <CTextInput
                        value={`${curData.maxTravelDistance}`}
                        onChangeText={(t) => {
                            if (!isNaN(+t)) {
                                onChange({ maxTravelDistance: +t });
                            }
                        }}
                        unit={'km'}
                        label={format(profProfileMessages.maxTravelDistance)}
                        placeholder={format(
                            profProfileMessages.maxTravelDistancePlaceholder,
                        )}
                    />
                )}
            </View>
        </>
    );
};
