import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CImage, CText } from '../../../../../../components';
import { EContractStatus } from '../../../../../../enums/EContractStatus';
import { isPeasant } from '../../../../../../utilities/auth';
import { ScrollProvider } from '../../../../../../utilities/contexts/Scroll';
import { useFireBase } from '../../../../../../utilities/firebase';
import {
    useNavigate,
    useSecureNavigate,
} from '../../../../../../utilities/routing';
import { useStyle } from '../../../../../../utilities/styles';
import { ContractOverviewBar } from '../../../../components/ContractOverviewBar';
import { contractMessages } from '../../../../contract.messages';
import { ContractNegotiationContext } from '../../ContractNegotiationContext';
import { InitContract } from '../InitContract';
import { ReviewContract } from '../ReviewContract';
import { ECollections, EUserType } from '../../../../../../enums';
import { ApplicantOverviewBar } from '../../../../components/ApplicantOverviewBar';
import { JobOverviewBar } from '../../../../components/JobOverviewBar';
import { MJob, MApplication } from '../../../../../../models';
import { ContractTitle } from '../../../../components/ContractTitle';
import { ContractCanceled } from '../ContractCanceled';
import { actionMessages } from '../../../../../../utilities/messages';

export const AgencyNegotiation: FC = () => {
    const style = useStyle();
    const { userData, getDataById } = useFireBase();
    const navigate = useNavigate();

    const { secureNavigate } = useSecureNavigate();
    // parent state
    const { negotiation, handleClose, originalNegotiation } = useContext(
        ContractNegotiationContext,
    );
    // local state
    const [job, setJob] = useState<MJob>();
    const [application, setApplication] = useState<MApplication>();
    /**
     * effect to navigate to contract view in case of signed or further advanced contract
     */
    useEffect(() => {
        if ([EContractStatus.signed].includes(negotiation.status)) {
            navigate(`/contract/${negotiation.documentId}`, { replace: true });
        }
    }, [negotiation]);
    /**
     * effect to load job details for yee to inspect and application for yer to inspect
     */
    useEffect(() => {
        if (userData.type === EUserType.agency) {
            getDataById(ECollections.jobs, originalNegotiation.jobId).then(
                (v) => {
                    setJob(new MJob(v));
                },
            );
        }
        if (userData.type === EUserType.employer) {
            getDataById(
                ECollections.applications,
                originalNegotiation.applicationId,
            ).then((v) => {
                const next = new MApplication(v);
                setApplication(next);
            });
        }
    }, [originalNegotiation]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <ContractTitle contract={negotiation} horizontalPadded />
                </View>
            </View>
            {negotiation.status !== EContractStatus.creating &&
                !negotiation.status.includes('review') && (
                    <ContractOverviewBar contract={negotiation} />
                )}
            {userData.type === EUserType.employer && application && (
                <ApplicantOverviewBar application={application} />
            )}
            {userData.type === EUserType.agency && job && (
                <JobOverviewBar job={job} />
            )}
            {negotiation.status === EContractStatus.creating &&
                (isPeasant(userData) ? (
                    <View style={style.card}>
                        <CImage image={'inReview'} />
                        <CText
                            centered
                            headline
                            message={contractMessages.beingCreated}
                        />
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CButton
                                cy={'cancel'}
                                title={actionMessages.close}
                                onPress={handleClose}
                                danger
                            />
                        </View>
                    </View>
                ) : (
                    <InitContract />
                ))}
            {negotiation.status === EContractStatus.review_yee &&
                (isPeasant(userData) ? (
                    <ReviewContract />
                ) : (
                    <View style={style.card}>
                        <CImage image={'inReview'} />
                        <CText
                            centered
                            headline
                            message={contractMessages.beingReviewed}
                        />
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CButton
                                cy={'cancel'}
                                title={actionMessages.close}
                                onPress={handleClose}
                                danger
                            />
                        </View>
                    </View>
                ))}
            {negotiation.status === EContractStatus.review_yer &&
                (isPeasant(userData) ? (
                    <View style={style.card}>
                        <CImage image={'inReview'} />
                        <CText
                            centered
                            headline
                            message={contractMessages.beingReviewed}
                        />
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            <CButton
                                cy={'cancel'}
                                title={actionMessages.close}
                                onPress={handleClose}
                                danger
                            />
                        </View>
                    </View>
                ) : (
                    <ReviewContract />
                ))}
            {negotiation.status === EContractStatus.canceled && (
                <ContractCanceled />
            )}
        </ScrollProvider>
    );
};
