import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image, View } from 'react-native';
import { getAsset } from '../../assets';
import { ECollections, EProfession } from '../../enums';
import { EGender } from '../../enums/EGender';
import {
    MApplication,
    MProfessionalInfo,
    MProfessionalProfile,
    MUserData,
} from '../../models';
import { useStyle } from '../../utilities/styles';
import { useFireBase } from '../../utilities/firebase';

interface IApplicationPictureProps {
    large?: boolean;
    small?: boolean;
    smaller?: boolean;
    data: MApplication;
}

const smallerSize = 36;
/**
 * profile picture based on application
 * @param param0
 * @returns
 */
export const ApplicationPicture: FC<IApplicationPictureProps> = ({
    data,
    large,
    small,
    smaller,
}) => {
    const style = useStyle();
    const { getDataById } = useFireBase();

    const [profile, setProfile] = useState<MProfessionalInfo>();
    /**
     * memoized src for image
     */
    const src = useMemo(() => {
        if (profile && profile.picture) {
            return { uri: profile.picture };
        }
        if (data.profileId) {
            if (data.gender === EGender.male) {
                return getAsset('agencyMPlaceholder');
            } else if (data.gender === EGender.female) {
                return getAsset('agencyFPlaceholder');
            }
        } else {
            if (data.profession === EProfession.doctor) {
                if (data.gender === EGender.male) {
                    return getAsset('placeholderM');
                } else if (data.gender === EGender.female) {
                    return getAsset('placeholderF');
                } else {
                    return getAsset('placeholderD');
                }
            } else if (data.profession === EProfession.nurse) {
                if (data.gender === EGender.male) {
                    return getAsset('placeholderMB');
                } else if (data.gender === EGender.female) {
                    return getAsset('placeholderFB');
                } else {
                    return getAsset('placeholderD');
                }
            } else {
                return getAsset('placeholderD');
            }
        }
        // fallback if nothing is matching
        return getAsset('placeholderD');
    }, [data, profile]);
    /**
     * effect to load related public profile info
     */
    useEffect(() => {
        if (data.profileId) {
            getDataById(ECollections.publicProfProfiles, data.profileId).then(
                (next) => setProfile(new MProfessionalProfile(next)),
            );
        } else if (data.applicantUid) {
            getDataById(ECollections.publicUsers, data.applicantUid).then(
                (next) => setProfile(new MUserData(next)),
            );
        }
    }, [data]);

    return (
        <View nativeID="profile-picture">
            {src ? (
                <Image
                    //@ts-ignore
                    source={src}
                    resizeMethod={'scale'}
                    style={[
                        { width: 70, height: 70, borderRadius: 35 },
                        small && style.listImage,
                        smaller && {
                            width: smallerSize,
                            height: smallerSize,
                            borderRadius: smallerSize / 2,
                        },
                        large && style.bigImage,
                    ]}
                />
            ) : (
                <View
                    style={[
                        { width: 70, height: 70, borderRadius: 35 },
                        small && style.listImage,
                        smaller && {
                            width: smallerSize,
                            height: smallerSize,
                            borderRadius: smallerSize / 2,
                        },
                        large && style.bigImage,
                    ]}
                />
            )}
        </View>
    );
};
