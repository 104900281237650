import { Unsubscribe } from 'firebase/firestore';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { ECollections, EContractStatus, EUserType } from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { useTheme } from '../../../utilities/styles';
import { IMenuButtonBaseProps, MenuButton } from './MenuButton';
import { CText } from '../../elements';
import { makeMenuStyles } from '../menu.style';
import { isSuperUser } from '../../../utilities/auth';
import { IFilter } from '../../../utilities/firebase/store';
import { generalMessages } from '../../../utilities/messages';
/**
 * Self conscious notification menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const NegotiationsMenuButton: FC<IMenuButtonBaseProps> = ({
    closeSidebar,
    detail,
}) => {
    // gloabl
    const { theme } = useTheme();
    const { watchDataIndex, userWorkplaces, getDataIndex, userData } =
        useFireBase();
    // local
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [negotiationCount, setNegotiationCount] = useState(0);
    /**
     * effect to hook into application collection
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((w) => w.documentId),
                    operator: 'in',
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        const queryOptions = {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    operator: 'in' as const,
                    value: [
                        EContractStatus.creating,
                        EContractStatus.review_yee,
                        EContractStatus.review_yer,
                        EContractStatus.accepted,
                    ],
                },
            ],
        };
        // getDataIndex(ECollections.applications, {
        //     ...queryOptions,
        //     getLength: true,
        // }).then((l) => {
        //     setNegotiationCount(l as number);
        // });
        watchDataIndex(ECollections.contracts, queryOptions, (event) => {
            // if (event.type === 'added') {
            //     setNegotiationCount((prev) => prev + 1);
            // }
            getDataIndex(ECollections.contracts, {
                ...queryOptions,
                getLength: true,
            }).then((v) => setNegotiationCount(v as number));
        }).then((us) => setUnsubscribe(() => us));
    }, [userWorkplaces]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={'contract'}
                title={generalMessages.contracts}
                targetLocation={negotiationCount ? 'negotiations' : 'contracts'}
                targetLocations={['negotiations', 'contracts']}
            />
            {!!negotiationCount &&
                (detail ? (
                    <CText
                        {...{ dataSet: { cy: 'negotiations-menu-count' } }}
                        style={{
                            zIndex: 50,
                            position: 'absolute',
                            color: theme.accentColor,
                            borderRadius: 5,
                            height: 10,
                            top: 16,
                            right: 0,
                        }}
                        message={`${negotiationCount}`}
                    />
                ) : (
                    <View
                        {...{ dataSet: { cy: 'new-negotiations-menu-bubble' } }}
                        style={localStyles.notificationBubble}
                    />
                ))}
        </View>
    );
};
