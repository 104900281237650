import React, { FC, useCallback, useContext, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCheckBox } from '../../../../../components';
import { useFormat } from '../../../../../utilities/intl';
import { actionMessages } from '../../../../../utilities/messages';
import { useStyle } from '../../../../../utilities/styles';
import { contractMessages } from '../../../contract.messages';
import { ContractNegotiationContext } from '../ContractNegotiationContext';
/**
 * view applicant information and confirm legitimacy
 * to be viewed and filled out by employer (workplace)
 */
export const ReviewApplicant: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    // context
    const { handleClose } = useContext(ContractNegotiationContext);
    // local state
    const [noRecentEmployment, setRecentEmployment] = useState(false);
    const [noRecentLeasing, setRecentLeasing] = useState(false);
    /**
     * callback to confirm review of applicant
     */
    const confirmApplicant = useCallback(() => {
        // TODO:
    }, [noRecentEmployment, noRecentLeasing]);
    /**
     * render
     */
    return (
        <View style={style.card}>
            <View style={style.verticalPadded}>
                <CCheckBox
                    checked={noRecentEmployment}
                    onCheckedChanged={setRecentEmployment}
                    title={format(contractMessages.confirmNoRecentEmployment)}
                />
            </View>
            <View style={style.verticalPadded}>
                <CCheckBox
                    checked={noRecentLeasing}
                    onCheckedChanged={setRecentLeasing}
                    title={format(
                        contractMessages.confirmNoRecentLeasingByOther,
                    )}
                />
            </View>
            <View style={style.horizontalSpaced}>
                <CButton
                    cy={'cancel'}
                    title={format(actionMessages.cancel)}
                    onPress={handleClose}
                    warning
                />
                <CButton
                    cy={'continue'}
                    title={format(actionMessages.confirm)}
                    onPress={confirmApplicant}
                    disabled={!noRecentEmployment || !noRecentLeasing}
                />
            </View>
        </View>
    );
};
