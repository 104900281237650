import { EApplicationType, EField, EProfession, ERegion } from '../enums';
import { MUserData } from './MUserData';
import { MRates } from './MRates';
import { MWorkplace } from './MWorkplace';
import { MBasicContract } from './MBasicContract';
/**
 * contract for reguar flow. extends basic with values that are relevant for billing / tracking contract details
 */
export class MContract extends MBasicContract {
    public type = EApplicationType.user;

    public canceledBy?: string;
    public cancelReason?: string;
    public cancelByAgencyAfterAgreement?: boolean;

    public applicationId = '';

    public changedBy = '';

    public usualWage = 0;
    public fixedJobWage = false;
    public wage = new MRates();
    public from = 0;
    public to = 0;

    public originalParameters = {
        wage: new MRates(),
        from: 0,
        to: 0,
    };

    public field?: EField;
    public profession?: EProfession;
    public education?: string;

    public employeeId = '';
    public profileId?: string;
    public employee?: MUserData;
    public agencyId = '';

    public employerId = '';
    public signingEmployerId = '';
    public workplaceId = '';
    public workplace?: MWorkplace;

    public employeeSignatureTimestamp?: number;
    public workplaceSignatureTimestamp?: number;

    public jobId = '';

    public region = ERegion.de;

    public activeDates: string[] = [];
    public activeMonths: string[] = [];

    constructor(params: Partial<MContract> = {}) {
        super(params);
        Object.assign(this, params);

        MContract.fixConstructor(this, params);
    }

    static fixConstructor = (that: any, params: Partial<MContract>) => {
        if (params.employee) {
            that.employee = new MUserData(params.employee);
        }

        if (params.workplace) {
            that.workplace = new MWorkplace(params.workplace);
        }

        if (params.originalParameters && params.originalParameters.wage) {
            that.originalParameters.wage = new MRates(
                params.originalParameters.wage,
            );
        }

        if (params.wage) {
            /**
             * * legacy wage fix
             */
            if (typeof params.wage === 'number') {
                const uPs = params as any;

                that.wage = new MRates({
                    wage: uPs.wage,
                    regularWage: uPs.regularWage,
                    standbyWage: uPs.standbyWage,
                    onCallActiveWage: uPs.onCallActiveWage,
                    onCallPassiveWage: uPs.onCallPassiveWage,
                });
            } else {
                /**
                 * wage assign
                 */
                that.wage = new MRates(params.wage);
            }
        }

        if (
            params.from &&
            params.to &&
            (!params.activeDates ||
                !params.activeDates.length ||
                !params.activeMonths ||
                !params.activeMonths.length)
        ) {
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            let pivot = params.from;
            const toDate = new Date(params.to);
            toDate.setDate(toDate.getDate() + 1);
            const end = toDate.getTime();
            while (pivot < end) {
                const curDate = new Date(pivot);
                nextActiveDates.push(curDate.toLocaleDateString('de'));
                const curMonth = curDate.getMonth();
                if (!nextActiveMonths.includes(curMonth)) {
                    nextActiveMonths.push(curMonth);
                }
                curDate.setDate(curDate.getDate() + 1);
                pivot = curDate.getTime();
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;
        }
    };
}
