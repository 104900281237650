import React, { FC } from 'react';
import { View } from 'react-native';
import { CButton, CLabel } from '../../../../components';
import { capitalizeAll, useFormat } from '../../../../utilities/intl';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { actionMessages } from '../../../../utilities/messages';
import { EJobStatus } from '../../../../enums';
import { jobApplicationMessages } from '../../JobApplication.messages';
import { jobMessages } from '../../../Job/Job.messages';
import { useNavigate } from 'react-router-native';
import { JobRowBody } from '../../../Job/List/components/JobRow/components/JobRowBody';
import { JobTitle } from '../../../Job/components/JobTitle';
import { JobRowSubHead } from '../../../Job/List/components/JobRow/components/JobRowSubHead';
import { MJob } from '../../../../models';

export const JobSideCard: FC<{
    job: MJob;
    applicationsLength?: number;
    share?: () => void;
    closeJob?: () => void;
    notInJobApplicationsView?: boolean;
    onClose?: () => void;
}> = ({
    job,
    applicationsLength,
    share,
    notInJobApplicationsView,
    onClose,
    closeJob,
}) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const navigate = useNavigate();

    return (
        <View style={{ flex: 1, maxWidth: 450 }}>
            <View style={[style.card]}>
                <View style={[style.horizontalSplit, style.horizontalWrap]}>
                    <JobTitle job={job} />
                    <View
                        style={[
                            style.horizontal,
                            { justifyContent: 'flex-end', flexGrow: 1 },
                        ]}
                    >
                        {job.status !== EJobStatus.draft ? (
                            !!applicationsLength && (
                                <CLabel
                                    checked
                                    color={theme.accentColor}
                                    title={`${applicationsLength} ${capitalizeAll(
                                        format(
                                            jobApplicationMessages.applicants,
                                        ),
                                    )}`}
                                    small
                                />
                            )
                        ) : (
                            <CLabel
                                checked
                                color={theme.accentColor}
                                title={jobMessages[EJobStatus.draft]}
                                small
                            />
                        )}
                        {onClose && (
                            <CButton
                                transparent
                                icon="close"
                                onPress={onClose}
                                iconColor={theme.textMainColor}
                            />
                        )}
                    </View>
                </View>
                {!notInJobApplicationsView ? (
                    <View style={[style.horizontalWrap, style.verticalPadded]}>
                        <CButton
                            disabled={
                                !!applicationsLength ||
                                [
                                    EJobStatus.filled,
                                    EJobStatus.archived,
                                    EJobStatus.disabled,
                                ].includes(job.status)
                            }
                            icon={'cog'}
                            onPress={() => navigate('/job/' + job.documentId)}
                            title={format(actionMessages.edit)}
                            small
                            style={[
                                style.horizontalPadded,
                                { marginVertical: 10 },
                            ]}
                        />
                        {job.status === EJobStatus.draft && (
                            <CButton
                                icon={'eye'}
                                onPress={() => {
                                    navigate(`/job/publish/${job.documentId}`, {
                                        replace: true,
                                    });
                                }}
                                title={format(jobMessages.publish)}
                                small
                                style={[
                                    style.horizontalPadded,
                                    { marginVertical: 10 },
                                ]}
                            />
                        )}
                        <CButton
                            icon={'plus'}
                            onPress={() => {
                                navigate(`/job/duplicate/${job.documentId}`, {
                                    replace: true,
                                });
                            }}
                            title={format(jobMessages.duplicate)}
                            small
                            style={[
                                style.horizontalPadded,
                                { marginVertical: 10 },
                            ]}
                        />
                        {share && (
                            <CButton
                                icon={'share'}
                                onPress={share}
                                small
                                style={[
                                    style.horizontalPadded,
                                    { marginVertical: 10 },
                                ]}
                            />
                        )}
                        {closeJob && (
                            <CButton
                                title={jobApplicationMessages.closeJob}
                                onPress={closeJob}
                                small
                                danger
                                style={[
                                    style.horizontalPadded,
                                    { marginVertical: 10 },
                                ]}
                            />
                        )}
                    </View>
                ) : (
                    <View style={[style.verticalPadded]}>
                        <CButton
                            onPress={() =>
                                navigate('/japplication/view/' + job.documentId)
                            }
                            small
                            title={jobMessages.viewJob}
                        />
                    </View>
                )}
                <JobRowSubHead job={job} showId />
            </View>
            <View style={style.card}>
                <JobRowBody job={job} />
            </View>
        </View>
    );
};
