import React, { FC, useEffect, useMemo, useState } from 'react';
import { MReview, MUserData } from '../../../models';
import { useStyle } from '../../../utilities/styles';
import { View } from 'react-native';
import { CCard, ProfilePicture, CText, StarDisplay } from '../../../components';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections } from '../../../enums';
import { reviewMessages } from '../review.messages';

export const ReviewRow: FC<{ review: MReview }> = ({ review }) => {
    // global state
    const { getDataById } = useFireBase();
    const style = useStyle();
    // local state
    const [author, setAuthor] = useState<MUserData>();
    /**
     * memoized general rating extracted for top display
     */
    const generalRating = useMemo(
        () => review.ratings.find((r) => r.id === 'general'),
        [review],
    );
    /**
     * effect to load author
     */
    useEffect(() => {
        getDataById(ECollections.publicUsers, review.documentId).then((r) =>
            setAuthor(new MUserData(r)),
        );
    }, [review]);
    /**
     * render
     */
    return (
        <CCard key={review.documentId} style={style.horizontal}>
            {author && <ProfilePicture data={author} />}
            <View style={[style.horizontalPadded]}>
                {generalRating && (
                    <View style={[style.horizontalSplit, style.centeredItems]}>
                        <CText message={{ id: generalRating.labelKey }} />
                        <StarDisplay stars={generalRating.value} />
                    </View>
                )}
                {review.ratings
                    .filter((r) => r.id !== 'general')
                    .map((r) => (
                        <View
                            key={r.id}
                            style={[style.horizontalSplit, style.centeredItems]}
                        >
                            <CText message={{ id: r.labelKey }} />
                            <StarDisplay stars={r.value} />
                        </View>
                    ))}
            </View>
            {!!review.text ? (
                <CText>{review.text}</CText>
            ) : (
                <CText message={reviewMessages.noReviewText} />
            )}
        </CCard>
    );
};
