import { defineMessages } from 'react-intl';

const scope = 'con.job.';
const errScope = scope + 'err.';
const textScope = scope + 'text.';

export const jobMessages = defineMessages({
    title: {
        id: scope + 'title',
        defaultMessage: 'Title',
    },
    id: {
        id: scope + 'id',
        defaultMessage: 'Job ID',
    },
    titlePlaceholder: {
        id: scope + 'titlePlaceholder',
        defaultMessage: 'Job Title. e.g.: anesthetist',
    },
    description: {
        id: scope + 'description',
        defaultMessage: 'Description',
    },
    wageLevel: {
        id: scope + 'wageLevel',
        defaultMessage: 'Wage level compared to similar employees',
    },
    wageLevelPlaceholder: {
        id: scope + 'wageLevelPlaceholder',
        defaultMessage: 'Select a applicable wage level',
    },
    descriptionPlaceholder: {
        id: scope + 'descriptionPlaceholder',
        defaultMessage: 'A short informative description of the job',
    },
    from: {
        id: scope + 'from',
        defaultMessage: 'Start date',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'End date',
    },
    workingTimes: {
        id: scope + 'workingTimes',
        defaultMessage: 'Working Times',
    },
    workingTimesPlaceholder: {
        id: scope + 'workingTimesPlaceholder',
        defaultMessage: 'Short description of working times e.g.: night shift',
    },
    liability: {
        id: scope + 'liability',
        defaultMessage: 'Type of insurance',
    },
    liabilityPlaceholder: {
        id: scope + 'liabilityPlaceholder',
        defaultMessage:
            'Short description of liability e.g.: professional liability',
    },
    location: {
        id: scope + 'location',
        defaultMessage: 'Location',
    },
    accommodation: {
        id: scope + 'accommodation',
        defaultMessage: 'Accommodation information',
    },
    accommodationPlaceholder: {
        id: scope + 'accommodationPlaceholder',
        defaultMessage:
            'Short accommodation information if possible e.g.: apartment, hotel, n.a.',
    },
    specializations: {
        id: scope + 'specializations',
        defaultMessage: 'Specializations',
    },
    specializationsPlaceholder: {
        id: scope + 'specializationsPlaceholder',
        defaultMessage: 'Select specializations',
    },
    educations: {
        id: scope + 'educations',
        defaultMessage: 'Educations',
    },
    yoe: {
        id: scope + 'yoe',
        defaultMessage: 'Years of experience',
    },
    yoePlaceholder: {
        id: scope + 'yoePlaceholder',
        defaultMessage: 'Select years of experience',
    },
    employeeProfession: {
        id: scope + 'employeeProfession',
        defaultMessage: 'Type',
    },
    employeePosition: {
        id: scope + 'employeePosition',
        defaultMessage: 'Position',
    },
    employeePositionPlaceholder: {
        id: scope + 'employeePositionPlaceholder',
        defaultMessage: 'Select position',
    },
    employType: {
        id: scope + 'employType',
        defaultMessage: 'Employment',
    },
    employTypePlaceholder: {
        id: scope + 'employTypePlaceholder',
        defaultMessage: 'Select type',
    },
    createNew: {
        id: scope + 'createNew',
        defaultMessage: 'Create new job',
    },
    providedAccommodation: {
        id: scope + 'providedAccommodation',
        defaultMessage: 'Will be provided',
    },
    selfselfAccommodation: {
        id: scope + 'selfselfAccommodation',
        defaultMessage: 'Must be provided by yourself',
    },
    jobInfos: {
        id: scope + 'jobInfos',
        defaultMessage: 'Information about the Job',
    },
    employerInfos: {
        id: scope + 'employerInfos',
        defaultMessage: 'Information about the employer',
    },
    employeeInfos: {
        id: scope + 'employeeInfos',
        defaultMessage: 'Information about the employee',
    },
    jobTitleMissingError: {
        id: errScope + 'jobTitleMissingError',
        defaultMessage: 'Job title is mandatory',
    },
    educationMissingError: {
        id: errScope + 'educationMissingError',
        defaultMessage: 'You need to select at least 1 education',
    },
    positionMissingError: {
        id: errScope + 'positionMissingError',
        defaultMessage: 'You need to select a position',
    },
    descriptionMissingError: {
        id: errScope + 'descriptionMissingError',
        defaultMessage: 'You need to write a description',
    },
    descriptionToShortError: {
        id: errScope + 'descriptionToShortError',
        defaultMessage:
            'The description is too short. It has to be {desired} characters long. Currently you have {length}',
    },
    fromTo: {
        id: scope + 'fromTo',
        defaultMessage: 'Period',
    },
    onRequest: {
        id: scope + 'onRequest',
        defaultMessage: 'On request',
    },
    unlimited: {
        id: scope + 'unlimited',
        defaultMessage: 'Unlimited',
    },
    selectPredefinedJobTemplate: {
        id: scope + 'selectPredefinedJobTemplate',
        defaultMessage: 'Select a predefined Job Template',
    },
    templates: {
        id: scope + 'templates',
        defaultMessage: 'Predefined Job Templates',
    },
    creating: {
        id: scope + 'creating',
        defaultMessage: 'Creating new Job',
    },
    editing: {
        id: scope + 'editing',
        defaultMessage: 'Editing Job: {job}',
    },
    filterByText: {
        id: scope + 'filterByText',
        defaultMessage: 'Text search',
    },
    enterTextToFilterBy: {
        id: scope + 'enterTextToFilterBy',
        defaultMessage: 'Enter text to search for',
    },
    searchRestrictions: {
        id: scope + 'searchRestrictions',
        defaultMessage: 'Only already loaded entries will be searched',
    },
    currentlyNoJobs: {
        id: scope + 'currentlyNoJobs',
        defaultMessage:
            'Currently, there are no available jobs. Please try again later or change your search parameters.',
    },
    timeFilter: {
        id: scope + 'timeFilter',
        defaultMessage: 'Filter by Time',
    },
    travelPay: {
        id: scope + 'travelPay',
        defaultMessage: 'Travel allowance',
    },
    // jobIntroduction1: {
    //     id: textScope + 'jobIntroduction1',
    //     defaultMessage:
    //         'Für den Zeitraum {from} bis {to} suchen wir eine/einen {employeeProfession} der Fachrichtung {employeeEducation} für die Station/den Bereich: {station}.',
    // },
    // jobIntroduction2: {
    //     id: textScope + 'jobIntroduction2',
    //     defaultMessage: 'Präferierte Spezialisierungen sind: {employeeSpecs}.',
    // },
    // jobIntroduction3: {
    //     id: textScope + 'jobIntroduction3',
    //     defaultMessage:
    //         'Der Einsatz findet in einer Einrichtung im PLZ-Gebiet {location} statt und ist auf Basis der: {jobType}.',
    // },
    jobIntorductionPositivity: {
        id: textScope + 'jobIntorductionPositivity',
        defaultMessage: 'We are looking forward to your application!',
    },
    jobEmployeeEdu: {
        id: textScope + 'jobEmployeeEdu',
        defaultMessage:
            'We are looking for a {employeeProfession} with an education in {employeeEdu} for the station/position {station}',
    },
    jobEmployeeYoe: {
        id: textScope + 'jobEmployeeYoe',
        defaultMessage: 'You should have {yoe} years of experience.',
    },
    jobEmployeeSpecs: {
        id: textScope + 'jobEmployeeSpecs',
        defaultMessage:
            'Possible specializations for the job are: {employeeSpecs}',
    },
    jobLocation: {
        id: textScope + 'jobLocation',
        defaultMessage:
            'The Institution is located in the zip code area: {location}',
    },
    jobBeds: {
        id: textScope + 'jobBeds',
        defaultMessage: 'The institution has {beds} beds',
    },
    aboutJob: {
        id: scope + 'aboutJob',
        defaultMessage: 'About this job',
    },
    aboutEmployee: {
        id: scope + 'aboutEmployee',
        defaultMessage: 'About the employee',
    },
    aboutEmployer: {
        id: scope + 'aboutEmployer',
        defaultMessage: 'About the employer',
    },
    zipCodeArea: {
        id: scope + 'zipCodeArea',
        defaultMessage: 'Zip code area',
    },
    openForHour: {
        id: scope + 'openForHour',
        defaultMessage: 'Open for a hour',
    },
    openForHours: {
        id: scope + 'openForHours',
        defaultMessage: 'Open for {x} hours',
    },
    openforDay: {
        id: scope + 'openForDay',
        defaultMessage: 'Open for a day',
    },
    openForDays: {
        id: scope + 'openForDays',
        defaultMessage: 'Open for {x} days',
    },
    applied: {
        id: scope + 'applied',
        defaultMessage: 'Applied',
    },
    applicationRejected: {
        id: scope + 'applicationRejected',
        defaultMessage: 'Rejected',
    },
    timeFilterPlaceholder: {
        id: scope + 'timeFilterPlaceholder',
        defaultMessage: 'Select a month to filter for',
    },
    publish: {
        id: scope + 'publish',
        defaultMessage: 'Publish job',
    },
    publishHint: {
        id: scope + 'publishHint',
        defaultMessage: 'It will be visible to all users',
    },
    draft: {
        id: scope + 'draft',
        defaultMessage: 'Draft',
    },
    public: {
        id: scope + 'public',
        defaultMessage: 'Public',
    },
    private: {
        id: scope + 'private',
        defaultMessage: 'Private',
    },
    filled: {
        id: scope + 'filled',
        defaultMessage: 'Filled',
    },
    disabled: {
        id: scope + 'disabled',
        defaultMessage: 'Disabled',
    },
    archived: {
        id: scope + 'archived',
        defaultMessage: 'Archived',
    },
    deleted: {
        id: scope + 'deleted',
        defaultMessage: 'Deleted',
    },
    saveAs: {
        id: scope + 'saveAs',
        defaultMessage: 'Save as',
    },
    average: {
        id: scope + 'average',
        defaultMessage: 'Average',
    },
    above_average: {
        id: scope + 'above_average',
        defaultMessage: 'Above average',
    },
    duplicate: {
        id: scope + 'duplicate',
        defaultMessage: 'Duplicate',
    },
    maximumPins: {
        id: scope + 'maximumPins',
        defaultMessage: 'Maximum number of pins.',
    },
    noMoreThan10Pins: {
        id: scope + 'noMoreThan10Pins',
        defaultMessage: 'You can not have more than 10 pins simultaneously.',
    },
    noJobsFound: {
        id: scope + 'noJobsFound',
        defaultMessage: 'Your search request did not yield any results.',
    },
    searchMatchesXofY: {
        id: scope + 'searchMatchesXofY',
        defaultMessage: 'Your current search matches {x} of {y} jobs',
    },
    pin: {
        id: scope + 'pin',
        defaultMessage: 'Pin',
    },
    unpin: {
        id: scope + 'unpin',
        defaultMessage: 'Unpin',
    },
    deleteConfirmTitle: {
        id: scope + 'deleteConfirmTitle',
        defaultMessage: 'Do you want to delete this job?',
    },
    deleteConfirmText: {
        id: scope + 'deleteConfirmText',
        defaultMessage: 'This action will be irreversible',
    },
    fromNow: {
        id: scope + 'fromNow',
        defaultMessage: 'From now on',
    },
    workplaceZipCode: {
        id: scope + 'workplaceZipCode',
        defaultMessage: 'Zip code',
    },
    pleaseFilterTheHospitalsFirst: {
        id: scope + 'pleaseFilterTheHospitalsFirst',
        defaultMessage: 'Your current search does not match any workplaces.',
    },
    jobDistanceLessThan50km: {
        id: scope + 'jobDistanceLessThan50km',
        defaultMessage: 'Nearby',
    },
    jobDistanceLessThan100km: {
        id: scope + 'jobDistanceLessThan100km',
        defaultMessage: 'Less than 100 km away',
    },
    jobDistanceLessThan200km: {
        id: scope + 'jobDistanceLessThan200km',
        defaultMessage: 'Less than 200 km away',
    },
    jobDistanceLessThan500km: {
        id: scope + 'jobDistanceLessThan500km',
        defaultMessage: 'Less than 500 km away',
    },
    jobDistanceGreaterThan500km: {
        id: scope + 'jobDistanceGreaterThan500km',
        defaultMessage: 'More than 500 km away',
    },
    unverified: {
        id: scope + 'unverified',
        defaultMessage: 'Not associated with a verified institution',
    },
    employeeAcademicTitle: {
        id: scope + 'employeeAcademicTitle',
        defaultMessage: 'Academic title required',
    },
    noWorkplaceSelected: {
        id: scope + 'noWorkplaceSelected',
        defaultMessage: 'No workplace selected',
    },
    personalizedJobTitle: {
        id: scope + 'personalizedJobTitle',
        defaultMessage: 'Personalized job title',
    },
    personalizedJobTitlePlaceholder: {
        id: scope + 'personalizedJobTitlePlaceholder',
        defaultMessage: 'Personalized job title (e.g. Representative service)',
    },
    createDraft: {
        id: scope + 'createDraft',
        defaultMessage: 'Created draft',
    },
    freeText: {
        id: scope + 'freeText',
        defaultMessage: 'Additional Information over this job listing',
    },
    freeTextDisplay: {
        id: scope + 'freeTextDisplay',
        defaultMessage:
            'Additional Information over this job listing from the employer:',
    },
    shareJob: {
        id: scope + 'shareJob',
        defaultMessage: 'Share job via e-mail?',
    },
    shareJobText: {
        id: scope + 'shareJobText',
        defaultMessage:
            'You can put into the following textbox any number of semicolon (;) separated mail addresses. All of them will receive a e-mail regarding this job listing.',
    },
    sharedJob: {
        id: scope + 'sharedJob',
        defaultMessage: 'You have shared the job',
    },
    sharedJobText: {
        id: scope + 'sharedJobText',
        defaultMessage: 'You have send out mails to {x} mail addresses.',
    },
    recipientPlaceholder: {
        id: scope + 'recipientPlaceholder',
        defaultMessage: 'e.g john@doe.com; jane@doe.com',
    },
    privateJobs: {
        id: scope + 'privateJobs',
        defaultMessage: 'Private job listings',
    },
    firstJobCreated: {
        id: scope + 'firstJobCreated',
        defaultMessage: 'Congratulations on creating your first job listing',
    },
    firstJobCreatedText: {
        id: scope + 'firstJobCreatedText',
        defaultMessage:
            'You can invite your known agencies to join {platform} and have access to your listing.',
    },
    inviteNow: {
        id: scope + 'inviteNow',
        defaultMessage: 'Invite now',
    },
    inviteLater: {
        id: scope + 'inviteLater',
        defaultMessage: 'Invite later',
    },
    viewJob: {
        id: scope + 'viewJob',
        defaultMessage: 'View job details',
    },
    privateJobInfo: {
        id: scope + 'privateJobInfo',
        defaultMessage:
            'Private job listings will only be visible to the selected workplaces ({workplace}) preferred agencies ({x})',
    },
    showFullAddress: {
        id: scope + 'showFullAddress',
        defaultMessage: 'Show full address on job listing',
    },
    rate: {
        id: scope + 'rate',
        defaultMessage: 'Rate',
    },
    rateHint: {
        id: scope + 'rateHint',
        defaultMessage:
            'Specify the rate you pay for this Job listing. If you supply a rate there will be no negotiation with employees.',
    },
    noJobRate: {
        id: scope + 'noJobRate',
        defaultMessage: 'Negotiable rate',
    },
    employerRating: {
        id: scope + 'employerRating',
        defaultMessage: 'Average rating of the employer:',
    },
});
