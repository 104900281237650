import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CFieldStringInput, CText } from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { userMessages } from '../../../User/user.messages';
import {
    EducationPicker,
    FieldPicker,
    PositionPicker,
    ProfessionPicker,
} from '../../../../components/Pickers';
import { EField, EProfession } from '../../../../enums';
import { profProfileMessages } from '../../ProfProfile.messages';

export interface IProfessionalUsersFilter {
    field?: EField;
    profession?: EProfession;
    education?: string;
    customStringFilter?: { field: string; value: string };
}

interface IProfessionalUsersFilterBarProps {
    onChange: (filter: IProfessionalUsersFilter) => void;
}

export const ProfessionalUsersFilterBar: FC<
    IProfessionalUsersFilterBarProps
> = ({ onChange }) => {
    const format = useFormat();
    const [field, setField] = useState<EField>();
    const [profession, setProfession] = useState<EProfession>();
    const [education, setEducation] = useState<string>();
    // const [position, setPosition] = useState<string>();
    const [customStringFilter, setCustomStringFilter] =
        useState<{ field: string; value: string }>();
    /**
     * set custom string filter after debounce
     */
    const handleNextCustomStringFilter = useCallback(
        (next: { value: string; field: string }) =>
            setCustomStringFilter((prev) => {
                if (next.value) {
                    // setMode([]);
                    // clearFilters();
                    return next;
                } else if (prev?.value) {
                    return { field: next.field, value: '' };
                } else {
                    return prev;
                }
            }),
        [],
    );
    /**
     * effect to write back state
     */
    useEffect(() => {
        onChange({
            education,
            customStringFilter,
            // position,
            field,
            profession,
        });
    }, [education, customStringFilter, field, profession]);
    /**
     * render
     */
    return (
        <View>
            <CText secondaryHeadline message={profProfileMessages.filterBy} />
            <FieldPicker
                onChange={(next) => {
                    setProfession(undefined);
                    setEducation(undefined);
                    // setPosition(undefined);
                    setField(next);
                }}
                value={field}
                allowEmpty
            />
            {field && (
                <ProfessionPicker
                    field={field}
                    onChange={(next) => {
                        setEducation(undefined);
                        // setPosition(undefined);
                        setProfession(next);
                    }}
                    value={profession}
                />
            )}
            {/* {profession && (
                <PositionPicker
                    profession={profession}
                    value={position || ''}
                    onChange={(v) => {
                        setPosition(v);
                    }}
                />
            )} */}
            {profession && (
                <EducationPicker
                    field={field}
                    profession={profession}
                    value={education}
                    onChange={(v) => {
                        setEducation(v as string);
                    }}
                />
            )}
            <CFieldStringInput
                value={customStringFilter}
                fields={['lastName', 'firstName'].map((f) => {
                    return {
                        label: format(
                            userMessages[f as keyof typeof userMessages],
                        ),
                        field: f,
                    };
                })}
                onChange={handleNextCustomStringFilter}
                cy={'prof-user-text-filter'}
            />
        </View>
    );
};
