import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { CButton, CText } from '../../../components';
import { MAvailability } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { useLocation, useSecureNavigate } from '../../../utilities/routing';
import {
    convertWeekAndYearToDate,
    day,
    week,
} from '../../../utilities/functions';

export const AvailabilityRow: FC<{ availability: MAvailability }> = ({
    availability,
}) => {
    const style = useStyle();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const location = useLocation();
    /**
     * days translated
     */
    const days = useMemo(() => {
        return [
            format(generalMessages.monday),
            format(generalMessages.tuesday),
            format(generalMessages.wednesday),
            format(generalMessages.thursday),
            format(generalMessages.friday),
            format(generalMessages.saturday),
            format(generalMessages.sunday),
        ];
    }, []);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from = convertWeekAndYearToDate(
            availability.year,
            availability.start,
        );
        const to = new Date(
            from.getTime() + (availability.repeatCount + 1) * week - day,
        );

        return { from, to };
    }, [availability]);
    /**
     * style for views
     */
    const viewStyle = useMemo(() => {
        return { flex: 1, justifyContent: 'center' as const };
    }, []);
    /**
     * render
     */
    return (
        <View style={[style.horizontalSplit]}>
            <View style={viewStyle}>
                <CText>{from.toLocaleDateString('de')}</CText>
            </View>
            <View style={viewStyle}>
                <CText>{to.toLocaleDateString('de')} </CText>
            </View>
            <View style={viewStyle}>
                <CText>{availability.note}</CText>
            </View>
            <View style={viewStyle}>
                {availability.days.map((d, i) => (
                    <View key={i} style={style.horizontal}>
                        <CText style={style.flex1}>{`${
                            days[d.day - 1]
                        }`}</CText>
                        <CText style={style.flex1}>{`${
                            d.inheriting
                                ? `${availability.from} - ${availability.to}`
                                : `${d.from} - ${d.to}`
                        }`}</CText>
                    </View>
                ))}
            </View>
            <CButton
                transparent
                small
                icon="cog"
                onPress={() => {
                    const ps = location.pathname.split('/');
                    const params = ['aId=' + availability.documentId];
                    if (ps.includes('profUser')) {
                        params.push('profileId=' + ps.pop());
                    }
                    secureNavigate('/calendar?' + params.join('&'));
                }}
            />
        </View>
    );
};
