import { defineMessages } from 'react-intl';

const scope = 'notification.';
const mailTemplateScope = 'mail.template.';

export const notificationMessages = defineMessages({
    newJobsForYou: {
        id: scope + 'newJobsForYou',
        defaultMessage:
            'The {platform} App has {x} new jobs from last week for you.',
    },
    newJobsForYouBody: {
        id: scope + 'newJobsForYouBody',
        defaultMessage:
            'There are {x} new jobs matching your education as {education}. View the jobs in the App to see more details!',
    },
    newJobsForYouButton: {
        id: scope + 'newJobsForYouButton',
        defaultMessage: 'Click here to view the jobs',
    },
    newJobsForYouAgency: {
        id: scope + 'newJobsForYouAgency',
        defaultMessage:
            'The {platform} App has {x} new jobs from last week for you.',
    },
    newJobsForYouAgencyBody: {
        id: scope + 'newJobsForYouAgencyBody',
        defaultMessage:
            'There are {x} new jobs in your region matching your selected fields. View the jobs in the App to see more details!',
    },
    newJobsForYouAgencyButton: {
        id: scope + 'newJobsForYouAgencyButton',
        defaultMessage: 'Click here to view the jobs',
    },
    bookOnboarding: {
        id: scope + 'bookOnboarding',
        defaultMessage: 'You are not verified with {platform}.',
    },
    bookOnboardingBody: {
        id: scope + 'bookOnboardingBody',
        defaultMessage:
            'To get access to all {platform} features you have to get verified by one of our agents. For this purpose you can book a appointment in the {platform} App.',
    },
    bookOnboardingButton: {
        id: scope + 'bookOnboardingButton',
        defaultMessage: 'Click here to book a appointment.',
    },
    completeAccount: {
        id: scope + 'completeAccount',
        defaultMessage: 'Your account is incomplete.',
    },
    completeAccountBody: {
        id: scope + 'completeAccountBody',
        defaultMessage:
            'Your account on the {platform} platform is incomplete. Visit your profile to fill in missing information. Providing more informations increase the perception of your resume and enhances the {platform} experience.',
    },
    completeAccountButton: {
        id: scope + 'completeAccountButton',
        defaultMessage: 'Visit your Profile.',
    },
    addAddressInformation: {
        id: scope + 'addAddressInformation',
        defaultMessage: 'Your address information is incomplete.',
    },
    addAddressInformationBody: {
        id: scope + 'addAddressInformationBody',
        defaultMessage:
            'Your account on the {platform} platform is not asociated with a valid address. Visit your profile to fill in the missing information. Providing a address will allow us to match you with Jobs within your reach.',
    },
    addAddressInformationButton: {
        id: scope + 'addAddressInformationButton',
        defaultMessage: 'Visit your Profile.',
    },
    unreadMessages: {
        id: scope + 'unreadMessages',
        defaultMessage: 'You have {x} new unread messages',
    },
    unreadMessagesBody: {
        id: scope + 'unreadMessagesBody',
        defaultMessage:
            "You have {x} new unread messages in in the {platform} App. Visit the App to see what's up. \n \n Click here to open the chat:",
    },
    unreadMessagesButton: {
        id: scope + 'unreadMessagesButton',
        defaultMessage: 'Open chat.',
    },
    newApplicationAdmin: {
        id: scope + 'newApplicationAdmin',
        defaultMessage: 'New application from {user}',
    },
    newApplicationAdminBody: {
        id: scope + 'newApplicationAdminBody',
        defaultMessage:
            'New application from {user}, for job {job} at workplace {workplace}. Visit the {platform} App to view more information. \n \n Click here to open the App:',
    },
    newApplicationAdminButton: {
        id: scope + 'newApplicationAdminButton',
        defaultMessage: 'Open {platform} App',
    },
    newApplication: {
        id: scope + 'newApplication',
        defaultMessage: 'There is a new application for one of your jobs!',
    },
    newApplicationBody: {
        id: scope + 'newApplicationBody',
        defaultMessage:
            'There is a new application for a job at workplace {workplace}. Visit the {platform} App to view more information. \n \n Click here to open the App:',
    },
    newApplicationButton: {
        id: scope + 'newApplicationButton',
        defaultMessage: 'Open {platform} App',
    },
    newUser: {
        id: scope + 'newUser',
        defaultMessage: 'New user {user}',
    },
    newUserBody: {
        id: scope + 'newUserBody',
        defaultMessage: 'Click here to open the user view:',
    },
    newUserButton: {
        id: scope + 'newUserButton',
        defaultMessage: 'Open App',
    },
    completeProfileProfession: {
        id: scope + 'completeProfileProfession',
        defaultMessage: 'Complete your professional informations',
    },
    completeProfileProfessionBody: {
        id: scope + 'completeProfileProfessionBody',
        defaultMessage:
            'Please confirm that your professional informations are complete and up to date. \n \n Click here to open your profile:',
    },
    completeProfileProfessionButton: {
        id: scope + 'completeProfileProfessionButton',
        defaultMessage: 'Open Profile',
    },
    completeProfileAddress: {
        id: scope + 'completeProfileAddress',
        defaultMessage: 'Complete your Addressinformation',
    },
    completeProfileAddressBody: {
        id: scope + 'completeProfileAddressBody',
        defaultMessage:
            'Please confirm that your addressinformation is complete and up to date. \n \n Click here to open your profile:',
    },
    completeProfileAddressButton: {
        id: scope + 'completeProfileAddressButton',
        defaultMessage: 'Open Profile',
    },
    completeProfileBanking: {
        id: scope + 'completeProfileBanking',
        defaultMessage: 'Complete your bankinginformations',
    },
    completeProfileBankingBody: {
        id: scope + 'completeProfileBankingBody',
        defaultMessage:
            'Please confirm that your bankinginformations are complete and up to date. \n \n Click here to open your profile:',
    },
    completeProfileBankingButton: {
        id: scope + 'completeProfileBankingButton',
        defaultMessage: 'Open Profile',
    },
    completeProfileDocuments: {
        id: scope + 'completeProfileDocuments',
        defaultMessage: 'Upload personal documents to verify your informations',
    },
    completeProfileDocumentsBody: {
        id: scope + 'completeProfileDocumentsBody',
        defaultMessage:
            'Please upload your certificates to {platform}. We will be using them to validate your data.',
    },
    completeProfileDocumentsButton: {
        id: scope + 'completeProfileDocumentsButton',
        defaultMessage: 'Open Profile',
    },
    toApp: {
        id: scope + 'toApp',
        defaultMessage: 'Open app',
    },
    emailVerify: {
        id: scope + 'emailVerify',
        defaultMessage: 'Please verify your e-mail address',
    },
    emailVerifyBody: {
        id: scope + 'emailVerifyBody',
        defaultMessage:
            'Please verify your e-mail address. To do that click on the button below. \n [button_location] \nIf the button does not work open this url in your browser: \n\n{url}',
    },
    emailVerifyButton: {
        id: scope + 'emailVerifyButton',
        defaultMessage: 'Verify your e-mail',
    },
    passwordReset: {
        id: scope + 'passwordReset',
        defaultMessage: 'Password reset issued',
    },
    passwordResetBody: {
        id: scope + 'passwordResetBody',
        defaultMessage:
            'A password reset was issued. If you did not issue this action you can ignore this e-mail. If you issued this action you can proceed by clicking on the button below or open this url in your browser: {url}',
    },
    passwordResetButton: {
        id: scope + 'passwordResetButton',
        defaultMessage: 'Reset password',
    },
    missedOnboarding: {
        id: scope + 'missedOnboarding',
        defaultMessage: 'You missed your onboarding',
    },
    missedOnboardingBody: {
        id: scope + 'missedOnboardingBody',
        defaultMessage:
            'Dear {title} {lastName}, unfortunately you were not able to attend the onboarding appointment, which is why I would like to arrange a new appointment with you. To do so, please go to the following LINK again and select a suitable time slot for onboarding. If you have any unanswered questions, I will be happy to answer them in advance via e-mail, or you can ask me in person during our short onboarding call. I look forward to getting to know you soon!',
    },
    missedOnboardingButton: {
        id: scope + 'missedOnboardingButton',
        defaultMessage: 'Book another onboarding',
    },
    onboardingReminder: {
        id: scope + 'onboardingReminder',
        defaultMessage: 'Your onboarding is about to start',
    },
    onboardingReminderBody: {
        id: scope + 'onboardingReminderBody',
        defaultMessage:
            'Dear {title} {lastName}, your onboarding is about to begin. Your Zoom url: \n \n {zoomLink}',
    },
    onboardingReminderButton: {
        id: scope + 'onboardingReminderButton',
        defaultMessage: 'Open zoom',
    },
    jobDraftCreated: {
        id: scope + 'jobDraftCreated',
        defaultMessage: 'Job draft created',
    },
    jobDraftCreatedBody: {
        id: scope + 'jobDraftCreatedBody',
        defaultMessage:
            'The job draft was successfully created. To publish it verify your E-Mail address by clicking on this link:',
    },
    jobDraftCreatedButton: {
        id: scope + 'jobDraftCreatedButton',
        defaultMessage: 'Publish Job',
    },
    jobDraftPublished: {
        id: scope + 'jobDraftPublished',
        defaultMessage: 'Job published',
    },
    jobDraftPublishedBody: {
        id: scope + 'jobDraftPublishedBody',
        defaultMessage:
            'The job draft was successfully published. You will receive updates as soon as someone applied for it. In the meantime you can setup your account on the WebApp.',
    },
    jobDraftPublishedButton: {
        id: scope + 'jobDraftPublishedButton',
        defaultMessage: 'Open Webapp',
    },
    agencyLicenseExpiring: {
        id: scope + 'agencyLicenseExpiring',
        defaultMessage: 'Your agency license will expire on {date}',
    },
    agencyLicenseExpiringBody: {
        id: scope + 'agencyLicenseExpiringBody',
        defaultMessage:
            'Your agency license was marked to expire soon. Please provide a new license or contact our support to prevent expiration and account suspension. All user connected to the agency will be provisionally deactivated in case the expiration date is reached.',
    },
    agencyLicenseExpiringButton: {
        id: scope + 'agencyLicenseExpiringButton',
        defaultMessage: 'Open agency',
    },
    newSupportRequest: {
        id: scope + 'newSupportRequest',
        defaultMessage: 'New support request from {user}',
    },
    newSupportRequestBody: {
        id: scope + 'newSupportRequestBody',
        defaultMessage: 'There is a new support request about: {reason}',
    },
    newSupportRequestButton: {
        id: scope + 'newSupportRequestButton',
        defaultMessage: 'Open support requests',
    },
    privacyStatement: {
        id: mailTemplateScope + 'privacyStatement',
        defaultMessage: 'Privacy statement',
    },
    receiveNoMoreMails: {
        id: mailTemplateScope + 'receiveNoMoreMails',
        defaultMessage: 'You want to receive no more e-mails from us?',
    },
    unsubscribe: {
        id: mailTemplateScope + 'unsubscribe',
        defaultMessage: 'Unsubscribe',
    },
    newProfProfileFromForm: {
        id: scope + 'newProfProfileFromForm',
        defaultMessage: 'One of your profile invite forms got filled in.',
    },
    newProfProfileFromFormBody: {
        id: scope + 'newProfProfileFromFormBody',
        defaultMessage: 'Check your profiles for a new entry.',
    },
    newProfProfileFromFormButton: {
        id: scope + 'newProfProfileFromFormButton',
        defaultMessage: 'Open profiles',
    },
    newUserInWorkplace: {
        id: scope + 'newUserInWorkplace',
        defaultMessage:
            'A new user has claimed an invite to your Workplace {workplace}',
    },
    newUserInWorkplaceBody: {
        id: scope + 'newUserInWorkplaceBody',
        defaultMessage:
            'Check in on your workplace to ensure everything is in order.',
    },
    newUserInWorkplaceButton: {
        id: scope + 'newUserInWorkplaceButton',
        defaultMessage: 'Open workplace',
    },
    newUserInAgency: {
        id: scope + 'newUserInAgency',
        defaultMessage:
            'A new user has claimed an invite to your Agency {agency}',
    },
    newUserInAgencyBody: {
        id: scope + 'newUserInAgencyBody',
        defaultMessage:
            'Check in on your agency to ensure everything is in order.',
    },
    newUserInAgencyButton: {
        id: scope + 'newUserInAgencyButton',
        defaultMessage: 'Open agency',
    },
    aAgencyUploadedANewLicenseFile: {
        id: scope + 'aAgencyUploadedANewLicenseFile',
        defaultMessage: 'Agency {agency} uploaded a new license file',
    },
    aAgencyUploadedANewLicenseFileBody: {
        id: scope + 'aAgencyUploadedANewLicenseFileBody',
        defaultMessage:
            'Please check it for validity and adjust expiration acordingly.',
    },
    aAgencyUploadedANewLicenseFileButton: {
        id: scope + 'aAgencyUploadedANewLicenseFileButton',
        defaultMessage: 'Open agency',
    },
    yourAgenciesLicenseStatusGotChanged: {
        id: scope + 'yourAgenciesLicenseStatusGotChanged',
        defaultMessage: 'Your License for agency {agency} got adjusted.',
    },
    yourAgenciesLicenseStatusGotChangedBody: {
        id: scope + 'yourAgenciesLicenseStatusGotChangedBody',
        defaultMessage: 'You license will expire {licenseUntil}.',
    },
    yourAgenciesLicenseStatusGotChangedButton: {
        id: scope + 'yourAgenciesLicenseStatusGotChangedButton',
        defaultMessage: 'Open agency',
    },
    negotiationInitiated: {
        id: scope + 'negotiationInitiated',
        defaultMessage:
            'The negotiation for {jobTitle} got started. Please review the set contract parameters.',
    },
    negotiationInitiatedBody: {
        id: scope + 'negotiationInitiatedBody',
        defaultMessage:
            'You have to confirm that the current contract parameters are acceptable or offer changes before you can proceed.',
    },
    negotiationInitiatedButton: {
        id: scope + 'negotiationInitiatedButton',
        defaultMessage: 'Open negotiation',
    },
    newNegotiationState: {
        id: scope + 'newNegotiationState',
        defaultMessage:
            'The negotiation for {jobTitle} got updated parameters. Please review the changes.',
    },
    newNegotiationStateBody: {
        id: scope + 'newNegotiationStateBody',
        defaultMessage:
            'Your contract partner did modify the parameters during negotiation. You have to confirm that these changes are acceptable before you can proceed',
    },
    newNegotiationStateButton: {
        id: scope + 'newNegotiationStateButton',
        defaultMessage: 'Open negotiation',
    },
    counterofferAccepted: {
        id: scope + 'counterofferAccepted',
        defaultMessage:
            'Your new negotiation parameters for {jobTitle} got accepted.',
    },
    counterofferAcceptedBody: {
        id: scope + 'counterofferAcceptedBody',
        defaultMessage: 'Please confirm that everything is in order.',
    },
    counterofferAcceptedButton: {
        id: scope + 'counterofferAcceptedButton',
        defaultMessage: 'Open negotiation',
    },
    negotiationAccepted: {
        id: scope + 'negotiationAccepted',
        defaultMessage:
            'The negotiation parameters for {jobTitle} got accepted.',
    },
    negotiationAcceptedBody: {
        id: scope + 'negotiationAcceptedBody',
        defaultMessage:
            'You are now connected to your contract partner. We have opened a chat for you to communicate in. Please make sure the paperwork is in order before the contract period starts.',
    },
    negotiationAcceptedButton: {
        id: scope + 'negotiationAcceptedButton',
        defaultMessage: 'Open contract',
    },
    negotiationRequiresSignature: {
        id: scope + 'negotiationRequiresSignature',
        defaultMessage: 'Contract document for {jobTitle} requires signature',
    },
    negotiationRequiresSignatureBody: {
        id: scope + 'negotiationRequiresSignatureBody',
        defaultMessage:
            'The negotiation parameters for {jobTitle} got accepted. A contract document got created. Please review it and apply your signature.',
    },
    negotiationRequiresSignatureButton: {
        id: scope + 'negotiationRequiresSignatureButton',
        defaultMessage: 'Open negotiation',
    },
    contractFinished: {
        id: scope + 'contractFinished',
        defaultMessage: 'The contract for {jobTitle} concluded.',
    },
    contractFinishedBody: {
        id: scope + 'contractFinishedBody',
        defaultMessage:
            'Please transmit a time sheet where you documented the working hours of your employee.',
    },
    contractFinishedButton: {
        id: scope + 'contractFinishedButton',
        defaultMessage: 'Open contract',
    },
    contractArchived: {
        id: scope + 'contractArchived',
        defaultMessage:
            'The chat for your contract for {jobTitle} got archived.',
    },
    contractArchivedBody: {
        id: scope + 'contractArchivedBody',
        defaultMessage:
            'If there are still details you need to discuss with your contract partner you can request a new chat to be opened for this contract.',
    },
    contractArchivedButton: {
        id: scope + 'contractArchivedButton',
        defaultMessage: 'Open webapp',
    },
    createFirstJob: {
        id: scope + 'createFirstJob',
        defaultMessage:
            'Reminder: Our Talents are waiting for you! Create your first Job listing on {platform}!',
    },
    createFirstJobBody: {
        id: scope + 'createFirstJobBody',
        defaultMessage:
            'Dear Mr/Mrs {lastName}\nWe would like to remind you, that your account got verified and you are now able to create job listings.\n Steaps to create a Job listing\n1. Login to the {platform} webapp.\n2. Click on "CREATE NEW JOB"\n3. Fill in the required information, and more.\nPlease ensure that all relevant details are correctly supplied, to have the best selection of Candidates for your requirements.\nIf you require assistance during this process please contact us under info@{domain}.\nWe are here to ensure you have a smooth recruitment process.\nThank you for your cooperation.\nBest regards\nThe {platform} team',
    },
    createFirstJobButton: {
        id: scope + 'createFirstJobButton',
        defaultMessage: 'Create a new job',
    },
    signatureForFileRequested: {
        id: scope + 'signatureForFileRequested',
        defaultMessage: 'New signature request',
    },
    signatureForFileRequestedBody: {
        id: scope + 'signatureForFileRequestedBody',
        defaultMessage:
            'In the contract for {jobTitle} your partner requested a courier signature for one or multiple files.',
    },
    signatureForFileRequestedButton: {
        id: scope + 'signatureForFileRequestedButton',
        defaultMessage: 'Open Contract',
    },
    namirialSignatureForFileRequested: {
        id: scope + 'namirialSignatureForFileRequested',
        defaultMessage: 'New signature request',
    },
    namirialSignatureForFileRequestedBody: {
        id: scope + 'namirialSignatureForFileRequestedBody',
        defaultMessage:
            'In the contract for {jobTitle} your partner requested a electronic signature for one or multiple files.',
    },
    namirialSignatureForFileRequestedButton: {
        id: scope + 'namirialSignatureForFileRequestedButton',
        defaultMessage: 'Open Contract',
    },
    agencyInviteFromWorkplace: {
        id: scope + 'agencyInviteFromWorkplace',
        defaultMessage:
            'You have been invited to join {platform} by {workplace}',
    },
    agencyInviteFromWorkplaceBody: {
        id: scope + 'agencyInviteFromWorkplaceBody',
        defaultMessage:
            '{user} of {workplace} has requested that you join {platform} to interact with their job listings. {platform} is a platform for employment leasing.',
    },
    agencyInviteFromWorkplaceButton: {
        id: scope + 'agencyInviteFromWorkplaceButton',
        defaultMessage: 'Sign up now',
    },
    shareJob: {
        id: scope + 'shareJob',
        defaultMessage: '{workplace} listed a job for {jobTitle}',
    },
    shareJobBody: {
        id: scope + 'shareJobBody',
        defaultMessage:
            "On the {platform} platform there is a new job listing from {workplace} for {jobTitle}. The job is available from {from} to {to}. Find out more by viewing the job. \n[button_location]\nIf the button above doesn't work you can follow this link instead: \n\n{url}\n\nWe are looking forward to seeing you on the {platform} platform.",
    },
    shareJobButton: {
        id: scope + 'shareJobButton',
        defaultMessage: 'View the job',
    },
    newPrivateJobForYou: {
        id: scope + 'newPrivateJobForYou',
        defaultMessage: '{workplace} offered you a job for {jobTitle}',
    },
    newPrivateJobForYouBody: {
        id: scope + 'newPrivateJobForYouBody',
        defaultMessage:
            'On the {platform} platform there is a new exclusive job listing for {jobTitle} at {workplace}. The job is available from {from} to {to}. Find out more by viewing the job using this button: \n[button_location]\nOr following this link: \n\n{url}',
    },
    newPrivateJobForYouButton: {
        id: scope + 'newPrivateJobForYouButton',
        defaultMessage: 'View the job',
    },
    profProfileInvite: {
        id: scope + 'profProfileInvite',
        defaultMessage: '{agency} wants you to fill in your information',
    },
    profProfileInviteBody: {
        id: scope + 'profProfileInviteBody',
        defaultMessage:
            '{agency} requested that you fill in your information on the {platfrom}. If you fill in your information properly your profile will have a greater changse to receive desireable job offers. Click on this button to fill in your information: \n[button_location]\nOr following this link: \n\n{url}',
    },
    profProfileInviteButton: {
        id: scope + 'profProfileInviteButton',
        defaultMessage: 'Open formular',
    },
    contractInvite: {
        id: scope + 'contractInvite',
        defaultMessage: '{user} of {resource} has created a contract',
    },
    contractInviteBody: {
        id: scope + 'contractInviteBody',
        defaultMessage:
            '{user} of {resource} created a contract on {platform}. If you do not have an account you have to create one to be able to interact with it. You can resolve the invite with your existing account or create a new one with this button: \n[button_location]\nOr by following this link: \n\n{url}',
    },
    contractInviteButton: {
        id: scope + 'contractInviteButton',
        defaultMessage: 'Open {platform}',
    },
});
