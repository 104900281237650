import React, { FC, useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { CButton, CCard, CIcon, CImage, CText } from '../../../components';
import { dashboardMessages } from '../Dashboard.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { contractMessages } from '../../Contract/contract.messages';
import { useFormat } from '../../../utilities/intl';
import { EUserType, ECollections, EContractStatus } from '../../../enums';
import { MContract } from '../../../models';
import { isSuperUser } from '../../../utilities/auth';
import { IFilter } from '../../../utilities/firebase/store';
import { useFireBase } from '../../../utilities/firebase';
import { ContractTitle } from '../../Contract/components/ContractTitle';
import { useNavigate } from '../../../utilities/routing';
import { day } from '../../../utilities/functions';

enum DCCMode {
    active = 'active',
    completed = 'completed',
    upcoming = 'upcoming',
    upcomingSoon = 'upcomingSoon',
    all = 'all',
}

export const DashboardComponentContracts: FC = () => {
    const { userData, userWorkplaces, getDataIndex } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { theme } = useTheme();
    // local state
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [mode, setMode] = useState<DCCMode>(DCCMode.upcomingSoon);
    /**
     * memoized contract for active mode
     */
    const activeContracts = useMemo(
        () =>
            contracts.filter((c) => c.from <= Date.now() && c.to > Date.now()),
        [contracts],
    );
    /**
     * memoized contract for completed mode
     */
    const completedContracts = useMemo(
        () => contracts.filter((c) => c.to < Date.now()),
        [contracts],
    );
    /**
     * memoized contract for upcoming mode
     */
    const upcomingContracts = useMemo(
        () => contracts.filter((c) => c.from >= Date.now() + day * 7),
        [contracts],
    );
    /**
     * memoized contract for upcoming mode
     */
    const soonUpcomingContracts = useMemo(
        () =>
            contracts.filter(
                (c) => c.from > Date.now() && c.from < Date.now() + day * 7,
            ),
        [contracts],
    );
    /**
     * memoized contracts filtered for current mode
     */
    const filteredContracts = useMemo(() => {
        switch (mode) {
            case DCCMode.active:
                return activeContracts;
            case DCCMode.completed:
                return completedContracts;
            case DCCMode.upcoming:
                return upcomingContracts;
            case DCCMode.upcomingSoon:
                return soonUpcomingContracts;
            default:
                return contracts;
        }
    }, [
        activeContracts,
        completedContracts,
        upcomingContracts,
        contracts,
        mode,
    ]);
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((w) => w.documentId),
                    operator: 'in',
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        getDataIndex(ECollections.contracts, {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    operator: 'in',
                    value: [EContractStatus.signed],
                },
            ],
        }).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setContracts(nextContracts);
        });
    }, [userWorkplaces, userData]);

    return (
        <View style={{ paddingBottom: 10 }}>
            <CCard>
                <CText message={dashboardMessages.yourContracts} headline />
                <View
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        style.horizontalWrap,
                    ]}
                >
                    {/* <CButton
                        title={
                            format(generalMessages.all) +
                            (contracts.length ? ` (${contracts.length})` : '')
                        }
                        onPress={() => {
                            setMode(DCCMode.all);
                        }}
                        small
                        minor={mode !== DCCMode.all}
                        style={style.smallMargin}
                    /> */}
                    <CButton
                        title={`${format(
                            userData.type === EUserType.agency
                                ? contractMessages.deployed
                                : contractMessages.active,
                        )} (${activeContracts.length})`}
                        onPress={() => {
                            setMode(DCCMode.active);
                        }}
                        small
                        minor={mode !== DCCMode.active}
                        style={style.smallMargin}
                        nativeId="active-contracts-button"
                    />
                    <CButton
                        title={`${format(contractMessages.aboutToStart)} (${
                            soonUpcomingContracts.length
                        })`}
                        onPress={() => {
                            setMode(DCCMode.upcomingSoon);
                        }}
                        small
                        minor={mode !== DCCMode.upcomingSoon}
                        style={style.smallMargin}
                        nativeId="soonUpcoming-contracts-button"
                    />
                    <CButton
                        title={`${format(contractMessages.upcoming)} (${
                            upcomingContracts.length
                        })`}
                        onPress={() => {
                            setMode(DCCMode.upcoming);
                        }}
                        small
                        minor={mode !== DCCMode.upcoming}
                        style={style.smallMargin}
                        nativeId="upcomming-contracts-button"
                    />
                    <CButton
                        title={`${format(contractMessages.toFinish)} (${
                            completedContracts.length
                        })`}
                        onPress={() => setMode(DCCMode.completed)}
                        small
                        minor={mode !== DCCMode.completed}
                        style={style.smallMargin}
                        nativeId="toFinish-contracts-button"
                    />
                </View>
                {!filteredContracts.length && (
                    <View style={[style.centeredItems, style.verticalPadded]}>
                        <CImage image={'search'} maxWidth={250} />
                        <CText message={dashboardMessages.noContracts} />
                    </View>
                )}
                {!!filteredContracts.length && (
                    <ScrollView
                        horizontal
                        style={[
                            style.horizontalHeavyPadded,
                            { paddingBottom: 10 },
                        ]}
                        contentContainerStyle={{ alignItems: 'stretch' }}
                    >
                        {filteredContracts.map((c) => (
                            <CCard
                                key={c.documentId}
                                style={{
                                    maxWidth: 350,
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                                outsideStyle={{ marginHorizontal: 10 }}
                                hoverEffects
                                inverted
                            >
                                <View
                                    style={[
                                        style.verticalHeavyPadded,
                                        style.horizontalHeavyPadded,
                                    ]}
                                >
                                    <ContractTitle contract={c} />
                                </View>
                                {c.from > Date.now() && (
                                    <CText
                                        secondaryHeadline
                                        style={[
                                            style.verticalHeavyPadded,
                                            style.horizontalHeavyPadded,
                                        ]}
                                        message={
                                            c.from - Date.now() > day
                                                ? format(
                                                      dashboardMessages.contractStartsIn,
                                                      {
                                                          x: Math.floor(
                                                              (c.from -
                                                                  Date.now()) /
                                                                  day,
                                                          ),
                                                      },
                                                  )
                                                : format(
                                                      dashboardMessages.contractStartsTomorrow,
                                                  )
                                        }
                                    />
                                )}
                                <View
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                        style.verticalHeavyPadded,
                                        style.horizontalHeavyPadded,
                                    ]}
                                >
                                    <CIcon
                                        icon="calendar"
                                        tint={theme.textSecondaryColor}
                                        size={14}
                                    />
                                    <CText style={style.leftPadded}>
                                        {/* {new Date(
                                        negotiation.createdOn,
                                    ).toLocaleDateString('de')} */}
                                        {`${new Date(c.from).toLocaleDateString(
                                            'de',
                                        )} - ${new Date(
                                            c.to,
                                        ).toLocaleDateString('de')}`}
                                    </CText>
                                </View>
                                <View
                                    style={[
                                        style.horizontalSplit,
                                        style.horizontalPadded,
                                    ]}
                                >
                                    <CButton
                                        title={dashboardMessages.openContract}
                                        onPress={() =>
                                            navigate(
                                                '/contract/' + c.documentId,
                                            )
                                        }
                                        transparent
                                        minor
                                    />
                                    <CButton
                                        title={
                                            dashboardMessages.openContractChat
                                        }
                                        onPress={() =>
                                            navigate('/chat/' + c.chatId)
                                        }
                                        transparent
                                        minor
                                    />
                                </View>
                            </CCard>
                        ))}
                    </ScrollView>
                )}
            </CCard>
        </View>
    );
};
