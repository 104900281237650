import { useEffect } from 'react';
import { useSearchParams } from '../../../../utilities/routing';
import { MContract, MSignaturePosition } from '../../../../models';
import { EUserType } from '../../../../enums';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';

export const useSignatureEffect = (
    issueReload: (next: number) => void,
    contract?: MContract,
) => {
    const [searchParams] = useSearchParams();
    const { callFunction } = useFireBase();
    const { lock } = useLock();
    return useEffect(() => {
        if (!contract) return;
        const spfid = searchParams.get('fid');
        const positionDataString = searchParams.get('posdata');
        if (!positionDataString) return;
        const unlock = lock();

        const positions = Array.from(JSON.parse(positionDataString)).map(
            (p) => {
                const next = new MSignaturePosition(p as MSignaturePosition);
                if (next.type === EUserType.user) {
                    next.userId = contract.employeeId;
                } else {
                    next.userId = contract.signingEmployerId;
                }
                return next;
            },
        );
        const post = {
            fileId: spfid,
            contractId: contract.documentId,
            positions,
        };
        callFunction('createNamirialEnvelope', post).then((response) => {
            searchParams.delete('fn');
            searchParams.delete('posdata');
            unlock();
            issueReload(Date.now());
        });
    }, [searchParams, contract]);
};
