import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { CButton, CImage, CText, Spinner } from '../../../../components';
import { MContract } from '../../../../models';
import { useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';
import { IFilter } from '../../../../utilities/firebase/store';
import { contractMessages } from '../../contract.messages';
import { ECollections, EContractStatus, EUserType } from '../../../../enums';
import { isSuperUser } from '../../../../utilities/auth';
import { ScrollProvider } from '../../../../utilities/contexts/Scroll';
import { ContractRow } from '../../List/components/ContractRow';
import { useNavigate } from 'react-router-native';
/**
 * lists open negotiations
 * @returns
 */
export const NegotiationList: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { getDataIndex, userData, userWorkplaces } = useFireBase();
    // local state
    const [negotiations, setNegotiations] = useState<MContract[]>([]);
    const [loadingNegotiations, setLoadingNegotiations] = useState(true);
    /**
     * negotiations sorted by createdOn
     */
    const sortedNegotiations = useMemo(
        () => negotiations.sort((a, b) => b.createdOn - a.createdOn),
        [negotiations],
    );
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const basefilter = [] as IFilter[];
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                basefilter.push({
                    field: 'workplaceId',
                    value: userWorkplaces.map((w) => w.documentId),
                    operator: 'in',
                });
            } else {
                basefilter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        getDataIndex(ECollections.contracts, {
            filter: [
                ...basefilter,
                {
                    field: 'status',
                    operator: 'in',
                    value: [
                        EContractStatus.creating,
                        EContractStatus.review_yee,
                        EContractStatus.review_yer,
                        EContractStatus.accepted,
                    ],
                },
            ],
        }).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setNegotiations(nextContracts);
            setLoadingNegotiations(false);
        });
    }, [userWorkplaces, userData]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <CText headline>{format(generalMessages.negotiations)}</CText>
                <View style={style.horizontal}>
                    <CButton
                        title={generalMessages.negotiations}
                        onPress={() => {
                            // nothing to do
                        }}
                        small
                        style={style.smallMargin}
                    />
                    <CButton
                        title={generalMessages.contracts}
                        onPress={() => {
                            navigate('/contracts');
                        }}
                        small
                        minor
                        style={style.smallMargin}
                    />
                    <View style={style.verticalPaddedThinSeparator} />
                    <CButton
                        title={contractMessages.history}
                        onPress={() => {
                            navigate('/contracthistory');
                        }}
                        small
                        minor
                        style={style.smallMargin}
                    />
                </View>
            </View>
            {!loadingNegotiations ? (
                <>
                    {sortedNegotiations.map((contract) => (
                        <ContractRow
                            key={contract.documentId}
                            negotiation={contract}
                        />
                    ))}
                    {!negotiations.length && (
                        <View style={style.card}>
                            <CImage image="negotiations" />
                            <CText centered headline>
                                {format(contractMessages.noOpenNegotiations)}
                            </CText>
                        </View>
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </ScrollProvider>
    );
};
