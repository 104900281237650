import React, { FC } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from '../../../../../utilities/routing';
import { CText, Spinner, UserTypeLabel } from '../../../../../components';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { ProfilePicture } from '../../../../../components/ProfilePicture';
import { MUserData } from '../../../../../models';
import { IChatListRowProps } from './IChatListRowProps';
import { useChatListRowState } from './useChatListRowState';
import { TouchableView } from '../../../../../components/TouchableView';
import { chatMessages } from '../../../Chat.messages';
import { timeString } from '../../../../../utilities/functions';
import { useFormat } from '../../../../../utilities/intl';
import { ContractTitle } from '../../../../Contract/components/ContractTitle';
import { EUserType } from '../../../../../enums';
/**
 * display a chat in a row
 * can set lastMessageCreatedOn to be ordered accordingly
 * @param param0
 * @returns
 */
export const ChatListRow: FC<IChatListRowProps> = (props) => {
    // global state
    const { theme } = useTheme();
    const { id } = useParams<{ id: string }>();
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    const {
        loading,
        mainCorrespondent,
        messageToDisplay,
        hasNewMessage,
        chat,
        lastMessage,
        supportRequest,
        contract,
        agency,
    } = useChatListRowState(props);
    /**
     * render
     */
    return (
        <View>
            <TouchableView
                cy={'open-chat-' + mainCorrespondent.documentId}
                onPress={() => navigate('/chat/' + chat.documentId)}
                style={[
                    style.horizontal,
                    {
                        justifyContent: 'space-between',
                        backgroundColor: theme.backgroundMainColor,
                        padding: 10,
                    },
                ]}
            >
                <ProfilePicture data={mainCorrespondent || new MUserData()} />
                <View
                    style={[
                        {
                            marginHorizontal: 10,
                            flex: 1,
                            backgroundColor: theme.backgroundSecondaryColor,
                            padding: 10,
                            borderRadius: 10,
                            shadowColor: theme.shadowColor,
                            shadowOffset: {
                                width: 0,
                                height: 1,
                            },
                            shadowOpacity: 0.18,
                            shadowRadius: 1.0,
                            elevation: 1,
                        },
                        id === chat.documentId && {
                            borderColor: theme.accentColor,
                            borderWidth: 1,
                            borderRadius: 10,
                        },
                    ]}
                >
                    {agency && (
                        <View style={style.horizontal}>
                            <CText message={agency.name} />
                        </View>
                    )}
                    {!supportRequest &&
                        !contract &&
                        mainCorrespondent.type === EUserType.talent && (
                            <View style={style.horizontal}>
                                <UserTypeLabel data={mainCorrespondent} small />
                            </View>
                        )}
                    {supportRequest && (
                        <CText numberOfLines={1}>
                            {format(chatMessages.supportRequest)}
                            {': '}
                            {supportRequest.reason}
                        </CText>
                    )}
                    {contract && (
                        <ContractTitle
                            contract={contract}
                            minor
                            numberOfLines={1}
                        />
                    )}
                    <View
                        style={[
                            style.horizontal,
                            {
                                justifyContent: 'space-between',
                            },
                        ]}
                    >
                        {!!mainCorrespondent && (
                            <CText secondaryHeadline>
                                {`${mainCorrespondent.firstName} ${mainCorrespondent.lastName}`}
                            </CText>
                        )}
                        {lastMessage && (
                            <CText>
                                {`${timeString(
                                    new Date(lastMessage.createdOn).getHours(),
                                    new Date(
                                        lastMessage.createdOn,
                                    ).getMinutes(),
                                )}`}
                            </CText>
                        )}
                    </View>
                    {!loading ? (
                        <>
                            <CText
                                message={
                                    messageToDisplay || chatMessages.noMessages
                                }
                                style={
                                    !!messageToDisplay && {
                                        color: theme.textMainColor,
                                    }
                                }
                            />
                            <View
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    top: 40,
                                }}
                            >
                                {hasNewMessage && (
                                    <View
                                        {...{
                                            dataSet: {
                                                cy:
                                                    'row-bubble-' +
                                                    mainCorrespondent.documentId,
                                            },
                                        }}
                                        style={{
                                            backgroundColor: theme.accentColor,
                                            borderRadius: 5,
                                            width: 10,
                                            height: 10,
                                        }}
                                    />
                                )}
                            </View>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </View>
            </TouchableView>
        </View>
    );
};
