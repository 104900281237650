import React, { FC } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import { CButton, CIcon, CLabel, CText, InfoBox } from '../../../components';
import { ExpandableCard } from '../../../components/ExpandableCard';
import { ResumeView } from '../../../components/ResumeView/ResumeView';
import { EApplicationStatus, ECollections, EUserType } from '../../../enums';
import { MApplication } from '../../../models/MApplication';
import { isManagingUser, isSuperUser } from '../../../utilities/auth';
import { getApplicationStatusColor } from '../../../utilities/constants';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, educationMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { JobTable } from '../../Job/components/JobTable';
import { jobMessages } from '../../Job/Job.messages';
import { applicationMessages } from '../application.messages';
import { ApplicantData } from './ApplicantData';
import { useDialog } from '../../../utilities/dialog';

interface IApplicationRow {
    application: MApplication;
    embedded?: boolean;
    noResume?: boolean;
    reload?: () => void;
}

export const ApplicationRow: FC<IApplicationRow> = ({
    application,
    reload,
    embedded,
    noResume,
}) => {
    const style = useStyle();
    const format = useFormat();
    const dialog = useDialog();
    const { theme } = useTheme();
    const { userData, put } = useFireBase();
    const navigate = useNavigate();
    /**
     * render
     */
    return (
        <ExpandableCard
            embedded={embedded}
            head={
                <View style={[style.flex1, style.horizontalSplit]}>
                    <View style={style.flex1}>
                        <View style={style.horizontalSplit}>
                            <CLabel
                                checked={true}
                                title={applicationMessages[application.status]}
                                color={getApplicationStatusColor(
                                    application.status,
                                )}
                                small
                            />
                        </View>
                        <CText bold headline>
                            {format(applicationMessages.applicationFor, {
                                job: application.job.employeeEducations
                                    .map((edu) => {
                                        const key =
                                            edu as keyof typeof educationMessages;
                                        return educationMessages[key]
                                            ? format(educationMessages[key])
                                            : '';
                                    })
                                    .join(', '),
                            })}
                        </CText>
                    </View>
                    <View>
                        <CText>
                            {`${format(
                                applicationMessages.applyTime,
                            )}: ${new Date(
                                application.createdOn,
                            ).toLocaleDateString('de')}`}
                        </CText>
                        {application.status === EApplicationStatus.open && (
                            <CButton
                                icon={'close'}
                                iconColor={theme.errorColor}
                                title={actionMessages.close}
                                minor
                                small
                                onPress={() =>
                                    dialog({
                                        title: applicationMessages.cancelApplication,
                                        message:
                                            applicationMessages.cancelApplicationText,
                                        cancelButton: {
                                            text: actionMessages.cancel,
                                        },
                                        icon: 'warning',
                                    }).then((ok) =>
                                        ok
                                            ? put(
                                                  ECollections.applications,
                                                  application.documentId,
                                                  {
                                                      ...application,
                                                      status: EApplicationStatus.closed,
                                                  },
                                              ).then(() =>
                                                  reload ? reload() : undefined,
                                              )
                                            : undefined,
                                    )
                                }
                            />
                        )}
                    </View>
                </View>
            }
            subHead={
                <View>
                    <View style={style.horizontal}>
                        <CIcon icon="calendar_outline" size={16} />
                        <CText style={style.leftPadded}>
                            {application.job.from > Date.now()
                                ? `${new Date(
                                      application.job.from,
                                  ).toLocaleDateString('de')} - ${
                                      application.job.to
                                          ? new Date(
                                                application.job.to,
                                            ).toLocaleDateString('de')
                                          : format(jobMessages.unlimited)
                                  }`
                                : format(jobMessages.onRequest)}
                        </CText>
                    </View>
                    <View style={[style.horizontal]}>
                        <CIcon icon="mapMarker_outline" size={16} />
                        <CText style={style.leftPadded}>
                            {`${format(jobMessages.zipCodeArea)}: ${
                                application.job.useFullAddress &&
                                application.job.fullAddress
                                    ? application.job.fullAddress.zipCode
                                    : application.job.location
                            }`}
                        </CText>
                    </View>
                    {(isSuperUser(userData) || isManagingUser(userData)) && (
                        <View style={[style.horizontal, style.verticalPadded]}>
                            {application.job.employeeEducations.find((e) =>
                                application.educations.includes(e),
                            ) ? (
                                <InfoBox
                                    message={applicationMessages.overlapEdu}
                                    type="success"
                                />
                            ) : (
                                <InfoBox
                                    message={applicationMessages.noOverlapEdu}
                                    type="error"
                                />
                            )}
                            <View style={{ width: 5 }} />
                            {!!application.job.employeeSpecializations.find(
                                (spec) =>
                                    application.specializations.find(
                                        (eSpec) => eSpec === spec,
                                    ),
                            ) ? (
                                <InfoBox
                                    message={applicationMessages.overlapSpec}
                                    type="success"
                                />
                            ) : (
                                <InfoBox
                                    message={applicationMessages.noOverlapSpec}
                                    type="error"
                                />
                            )}
                        </View>
                    )}
                </View>
            }
            body={
                <>
                    <CText
                        bold
                        headline
                        style={style.verticalPadded}
                        message={jobMessages.jobInfos}
                    />
                    <JobTable job={application.job} />
                    <CText
                        bold
                        headline
                        style={style.verticalPadded}
                        message={applicationMessages.applicantData}
                    />
                    <ApplicantData application={application} />
                    {!noResume && (
                        <ResumeView
                            self={userData.type === EUserType.user}
                            applicationId={application.documentId}
                        />
                    )}
                </>
            }
            customOnClick={
                (application.contractId &&
                    (application.status === EApplicationStatus.negotiating
                        ? () =>
                              navigate(
                                  '/contract/negotiation/' +
                                      application.contractId,
                              )
                        : application.status === EApplicationStatus.accepted
                        ? () => navigate('/contract/' + application.contractId)
                        : undefined)) ||
                undefined
            }
        />
    );
};
