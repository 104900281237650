import { defineMessages } from 'react-intl';

const scope = 'con.application.';
const scopeLabel = scope + 'label.';

export const applicationMessages = defineMessages({
    applyForJob: {
        id: scope + 'applyForJob',
        defaultMessage: 'Create new Application',
    },
    editApplication: {
        id: scope + 'editApplication',
        defaultMessage: 'Edit Application',
    },
    employeeProfession: {
        id: scope + 'employeeProfession',
        defaultMessage: 'Employee profession',
    },
    applicantData: {
        id: scope + 'applicantData',
        defaultMessage: 'Applicant Data',
    },
    yourResume: {
        id: scope + 'yourResume',
        defaultMessage: 'Your Resume',
    },
    yourResumeWillBeAnonym: {
        id: scope + 'yourResumeWillBeAnonym',
        defaultMessage:
            'Your Resume will be anonymized to ensure equal treatment',
    },
    yourData: {
        id: scope + 'yourData',
        defaultMessage: 'Your Data',
    },
    overlapSpec: {
        id: scope + 'overlapSpec',
        defaultMessage: 'Overlaps with required Specializations',
    },
    noOverlapSpec: {
        id: scope + 'noOverlapSpec',
        defaultMessage: 'Does not overlap with required Specializations',
    },
    overlapEdu: {
        id: scope + 'overlapEdu',
        defaultMessage: 'Does match with required Education',
    },
    noOverlapEdu: {
        id: scope + 'noOverlapEdu',
        defaultMessage: 'Does not match with required Education',
    },
    applicationFor: {
        id: scope + 'applicationFor',
        defaultMessage: 'Application for {job}',
    },
    offerJob: {
        id: scope + 'offerJob',
        defaultMessage: 'Offer Job',
    },
    noApplications: {
        id: scope + 'noApplications',
        defaultMessage:
            'You have currently no applications connected to your account. You can look and apply for a job here:',
    },
    goToJobs: {
        id: scope + 'goToJobs',
        defaultMessage: 'Go to Jobs',
    },
    open: {
        id: scopeLabel + 'open',
        defaultMessage: 'Open',
    },
    closed: {
        id: scopeLabel + 'closed',
        defaultMessage: 'Closed',
    },
    rejected: {
        id: scopeLabel + 'rejected',
        defaultMessage: 'Rejected',
    },
    negotiating: {
        id: scopeLabel + 'negotiating',
        defaultMessage: 'Negotiating',
    },
    accepted: {
        id: scopeLabel + 'accepted',
        defaultMessage: 'Accepted',
    },
    applyTime: {
        id: scopeLabel + 'applyTime',
        defaultMessage: 'Applied on',
    },
    youAreNotVerified: {
        id: scope + 'youAreNotVerified',
        defaultMessage: 'You are not Verified',
    },
    verifyYourself: {
        id: scope + 'verifyYourself',
        defaultMessage: 'Contact an Agent to get started',
    },
    wrongType: {
        id: scope + 'wrongType',
        defaultMessage: 'You are applying as {typeA}',
    },
    wrongTypeDesc: {
        id: scope + 'wrongTypeDesc',
        defaultMessage:
            'You are applying as {typeA}, but are registered as {typeB}. Do you want to continue?',
    },
    confirmClose: {
        id: scope + 'confirmClose',
        defaultMessage: 'Do you really want to close this Application?',
    },
    confirmCloseMessage: {
        id: scope + 'confirmCloseMessage',
        defaultMessage:
            'You will not be taken into consideration from the employer. You can revoke this later to reapply for the job.',
    },
    createMatchingProfile: {
        id: scope + 'createMatchingProfile',
        defaultMessage: 'Create quick profile.',
    },
    createNewProfile: {
        id: scope + 'createNewProfile',
        defaultMessage: 'Create new profile',
    },
    rate: {
        id: scope + 'rate',
        defaultMessage: 'Rate',
    },
    noRate: {
        id: scope + 'noRate',
        defaultMessage: 'Negotiable',
    },
    useOwnResume: {
        id: scope + 'useOwnResume',
        defaultMessage: 'You can use your own resume instead',
    },
    useOwnResumeDescription: {
        id: scope + 'useOwnResumeDescription',
        defaultMessage:
            'You can use a uploaded resume to replace the generated one. This will not influence your application or the values displayed. It will just replace the resume that the employer downloads after interest is already generated.',
    },
    useOwnResumePlaceholder: {
        id: scope + 'useOwnResumePlaceholder',
        defaultMessage: 'Select a resume to use it',
    },
    uploadAResume: {
        id: scope + 'uploadAResume',
        defaultMessage:
            'You can upload a resume to your profile to replace the generated pdf. Doing so will not influence the values displayed for initial comparison, but might enhance you chance to be selected later on.',
    },
    noProfiles: {
        id: scope + 'noProfiles',
        defaultMessage:
            'You have no detailed profiles. You can create a matching profile to apply with, which will be only available during this process.',
    },
    noMatchingProfile: {
        id: scope + 'noMatchingProfile',
        defaultMessage:
            'Your profiles do not seem to match the job description. You can select one that partially fits or create a exactly matching one, which will only be available during this process.',
    },
    successfullyApplied: {
        id: scope + 'successfullyApplied',
        defaultMessage: 'You have successfully applied',
    },
    successfullyAppliedText: {
        id: scope + 'successfullyAppliedText',
        defaultMessage:
            'You will be notified once your application gets accepted and negotiation starts.',
    },
    successfullyAppliedWithRate: {
        id: scope + 'successfullyAppliedWithRate',
        defaultMessage:
            'You have successfully applied with a hourly rate of {rate}. You will be notified once your application gets accepted and negotiation starts.',
    },
    noEmploymentLeasingLicense: {
        id: scope + 'noEmploymentLeasingLicense',
        defaultMessage: 'Your agency seems to have no valid license yet.',
    },
    noEmploymentLeasingLicenseText: {
        id: scope + 'noEmploymentLeasingLicenseText',
        defaultMessage: 'You need a valid license to apply for a job.',
    },
    openAgency: {
        id: scope + 'openAgency',
        defaultMessage: 'Open agency',
    },
    useWageOnApplication: {
        id: scope + 'useWageOnApplication',
        defaultMessage:
            'Apply with a predefined hourly rates. The rate will be displayed during application and used to initialize the contract. The employer might try to negotiate the rate you offer now.',
    },
    applyWithWage: {
        id: scope + 'applyWithWage',
        defaultMessage: 'Apply with these rates',
    },
    cancelApplication: {
        id: scope + 'cancelApplication',
        defaultMessage: 'Cancel application',
    },
    cancelApplicationText: {
        id: scope + 'cancelApplicationText',
        defaultMessage:
            'Please confirm that you want to cancel this application',
    },
    confirmTempProfile: {
        id: scope + 'confirmTempProfile',
        defaultMessage: 'Are you sure you want to use a quick profile?',
    },
    confirmTempProfileText: {
        id: scope + 'confirmTempProfileText',
        defaultMessage:
            'Using a quick profile might not yield the best results. A quick profile does exactly match the job description. Exceeding the Job description by having other qualifications might be beneficial to your application',
    },
    rating: {
        id: scope + 'rating',
        defaultMessage: 'Average Rating',
    },
    overlappingApplications: {
        id: scope + 'overlappingApplications',
        defaultMessage:
            'You already have open applications for this jobs deployment period. Please make sure you are available before applying.',
    },
    overlappingNegotiations: {
        id: scope + 'overlappingNegotiations',
        defaultMessage:
            'You already have open negotiations for this jobs deployment period. Please make sure you are available before applying.',
    },
    overlappingContracts: {
        id: scope + 'overlappingContracts',
        defaultMessage:
            'You already have open contracts for this jobs deployment period. Please make sure you are available before applying.',
    },
    ensureYouAreAvailable: {
        id: scope + 'ensureYouAreAvailable',
        defaultMessage:
            'Confirm that you are available for the deployment period',
    },
    overlappingApplicationsTalent: {
        id: scope + 'overlappingApplicationsTalent',
        defaultMessage:
            'This talentprofile is used in open applications for this jobs deployment period. Please make sure that you have available personnel for this job.',
    },
    overlappingNegotiationsTalent: {
        id: scope + 'overlappingNegotiationsTalent',
        defaultMessage:
            'This talentprofile already has open negotiations for this jobs deployment period. Please make sure that you have available personnel for this job.',
    },
    overlappingContractsTalent: {
        id: scope + 'overlappingContractsTalent',
        defaultMessage:
            'This talentprofile already has open contracts for this jobs deployment period. Please make sure that you have available personnel for this job.',
    },
    ensureYourTalentIsAvailable: {
        id: scope + 'ensureYourTalentIsAvailable',
        defaultMessage:
            'Confirm that you have available talents for the deployment period',
    },
});
