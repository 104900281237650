import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CText } from '../../../components';
import { CDatePicker } from '../../../components';
import { ECollections } from '../../../enums';
import { MAvailability } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { profileMessages } from '../Profile.messages';
import { AvailabilityRow } from './AvailabilityRow';
import { ProfileContext } from '../Profile.context';
/**
 * Profile Section do display availabilities and
 * edit / set available from to lock account out of notifications until then
 * @param param0 profile section props
 * @returns profile section
 */
export const ProfileSectionAvailabilities: FC = () => {
    const format = useFormat();
    const style = useStyle();
    const { getDataIndex } = useFireBase();
    const { curData, prevData, onChange } = useContext(ProfileContext);
    const [availabilities, setAvailabilities] = useState<MAvailability[]>([]);
    /**
     * effect to load availabilities
     */
    useEffect(() => {
        getDataIndex(ECollections.availabilities, {
            filter: [{ field: 'uid', value: prevData.documentId }],
        }).then((results) => {
            setAvailabilities(
                (results as MAvailability[]).map((r) => new MAvailability(r)),
            );
        });
    }, [prevData]);
    /**
     * render
     */
    return (
        <View style={{ zIndex: 1 }}>
            <CText style={style.verticalPadded}>
                {format(profileMessages.availableFromDescription)}
            </CText>
            <CDatePicker
                title={format(profileMessages.availableFrom)}
                value={
                    curData.availableFrom
                        ? new Date(curData.availableFrom)
                        : undefined
                }
                onChange={(nextDate) => {
                    onChange({ availableFrom: nextDate.getTime() });
                }}
            />
            <CText secondaryHeadline>
                {format(profileMessages.explicitAvailabilities)}
            </CText>
            <CText style={style.verticalPadded}>
                {format(profileMessages.explicitAvailabilitiesDescription)}
            </CText>
            {!!availabilities.length && (
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.from)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.to)}</CText>
                    </View>
                    <View style={{ flex: 1 }}>
                        <CText bold>{format(profileMessages.note)}</CText>
                    </View>
                    <View style={[{ flex: 1 }, style.horizontal]}>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.days)}</CText>
                        </View>
                        <View style={{ flex: 1 }}>
                            <CText bold>{format(profileMessages.times)}</CText>
                        </View>
                    </View>
                    <View style={{ width: 50 }} />
                </View>
            )}
            {availabilities.map((a) => (
                <AvailabilityRow key={a.documentId} availability={a} />
            ))}
            {!availabilities.length && (
                <View style={[style.verticalPadded, style.horizontalSpaced]}>
                    <CText>
                        {format(profileMessages.noExplicitAvailabilities)}
                    </CText>
                </View>
            )}
        </View>
    );
};
