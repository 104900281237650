import { createContext } from 'react';
import { EEnvironment, ERegion } from '../../../enums';

export const EnvironmentContext = createContext(
    {} as {
        environment: EEnvironment;
        setEnvironment: (next: EEnvironment) => void;
        region: ERegion;
        setRegion: (next: ERegion) => void;
    },
);
