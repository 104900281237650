import React, { FC, useCallback, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import {
    Spinner,
    CButton,
    CText,
    InfoBox,
    CPicker,
    CTextInput,
    CCard,
} from '../../../components';
import { MAgency, MInvite, MWorkplace } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { workplaceMessages } from '../../Workplace/workplace.messages';
import { ECollections, EInviteStatus } from '../../../enums';
import { useDialog } from '../../../utilities/dialog';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { AgencyRow } from '../../Agency/List/components/AgencyRow';
import { useEnvironment } from '../../../utilities/contexts';
import { agencyMessages } from '../agency.messages';
import { useLock } from '../../../utilities/hooks';
import Clipboard from '@react-native-clipboard/clipboard';

export const MyAgencies: FC<{ workplace?: MWorkplace }> = (props) => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { lock } = useLock();
    const {
        post,
        getDataById,
        userData,
        getDataIndex,
        callFunction,
        userWorkplaces,
        put,
        reloadUserData,
    } = useFireBase();
    const { environment } = useEnvironment();
    const dialog = useDialog();
    // local state
    const [workplace, setWorkplace] = useState(props.workplace);
    const [invites, setInvites] = useState<MInvite[]>([]);
    const [agencies, setAgencies] = useState<
        { agency: MAgency; workplaceIds: string[] }[]
    >([]);
    const [agencyToAdd, setAgencyToAdd] = useState<string>('');
    const [loading, setLoading] = useState(true);
    /**
     * callback to load invites
     */
    const loadInvites = useCallback(() => {
        const workplacesToWorkWith = props.workplace
            ? [props.workplace]
            : userWorkplaces;

        Promise.all(
            workplacesToWorkWith.map(async (wp) => {
                const result = await getDataIndex(
                    `${ECollections.workplaces}/${wp.documentId}/${ECollections.invites}`,
                    {
                        filter: [
                            { field: 'status', value: EInviteStatus.open },
                        ],
                    },
                );
                return (result as any[]).map((r) => new MInvite(r));
            }),
        ).then((invites) => {
            setInvites(invites.reduce((acc, i) => [...acc, ...i], []));
        });
    }, [props, userWorkplaces]);
    /**
     * callback to create invite for agency
     */
    const createInvite = useCallback(() => {
        if (!workplace) {
            return;
        }
        dialog({
            title: workplaceMessages.createAgencyInvite,
            message: workplaceMessages.createAgencyInviteText,
            textInputs: [
                {
                    title: workplaceMessages.recipientPlaceholder,
                    id: 'mail',
                },
            ],
            buttons: [
                {
                    text: actionMessages.send,
                    onPress: async (inputs) => {
                        const unlock = lock();
                        const mail = inputs?.find(
                            (v) => v.id === 'mail',
                        )?.value;
                        /**
                         * call createInviteForPrivateAgency
                         */
                        await callFunction('createInviteForPrivateAgency', {
                            workplaceId: workplace.documentId,
                            mail,
                        });
                        loadInvites();
                        unlock();
                        dialog({
                            title: workplaceMessages.agencyInviteSent,
                            message: format(
                                workplaceMessages.agencyInviteSentText,
                                {
                                    recipients: mail,
                                },
                            ),
                            icon: 'success',
                        });
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
    }, [workplace, userData, loadInvites]);
    /**
     * callback to issue addition action of agency by id
     */
    const addAgency = useCallback(() => {
        if (!workplace) {
            return;
        }
        getDataById(ECollections.agencies, agencyToAdd).then((a) => {
            if (a && a.documentId) {
                put(ECollections.workplaces, workplace.documentId, {
                    ...workplace,
                    agencies: [...workplace.agencies, agencyToAdd],
                });
                setAgencyToAdd('');
                reloadUserData();
            } else {
                setAgencyToAdd('');
                dialog({
                    title: workplaceMessages.didNotFindAgency,
                    message: workplaceMessages.didNotFindAgencyText,
                    icon: 'error',
                });
            }
        });
    }, [workplace, agencyToAdd]);
    /**
     * callback to remove preffered agency from workplace
     */
    const handleRemove = useCallback(
        async (wpId: string, agency: MAgency) => {
            const wpToRemoveFrom = userWorkplaces.find(
                (wp) => wp.documentId === wpId,
            );
            if (
                wpToRemoveFrom &&
                (await dialog({
                    icon: 'warning',
                    title: agencyMessages.confirmRemoval,
                    message: format(agencyMessages.confirmRemovalText, {
                        agency: agency.name,
                        workplace: wpToRemoveFrom.name,
                    }),
                    cancelButton: { text: actionMessages.cancel },
                    buttons: [
                        {
                            text: actionMessages.remove,
                            color: theme.errorColor,
                        },
                    ],
                }))
            ) {
                await put(ECollections.workplaces, wpToRemoveFrom.documentId, {
                    ...wpToRemoveFrom,
                    agencies: wpToRemoveFrom.agencies.filter(
                        (a) => a !== agency.documentId,
                    ),
                });
                reloadUserData();
            }
        },
        [userWorkplaces],
    );
    /**
     * effect to load prev data
     */
    useEffect(() => {
        const workplacesToWorkWith = props.workplace
            ? [props.workplace]
            : userWorkplaces;
        if (workplacesToWorkWith.length) {
            setWorkplace(workplacesToWorkWith[0]);
        }
        Promise.all(
            workplacesToWorkWith
                .reduce((acc, wp) => {
                    for (const agencyId of wp.agencies) {
                        const prev = acc.find((p) => p.agencyId === agencyId);
                        if (prev) {
                            prev.workplaceIds.push(wp.documentId);
                        } else {
                            acc.push({
                                workplaceIds: [wp.documentId],
                                agencyId,
                            });
                        }
                    }
                    return acc;
                }, [] as { workplaceIds: string[]; agencyId: string }[])
                .map(async ({ workplaceIds, agencyId }) => {
                    const a = await getDataById(
                        ECollections.agencies,
                        agencyId,
                    );
                    return { workplaceIds, agency: new MAgency(a) };
                }),
        ).then(setAgencies);
        setLoading(false);
    }, [userWorkplaces, props]);
    /**
     * effect to load invites if callback should change
     */
    useEffect(loadInvites, [loadInvites]);
    /**
     * loading indicator while loading
     */
    if (loading) {
        return <Spinner />;
    }
    return (
        <ScrollProvider
            style={!props.workplace && style.paddedScrollableMainView}
        >
            <View style={!props.workplace && style.card}>
                <CText secondaryHeadline message={workplaceMessages.agencies} />
                <View style={style.verticalPadded}>
                    <InfoBox
                        message={workplaceMessages.preferredAgenciesInfo}
                    />
                </View>
            </View>
            {agencies.map(({ agency, workplaceIds }) => (
                <AgencyRow
                    key={agency.documentId}
                    agency={agency}
                    workplaces={
                        workplaceIds
                            .map((wpid) =>
                                userWorkplaces.find(
                                    (wp) => wp.documentId === wpid,
                                ),
                            )
                            .filter((wp) => !!wp) as MWorkplace[]
                    }
                    onRemoveWorkplace={(wpId) => handleRemove(wpId, agency)}
                />
            ))}
            <CCard embedded={!!props.workplace}>
                <View style={[style.horizontal, style.verticalPadded]}>
                    <InfoBox
                        message={format(workplaceMessages.inviteAgency, {
                            platform: environment,
                        })}
                    />
                </View>
                {userWorkplaces.length > 1 && (
                    <View>
                        <CPicker
                            title={agencyMessages.yourWorkplaces}
                            values={userWorkplaces.map((wp) => ({
                                value: wp.documentId,
                                label: wp.name,
                            }))}
                            onChange={(v) => {
                                const next = userWorkplaces.find(
                                    (wp) => wp.documentId === v,
                                );
                                if (next) {
                                    setWorkplace(next);
                                }
                            }}
                            value={workplace?.documentId || ''}
                        />
                    </View>
                )}
                <View style={style.horizontalSpaced}>
                    <CButton
                        title={workplaceMessages.createAgencyInvite}
                        icon="plus"
                        onPress={createInvite}
                    />
                </View>
            </CCard>
            <CCard embedded={!!props.workplace}>
                <CText
                    message={workplaceMessages.openAgencyInvites}
                    secondaryHeadline
                />
                {invites.map((invite) => {
                    const basepath =
                        Platform.OS === 'web'
                            ? // @ts-ignore
                              window?.location?.hostname
                            : 'app.timeployees.de';
                    const isLocalhost = basepath === 'localhost';
                    const inviteURL = `${
                        isLocalhost ? '' : 'https://'
                    }${basepath}${isLocalhost ? ':3000' : ''}/invite?invite=${
                        invite.documentId
                    }&target=${invite.targetId}&type=agencyReferral`;
                    return (
                        <View
                            key={invite.documentId}
                            style={[style.horizontalSplit, style.centeredItems]}
                        >
                            <View>
                                <CText>{inviteURL}</CText>
                                {!!invite.title && (
                                    <CText>{invite.title}</CText>
                                )}
                            </View>
                            <CButton
                                icon={'clipboardMulti_outline'}
                                onPress={() => {
                                    Clipboard.setString(inviteURL);
                                }}
                                minor
                                transparent
                            />
                        </View>
                    );
                })}
            </CCard>
            <CCard embedded={!!props.workplace}>
                <View style={[style.horizontal, { alignItems: 'flex-end' }]}>
                    <View style={style.flex1}>
                        <CTextInput
                            label={workplaceMessages.idOfAgencyToAdd}
                            placeholder={
                                workplaceMessages.idOfAgencyToAddPlaceholder
                            }
                            value={agencyToAdd}
                            onChangeText={setAgencyToAdd}
                            autoExtend
                        />
                    </View>
                    <CButton
                        style={{ marginBottom: 15 }}
                        title={workplaceMessages.addAgency}
                        icon="plus"
                        onPress={addAgency}
                    />
                </View>
            </CCard>
        </ScrollProvider>
    );
};
