import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Spinner } from '../../../components';
import { ECollections } from '../../../enums';
import { MAgency } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { AgencyRow } from '../../Agency/List/components/AgencyRow';
import { ProfileContext } from '../Profile.context';
/**
 * profile section agency. contains a list of all agencies for a user.
 * the list should never be longer than 1
 * @returns
 */
export const ProfileSectionAgency: FC = () => {
    const { curData } = useContext(ProfileContext);
    const { getDataIndex } = useFireBase();
    const [agencys, setAgencies] = useState<MAgency[]>([]);
    const [loading, setLoading] = useState(true);
    /**
     * effect to load all agencies assigned to user
     */
    useEffect(() => {
        setLoading(true);
        const filter: IFilter[] = [];
        filter.push({
            field: 'users',
            operator: 'array-contains',
            value: curData.documentId,
        });
        const params: any = {
            filter,
            limit: 10,
        };
        getDataIndex(ECollections.agencies, params).then((result) => {
            const next = (result as MAgency[]).map((r) => new MAgency(r));
            setAgencies(next);
            setLoading(false);
        });
    }, [curData]);
    /**
     * render
     */
    return (
        <View>
            {loading ? (
                <Spinner />
            ) : (
                agencys.map((agency) => (
                    <AgencyRow key={agency.documentId} agency={agency} />
                ))
            )}
        </View>
    );
};
