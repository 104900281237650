import React, { FC, useContext } from 'react';
import {
    CText,
    CPicker,
    CTextInput,
    Radio,
    ProfilePicture,
    CButton,
} from '../../../../components';
import { useStyle } from '../../../../utilities/styles';
import { View } from 'react-native';
import { CDatePicker } from '../../../../components';
import { year } from '../../../../utilities/functions';
import { useFormat } from '../../../../utilities/intl';
import {
    actionMessages,
    doctorTitleMessages,
} from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { countryMessages } from '../../../../utilities/messages/country.messages';
import { ProfileContext } from '../../../Profile/Profile.context';
import { EGender } from '../../../../enums';
import { useFireBase } from '../../../../utilities/firebase';
import { isSuperUser } from '../../../../utilities/auth';

/**
 * common country keys sorted as displayed
 */
const priorisedCountryKeys = ['germany'];
/**
 * all remaining country keys sorted alphabetically
 */
const otherCountryKeys = [
    ...Object.keys(countryMessages)
        .filter((key) => !priorisedCountryKeys.includes(key))
        .sort(),
];
/**
 * section of professional data that is autofilled based on your account
 * if you only manage your own professional data
 * @param param0
 * @returns
 */
export const ProfessionalBasic: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { isLoggedIn } = useFireBase();
    // context
    const { curData, onChange } = useContext(ProfileContext);
    /**
     * render
     */
    return (
        <>
            {curData.documentId && isLoggedIn && (
                <View style={style.verticalPadded}>
                    <CText>{format(profileMessages.profilePicture)}</CText>
                    <View style={style.verticalPadded}>
                        <View style={{ marginHorizontal: 'auto' }}>
                            <ProfilePicture
                                data={curData}
                                onChange={(picture) => onChange({ picture })}
                                large
                                isProfProfile
                            />
                        </View>
                        <View style={{ marginHorizontal: 'auto' }}>
                            {!!curData.picture && (
                                <View style={style.verticalPadded}>
                                    <CButton
                                        icon="minus"
                                        title={actionMessages.delete}
                                        onPress={() =>
                                            onChange({ picture: '' })
                                        }
                                        smaller
                                        minor
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
            <CText message={format(profileMessages.gender2)} />
            <Radio
                onChange={(gender) => onChange({ gender })}
                value={curData.gender}
                values={[
                    {
                        label: format(profileMessages.salutationMale),
                        value: EGender.male,
                    },
                    {
                        label: format(profileMessages.salutationFemale),
                        value: EGender.female,
                    },
                ]}
                horizontal
            />
            <View style={style.verticalPadded}>
                <CText>{format(profileMessages.selectTitle)}</CText>
                <Radio
                    onChange={(title) => onChange({ title })}
                    value={curData.title}
                    values={[
                        {
                            label: format(doctorTitleMessages.noTitle),
                            value: undefined,
                        },
                        {
                            label: format(doctorTitleMessages.doctor),
                            value: 'doctor',
                        },
                        {
                            label: format(doctorTitleMessages.professor),
                            value: 'professor',
                        },
                        {
                            label: format(doctorTitleMessages.professordoctor),
                            value: 'professordoctor',
                        },
                        // ...Object.keys(doctorTitleMessages).map((v) => {
                        //     const parsedV =
                        //         v as keyof typeof doctorTitleMessages;
                        //     return {
                        //         label: format(
                        //             doctorTitleMessages[v as EDoctorTitle],
                        //         ),
                        //         value:
                        //             parsedV !== 'noTitle' ? v : undefined,
                        //     };
                        // }),
                    ]}
                    horizontal
                />
            </View>
            <CTextInput
                value={curData.firstName}
                onChangeText={(firstName) => onChange({ firstName })}
                label={format(profileMessages.firstName)}
                autoExtend
            />
            <CTextInput
                value={curData.lastName}
                onChangeText={(lastName) => onChange({ lastName })}
                label={format(profileMessages.lastName)}
                autoExtend
            />
            <CTextInput
                value={curData.mail}
                onChangeText={(mail) => onChange({ mail })}
                label={format(profileMessages.mail)}
                autoExtend
            />
            <CTextInput
                value={curData.phone}
                onChangeText={(phone) => onChange({ phone })}
                label={format(profileMessages.phone)}
                autoExtend
            />
            <CDatePicker
                value={new Date(curData.dateOfBirth)}
                onChange={(dob) => onChange({ dateOfBirth: dob.getTime() })}
                title={format(profileMessages.dob)}
                maxDate={Date.now() - year * 16}
                minDate={Date.now() - year * 100}
            />
            <CPicker
                title={format(profileMessages.nationality)}
                values={[
                    {
                        label: `-${format(
                            profileMessages.nationalityPlaceholder,
                        )}-`,
                        value: undefined,
                        hidden: true,
                    },
                    ...priorisedCountryKeys.map((v) => {
                        return {
                            // @ts-ignore
                            label: format(countryMessages[v]),
                            value: v,
                        };
                    }),
                    {
                        label: `-----------------`,
                        value: undefined,
                        disabled: true,
                    },
                    ...otherCountryKeys.map((v) => {
                        return {
                            // @ts-ignore
                            label: format(countryMessages[v]),
                            value: v,
                        };
                    }),
                ]}
                value={curData.nationality}
                onChange={(nationality) => onChange({ nationality })}
            />
        </>
    );
};
