import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { CText } from '../../../components';
import { EApplicationType, EContractStatus, EProfession } from '../../../enums';
import {
    educationMessages,
    fieldMessages,
    generalMessages,
} from '../../../utilities/messages';
import { contractMessages } from '../contract.messages';
import { useFormat } from '../../../utilities/intl';
import { useFireBase } from '../../../utilities/firebase';
import { MContract } from '../../../models';
import { useStyle } from '../../../utilities/styles';
import { isPeasant } from '../../../utilities/auth';

export const ContractTitle: FC<{
    contract: MContract;
    horizontalPadded?: boolean;
    numberOfLines?: number;
    minor?: boolean;
    secondary?: boolean;
}> = ({ contract, horizontalPadded, numberOfLines, minor, secondary }) => {
    const format = useFormat();
    const { userData } = useFireBase();
    const style = useStyle();
    const isContract = useMemo(
        () =>
            [EContractStatus.signed, EContractStatus.completed].includes(
                contract.status,
            ),
        [contract],
    );

    if (contract.type === EApplicationType.default) {
        return (
            <View
                style={[
                    horizontalPadded && style.horizontalPadded,
                    { display: 'flex', flex: 1 },
                    style.horizontal,
                ]}
            >
                {!!contract.title ? (
                    <CText
                        numberOfLines={numberOfLines}
                        headline={!minor && !secondary}
                        secondaryHeadline={secondary}
                    >
                        {format(contractMessages.unmanagedContract)}
                        {' - '}
                        {contract.title}
                    </CText>
                ) : (
                    <CText
                        numberOfLines={numberOfLines}
                        headline={!minor && !secondary}
                        secondaryHeadline={secondary}
                        message={contractMessages.unmanagedContract}
                    />
                )}
            </View>
        );
    }

    return (
        <View
            style={[
                horizontalPadded && style.horizontalPadded,
                { display: 'flex', flex: 1 },
            ]}
        >
            {isPeasant(userData) || !contract.title ? (
                <CText
                    headline={!minor && !secondary}
                    secondaryHeadline={secondary}
                    style={{ display: 'flex', flex: 1 }}
                    numberOfLines={numberOfLines}
                >
                    {contract.education &&
                        format(
                            isContract
                                ? contractMessages.contractForJobInX
                                : contractMessages.negotiationForJobInX,

                            {
                                x: [
                                    contract.profession &&
                                    contract.profession !==
                                        EProfession.undefined
                                        ? format(
                                              generalMessages[
                                                  contract.profession
                                              ],
                                          )
                                        : contract.field
                                        ? format(fieldMessages[contract.field])
                                        : '',
                                    format(
                                        educationMessages[
                                            contract.education as keyof typeof educationMessages
                                        ],
                                    ),
                                ]
                                    .filter((v) => !!v && v !== 'undefined')
                                    .join(' - '),
                            },
                        )}
                </CText>
            ) : (
                <>
                    <CText
                        headline={!minor && !secondary}
                        secondaryHeadline={secondary}
                        style={{ display: 'flex', flex: 1 }}
                        numberOfLines={numberOfLines}
                    >
                        {format(
                            isContract
                                ? contractMessages.contractForCustomTitle
                                : contractMessages.negotiationForCustomTitle,
                            { x: contract.title },
                        )}
                    </CText>
                    {!minor && !numberOfLines && (
                        <CText
                            message={format(
                                educationMessages[
                                    contract.education as keyof typeof educationMessages
                                ],
                            )}
                        />
                    )}
                </>
            )}
        </View>
    );
};
