import { defineMessages } from 'react-intl';

const scope = 'con.profProfile.';

export const profProfileMessages = defineMessages({
    unverified: {
        defaultMessage: 'You are not verified.',
        id: scope + 'unverified',
    },
    noProfiles: {
        defaultMessage:
            'No profiles found. Create a new one representing your employees.',
        id: scope + 'noProfiles',
    },
    getVerified: {
        defaultMessage:
            'To create a professional profile you need to book an onboarding to verify yourself with one of our agents.',
        id: scope + 'getVerified',
    },
    estPromotion: {
        defaultMessage: 'Estimated promotion to specialist physician.',
        id: scope + 'estPromotion',
    },
    previewResume: {
        defaultMessage: 'Preview Resume',
        id: scope + 'previewResume',
    },
    previewAnonym: {
        defaultMessage: 'Preview Anonymous Resume',
        id: scope + 'previewAnonym',
    },
    languages: {
        id: scope + 'languages',
        defaultMessage: 'Languages',
    },
    rate: {
        id: scope + 'rate',
        defaultMessage: 'Rate',
    },
    additions: {
        id: scope + 'additions',
        defaultMessage: 'Additions',
    },
    profProfileExplanation: {
        id: scope + 'profProfileExplanation',
        defaultMessage: `Here you will find your created professional user profiles to apply for available jobs.

You have the possibility to create new and more precise profiles by using the plus (+), as well as to adjust & complete the already existing profiles by clicking on the entries in the list below. The filter function helps you find your way more easily in case of a high number of created profiles.`,
    },
    confirmDelete: {
        id: scope + 'confirmDelete',
        defaultMessage: 'Are you sure you want to delete this user?',
    },
    confirmDeleteMessage: {
        id: scope + 'confirmDeleteMessage',
        defaultMessage: 'This action cannot be undone once completed!',
    },
    watchForNewJobs: {
        id: scope + 'watchForNewJobs',
        defaultMessage: 'Watch for new jobs',
    },
    watchForNewJobsForThisProfile: {
        id: scope + 'watchForNewJobsForThisProfile',
        defaultMessage: 'Watch for new jobs',
    },
    watchForNewJobsForThisProfileDescription: {
        id: scope + 'watchForNewJobsForThisProfileDescription',
        defaultMessage:
            'By selecting watch new jobs, you will automatically create an application for new jobs matching this profile (field, profession, education ....)',
    },
    watching: {
        id: scope + 'watching',
        defaultMessage: 'Watching',
    },
    filterBy: {
        id: scope + 'filterBy',
        defaultMessage: 'Filter by',
    },
    showAccessibilityButtons: {
        id: scope + 'showAccessibilityButtons',
        defaultMessage: 'Show import section',
    },
    hideAccessibilityButtons: {
        id: scope + 'hideAccessibilityButtons',
        defaultMessage: 'Hide import section',
    },
    createNewProfile: {
        id: scope + 'createNewProfile',
        defaultMessage: 'Create new talent-profile',
    },
    createInvite: {
        id: scope + 'createInvite',
        defaultMessage: 'Create invite',
    },
    openInvites: {
        id: scope + 'openInvites',
        defaultMessage: 'Open invites',
    },
    rateHint: {
        id: scope + 'rateHint',
        defaultMessage:
            'Specify the rate that you charge clients for this or equivalent employees here. This value, if activated, will be used for the subsequent "Automatically apply" function to automatically apply to suitable positions. Additionally, this value will be pre-filled once you apply for a job with this profile.',
    },
    invite: {
        id: scope + 'invite',
        defaultMessage: 'Please enter the name of the talent to invite.',
    },
    inviteText: {
        id: scope + 'inviteText',
        defaultMessage:
            'If you want to immediately send the invite out via email you have to enter the email address and name of your talent.',
    },
    createInviteAndSendMail: {
        id: scope + 'createInviteAndSendMail',
        defaultMessage: 'Create invite and send mail',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'Email',
    },
    noRate: {
        id: scope + 'noRate',
        defaultMessage: 'No hourly rate',
    },
    viewInvites: {
        id: scope + 'viewInvites',
        defaultMessage: 'View open invites',
    },
    closeInvites: {
        id: scope + 'closeInvites',
        defaultMessage: 'Close invites',
    },
    yourProfProfiles: {
        id: scope + 'yourProfProfiles',
        defaultMessage: 'Your talent profiles',
    },
    csvHint: {
        id: scope + 'csvHint',
        defaultMessage:
            'If you have a existing list of talents, which you can export as csv file, please contact us.',
    },
    mailSend: {
        id: scope + 'mailSend',
        defaultMessage: 'You have sent a mail to {mail}',
    },
    mailSendText: {
        id: scope + 'mailSendText',
        defaultMessage:
            'You have sent a mail to {mail}. As soon as the talent filled his or her details you will receive a notification.',
    },
    saveAndCreateAccount: {
        id: scope + 'saveAndCreateAccount',
        defaultMessage: 'Save and register new account',
    },
    confirmFormulaClose: {
        id: scope + 'confirmFormulaClose',
        defaultMessage: 'Are you sure you want to close this profile link?',
    },
    confirmFormulaCloseText: {
        id: scope + 'confirmFormulaCloseText',
        defaultMessage:
            'The link will become unusable and you will have to open a new one.',
    },
    employType: {
        id: scope + 'employType',
        defaultMessage: 'Preferred Employment Type',
    },
    willingToTravel: {
        id: scope + 'willingToTravel',
        defaultMessage: 'Willing to travel for a job',
    },
    notWillingToTravel: {
        id: scope + 'notWillingToTravel',
        defaultMessage: 'Not willing to travel for a job',
    },
    neutralToTravel: {
        id: scope + 'neutralToTravel',
        defaultMessage: 'Limited travel for a job',
    },
    maxTravelDistance: {
        id: scope + 'maxTravelDistance',
        defaultMessage: 'Your maximum travel distance',
    },
    maxTravelDistancePlaceholder: {
        id: scope + 'maxTravelDistancePlaceholder',
        defaultMessage: 'Your maximum travel distance in km',
    },
    travelAvailability: {
        id: scope + 'travelAvailability',
        defaultMessage: 'Travel availability',
    },
});
