import React, { FC, useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { EEnvironment, EUserType } from '../../enums';
import {
    isEmployer,
    isManagingUser,
    isPeasant,
    isSuperUser,
} from '../../utilities/auth';
import { isLocalhost, shouldUseEmulators } from '../../utilities/constants';
import { useFireBase } from '../../utilities/firebase';
import { actionMessages } from '../../utilities/messages/action.messages';
import { generalMessages } from '../../utilities/messages/general.messages';
import { useStyle } from '../../utilities/styles';
import {
    ChatMenuButton,
    DocumentScanListMenuButton,
    JobApplicationsButton,
    MenuButton,
    NotificationMenuButton,
    ProfProfileMenuButton,
    SupportRequestMenuButton,
} from './components';
import { useEnvironment } from '../../utilities/contexts';
import { NegotiationsMenuButton } from './components/NegotiationsMenuButton';
import { useSecureNavigate } from '../../utilities/routing';

interface IMenuContentProps {
    detail?: boolean;
    closeSidebar?: () => void;
    isExpanded: boolean;
    onChangeExpanded: (bool: boolean) => void;
}

export const MenuContent: FC<IMenuContentProps> = ({
    closeSidebar,
    detail,
    isExpanded,
}) => {
    // global state
    const styles = useStyle();
    const { logOut, userData } = useFireBase();
    const { environment } = useEnvironment();
    const { secureNavigate } = useSecureNavigate();
    // local state
    const [showDataMenu, onChangeShowDataMenu] = useState<boolean>(false);
    /**
     * effect to set data menu
     */
    useEffect(() => {
        if (!isExpanded && showDataMenu) {
            onChangeShowDataMenu(isExpanded);
        }
    }, [isExpanded, showDataMenu]);
    /**
     * render
     */
    return (
        <View
            style={[
                {
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                },
                !isExpanded && { alignItems: 'center' },
            ]}
            nativeID="menu-content"
        >
            <MenuButton
                targetLocation={`/`}
                title={generalMessages.overview}
                icon={'home'}
                detail={detail}
                closeSidebar={closeSidebar}
                exact
            />
            <View style={styles.thinSeparator} />
            <View style={{ flex: 1 }}>
                <ScrollView>
                    {isSuperUser(userData) && (
                        <MenuButton
                            targetLocation={`/user`}
                            title={generalMessages.users}
                            icon={'accountMulti'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {userData.type === EUserType.user && (
                        <MenuButton
                            targetLocation={`/resume`}
                            title={generalMessages.resume}
                            icon={'resume'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {(isSuperUser(userData) ||
                        userData.type === EUserType.agency) && (
                        <ProfProfileMenuButton
                            targetLocation={`/profUser`}
                            title={generalMessages.professionalUsers}
                            icon={'accountMulti'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {isSuperUser(userData) && !shouldUseEmulators() && (
                        <MenuButton
                            targetLocation={
                                environment === EEnvironment.SITUS
                                    ? 'https://situsdocs.de/wp-admin/admin.php?page=latepoint'
                                    : 'https://timeployees.de/wp-admin/admin.php?page=latepoint'
                            }
                            title={'Latepoint'}
                            icon={'calendar'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {(userData.type === EUserType.user ||
                        isManagingUser(userData) ||
                        isSuperUser(userData)) && (
                        <MenuButton
                            targetLocation={`/calendar`}
                            title={generalMessages.calendar}
                            icon={'calendar'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    <ChatMenuButton
                        detail={detail}
                        closeSidebar={closeSidebar}
                        targetLocation={`/chat`}
                        title={generalMessages.chat}
                        icon={'chat'}
                    />
                    {isSuperUser(userData) && (
                        <SupportRequestMenuButton
                            detail={detail}
                            closeSidebar={closeSidebar}
                            targetLocation={'/supportrequests'}
                            title={generalMessages.supportRequests}
                            icon={'helpCircle'}
                        />
                    )}
                    {isSuperUser(userData) && (
                        <DocumentScanListMenuButton
                            targetLocation={`/documentScan/list`}
                            title={generalMessages.documentReview}
                            icon={'review'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {!isPeasant(userData) &&
                        ![EUserType.basic, EUserType.talent].includes(
                            userData.type,
                        ) && (
                            <JobApplicationsButton
                                targetLocation={`/japplications`}
                                title={generalMessages.jobApplications}
                                icon={'cardAccountDetails'}
                                detail={detail}
                                closeSidebar={closeSidebar}
                            />
                        )}
                    {isSuperUser(userData) && (
                        <MenuButton
                            targetLocation={`/agency`}
                            title={generalMessages.agencies}
                            icon={'agency'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {isSuperUser(userData) && (
                        <MenuButton
                            targetLocation={`/workplace`}
                            title={generalMessages.workplaces}
                            icon={'workplace'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {(isPeasant(userData) || isSuperUser(userData)) && (
                        <MenuButton
                            targetLocation={`/job`}
                            title={generalMessages.jobs}
                            icon={'clipboardMulti'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {(isSuperUser(userData) || isPeasant(userData)) && (
                        <MenuButton
                            targetLocation={`/application`}
                            title={generalMessages.applications}
                            icon={'clipboardAccount'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {/* TODO: remove localhost restriction */}
                    {isLocalhost() && (
                        <NegotiationsMenuButton
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    <MenuButton
                        targetLocation={`/billingcenter`}
                        title={generalMessages.billingCenter}
                        icon={'accountCash'}
                        detail={detail}
                        closeSidebar={closeSidebar}
                    />
                    {userData.type === EUserType.user && (
                        <MenuButton
                            targetLocation={`/referrals`}
                            title={generalMessages.referrals}
                            icon={'accountMultiPlus'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    {userData.type === EUserType.admin && (
                        <MenuButton
                            targetLocation={`/referrals`}
                            title={generalMessages.referrals}
                            icon={'accountMultiPlus'}
                            detail={detail}
                            closeSidebar={closeSidebar}
                        />
                    )}
                    <NotificationMenuButton
                        targetLocation={`/notifications`}
                        title={generalMessages.notifications}
                        icon={'bell'}
                        detail={detail}
                        closeSidebar={closeSidebar}
                    />
                </ScrollView>
            </View>
            <View style={styles.thinSeparator} />
            <View>
                <MenuButton
                    targetLocation={`/profile`}
                    title={generalMessages.profile}
                    icon={'account'}
                    detail={detail}
                    closeSidebar={closeSidebar}
                />
                {isEmployer(userData) && (
                    <MenuButton
                        targetLocation={`/myAgencies`}
                        title={generalMessages.myAgencies}
                        icon={'agency'}
                        detail={detail}
                        closeSidebar={closeSidebar}
                    />
                )}
                <MenuButton
                    targetLocation={`/helpcenter`}
                    title={generalMessages.helpCenter}
                    icon={'commentQuestion'}
                    detail={detail}
                    closeSidebar={closeSidebar}
                />
                <MenuButton
                    targetLocation={`/settings`}
                    title={generalMessages.settings}
                    icon={'cog'}
                    detail={detail}
                    closeSidebar={closeSidebar}
                />
                <MenuButton
                    cy={'logout-menu-button'}
                    customFunction={
                        Platform.OS !== 'web'
                            ? () => {
                                  if (closeSidebar) closeSidebar();
                                  secureNavigate('/logout');
                              }
                            : logOut
                    }
                    title={actionMessages.logout}
                    icon={'logout'}
                    detail={detail}
                />
            </View>
        </View>
    );
};
