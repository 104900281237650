import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from '../../utilities/routing';
import { ECollections } from '../../enums';
import { useFireBase } from '../../utilities/firebase';
import {
    MAgency,
    MProfessionalInfo,
    MProfessionalProfile,
    MReview,
    MUserData,
    MWorkplace,
} from '../../models';
import { ScrollProvider } from '../../utilities/contexts';
import {
    CButton,
    CCard,
    CImage,
    CText,
    ProfilePicture,
    Spinner,
    StarDisplay,
} from '../../components';
import { useStyle } from '../../utilities/styles';
import { ReviewRow } from './components/ReviewRow';
import { View } from 'react-native';
import { reviewMessages } from './review.messages';
import { useFormat } from '../../utilities/intl';

export const Reviews: FC = () => {
    // global state
    const { getDataIndex, getDataById } = useFireBase();
    const { type, id } = useParams();
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();
    // local state
    const [reviews, setReviews] = useState<MReview[]>([]);
    const [resource, setResource] = useState<
        MUserData | MWorkplace | MAgency | MProfessionalProfile
    >();

    const resourceName = useMemo(() => {
        if (!resource) return '';
        if (type === ECollections.profProfiles) {
            format(reviewMessages.profile);
        }
        const userResource = resource as MProfessionalInfo;
        const nonUserResource = resource as MWorkplace | MAgency;
        if (userResource.firstName || userResource.lastName) {
            return `${userResource.firstName} ${userResource.lastName}`;
        } else if (nonUserResource.name) {
            return nonUserResource.name;
        }
    }, [resource, type, format]);

    const averageRating = useMemo(
        () =>
            reviews.reduce(
                (acc, r) =>
                    acc +
                    (r.ratings.find((r) => r.id === 'general')?.value || 0),
                0,
            ) / reviews.length,
        [reviews],
    );

    useEffect(() => {
        if (
            !type ||
            !id ||
            ![
                ECollections.agencies,
                ECollections.workplaces,
                ECollections.profProfiles,
            ].includes(type as ECollections)
        )
            return;

        getDataIndex(`${type}/${id}/${ECollections.reviews}`, {
            limit: 10,
            orderBy: 'createdOn',
        }).then((response) => {
            const next = (response as MReview[]).map((r) => new MReview(r));
            setReviews(next);
        });
        const selectPubCollection = () => {
            switch (type) {
                case ECollections.agencies:
                    return ECollections.agencies;
                case ECollections.workplaces:
                    return ECollections.publicWorkplaces;
                case ECollections.profProfiles:
                    return ECollections.publicProfProfiles;
                case ECollections.users:
                    return ECollections.publicUsers;
                default:
                    return ECollections.publicUsers;
            }
        };
        getDataById(selectPubCollection(), id).then((d) => setResource(d));
    }, [id, type]);

    if (!resource) {
        return <Spinner />;
    }

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard style={style.horizontal}>
                <CButton
                    cy={'back'}
                    onPress={() => {
                        navigate(-1);
                    }}
                    icon={'chevronLeft'}
                    small
                />
                <CText
                    style={style.leftPadded}
                    message={resourceName}
                    headline
                />
            </CCard>
            <CCard style={[style.horizontal, style.centeredItems]}>
                <ProfilePicture
                    data={resource}
                    isProfProfile={type === ECollections.profProfiles}
                />
                <View style={style.horizontalPadded}>
                    {resource.reviewCount ? (
                        <CText
                            message={format(reviewMessages.reviewCount, {
                                x: resource.reviewCount,
                                resource: resourceName,
                            })}
                        />
                    ) : (
                        <CText
                            message={format(reviewMessages.noReviews, {
                                resource: resourceName,
                            })}
                        />
                    )}
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CText message={reviewMessages.overallAverage} />
                        <StarDisplay stars={resource.averageRating} />
                    </View>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CText message={reviewMessages.recentAverage} />
                        <StarDisplay stars={averageRating} />
                    </View>
                </View>
            </CCard>
            {reviews.map((review) => (
                <ReviewRow review={review} key={review.documentId} />
            ))}
            {!reviews.length && (
                <CCard>
                    <CImage image="referral" />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            centered
                            headline
                            message={format(reviewMessages.noReviews, {
                                resource: resourceName,
                            })}
                        />
                    </View>
                </CCard>
            )}
        </ScrollProvider>
    );
};
