import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../../utilities/routing';
import { CImage, CText } from '../../../../components';
import { ExpandableCard } from '../../../../components/ExpandableCard';
import { MWorkplace, MUserData } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useStyle } from '../../../../utilities/styles';
import { isSuperUser } from '../../../../utilities/auth';
import { useFormat } from '../../../../utilities/intl';
import { ProfilePicture } from '../../../../components/ProfilePicture';
import { ECollections } from '../../../../enums';
import { workplaceMessages } from '../../workplace.messages';
import { addressMessages } from '../../../../components/AddressInput/address.messages';

interface IWorkplaceRowProps {
    workplace: MWorkplace;
}

export const WorkplaceRow: FC<IWorkplaceRowProps> = ({ workplace }) => {
    const [owner, setOwner] = useState<MUserData>();
    const { getDataById, userData } = useFireBase();
    const style = useStyle();
    const navigate = useNavigate();
    const format = useFormat();

    useEffect(() => {
        if (workplace.owner) {
            try {
                getDataById(ECollections.publicUsers, workplace.owner).then(
                    (response) => {
                        setOwner(new MUserData({ ...response }));
                    },
                );
            } catch (e) {
                setOwner(new MUserData({ firstName: 'unavailable' }));
            }
        }
    }, []);
    return (
        <ExpandableCard
            cy={'edit-workplace-' + workplace.name}
            head={
                <View style={[style.horizontalSplit, { width: '100%' }]}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <ProfilePicture data={workplace} small />
                        <CText bold headline>
                            {workplace.name}
                        </CText>
                    </View>
                    {isSuperUser(userData) && (
                        <View
                            style={[
                                style.verticalPadded,
                                style.horizontalPadded,
                            ]}
                        >
                            <CImage image={workplace.region} fixedHeight={20} />
                        </View>
                    )}
                </View>
            }
            subHead={
                <>
                    <CText>
                        {format(addressMessages.zipCode)}:{' '}
                        {workplace.address.zipCode}
                    </CText>
                    <CText>
                        {owner &&
                            `${format(workplaceMessages.owner)}: ${
                                owner.firstName
                            } ${owner.lastName}`}
                    </CText>
                </>
            }
            customOnClick={
                (isSuperUser(userData) ||
                    workplace.users.includes(userData.documentId)) &&
                (() => navigate('/workplace/edit/' + workplace.documentId))
            }
        />
    );
};
