import React, { FC } from 'react';
import { View } from 'react-native';
import {
    MContract,
    MNegotiationHistory,
    MRates,
    MUserData,
} from '../../../../../models';
import { CText, CDatePicker, InfoBox } from '../../../../../components';
import { jobMessages } from '../../../../Job/Job.messages';
import { useStyle } from '../../../../../utilities/styles';
import { contractMessages } from '../../../contract.messages';
import { useFormat } from '../../../../../utilities/intl';
import { Rates } from '../../../../../components/Wage/Rates';
import { RatesView } from '../../../../../components/Wage/RatesView';

interface IContractValueCardProps {
    contract: MContract;
    original?: boolean;
    edit?: boolean;
    onChange?: (next: Partial<MContract>) => void;
    author?: MUserData;
    prev?: MNegotiationHistory;
}
export const ContractValueCard: FC<IContractValueCardProps> = ({
    contract,
    original,
    edit,
    onChange,
    author,
    prev,
}) => {
    const format = useFormat();
    const style = useStyle();
    if (edit && onChange) {
        /**
         * edit render
         */
        return (
            <>
                {author && (
                    <CText
                        message={
                            format(contractMessages.changeAuthoredBy) +
                            ' ' +
                            author.firstName +
                            ' ' +
                            author.lastName
                        }
                    />
                )}
                {contract.fixedJobWage ? (
                    <>
                        <View style={style.verticalPadded}>
                            <InfoBox
                                message={contractMessages.presetRateFromJob}
                                type={'info'}
                            />
                        </View>
                        <RatesView curData={contract.wage} />
                    </>
                ) : (
                    <Rates
                        curData={contract.wage}
                        onChange={(wage) =>
                            onChange({
                                wage: new MRates({
                                    ...contract.wage,
                                    ...wage,
                                }),
                            })
                        }
                    />
                )}
                <View style={[style.horizontal, { zIndex: 1 }]}>
                    <CDatePicker
                        title={jobMessages.from}
                        value={new Date(contract.from)}
                        onChange={(next) => onChange({ from: next.getTime() })}
                    />
                    <CDatePicker
                        title={jobMessages.to}
                        value={new Date(contract.to)}
                        onChange={(next) => onChange({ to: next.getTime() })}
                    />
                </View>
            </>
        );
    }
    /**
     * view render
     */
    return (
        <>
            {author ? (
                <CText
                    message={
                        format(contractMessages.changeAuthoredBy) +
                        ' ' +
                        author.firstName +
                        ' ' +
                        author.lastName
                    }
                />
            ) : (
                <View style={{ height: 18 }} />
            )}
            <View style={{ flexGrow: 1 }}>
                <RatesView
                    curData={
                        original
                            ? contract.originalParameters.wage
                            : contract.wage
                    }
                    // style={style.flex1}
                    prev={prev?.wage}
                    original={
                        !original ? contract.originalParameters.wage : undefined
                    }
                />
            </View>
            <View style={[style.horizontal, { paddingVertical: 30 }]}>
                <View style={[style.horizontalPadded, { paddingBottom: 20 }]}>
                    <CText message={jobMessages.from} />
                    <CText
                        style={[
                            !original &&
                                contract.originalParameters.from !==
                                    contract.from &&
                                style.warning,
                            prev && prev.from !== contract.from && style.error,
                        ]}
                        message={new Date(
                            original
                                ? contract.originalParameters.from
                                : contract.from,
                        ).toLocaleDateString('de')}
                    />
                </View>
                <View style={style.horizontalPadded}>
                    <CText message={jobMessages.to} />
                    <CText
                        style={[
                            !original &&
                                contract.originalParameters.to !==
                                    contract.to &&
                                style.warning,
                            prev && prev.to !== contract.to && style.error,
                        ]}
                        message={new Date(
                            original
                                ? contract.originalParameters.to
                                : contract.to,
                        ).toLocaleDateString('de')}
                    />
                </View>
            </View>
        </>
    );
};
