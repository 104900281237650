import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CPicker, CTextInput } from '../elements';
import { phonePrefixes } from './phonePrefixes';
import { useEnvironment } from '../../utilities/contexts';
import { ERegion } from '../../enums';
import { useStyle } from '../../utilities/styles';

export const ProneNumberInput: FC<{
    value: string;
    onChange: (next: string) => void;
}> = ({ value, onChange }) => {
    const { region } = useEnvironment();
    const style = useStyle();
    /**
     * memoized destruction of phone number
     */
    const { prefix, mainNumber } = useMemo(() => {
        if (value.length <= 3) {
            return { prefix: '', mainNumber: value };
        }
        const nextPP = phonePrefixes.find((pp) =>
            value.includes('+' + pp.code),
        );
        const defaultCode = region === ERegion.de ? '49' : '27';
        const next = {
            prefix: nextPP?.code || defaultCode,
            mainNumber: nextPP ? value.replace('+' + nextPP.code, '') : value,
        };

        return next;
    }, [value]);
    /**
     * handle prefix change
     */
    const handlePrefix = useCallback(
        (nextPrefix: string) => {
            onChange('+' + nextPrefix + mainNumber);
        },
        [mainNumber],
    );
    /**
     * handle main number change
     */
    const handleMainNumber = useCallback(
        (nextNumber: string) => {
            onChange('+' + prefix + nextNumber);
        },
        [prefix],
    );

    return (
        <View style={[style.horizontal, style.centeredItems]}>
            <CPicker
                value={prefix}
                values={phonePrefixes
                    .filter((v) => ['DE', 'ZA'].includes(v.iso))
                    .map((pp) => ({
                        label: '+' + pp.code + ' ' + pp.iso,
                        value: pp.code,
                    }))}
                onChange={handlePrefix}
                style={style.horizontalPadded}
            />
            <CTextInput
                value={mainNumber}
                onChangeText={handleMainNumber}
                autoExtend
                horizontal
                style={[style.flex1, style.leftPadded]}
            />
        </View>
    );
};
