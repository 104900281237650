import { defineMessages } from 'react-intl';

const scope = 'con.timeTracking.';

export const timeTrackingMessages = defineMessages({
    from: {
        id: scope + 'from',
        defaultMessage: 'From',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'To',
    },
    breaks: {
        id: scope + 'breaks',
        defaultMessage: 'Breaks',
    },
    date: {
        id: scope + 'date',
        defaultMessage: 'Date',
    },
    addShift: {
        id: scope + 'addShift',
        defaultMessage: 'Add shift',
    },
    saveAndComplete: {
        id: scope + 'saveAndComplete',
        defaultMessage: 'Save and complete',
    },
    confirmComplete: {
        id: scope + 'confirmComplete',
        defaultMessage: 'Please confirm completion',
    },
    confirmCompleteText: {
        id: scope + 'confirmCompleteText',
        defaultMessage: 'You confirm, that all times are correct.',
    },
    timesheet: {
        id: scope + 'timesheet',
        defaultMessage: 'Timesheet',
    },
    noBreaks: {
        id: scope + 'noBreaks',
        defaultMessage: 'No breaks for this period',
    },
    addBreak: {
        id: scope + 'addBreak',
        defaultMessage: 'Add break',
    },
});
