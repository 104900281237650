import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import ProfProfileEdit from '../../ProfProfile/Edit';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { useFireBase } from '../../../utilities/firebase';
import { MProfessionalProfile } from '../../../models';
import { CButton, CIcon, CText } from '../../../components';
import { actionMessages } from '../../../utilities/messages';
import { useSearchParams } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { urlHandlerMessages } from '../urlHandler.messages';
import { ECollections } from '../../../enums';
import { useLock } from '../../../utilities/hooks';
import { Register } from '../../Start/components';

export const FillProfProfile: FC = () => {
    // global state
    const { isLoggedIn, logOut, callFunction, getDataById } = useFireBase();
    const [searchParams] = useSearchParams();
    const { theme } = useTheme();
    const { lock } = useLock();
    const style = useStyle();
    // local state
    const [done, setDone] = useState(false);
    const [register, setRegister] = useState(false);
    const [mail, setMail] = useState<string>();
    const [error, setError] = useState<string>();

    const [predefinedData, setPredefinedData] =
        useState<MProfessionalProfile>();

    const handleSave = useCallback((next: MProfessionalProfile) => {
        const unlock = lock();
        callFunction('fillProfProfile', next).then((r) => {
            if (r === 'success') {
                setDone(true);
            } else {
                setError(r);
            }
            unlock();
        });
    }, []);

    const handleSaveAndRegister = useCallback((next: MProfessionalProfile) => {
        const unlock = lock();
        callFunction('fillProfProfile', next).then((r) => {
            if (r === 'success') {
                if (next.mail) {
                    setMail(next.mail);
                }
                setRegister(true);
            } else {
                setError(r);
            }
            unlock();
        });
    }, []);

    useEffect(() => {
        const documentId = searchParams.get('profile');
        if (documentId) {
            getDataById(ECollections.profProfiles, documentId).then((v) => {
                setPredefinedData(new MProfessionalProfile(v));
            });
        }
    }, [searchParams]);

    if (register) {
        return (
            <StandaloneWrapper>
                <Register mail={mail} />
            </StandaloneWrapper>
        );
    }

    if (done) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.horizontalSpaced]}>
                    <View style={style.centeredItems}>
                        <CIcon
                            icon={'check'}
                            tint={theme.successColor}
                            size={50}
                        />
                        <CText
                            message={
                                urlHandlerMessages.successfullyEnteredInformations
                            }
                        />
                    </View>
                </View>
            </StandaloneWrapper>
        );
    }
    if (!predefinedData || error) {
        return (
            <StandaloneWrapper>
                <View style={[style.centeredItems, style.horizontalSpaced]}>
                    <View style={style.centeredItems}>
                        <CIcon
                            icon={'close'}
                            tint={theme.errorColor}
                            size={50}
                        />
                        <CText
                            message={urlHandlerMessages.thereWasAnErrorEntering}
                        />
                        <CText>{error}</CText>
                    </View>
                </View>
            </StandaloneWrapper>
        );
    }

    if (!isLoggedIn) {
        return (
            <StandaloneWrapper>
                <ProfProfileEdit
                    nested
                    saveOverride={handleSave}
                    saveAndRegisterNewUser={handleSaveAndRegister}
                    predefinedData={predefinedData}
                    showUnsaveDocSection
                />
            </StandaloneWrapper>
        );
    }

    return (
        <View>
            <CText message={urlHandlerMessages.thisViewIsForLoogedOutUsers} />
            <CText message={urlHandlerMessages.pleaseLogOut} />
            <CButton title={actionMessages.logout} onPress={() => logOut()} />
        </View>
    );
};
