import { defineMessages } from 'react-intl';

const scope = 'con.urlHandler.';

export const urlHandlerMessages = defineMessages({
    unsubscribe: {
        id: scope + 'unsubscribe',
        defaultMessage:
            'You have successfully issued the action to get unsubscribed',
    },
    createJobListing: {
        id: scope + 'createJobListing',
        defaultMessage: 'Create job listing',
    },
    createdJobListing: {
        id: scope + 'createdJobListing',
        defaultMessage:
            'The job listing has been created as draft. Please register or verify your e-mail address to make it public.',
    },
    confirmingJobListing: {
        id: scope + 'confirmingJobListing',
        defaultMessage: 'Confirming job listing. Please wait...',
    },
    confirmedJobListing: {
        id: scope + 'confirmedJobListing',
        defaultMessage:
            'The job listing has been confirmed. Please setup your account for further interactions',
    },
    toRegistration: {
        id: scope + 'toRegistration',
        defaultMessage: 'Go to registration',
    },
    invalidMailFormat: {
        id: scope + 'invalidMailFormat',
        defaultMessage: 'The mail address seems to be invalid.',
    },
    invalidMailProvider: {
        id: scope + 'invalidMailProvider',
        defaultMessage:
            'We do not accept e-mail addresses from public providers. Please use a private mail address.',
    },
    provideMail: {
        id: scope + 'provideMail',
        defaultMessage:
            'To create a job listing, you need to give us a valid e-mail address belonging to your institution. We will use it to contact you about updates to your job listing. You can create a account with the same e-mail address and it will receive administrative access to your job listing.',
    },
    thisViewIsForLoogedOutUsers: {
        id: scope + 'thisViewIsForLoogedOutUsers',
        defaultMessage: 'This view is for users that are not logged in.',
    },
    pleaseLogOut: {
        id: scope + 'pleaseLogOut',
        defaultMessage: 'If you intend to use it please log out.',
    },
    successfullyEnteredInformations: {
        id: scope + 'successfullyEnteredInformations',
        defaultMessage: 'You successfully entered your information.',
    },
    thereWasAnErrorEntering: {
        id: scope + 'thereWasAnErrorEntering',
        defaultMessage: 'An error occurred',
    },
    inviteSuccess: {
        id: scope + 'inviteSuccess',
        defaultMessage: 'Success',
    },
    inviteSuccesstext: {
        id: scope + 'inviteSuccesstext',
        defaultMessage: 'You have successfully claimed the invite.',
    },
    inviteError: {
        id: scope + 'inviteError',
        defaultMessage: 'The invite could not be claimed.',
    },
    resolvingInvite: {
        id: scope + 'resolvingInvite',
        defaultMessage: 'Trying to resolve invite...',
    },
});
