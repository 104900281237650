import { defineMessages } from 'react-intl';

const scope = 'component.picker.';

export const pickerMessages = defineMessages({
    education: {
        id: scope + 'education',
        defaultMessage: 'Education',
    },
    educationPlaceholder: {
        id: scope + 'educationPlaceholder',
        defaultMessage: 'Select a education',
    },
    profession: {
        id: scope + 'profession',
        defaultMessage: 'Profession',
    },
    professionPlaceholder: {
        id: scope + 'professionPlaceholder',
        defaultMessage: 'Select a profession',
    },
    specialization: {
        id: scope + 'specialization',
        defaultMessage: 'Specialization',
    },
    specializationPlaceholder: {
        id: scope + 'specializationPlaceholder',
        defaultMessage: 'Select a specialization',
    },
    position: {
        id: scope + 'position',
        defaultMessage: 'Position',
    },
    positionPlaceholder: {
        id: scope + 'positionPlaceholder',
        defaultMessage: 'Select a position',
    },
    field: {
        id: scope + 'field',
        defaultMessage: 'Special field',
    },
    fieldPlaceholder: {
        id: scope + 'fieldPlaceholder',
        defaultMessage: 'Select a special field',
    },
});
