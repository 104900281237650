import React, { FC } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CIcon,
    CImage,
    CLabel,
    CPicker,
    CText,
    CTextInput,
    Spinner,
} from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ERegion, EUserType } from '../../../enums';
import { generalMessages } from '../../../utilities/messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { useFormat } from '../../../utilities/intl';
import { userMessages } from '../user.messages';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { getUserTypeColor } from '../../../utilities/constants';
import { ProfileSectionAddress } from '../../Profile/components/PSAddress';
import { ProfileSectionBank } from '../../Profile/components/PSBank';
import { ProfileSectionBasic } from '../../Profile/components/PSBasic';
import { ProfileSectionDocuments } from '../../Profile/components/PSDocuments';
import { RichTextEditor, RichTextView } from '../../../components/RichText';
import { ProfessionalUserTable } from './components/ProfessionalUserTable';
import { PersonalUserTable } from './components/PersonalUserTable';
import { SendCustomMail } from '../../Dashboard/components/SendCustomMail';
import { ProfileSectionProfessional } from '../../Profile/components/PSProfessional';
import { useUserViewState } from './useUserViewState';
import { ProfileSectionAvailabilities } from '../../Profile/components/PSAvailabilities';
import { UserViewHeadlineBar } from './components/UserViewHeadlineBar';
import { AssignedAgents } from './components/AssignedAgents';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';
import { ExpandableCard } from '../../../components/ExpandableCard';
import { ProfileContext } from '../../Profile/Profile.context';
import { ProfileSectionAgency } from '../../Profile/components/PSAgency';
import { ProfileSectionWorkplace } from '../../Profile/components/PSWorkplace';
/**
 * view for administrative users to inspect and edit other users
 * @returns
 */
export const UserView: FC = () => {
    // globale state
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    // /local state
    const {
        loading,
        user,
        prevUser,
        agents,
        verificator,
        onChange,
        setDisabled,
        setExtraAvailable,
        setExtraMobile,
        handleSave,
        handleVerifyChange,
        handleMissedOnboardingNotification,
        handleValidity,
        note,
        setNote,
        editingNote,
        setEditingNote,
        handleNoteSave,
        addressComplete,
        basicDataComplete,
        bankInfoComplete,
        activeChat,
        toggleDeleteIssuedOn,
        sendProfileReminder,
        sendProfessionReminder,
        sendAddressReminder,
        sendBankReminder,
    } = useUserViewState();
    /**
     * return spinner if loading
     */
    if (loading) {
        <Spinner />;
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData: user,
                prevData: prevUser,
                onChange: onChange,
                onValidityChange: handleValidity,
            }}
        >
            <ScrollProvider style={style.paddedScrollableMainView}>
                <UserViewHeadlineBar
                    user={user}
                    prevUser={prevUser}
                    setDisabled={setDisabled}
                    setExtraAvailable={setExtraAvailable}
                    setExtraMobile={setExtraMobile}
                    handleSave={handleSave}
                    handleVerifyChange={handleVerifyChange}
                    handleMissedOnboardingNotification={
                        handleMissedOnboardingNotification
                    }
                    activeChat={activeChat}
                    toggleDeleteIssuedOn={toggleDeleteIssuedOn}
                />
                <View style={style.card}>
                    <View style={style.horizontalWrap}>
                        <View>
                            <View
                                style={[
                                    style.horizontalSplit,
                                    style.horizontalWrap,
                                ]}
                            >
                                <ProfilePicture data={user} />
                                <View
                                    style={[
                                        style.horizontal,
                                        style.horizontalPadded,
                                        style.centeredItems,
                                    ]}
                                >
                                    <View style={style.horizontalPadded}>
                                        <CImage
                                            image={user.region}
                                            fixedHeight={20}
                                        />
                                    </View>
                                    <CPicker
                                        values={Object.values(ERegion).map(
                                            (v) => ({
                                                value: v,
                                                label: v,
                                            }),
                                        )}
                                        onChange={(v) => {
                                            onChange({ region: v });
                                        }}
                                        value={user.region}
                                    />
                                </View>
                            </View>
                            <View style={style.horizontalWrap}>
                                <CTextInput
                                    label={format(profileMessages.firstName)}
                                    value={user.firstName}
                                    onChangeText={(firstName) =>
                                        onChange({ firstName })
                                    }
                                />
                                <View style={style.horizontalPadded} />
                                <CTextInput
                                    label={format(profileMessages.lastName)}
                                    value={user.lastName}
                                    onChangeText={(lastName) =>
                                        onChange({ lastName })
                                    }
                                />
                            </View>
                        </View>
                        <View style={{ flex: 1 }} />
                        <View style={{ width: '40%' }}>
                            <AssignedAgents
                                agents={agents}
                                user={user}
                                onChange={onChange}
                            />
                        </View>
                    </View>
                    {user.type !== EUserType.user ? (
                        <View style={style.verticalPadded}>
                            <CText>{format(profileMessages.accountType)}</CText>
                            <CLabel
                                checked={true}
                                title={format(generalMessages[user.type])}
                                color={getUserTypeColor(user.type)}
                            />
                        </View>
                    ) : (
                        <>
                            {!!user.profession && (
                                <View style={style.verticalPadded}>
                                    <CText>
                                        {format(profileMessages.profession)}
                                    </CText>
                                    <CLabel
                                        checked={true}
                                        title={format(
                                            generalMessages[user.profession],
                                        )}
                                        color={theme.accentColor}
                                    />
                                </View>
                            )}
                            <ProfessionalUserTable profInfos={user} />
                        </>
                    )}
                    <View style={style.verticalPadded} />
                    <PersonalUserTable user={user} verificator={verificator} />
                    <View style={style.card}>
                        {editingNote ? (
                            <RichTextEditor text={note} onChange={setNote} />
                        ) : (
                            <RichTextView html={note} />
                        )}
                        <View
                            style={[
                                style.horizontalSpaced,
                                style.verticalPadded,
                            ]}
                        >
                            {editingNote ? (
                                <CButton
                                    icon={'check'}
                                    title={format(userMessages.finishEditing)}
                                    onPress={() => {
                                        handleNoteSave().then(() => {
                                            setEditingNote(false);
                                        });
                                    }}
                                />
                            ) : (
                                <CButton
                                    title={format(userMessages.editNote)}
                                    onPress={() => {
                                        setEditingNote(true);
                                    }}
                                />
                            )}
                        </View>
                    </View>
                </View>
                {user.type === EUserType.user && (
                    <ExpandableCard
                        style={[style.card, { zIndex: 10 }]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.availabilities)}
                                </CText>
                            </View>
                        }
                        body={<ProfileSectionAvailabilities />}
                        preventHover
                    />
                )}
                {user.type === EUserType.user && (
                    <ExpandableCard
                        cy={'ps-documents'}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.documents)}
                                </CText>
                            </View>
                        }
                        body={
                            <>
                                {!!user.mail && (
                                    <View style={style.horizontalSplit}>
                                        <View />
                                        <CButton
                                            cy={
                                                'document-reminder-notification'
                                            }
                                            title={format(
                                                profileMessages.sendReminder,
                                            )}
                                            onPress={sendProfileReminder}
                                        />
                                    </View>
                                )}
                                <ProfileSectionDocuments />
                            </>
                        }
                        preventHover
                    />
                )}
                <ExpandableCard
                    zIndex={10}
                    head={
                        <View style={[style.horizontalSplit, { flex: 1 }]}>
                            <CText bold headline>
                                {format(profileMessages.basic)}
                            </CText>
                        </View>
                    }
                    body={<ProfileSectionBasic />}
                    preventHover
                />
                {user.type == EUserType.user && (
                    <ExpandableCard
                        zIndex={9}
                        style={[
                            basicDataComplete
                                ? style.successBorder
                                : style.errorBorder,
                        ]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.personal)}
                                </CText>
                                {basicDataComplete ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                {!!user.mail && (
                                    <View style={style.horizontalSplit}>
                                        <View />
                                        <CButton
                                            title={format(
                                                profileMessages.sendReminder,
                                            )}
                                            onPress={sendProfessionReminder}
                                        />
                                    </View>
                                )}
                                {user.type === EUserType.user && (
                                    <ProfileSectionProfessional />
                                )}
                            </>
                        }
                        preventHover
                    />
                )}
                {user.type === EUserType.user && (
                    <ExpandableCard
                        style={[
                            style.card,
                            addressComplete
                                ? style.successBorder
                                : style.errorBorder,
                        ]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.addressInfo)}
                                </CText>
                                {addressComplete ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                {!!user.mail && (
                                    <View
                                        style={[
                                            style.horizontalSplit,
                                            style.centeredItems,
                                        ]}
                                    >
                                        <CText bold headline>
                                            {format(
                                                profileMessages.addressInfo,
                                            )}
                                        </CText>
                                        <CButton
                                            title={format(
                                                profileMessages.sendReminder,
                                            )}
                                            onPress={sendAddressReminder}
                                        />
                                    </View>
                                )}
                                <ProfileSectionAddress />
                            </>
                        }
                        preventHover
                    />
                )}
                {user.type === EUserType.user && (
                    <ExpandableCard
                        style={[
                            style.card,
                            bankInfoComplete
                                ? style.successBorder
                                : style.errorBorder,
                        ]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.bankInfo)}
                                </CText>
                                {bankInfoComplete ? (
                                    <CIcon
                                        icon={'check'}
                                        tint={theme.successColor}
                                    />
                                ) : (
                                    <CIcon
                                        icon={'close'}
                                        tint={theme.errorColor}
                                    />
                                )}
                            </View>
                        }
                        body={
                            <>
                                {!!user.mail && (
                                    <View
                                        style={[
                                            style.horizontalSplit,
                                            style.centeredItems,
                                        ]}
                                    >
                                        <CText bold headline>
                                            {format(profileMessages.bankInfo)}
                                        </CText>
                                        <CButton
                                            title={format(
                                                profileMessages.sendReminder,
                                            )}
                                            onPress={sendBankReminder}
                                        />
                                    </View>
                                )}
                                <ProfileSectionBank />
                            </>
                        }
                        preventHover
                    />
                )}
                {user.type === EUserType.agency && (
                    <ExpandableCard
                        style={[style.card, { zIndex: 10 }]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.agencies)}
                                </CText>
                            </View>
                        }
                        body={<ProfileSectionAgency />}
                        preventHover
                    />
                )}
                {user.type === EUserType.employer && (
                    <ExpandableCard
                        style={[style.card, { zIndex: 10 }]}
                        head={
                            <View style={[style.horizontalSplit, { flex: 1 }]}>
                                <CText bold headline>
                                    {format(profileMessages.workplaces)}
                                </CText>
                            </View>
                        }
                        body={<ProfileSectionWorkplace />}
                        preventHover
                    />
                )}
                <SendCustomMail target={user} />
            </ScrollProvider>
        </ProfileContext.Provider>
    );
};
