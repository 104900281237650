import { StyleSheet } from 'react-native';
import { ITheme } from '../../utilities/styles/ITheme';

export const makeTabButtonsStyles = (theme: ITheme) =>
    StyleSheet.create({
        tabButton: {
            padding: 10,
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomWidth: 5,
            borderColor: 'transparent',
        },
        activeTab: {
            borderColor: theme.accentColor,
            borderBottomWidth: 5,
            padding: 10,
            flex: 1,
        },
        tabtext: {
            marginHorizontal: 'auto',
            fontSize: 18,
            color: theme.accentColor,
        },
    });
