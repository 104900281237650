import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MContract,
    MNegotiationHistory,
    MUserData,
} from '../../../../../models';
import { useFireBase } from '../../../../../utilities/firebase';
import { ECollections } from '../../../../../enums';
import { ContractValueCard } from './ContractValueCard';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { CIcon, CText } from '../../../../../components';
import { contractMessages } from '../../../contract.messages';

export const NegotiationHistory: FC<{ contract: MContract }> = ({
    contract,
}) => {
    const { getDataIndex, getDataById } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();

    const [history, setHistory] = useState<MNegotiationHistory[]>([]);
    const [users, setUsers] = useState<MUserData[]>([]);

    const assignedUsers = useMemo(() => {
        return history.map((h) => users.find((u) => u.documentId === h.author));
    }, [users]);

    useEffect(() => {
        getDataIndex(
            `${ECollections.contracts}/${contract.documentId}/${ECollections.negotiationHistory}`,
        ).then((res) => {
            setHistory((res as any[]).map((v) => new MNegotiationHistory(v)));
        });
    }, [contract]);

    useEffect(() => {
        const authorlist = Array.from(new Set(history.map((h) => h.author)));
        Promise.all(
            authorlist.map((a) => getDataById(ECollections.publicUsers, a)),
        ).then((res) => {
            setUsers(res.map((v) => new MUserData(v)));
        });
    }, [history]);

    return (
        <View style={[style.horizontalWrap, style.centeredItems]}>
            <View style={{ alignSelf: 'stretch' }}>
                <CText
                    secondaryHeadline
                    message={contractMessages.originalValues}
                />
                <ContractValueCard contract={contract} original />
            </View>
            <CIcon
                icon={'chevronRight'}
                style={style.horizontalPadded}
                size={40}
                tint={theme.accentColor}
            />
            {history.map((historyEntry, index) => (
                <React.Fragment key={historyEntry.documentId}>
                    <View style={{ alignSelf: 'stretch' }}>
                        <CText
                            secondaryHeadline
                            message={
                                new Date(
                                    historyEntry.createdOn,
                                ).toLocaleDateString('de') +
                                ' ' +
                                new Date(
                                    historyEntry.createdOn,
                                ).toLocaleTimeString('de')
                            }
                        />
                        <ContractValueCard
                            key={historyEntry.documentId}
                            contract={{ ...contract, ...historyEntry }}
                            prev={
                                index
                                    ? { ...contract, ...history[index - 1] }
                                    : {
                                          ...contract,
                                          ...contract.originalParameters,
                                          author: '',
                                      }
                            }
                            author={assignedUsers[index]}
                        />
                    </View>
                    <CIcon
                        icon={'chevronRight'}
                        style={style.horizontalPadded}
                        size={40}
                        tint={theme.accentColor}
                    />
                </React.Fragment>
            ))}
        </View>
    );
};
