import { ITheme } from '../ITheme';

export const lightTheme: ITheme = {
    backgroundMainColor: '#ffffff',
    backgroundSecondaryColor: '#f8f9fd',
    backgroundTertiaryColor: '#f5f5f5',
    borderColor: '#cdced5',
    textMainColor: '#1f1f1f',
    textSecondaryColor: '#6a6e83',
    textTertiaryColor: '#a7aeb3',
    accentColor: '#5a81fa',
    darkAccentColor: '#2c3d8f',
    textAccentColor: '#f8f9fd',
    fontMain: 'Poppins',
    fontThin: 'Poppins',
    errorColor: '#e85b55',
    warningColor: '#feae7f',
    successColor: '#39d08b',
    mainFontFamily: 'Poppins',
    headlineFontFamily: 'DM Sans',
    shadowColor: '#000',
};
