import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MJob } from '../../../models';
import { CButton, CText } from '../../../components';
import { JobRow } from '../../Job/List/components/JobRow';
import { calendarMessages } from '../Calendar.messages';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/Job.messages';
import { useNavigate } from '../../../utilities/routing';
/**
 * todays jobs component for employers below calendar
 * @param param0
 * @returns
 */
export const TodaysJobs: FC<{
    curDate: Date;
    setRange: (r: string[]) => void;
    thisMonthsJobs: MJob[];
    selectedJob?: MJob;
    setSelectedJob: (next: MJob) => void;
}> = ({ curDate, setRange, thisMonthsJobs, setSelectedJob, selectedJob }) => {
    // global state
    const format = useFormat();
    const navigate = useNavigate();
    const style = useStyle();
    // local state
    const [jobs, setJobs] = useState<MJob[]>([]);
    /**
     * effect to set jobs filtered based on cur date
     */
    useEffect(() => {
        setJobs(
            thisMonthsJobs.filter((j) =>
                j.activeDates.includes(curDate.toLocaleDateString('de')),
            ),
        );
    }, [curDate, thisMonthsJobs]);
    /**
     * render
     */
    return (
        <View>
            {/* {selectedJob && (
                <>
                    <View style={style.card}>
                        <CText
                            secondaryHeadline
                            message={calendarMessages.selectedJob}
                        />
                    </View>
                    <JobRow job={selectedJob} highlight />
                </>
            )} */}
            {!!jobs.length && (
                <View style={style.card}>
                    <CText
                        secondaryHeadline
                        message={format(calendarMessages.todaysJobs, {
                            date: curDate.toLocaleDateString('de'),
                        })}
                    />
                </View>
            )}
            {jobs.map((job) => (
                <React.Fragment key={job.documentId}>
                    <JobRow
                        job={job}
                        highlight={job.documentId === selectedJob?.documentId}
                        customOnClick={() => {
                            setRange(job.activeDates);
                            setSelectedJob(job);
                        }}
                        noSubHead
                    />
                </React.Fragment>
            ))}
            {!jobs.length && (
                <View style={[style.centeredItems, style.card]}>
                    <CText
                        secondaryHeadline
                        message={calendarMessages.noJobsToday}
                        style={style.verticalPadded}
                    />
                    <CButton
                        onPress={() =>
                            navigate('/job/new?from=' + curDate.getTime())
                        }
                        title={jobMessages.createNew}
                        icon="plus"
                    />
                </View>
            )}
        </View>
    );
};
