import React, { FC, SetStateAction, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CText, CTimePicker } from '../../../components';
import { TouchableView } from '../../../components/TouchableView';
import { MAvailability } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { convertWeekAndYearToDate } from '../../../utilities/functions';
import { calendarMessages } from '../Calendar.messages';

export interface IAllDayAvailabilityProps {
    availability: MAvailability;
    onChange: (next: SetStateAction<MAvailability>) => void;
}
/**
 * day availability row for availability edit
 */
export const AllDayAvailability: FC<IAllDayAvailabilityProps> = ({
    availability,
    onChange,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    /**
     * from to from self or inherited
     */
    const { from, to } = useMemo(() => availability, [availability]);
    /**
     * return base date
     */
    const baseDate = useMemo(
        () =>
            new Date(
                convertWeekAndYearToDate(availability.year, availability.start),
            ),
        [availability],
    );
    /**
     * memoized time picker readable from
     */
    const fromDate = useMemo(
        () =>
            new Date(
                baseDate.getFullYear(),
                baseDate.getMonth(),
                baseDate.getDate(),
                from,
            ),
        [baseDate, from],
    );
    /**
     * memoized time picker readable from
     */
    const toDate = useMemo(
        () =>
            new Date(
                baseDate.getFullYear(),
                baseDate.getMonth(),
                baseDate.getDate(),
                to === 24 ? 23 : to,
            ),
        [baseDate, to],
    );
    /**
     * callback to remove inheriting from all days
     */
    const stopInheriting = useCallback(
        () =>
            onChange((prev) => ({
                ...prev,
                days: prev.days
                    .map((d) => ({ ...d, inheriting: false }))
                    .filter((d) => d.from || d.to),
            })),
        [onChange],
    );
    /**
     * set from callback
     */
    const setFrom = useCallback(
        (from: number) => onChange((prev) => ({ ...prev, from: +from })),
        [onChange],
    );
    /**
     * set to callback
     */
    const setTo = useCallback(
        (to: number) => onChange((prev) => ({ ...prev, to: +to })),
        [onChange],
    );
    /**
     * render
     */
    return (
        <View
            style={[
                {
                    borderWidth: 1,
                    borderColor: 'transparent',
                    borderRadius: 10,
                    zIndex: 1,
                },
            ]}
        >
            <TouchableView
                style={[
                    style.horizontal,
                    style.centeredItems,
                    { paddingVertical: 10 },
                ]}
                onPress={stopInheriting}
            >
                <CCheckBox checked={true} onCheckedChanged={stopInheriting} />
                <CText secondaryHeadline message={calendarMessages.everyDay} />
            </TouchableView>
            <View
                style={[
                    {
                        zIndex: 1,
                        borderWidth: 1,
                        borderColor: theme.borderColor,
                        borderRadius: 10,
                        backgroundColor: theme.backgroundSecondaryColor,
                        marginVertical: 10,
                    },
                ]}
            >
                <View
                    style={[
                        style.horizontalSplit,
                        style.horizontalHeavyPadded,
                        style.centeredItems,
                        { zIndex: 2 },
                    ]}
                >
                    <CText message={calendarMessages.from} />
                    <CTimePicker
                        value={fromDate}
                        onChange={(next) => setFrom(next.getHours())}
                        timeIntervals={60}
                    />
                </View>
                <View style={style.thinSeparator} />
                <View
                    style={[
                        style.horizontalSplit,
                        style.horizontalHeavyPadded,
                        style.centeredItems,
                        { zIndex: 1 },
                    ]}
                >
                    <CText message={calendarMessages.to} />
                    <CTimePicker
                        value={toDate}
                        onChange={(next) => setTo(next.getHours())}
                        timeIntervals={60}
                    />
                </View>
            </View>
        </View>
    );
};
