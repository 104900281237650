import {
    EDenominations,
    EEmploymentType,
    EField,
    ELanguage,
    ERegion,
    EUserType,
} from '../enums';
import { MAddress } from './MAddress';
import { MBankDetails } from './MBankDetails';
import { MProfessionalInfo } from './MProfessionalInfo';
/**
 * general user data
 */
export class MUserData extends MProfessionalInfo {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';
    /**
     * account type
     */
    public type: EUserType = EUserType.default;
    /**
     * title of account holder (for users, agents, moderators, admins)
     */
    // declare title?: EDoctorTitle;
    /**
     * firstname of account holder (for users, agents, moderators, admins)
     */
    public firstName = '';
    /**
     * lastname of account holder (for users, agents, moderators, admins)
     */
    public lastName = '';
    /**
     * name of account holder combined from last x first (for users, agents, moderators, admins)
     */
    public name = '';
    /**
     * mail of account
     */
    public mail = '';
    /**
     * gender of account holder
     */
    // declare gender?: EGender;
    /**
     * phone of account
     */
    public phone = '';
    /**
     * nationality of account
     */
    // declare nationality: keyof typeof countryMessages;
    /**
     * address of account
     */
    public address = new MAddress();
    /**
     * bolean to be set if billing address differs
     */
    public billingAddressDiffers = false;
    /**
     * billingAddress of account
     */
    public billingAddress = new MAddress();
    /**
     * bank details of account
     */
    public bankDetails = new MBankDetails();
    /**
     * tax number of account
     */
    public taxNumber = '';
    /**
     * register number of account
     */
    public registerNumber = '';
    /**
     * social security number of account
     */
    public socialSecurityNumber = '';

    public childAllowance = '';
    public denomination?: EDenominations = EDenominations.none;
    public healthInsurance = '';
    public fullTime = false;
    public partTime = false;
    public shortTime = false;
    public paymentInterval: 'weekly' | 'biWeekly' | 'monthly' = 'monthly';
    public agreeDataShare = false;
    public readAGB = false;
    public marketingMail = true;
    public agreeDataUsage = false;

    public extraAvailable = false;
    public extraMobile = false;

    public availableFrom = 0;

    public howDidYouFindOut = '';
    /**
     * document ids of assigned agents
     */
    public assignedAgents: string[] = [];

    public disabled = false;

    public verified = false;
    public verifiedOn = 0;
    public verifiedFrom = '';
    public verificationReminderCount = 0;

    public deleteIssuedOn = 0;
    public deletedOn?: number;

    public notifications = {
        unreadMessages: true,
        newJobs: true,
        marketingMail: true,
    };

    public emailVerifyLink = '';
    public emailVerifyIssuedOn = 0;

    public touched = 0;

    public region = ERegion.de;
    public preferredLanguage = ELanguage.de;
    /**
     * fields for user to have access to. important for agencies && workplaces
     */
    public fields: EField[] = [];

    public talentId?: string;

    public agencyId?: string;

    // public professionalInformations: MProfessionalInfo[] = [];

    constructor(params: Partial<MUserData> = {}) {
        super(params);
        // const cleanParams = params;
        Object.assign(this, {
            ...params,
            notifications: {
                ...this.notifications,
                ...(params.notifications || {}),
            },
        });

        MProfessionalInfo.fixConstructor(this, params);

        MUserData.fixConstructor(this, params);
    }
    /**
     * static method to fix shorcommings of object assign and patch compatibilies with incomplete paramsets
     * @param that object that just got object assigned
     * @param params params to apply fixes with
     */
    static fixConstructor = (that: any, params: Partial<MUserData>) => {
        if (params.bankDetails) {
            that.bankDetails = new MBankDetails(params.bankDetails);
        }

        if (params.address) {
            that.address = new MAddress(params.address);
        }
        if (params.billingAddress) {
            that.billingAddress = new MAddress(params.billingAddress);
        }
        if (
            params.fullTime &&
            !params.employmentType?.includes(EEmploymentType.fullTime)
        ) {
            that.employmentType.push(EEmploymentType.fullTime);
        }
        if (
            params.partTime &&
            !params.employmentType?.includes(EEmploymentType.partTime)
        ) {
            that.employmentType.push(EEmploymentType.partTime);
        }
        if (
            params.shortTime &&
            !params.employmentType?.includes(EEmploymentType.personnelLeasing)
        ) {
            that.employmentType.push(EEmploymentType.personnelLeasing);
        }
    };
}
