import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-native';
import { CText, Spinner } from '../../../../components';
import { ECollections } from '../../../../enums';
import { EApplicationType } from '../../../../enums/EApplicationType';
import { MContract } from '../../../../models';
import { isEmployer, isSuperUser } from '../../../../utilities/auth';
import { useDialog } from '../../../../utilities/dialog';
import { useFireBase } from '../../../../utilities/firebase';
import { week } from '../../../../utilities/functions';
import { actionMessages } from '../../../../utilities/messages';
import { ContractNegotiationContext } from './ContractNegotiationContext';
import {
    AdminNegotiation,
    AgencyNegotiation,
    UserNegotiation,
} from './components';
import { contractMessages } from '../../contract.messages';
import { useLock } from '../../../../utilities/hooks';
/**
 * Contract Negotiation View. WIll choose the right negotiation view on a per user type basis and provide context for contract data
 * @returns
 */
export const ContractNegotiation: FC = () => {
    // global state
    const { id } = useParams<{ id: string }>();
    const { lock } = useLock();
    const { watchDataById, userData, callFunction, userWorkplaces } =
        useFireBase();
    const dialog = useDialog();
    // local state
    const [negotiation, setNegotiation] = useState<MContract>();
    const [originalNegotiation, setOgNegotiation] = useState<MContract>();
    const [askedForTakeover, setAskedForTakeover] = useState(false);
    /**
     * on change callback to handle partial new contracct infos and fuse them with the contact infos
     */
    const onChange = useCallback((next: Partial<MContract>) => {
        setNegotiation((prev) => {
            return new MContract({ ...prev, ...next });
        });
    }, []);
    /**
     * callback to handle close of negotiation
     */
    const handleClose = useCallback(() => {
        if (!negotiation) {
            return;
        }
        let reason = '';
        dialog({
            title: contractMessages.youIssuedAClose,
            message: contractMessages.youIssuedACloseText,
            cancelButton: {
                text: actionMessages.cancel,
            },
            buttons: [
                {
                    text: actionMessages.ok,
                    onPress: (inputs) => {
                        reason =
                            inputs?.find((i) => i.id === 'reason')?.value || '';
                    },
                    disabled: (inputs) => {
                        const tip = inputs?.find((i) => i.id === 'reason');
                        if (!tip) {
                            return true;
                        } else {
                            return !tip.value.length;
                        }
                    },
                },
            ],
            textInputs: [
                {
                    id: 'reason',
                    title: contractMessages.negotiationCancellationReason,
                },
            ],
            icon: 'warning',
        }).then(async (ok) => {
            console.log(ok, 'yeet');
            if (ok) {
                const unlock = lock();
                await callFunction('cancelContract', {
                    contractId: negotiation.documentId,
                    cancelReason: reason,
                });
                unlock();
            }
        });
    }, [negotiation]);
    /**
     * effect to get negotiation
     */
    useEffect(() => {
        if (!id) {
            return;
        }
        return watchDataById(ECollections.contracts, id, (res) => {
            const next = new MContract(res);
            if (!next.from) {
                next.from = Date.now() + week;
            }
            if (!next.to) {
                next.to = next.from + week;
            }
            setNegotiation(next);
            setOgNegotiation(next);
        });
    }, [id, watchDataById]);
    /**
     * effect to take over negotiation if same workplace user
     */
    useEffect(() => {
        if (!negotiation || askedForTakeover) {
            return;
        }
        if (
            isEmployer(userData) &&
            negotiation.employerId !== userData.documentId &&
            userWorkplaces.find(
                (wp) => wp.documentId === negotiation.workplaceId,
            )
        ) {
            setAskedForTakeover(true);
            dialog({
                icon: 'question',
                title: contractMessages.takeOverNegotiation,
                message: contractMessages.takeOverNegotiationText,
                buttons: [
                    {
                        text: actionMessages.ok,
                        onPress: () => {
                            callFunction('takeOverContract', {
                                contractId: negotiation.documentId,
                            });
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            });
        }
    }, [negotiation, userData, userWorkplaces, askedForTakeover]);
    /**
     * return spinner in case of no data
     */
    if (!negotiation || !originalNegotiation) {
        return <Spinner />;
    }
    /**
     * return admin negotiation view for super users
     */
    if (isSuperUser(userData)) {
        return (
            <ContractNegotiationContext.Provider
                value={{
                    negotiation,
                    originalNegotiation,
                    onChange,
                    handleClose,
                }}
            >
                <AdminNegotiation />
            </ContractNegotiationContext.Provider>
        );
    }
    /**
     * return negotiation view for respsective user types
     */
    switch (negotiation.type) {
        case EApplicationType.user:
            return (
                <ContractNegotiationContext.Provider
                    value={{
                        negotiation,
                        originalNegotiation,
                        onChange,
                        handleClose,
                    }}
                >
                    <UserNegotiation />
                </ContractNegotiationContext.Provider>
            );
        case EApplicationType.agency:
            return (
                <ContractNegotiationContext.Provider
                    value={{
                        negotiation,
                        originalNegotiation,
                        onChange,
                        handleClose,
                    }}
                >
                    <AgencyNegotiation />
                </ContractNegotiationContext.Provider>
            );
        default:
            return <CText>ERROR</CText>;
    }
};
