import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CText,
    CTextInput,
    TouchableView,
} from '../../../components';
import { CDatePicker } from '../../../components';
import { ECollections, EUserType } from '../../../enums';
import { MAvailability } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../../../utilities/functions';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { calendarMessages } from '../Calendar.messages';
import { useSecureNavigate } from '../../../utilities/routing';
import { IEditAvailabilityProps } from './EditAvailability';
import { DayAvailability2 } from './DayAvailability.v2';
import { AllDayAvailability } from './AllDayAvailability';
/**
 * if availability edit was so good. why is there no availability edit 2
 */
export const EditAvailability2: FC<IEditAvailabilityProps> = ({
    close,
    prev,
    startDate,
    startHour,
    returnToProfile,
    selectedProfile,
}) => {
    // global state
    const style = useStyle();
    const dialog = useDialog();
    const { theme } = useTheme();
    const { width } = useDimensions();
    const { secureNavigate } = useSecureNavigate();
    const format = useFormat();
    const { post, put, userData, remove } = useFireBase();
    // local state
    const [currentDate, setCurrentDate] = useState(new Date());
    const [availability, setAvailability] = useState(
        new MAvailability({
            year: new Date().getFullYear(),
            start: getWeekNumber(new Date()),
        }),
    );
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from = convertWeekAndYearToDate(
            availability.year,
            availability.start,
        );
        const to = new Date(
            from.getTime() + (availability.repeatCount + 1) * week - day,
        );

        return { from, to };
    }, [availability]);
    /**
     * days translated
     */
    const days = useMemo(() => {
        return [
            format(generalMessages.monday),
            format(generalMessages.tuesday),
            format(generalMessages.wednesday),
            format(generalMessages.thursday),
            format(generalMessages.friday),
            format(generalMessages.saturday),
            format(generalMessages.sunday),
        ];
    }, []);
    /**
     * handle date change callback
     */
    const handleDateChange = useCallback((d: Date) => {
        const w = getWeekNumber(d);
        const y = d.getFullYear();
        setCurrentDate(d);
        setAvailability((prev) => {
            return { ...prev, year: y, start: w };
        });
    }, []);
    /**
     * callback to get repeat count from selected date
     */
    const handleToChange = useCallback((d: Date) => {
        setAvailability((prev) => {
            const from = convertWeekAndYearToDate(
                availability.year,
                availability.start,
            );
            const diff = d.getTime() - from.getTime();
            const repeatCount = Math.floor(diff / week);

            return { ...prev, repeatCount };
        });
    }, []);
    /**
     * callback to set everyday to inheriting
     */
    const setInheriting = useCallback(
        () =>
            setAvailability((prev) => ({
                ...prev,
                days: days.map((_, i) => {
                    const prevDay = prev.days.find((d) => d.day === i + 1);
                    return prevDay
                        ? { ...prevDay, inheriting: true }
                        : {
                              day: i + 1,
                              inheriting: true,
                              from: 0,
                              to: 0,
                          };
                }),
            })),
        [days],
    );
    /**
     * save callback
     */
    const handleSave = useCallback(async () => {
        const next = new MAvailability({
            ...availability,
            uid: userData.documentId,
            profileId: selectedProfile?.documentId,
            agencyId: selectedProfile?.agencyId,
            field: selectedProfile?.field,
            educations: selectedProfile?.educations,
            profession: selectedProfile?.profession,
            region: selectedProfile?.region,
        });
        if (userData.type === EUserType.user) {
            next.field = userData.field;
            next.educations = userData.educations;
            next.profession = userData.profession;
            next.region = userData.region;
        }
        if (next.documentId) {
            await put(
                ECollections.availabilities,
                availability.documentId,
                next,
            );
        } else {
            await post(ECollections.availabilities, next);
        }

        close();
    }, [userData, availability, selectedProfile]);
    /**
     * effect to init availability with prev
     */
    useEffect(() => {
        if (prev) {
            setAvailability(new MAvailability(prev));
            setCurrentDate(convertWeekAndYearToDate(prev.year, prev.start));
        } else if (startDate) {
            handleDateChange(new Date(startDate));
            setAvailability((prev) => {
                if (startHour !== undefined) {
                    prev.from = startHour;
                    prev.to = startHour + 1;
                }
                const preselectedDay = {
                    day: new Date(startDate).getDay(),
                    from: prev.from,
                    to: prev.to,
                    inheriting: false,
                };
                prev.days.push(preselectedDay);
                return new MAvailability({ ...prev });
            });
        }
    }, [prev, startDate, startHour, handleDateChange]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.card,
                {
                    maxWidth: width - 20,
                },
            ]}
        >
            <CText style={style.verticalPadded} bold headline>
                {availability.documentId
                    ? format(calendarMessages.editAvailability)
                    : format(calendarMessages.addAvailability)}
            </CText>
            {returnToProfile && (
                <View style={style.horizontal}>
                    <CButton
                        small
                        icon={'chevronLeft'}
                        title={format(calendarMessages.returnToProfile)}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                    />
                </View>
            )}
            <View style={[{ zIndex: 100 }]}>
                <View
                    style={[
                        style.horizontal,
                        style.horizontalWrap,
                        { zIndex: 100, alignItems: 'center' },
                    ]}
                >
                    <CText style={style.horizontalPadded}>
                        {format(calendarMessages.start)}
                    </CText>
                    <CDatePicker
                        value={currentDate}
                        minDate={new Date().getTime()}
                        onChange={handleDateChange}
                    />
                    <CTextInput
                        horizontal
                        label={format(calendarMessages.note)}
                        placeholder={format(calendarMessages.notePlaceholder)}
                        value={availability.note}
                        onChangeText={(t) => {
                            setAvailability((prev) => {
                                prev.note = t;
                                return { ...prev };
                            });
                        }}
                        autoExtend
                    />
                </View>
            </View>
            {availability.days.length === 7 &&
            !availability.days.find((d) => !d.inheriting) ? (
                <View style={{ zIndex: 1 }}>
                    <AllDayAvailability
                        availability={availability}
                        onChange={setAvailability}
                    />
                </View>
            ) : (
                <View style={{ zIndex: 1 }}>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { paddingVertical: 10 },
                        ]}
                        onPress={setInheriting}
                    >
                        <CCheckBox
                            checked={false}
                            onCheckedChanged={setInheriting}
                        />
                        <CText
                            secondaryHeadline
                            message={calendarMessages.everyDay}
                        />
                    </TouchableView>
                    {days.map((day, i) => (
                        <View style={{ zIndex: days.length + 1 - i }} key={day}>
                            <DayAvailability2
                                currentDate={currentDate}
                                day={day}
                                dayNumber={i + 1}
                                availability={availability}
                                onChange={setAvailability}
                                startHour={startHour}
                            />
                        </View>
                    ))}
                </View>
            )}
            <View
                style={[
                    style.horizontalSplit,
                    style.verticalHeavyPadded,
                    style.centeredItems,
                ]}
            >
                <View />
                <View style={{ alignItems: 'flex-end' }}>
                    <CCheckBox
                        checked={availability.repeat}
                        title={format(calendarMessages.repeatWeekly)}
                        onCheckedChanged={(repeat) =>
                            setAvailability((prev) => {
                                return { ...prev, repeat };
                            })
                        }
                    />
                    {availability.repeat && (
                        <>
                            <CDatePicker
                                title={calendarMessages.fromWeek}
                                value={from}
                                minDate={new Date().getTime()}
                                onChange={handleDateChange}
                                disabled={!availability.repeat}
                            />
                            <CDatePicker
                                title={calendarMessages.toWeek}
                                value={to}
                                minDate={from.getTime()}
                                onChange={handleToChange}
                                disabled={!availability.repeat}
                            />
                        </>
                    )}
                    {/*
                    <CTextInput
                        disabled={!availability.repeat}
                        horizontal
                        label={format(calendarMessages.repeatCounts)}
                        value={`${availability.repeatCount}`}
                        onChangeText={(t) => {
                            const n = Number(t);
                            if (!isNaN(n) && n < 1000) {
                                setAvailability((prev) => {
                                    return {
                                        ...prev,
                                        repeatCount: n,
                                    };
                                });
                            }
                        }}
                        length={3}
                    />
                    */}
                </View>
            </View>
            <View
                style={[
                    style.horizontalSplit,
                    style.horizontalWrap,
                    style.verticalHeavyPadded,
                ]}
            >
                <CButton
                    title={format(actionMessages.cancel)}
                    onPress={close}
                    warning
                />
                {prev && (
                    <CButton
                        title={format(actionMessages.delete)}
                        onPress={() => {
                            dialog({
                                title: format(calendarMessages.confirmDelete),
                                message: format(
                                    calendarMessages.confirmDeleteText,
                                ),
                                buttons: [
                                    {
                                        text: format(actionMessages.delete),
                                        color: theme.errorColor,
                                    },
                                ],
                                cancelButton: {
                                    text: format(actionMessages.cancel),
                                },
                            }).then((confirmed) => {
                                if (confirmed) {
                                    remove(
                                        ECollections.availabilities,
                                        availability.documentId,
                                    ).then(() => {
                                        close();
                                    });
                                }
                            });
                        }}
                        danger
                    />
                )}
                <CButton
                    title={format(actionMessages.save)}
                    onPress={handleSave}
                    style={{ backgroundColor: theme.successColor }}
                />
            </View>
        </View>
    );
};
