import { defineMessages } from 'react-intl';

const scope = 'employmentType.';
/**
 * employment types
 * is used for employment types picker
 */
export const employmentTypeMessages = defineMessages({
    fullTime: { id: scope + 'fullTime', defaultMessage: 'Full-time' },
    partTime: { id: scope + 'partTime', defaultMessage: 'Part-time' },
    selfEmployed: {
        id: scope + 'selfEmployed',
        defaultMessage: 'Self-employed',
    },
    freelance: { id: scope + 'freelance', defaultMessage: 'Freelance' },
    contract: {
        id: scope + 'contract',
        defaultMessage: 'Contract',
    },
    personnelLeasing: {
        id: scope + 'personnelLeasing',
        defaultMessage: 'Personnel leasing',
    },
    internship: {
        id: scope + 'internship',
        defaultMessage: 'Internship',
    },
    apprenticeship: {
        id: scope + 'apprenticeship',
        defaultMessage: 'Apprenticeship',
    },
    civilServiceInternship: {
        id: scope + 'civilServiceInternship',
        defaultMessage: 'Civil service internship',
    },
    lifetimeCivilServant: {
        id: scope + 'lifetimeCivilServant',
        defaultMessage: 'Lifetime civil servant',
    },
    coOp: {
        id: scope + 'coOp',
        defaultMessage: 'Co-Op',
    },
    workStudy: {
        id: scope + 'workStudy',
        defaultMessage: 'Work study',
    },
});
