export class MFromTo {
    public from = 0;
    public fromHour = 0;
    public fromMinute = 0;
    public to = 0;
    public toHour = 0;
    public toMinute = 0;

    constructor(params: Partial<MFromTo> = {}) {
        Object.assign(this, params);
    }
}

export class MShift extends MFromTo {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';

    public timesheetId = '';

    public date = '';
    public month = '';
    public year = '';
    public complete = false;
    public breaks: MFromTo[] = [];

    public geolocation?: {
        latitude: number;
        longitude: number;
    };

    constructor(params: Partial<MShift> = {}) {
        super(params);
        Object.assign(this, params);

        if (params.breaks) {
            this.breaks = params.breaks.map((b) => new MFromTo(b));
        }
    }
}

export class MTimesheet {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';

    public complete = false;

    public contractId = '';
    public workplaceId = '';
    public employeeId = '';
    public agencyId?: string;

    public isAgency = false;

    constructor(params: Partial<MTimesheet> = {}) {
        Object.assign(this, params);
    }
}
