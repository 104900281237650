import React, { FC } from 'react';
import { CButton, CText, TouchableView } from '../../../components';
import { MSignaturePosition } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';

export const PositionRow: FC<{
    onPress: () => void;
    onRemove: () => void;
    position: MSignaturePosition;
    active: boolean;
}> = ({ onPress, position, active, onRemove }) => {
    const style = useStyle();
    const { theme } = useTheme();
    return (
        <TouchableView
            onPress={onPress}
            style={[
                style.card,
                { borderWidth: 1, borderColor: theme.backgroundMainColor },
                active && style.accentBorder,
                style.horizontalSplit,
                style.centeredItems,
            ]}
        >
            <View>
                <CText>x: {position.x}</CText>
                <CText>y: {position.y}</CText>
                <CText>p: {position.page}</CText>
            </View>
            <CButton
                icon="minus"
                onPress={onRemove}
                transparent
                minor
                small
                iconColor={theme.errorColor}
            />
        </TouchableView>
    );
};
