import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Linking, Pressable, View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CIcon,
    CText,
    Radio,
} from '../../../../../components';
import {
    EApplicationType,
    ECollections,
    EContractStatus,
    ERegion,
    EUserType,
} from '../../../../../enums';
import { useFormat } from '../../../../../utilities/intl';
import { actionMessages } from '../../../../../utilities/messages';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import { contractMessages } from '../../../contract.messages';
import { ContractNegotiationContext } from '../ContractNegotiationContext';
import { sortObjectKeys } from '../../../../../utilities/functions/sortObjectKeys';
import { ContractValueCard } from './ContractValueCard';
import { useFireBase } from '../../../../../utilities/firebase';
import { MNegotiationHistory, MUserData } from '../../../../../models';
import { NegotiationHistory } from './NegotiationHistory';
import { useDialog } from '../../../../../utilities/dialog';
import { isPeasant } from '../../../../../utilities/auth';
/**
 * review and accept or decline the contract as user / agemcy
 * @param param0 props
 * @returns
 */
export const ReviewContract: FC = () => {
    // global context
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const dialog = useDialog();
    const { callFunction, userData, getDataById, getDataIndex } = useFireBase();
    // context
    const { negotiation, originalNegotiation, handleClose, onChange } =
        useContext(ContractNegotiationContext);
    // local state
    const [extConsentType, setExtConsent] = useState(0);
    const [confirmContract, setConfirmContract] = useState(false);
    const [edit, setEdit] = useState(false);
    const [changeAuthor, setChangeAuthor] = useState<MUserData>();
    const [openHistory, setOpenHistory] = useState(false);
    const [prevStep, setPrevStep] = useState<MNegotiationHistory>();
    const [readPrevStep, setReadPrevStep] = useState<MNegotiationHistory>();
    const [acceptLeasingConditions, setAcceptLeasingConditions] =
        useState(false);
    /**
     * memoized if changed happened
     */
    const changedContract = useMemo(
        () =>
            JSON.stringify(originalNegotiation) !== JSON.stringify(negotiation),
        [originalNegotiation, negotiation],
    );
    /**
     * not original memo
     */
    const isNotOriginal = useMemo(() => {
        const og = JSON.stringify(
            sortObjectKeys({
                ...originalNegotiation.originalParameters,
                wage: sortObjectKeys(
                    originalNegotiation.originalParameters.wage,
                ),
            }),
        );
        const cur = JSON.stringify(
            sortObjectKeys({
                from: originalNegotiation.from,
                to: originalNegotiation.to,
                wage: sortObjectKeys(originalNegotiation.wage),
            }),
        );
        return og !== cur;
    }, [originalNegotiation]);
    /**
     * callback to issue accept
     */
    const acceptContract = useCallback(async () => {
        await callFunction('acceptContract', {
            contractId: negotiation.documentId,
        });
        if (userData.type === EUserType.employer) {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOfferWorkplace,
                message: contractMessages.acceptedOfferWorkplaceText,
            });
        }
        if (negotiation.status === EContractStatus.review_yer) {
            return;
        }
        if (userData.type === EUserType.agency) {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOfferAgency,
                message: contractMessages.acceptedOfferAgencyText,
            });
        } else {
            await dialog({
                icon: 'success',
                title: contractMessages.acceptedOffer,
                message: contractMessages.acceptedOfferText,
            });
        }
    }, [negotiation, userData]);
    /**
     * callback to issue a change
     */
    const changeContract = useCallback(async () => {
        await callFunction('changeContract', {
            contractId: negotiation.documentId,
            ...negotiation.wage,
            from: negotiation.from,
            to: negotiation.to,
        });
        await dialog({
            icon: 'info',
            title: contractMessages.changedOffer,
            message: contractMessages.changedOfferText,
        });
    }, [negotiation]);
    /**
     * effect to load previous step
     */
    useEffect(() => {
        getDataIndex(
            `${ECollections.contracts}/${originalNegotiation.documentId}/${ECollections.negotiationHistory}`,
            { orderBy: 'createdOn', limit: 2 },
        ).then((res) => {
            const next = (res as any[]).map((v) => new MNegotiationHistory(v));
            if (next.length === 2) {
                setPrevStep(next[1]);
            } else if (next.length === 1) {
                setPrevStep(
                    new MNegotiationHistory(
                        originalNegotiation.originalParameters,
                    ),
                );
            }
        });
    }, [originalNegotiation]);
    /**
     * effect to load author name
     */
    useEffect(() => {
        if (
            negotiation.changedBy &&
            negotiation.changedBy !== changeAuthor?.documentId
        ) {
            getDataById(ECollections.publicUsers, negotiation.changedBy).then(
                (v) => {
                    setChangeAuthor(new MUserData(v));
                },
            );
        }
    }, [negotiation]);
    /**
     * effect to load author name
     */
    useEffect(() => {
        if (
            prevStep &&
            (originalNegotiation.from !== prevStep.from ||
                originalNegotiation.to !== prevStep.to) &&
            (!readPrevStep || prevStep.documentId !== readPrevStep?.documentId)
        ) {
            setReadPrevStep(prevStep);
            if (isPeasant(userData)) {
                dialog({
                    title: contractMessages.timeChanged,
                    message: contractMessages.timeChangedText,
                    buttons: [{ text: actionMessages.ok }],
                    icon: 'warning',
                });
            }
        }
    }, [originalNegotiation, prevStep, readPrevStep]);
    /**
     * render
     */
    return (
        <>
            <View style={[style.card, { zIndex: 1 }]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CText headline style={style.verticalPadded}>
                        {format(contractMessages.negotiationReview)}
                    </CText>
                    <Pressable
                        onPress={() => {
                            setOpenHistory(!openHistory);
                        }}
                        style={style.horizontalPadded}
                    >
                        <CIcon icon="history" tint={theme.borderColor} />
                    </Pressable>
                </View>
                {openHistory && <NegotiationHistory contract={negotiation} />}
                <View
                    style={[
                        style.horizontalWrap,
                        style.centeredItems,
                        { zIndex: 1, alignItems: 'center' },
                    ]}
                >
                    <View style={{ flexGrow: 1 }}>
                        <CText
                            secondaryHeadline
                            message={contractMessages.originalValues}
                        />
                        <ContractValueCard
                            contract={originalNegotiation}
                            original
                        />
                    </View>
                    {isNotOriginal && (
                        <>
                            <CIcon
                                icon={'chevronRight'}
                                style={style.horizontalPadded}
                                size={40}
                                tint={
                                    changedContract
                                        ? theme.accentColor
                                        : theme.warningColor
                                }
                            />
                            <View
                                style={[
                                    { flexGrow: 1 },
                                    !edit && {
                                        borderColor: theme.accentColor,
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        paddingHorizontal: 5,
                                    },
                                ]}
                            >
                                <CText
                                    secondaryHeadline
                                    message={contractMessages.currentValues}
                                />
                                <ContractValueCard
                                    prev={prevStep}
                                    contract={originalNegotiation}
                                    author={changeAuthor}
                                />
                            </View>
                        </>
                    )}
                    {edit && (
                        <CIcon
                            icon={'chevronRight'}
                            style={style.horizontalPadded}
                            size={40}
                            tint={theme.successColor}
                        />
                    )}
                </View>
                {!edit && !confirmContract && (
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CButton
                            cy={'editTerms'}
                            onPress={() => setEdit(true)}
                            title={contractMessages.editTerms}
                            warning
                        />
                        <CButton
                            cy={'confirmTerms'}
                            onPress={() => setConfirmContract(true)}
                            title={format(contractMessages.confirmTerms)}
                            style={{
                                backgroundColor: theme.successColor,
                            }}
                        />
                    </View>
                )}
                {edit && (
                    <>
                        <View
                            style={{
                                borderColor: theme.accentColor,
                                borderWidth: 1,
                                borderRadius: 5,
                                paddingHorizontal: 5,
                                flexGrow: 1,
                            }}
                        >
                            <CText
                                secondaryHeadline
                                message={contractMessages.newValues}
                            />
                            <ContractValueCard
                                contract={negotiation}
                                edit
                                onChange={onChange}
                            />
                        </View>
                    </>
                )}
            </View>
            {confirmContract && (
                <View style={style.card}>
                    <CCheckBox
                        cy={'confirmTerms'}
                        checked={confirmContract}
                        onCheckedChanged={setConfirmContract}
                        title={format(
                            contractMessages.confirmTermsCheckBoxText,
                        )}
                    />
                </View>
            )}
            {confirmContract &&
                negotiation.type === EApplicationType.agency &&
                userData.type === EUserType.agency && (
                    <View style={style.card}>
                        <View style={[style.horizontal, style.verticalPadded]}>
                            <CButton
                                title={contractMessages.openTarif}
                                onPress={() =>
                                    Linking.openURL(
                                        'https://timeployees.de/gebuehrenordnung',
                                    )
                                }
                                minor
                            />
                        </View>
                        <CCheckBox
                            cy="agencyConditions"
                            checked={acceptLeasingConditions}
                            onCheckedChanged={setAcceptLeasingConditions}
                            title={contractMessages.confirmAgencyConditions}
                        />
                    </View>
                )}
            {confirmContract &&
                !changedContract &&
                negotiation.region === ERegion.de &&
                negotiation.type === EApplicationType.user &&
                userData.type === EUserType.user && (
                    <View style={style.card}>
                        <View style={style.verticalPadded}>
                            <CText bold>
                                {format(contractMessages.extConsentTitle)}
                            </CText>
                            <Radio
                                values={[
                                    {
                                        label: format(
                                            contractMessages.extConsent0,
                                        ),
                                        value: 0,
                                    },
                                    {
                                        label: format(
                                            contractMessages.extConsent1,
                                        ),
                                        value: 1,
                                    },
                                    {
                                        label: format(
                                            contractMessages.extConsent2,
                                        ),
                                        value: 2,
                                    },
                                ]}
                                value={extConsentType}
                                onChange={setExtConsent}
                            />
                        </View>
                    </View>
                )}
            {!edit && (
                <View style={[style.card, style.horizontalSpaced]}>
                    <CButton
                        cy={'cancel'}
                        title={actionMessages.close}
                        onPress={handleClose}
                        danger
                    />
                    <CButton
                        disabled={
                            changedContract ||
                            !confirmContract ||
                            (userData.type === EUserType.agency &&
                                !acceptLeasingConditions)
                        }
                        cy={'continue'}
                        title={format(actionMessages.continue)}
                        onPress={acceptContract}
                        disableOnClick
                    />
                </View>
            )}
            {edit && (
                <View style={[style.card, style.horizontalSpaced]}>
                    <CButton
                        cy={'cancel'}
                        title={actionMessages.cancel}
                        onPress={() => setEdit(false)}
                        warning
                    />
                    <CButton
                        cy={'confirmTerms'}
                        disabled={changedContract}
                        onPress={() => {
                            setConfirmContract(true);
                            setEdit(false);
                        }}
                        title={format(contractMessages.confirmTerms)}
                        style={{
                            backgroundColor: theme.successColor,
                        }}
                    />
                    <CButton
                        disabled={!changedContract}
                        cy={'offerChanges'}
                        title={format(contractMessages.counteroffer)}
                        onPress={async () => {
                            onChange(negotiation);
                            await changeContract();
                        }}
                        disableOnClick
                    />
                </View>
            )}
        </>
    );
};
