import React, { FC, useCallback, useContext, useEffect } from 'react';
import { View } from 'react-native';
import { CDatePicker, CPicker, CText } from '../../../../components';
import {
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
    SpecializationPicker,
} from '../../../../components/Pickers';
import { PositionPicker } from '../../../../components/Pickers/PositionPicker';
import { EField, EProfession } from '../../../../enums';
import { MProfessionalInfo } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { useFormat } from '../../../../utilities/intl';
import { useStyle } from '../../../../utilities/styles';
import { ProfileContext } from '../../../Profile/Profile.context';
import { profileMessages } from '../../../Profile/Profile.messages';
import { profProfileMessages } from '../../ProfProfile.messages';

export const ProfessionalInformation: FC = () => {
    const { userData } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    /**
     * validityFunctions
     */
    const noEducation = useCallback((data: MProfessionalInfo) => {
        if (!data.educations.length) {
            return format(profileMessages.noEducation);
        }
    }, []);
    /**
     * effect to comunicate validity to context
     */
    useEffect(() => {
        const validity = noEducation(curData) || '';
        onValidityChange(
            validity ? format(profileMessages.noEducation) : '',
            'professional',
        );
    }, [curData, noEducation]);

    return (
        <>
            {userData.documentId !== curData.documentId && (
                <View style={style.verticalPadded}>
                    <FieldPicker
                        value={curData.field}
                        onChange={(field) =>
                            onChange({
                                field,
                                profession: undefined,
                                position: undefined,
                                educations: [],
                                specializations: [],
                            })
                        }
                    />
                </View>
            )}
            {userData.documentId !== curData.documentId &&
                curData.field &&
                curData.field !== EField.undefined && (
                    <View style={style.verticalPadded}>
                        <ProfessionPicker
                            field={curData.field}
                            value={curData.profession}
                            onChange={(profession) =>
                                onChange({
                                    profession,
                                    position: undefined,
                                    educations: [],
                                    specializations: [],
                                })
                            }
                        />
                    </View>
                )}
            {curData.profession &&
                (curData.profession !== EProfession.undefined ||
                    curData.field === EField.general) && (
                    <View style={style.verticalPadded}>
                        <PositionPicker
                            profession={curData.profession}
                            value={curData.position}
                            onChange={(position) => onChange({ position })}
                        />
                    </View>
                )}
            {/* Input for estimated promotion for self */}
            {['assistantphysician', 'physicianInApprobation'].includes(
                curData.position,
            ) && (
                <View style={{ zIndex: 100 }}>
                    <CDatePicker
                        value={
                            curData.estPromotion
                                ? new Date(curData.estPromotion)
                                : undefined
                        }
                        title={format(profProfileMessages.estPromotion)}
                        onChange={(nextDate) => {
                            onChange({ estPromotion: nextDate.getTime() });
                        }}
                    />
                </View>
            )}
            {curData.profession &&
                (curData.profession !== EProfession.undefined ||
                    curData.field === EField.general) && (
                    <EducationPicker
                        field={curData.field}
                        profession={curData.profession}
                        values={curData.educations}
                        multi
                        onChange={(next) =>
                            onChange({ educations: next as string[] })
                        }
                        ignoreMultiLimit={curData.field === EField.general}
                    />
                )}
            {curData.profession &&
                (curData.profession !== EProfession.undefined ||
                    curData.field === EField.general) && (
                    <CPicker
                        title={format(profileMessages.yoe)}
                        value={curData.yearsOfExperience}
                        values={[
                            {
                                label: `-${format(
                                    profileMessages.yoePlaceholder,
                                )}-`,
                                value: undefined,
                                hidden: true,
                            },
                            ...[...Array(60).keys()].map((v) => {
                                return {
                                    label: `${v}`,
                                    value: v,
                                };
                            }),
                        ]}
                        onChange={(yearsOfExperience) =>
                            onChange({ yearsOfExperience })
                        }
                    />
                )}
            {curData.profession &&
                (curData.profession !== EProfession.undefined ||
                    curData.field === EField.general) && (
                    <SpecializationPicker
                        multi
                        profession={curData.profession}
                        values={curData.specializations}
                        onChange={(next) =>
                            onChange({ specializations: next as string[] })
                        }
                    />
                )}
            {/* <View style={style.verticalPadded}>
                <CCheckBox
                    checked={curData.noCrossGenderWork}
                    onCheckedChanged={(noCrossGenderWork) =>
                        onChange({ noCrossGenderWork })
                    }
                    title={format(profileMessages.noCrossGenderWork)}
                />
            </View> */}
            {noEducation(curData) && (
                <CText style={style.error}>{noEducation(curData)}</CText>
            )}
        </>
    );
};
