import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from '../../../utilities/routing';
import { CButton, CText, Spinner } from '../../../components';
import { MWorkplace } from '../../../models';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle } from '../../../utilities/styles';
import { WorkplaceRow } from './components/WorkplaceRow';
import { ECollections } from '../../../enums';
import {
    EWorkplaceFilterModes,
    WorkplaceFilterBar,
} from './components/WorkplaceFilterBar';
import { IFilter } from '../../../utilities/firebase/store';
import { actionMessages } from '../../../utilities/messages';
import { ScrollProvider } from '../../../utilities/contexts/Scroll';

export const WorkplaceList: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { getDataIndex, userData } = useFireBase();
    const [workplaces, setWorkplaces] = useState<MWorkplace[]>([]);
    const [workplacesLength, setWorkplacesLength] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [endReached, setEndReached] = useState(false);

    const [mode, setMode] = useState<EWorkplaceFilterModes[]>([
        EWorkplaceFilterModes.all,
    ]);
    const [customStringFilter, setCustomStringFilter] = useState<{
        field: string;
        value: string;
    }>();
    /**
     * callback to add a filtermode
     */
    const addMode = useCallback((nextMode: EWorkplaceFilterModes) => {
        setMode((prev) => {
            const next = Array.from(prev);
            const prevIndex = next.indexOf(nextMode);
            if (prev.length === 1 && next[0] === EWorkplaceFilterModes.all) {
                return [nextMode];
            } else if (next.length === 1 && prevIndex >= 0) {
                return [EWorkplaceFilterModes.all];
            } else if (prevIndex >= 0) {
                next.splice(prevIndex, 1);
                return next;
            } else if (
                [
                    EWorkplaceFilterModes.verified,
                    EWorkplaceFilterModes.unverified,
                ].includes(nextMode)
            ) {
                const index1 = next.findIndex(
                    (v) => v === EWorkplaceFilterModes.verified,
                );
                const index2 = next.findIndex(
                    (v) => v === EWorkplaceFilterModes.unverified,
                );
                if (index1 >= 0) {
                    next.splice(index1, 1);
                }
                if (index2 >= 0) {
                    next.splice(index2, 1);
                }
                next.push(nextMode);
                return next;
            } else {
                next.push(nextMode);
                return next;
            }
        });
    }, []);

    const loadMore = useCallback(
        async (offset?: MWorkplace) => {
            const filter: IFilter[] = [];
            const partialMatch: { field: string; value: string } | undefined =
                undefined;
            const params: any = {
                filter,
                limit: 10,
                partialMatch,
            };
            if (!isSuperUser(userData)) {
                filter.push({
                    field: 'users',
                    operator: 'array-contains',
                    value: userData.documentId,
                });
            }
            if (mode.includes(EWorkplaceFilterModes.verified)) {
                params.orderBy = 'owner';
            }
            if (
                customStringFilter &&
                customStringFilter.field &&
                customStringFilter.value
            ) {
                const field =
                    customStringFilter.field === 'zipCode'
                        ? 'address.zipCode'
                        : customStringFilter.field;
                params.partialMatch = {
                    field,
                    value: customStringFilter.value,
                };
            }
            if (offset) {
                params.startDocumentId = offset.documentId;
            } else {
                getDataIndex(ECollections.workplaces, {
                    ...params,
                    getLength: true,
                }).then((res) => {
                    setWorkplacesLength(res as number);
                });
            }
            getDataIndex(ECollections.workplaces, params).then((result) => {
                const next = (result as MWorkplace[]).map((r) => {
                    if (r.address) {
                        return new MWorkplace({
                            ...r,
                            address: {
                                ...r.address,
                                zipCode: `${r.address.zipCode}`,
                            },
                        });
                    } else {
                        return new MWorkplace(r);
                    }
                });
                if (!offset) {
                    setWorkplaces(next);
                    setLoading(false);
                } else {
                    setWorkplaces((current) => {
                        return [...current, ...next];
                    });
                    setLoadingMore(false);
                }
            });
        },
        [mode, customStringFilter],
    );
    /**
     * effect to trigger reload
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card]}>
                <View style={style.horizontalSplit}>
                    <CText bold headline>
                        {format(generalMessages.workplaces)}
                    </CText>
                    {isSuperUser(userData) && (
                        <CButton
                            cy={'add-workplace'}
                            onPress={() => {
                                navigate('/workplace/edit/new');
                            }}
                            icon={'plus'}
                        />
                    )}
                </View>
                {isSuperUser(userData) && (
                    <WorkplaceFilterBar
                        mode={mode}
                        onSet={(p) => {
                            setMode(p.mode);
                        }}
                        addMode={addMode}
                        setCustomStringFilter={(next) => {
                            setCustomStringFilter(next);
                        }}
                        customStringFilter={customStringFilter}
                    />
                )}
            </View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {workplaces.map((workplace) => (
                        <WorkplaceRow
                            key={workplace.documentId}
                            workplace={workplace}
                        />
                    ))}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!workplaces.length &&
                        workplaces.length % 10 === 0 &&
                        !endReached && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(
                                            workplaces[workplaces.length - 1],
                                        );
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                </>
            )}
        </ScrollProvider>
    );
};
