import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { MApplication } from '../../../models/MApplication';
import { CText, Spinner, CButton } from '../../../components';
import { ApplicationPicture } from '../../../components/ProfilePicture/ApplicationPicture';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import {
    educationMessages,
    specializationMessages,
    positionMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/Job.messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { EEnvironment } from '../../../enums';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * overview of applicant connected to contract
 * @param param0
 * @returns
 */
export const ApplicantOverviewBar: FC<{
    application: MApplication;
}> = ({ application }) => {
    // global state
    const { getFile, callFunction } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    const { environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    // local state
    const [resumeId, setResumeId] = useState('');
    const [resume, setResume] = useState<string>('');
    /**
     * effect to get resume with precatch to lessen the amount of calls
     */
    useEffect(() => {
        if (!application || resumeId === application?.documentId) return;
        setResumeId(application.documentId);
        callFunction('getResume', {
            applicationId: application.documentId,
            timeployees: environment === EEnvironment.TimePloyees,
            lang: language,
        }).then((fn) => setResume(fn));
    }, [application, resumeId, language, environment]);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontal]}>
            <View style={[style.flex1, style.horizontalWrap]}>
                <View style={style.horizontalPadded}>
                    <ApplicationPicture data={application} />
                </View>
                <View style={[style.horizontalPadded]}>
                    <CText message={jobMessages.educations} />
                    <CText
                        headline
                        message={application.educations
                            .map((e) =>
                                format(
                                    educationMessages[
                                        e as keyof typeof educationMessages
                                    ],
                                ),
                            )
                            .join(',\n')}
                    />
                </View>
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={jobMessages.specializations} />
                    <CText
                        headline
                        message={application.specializations
                            .map((spec) =>
                                format(
                                    specializationMessages[
                                        spec as keyof typeof specializationMessages
                                    ],
                                ),
                            )
                            .join(',\n')}
                    />
                </View>
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={jobMessages.employeePosition} />
                    <CText
                        headline
                        message={
                            positionMessages[
                                application.position as keyof typeof positionMessages
                            ]
                        }
                    />
                </View>
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={profileMessages.yoe} />
                    <CText
                        headline
                        message={`${application.yearsOfExperience} ${format(
                            application.yearsOfExperience !== 1
                                ? generalMessages.years
                                : generalMessages.year,
                        )}`}
                    />
                </View>
            </View>
            {!resume ? (
                <View>
                    <Spinner small />
                </View>
            ) : (
                <CButton
                    onPress={() => {
                        getFile(resume).then((res) => {
                            const blob = new Blob(
                                // @ts-ignore
                                [res],
                                {
                                    type: 'application/pdf',
                                },
                            );
                            // @ts-ignore
                            window.open(URL.createObjectURL(blob));
                        });
                    }}
                    icon="download"
                />
            )}
        </View>
    );
};
