import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Linking, View } from 'react-native';
import { useLocation } from '../../../utilities/routing';
import {
    CButton,
    CLabel,
    CPicker,
    CText,
    CTextInput,
} from '../../../components';
import { ExpandableCard } from '../../../components/ExpandableCard';
import { RichTextEditor } from '../../../components/RichText';
import { RichTextView } from '../../../components/RichText';
import { MHelp } from '../../../models/MHelp';
import { isSuperUser } from '../../../utilities/auth';
import { useFireBase } from '../../../utilities/firebase';
import { LanguageContext } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { HelpContext } from '../HelpCenter';
import { useNavigate } from '../../../utilities/routing';
import { helpCenterMessages } from '../helpCenter.messages';
import { useFormat } from '../../../utilities/intl';
import { actionMessages, generalMessages } from '../../../utilities/messages';
import { ECollections, EUserType } from '../../../enums';
import { ScrollContext } from '../../../utilities/contexts/Scroll';
import { getUserTypeColor } from '../../../utilities/constants';

interface IHelpRowProps {
    item: MHelp;
    sub?: boolean;
}

export const HelpRow: FC<IHelpRowProps> = ({ item, sub }) => {
    // global state
    const { language } = useContext(LanguageContext);
    const { userData, put, post, remove } = useFireBase();
    const { theme } = useTheme();
    const style = useStyle();
    const format = useFormat();
    const location = useLocation();
    const navigate = useNavigate();
    const { y, setY, maxScrollY } = useContext(ScrollContext);
    // local state
    const [help, setHelp] = useState(item);
    const [edit, setEdit] = useState(false);
    const [myY, setMyY] = useState(0);
    const { helps, reload } = useContext(HelpContext);
    const [didCall, setDidCall] = useState(false);
    /**
     * memoized helps that have same parent
     */
    const sisterHelps = useMemo(() => {
        return helps.filter((h) => h.parent === help.parent);
    }, [helps, help]);
    /**
     * know index in list (compared to sisters)
     */
    const myIndex = useMemo(
        () => sisterHelps.findIndex((h) => h.documentId === help.documentId),
        [sisterHelps, help],
    );
    /**
     * memoized children
     */
    const childrenHelps = useMemo(() => {
        return helps.filter((h) => h.parent === item.documentId);
    }, [helps]);
    /**
     * on change callback
     */
    const onChange = useCallback((next: Partial<MHelp>) => {
        setHelp((prev) => {
            return { ...prev, ...next };
        });
    }, []);
    /**
     * function to move
     * @param m mode
     */
    const move = (m: 'up' | 'down') => {
        const i = sisterHelps.findIndex(
            (h) => h.documentId === help.documentId,
        );
        let nextOrder = 0;
        if (m === 'down') {
            if (i > sisterHelps.length - 2) {
            } else {
                const pre = sisterHelps[i + 1];
                const post = sisterHelps[i + 2];
                if (post) {
                    nextOrder = (post.order + pre.order) / 2;
                } else {
                    nextOrder = pre.order - 1;
                }
            }
        } else {
            if (i > 1) {
                const post = sisterHelps[i - 1];
                const pre = sisterHelps[i - 2];
                nextOrder = (pre.order + post.order) / 2;
            } else {
                const post = sisterHelps[i - 1];
                nextOrder = post.order + 1;
            }
        }
        put(ECollections.helps, help.documentId, {
            ...help,
            order: nextOrder,
        }).then(reload);
    };

    useEffect(() => {
        setDidCall(false);
    }, [location, maxScrollY]);
    /**
     * effect to apply scroll value
     *
     * TODO: manage to avoid blockedByDidCall
     */
    useEffect(() => {
        if (didCall) {
            // console.log('blockedByDidCall');

            return;
        }
        const lSplit = location.pathname.split('/');
        const dest = lSplit[lSplit.length - 1];

        if (dest === item.documentId) {
            const safeY = y > maxScrollY ? maxScrollY : y;
            const nextY = myY + (safeY > 0 ? safeY : 0);
            // console.log(
            //     'my:',
            //     myY,
            //     'safe:',
            //     safeY,
            //     'combined:',
            //     nextY,
            //     'cur:',
            //     y,
            // );
            if (nextY !== y) {
                setDidCall(true);
                setY(nextY);
            }
        }
    }, [maxScrollY, location, didCall]);
    /**
     * render
     */
    return (
        <>
            <ExpandableCard
                onRef={(view) => {
                    if (!view) return;
                    view.measureInWindow((x, measuredY) => {
                        if (myY !== measuredY) {
                            setMyY(measuredY);
                        }
                    });
                }}
                style={sub && [style.verticalPadded]}
                head={
                    edit ? (
                        <>
                            <View style={{ flex: 1 }}>
                                <CTextInput
                                    value={help.title}
                                    onChangeText={(title) =>
                                        onChange({ title })
                                    }
                                    autoExtend
                                />
                            </View>
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CButton
                                    icon={'minus'}
                                    fontStyles={{ color: theme.errorColor }}
                                    iconColor={theme.errorColor}
                                    onPress={() => {
                                        remove(
                                            ECollections.helps,
                                            help.documentId,
                                        ).then(reload);
                                    }}
                                    transparent
                                    small
                                />
                                <CButton
                                    icon={'check'}
                                    onPress={() => {
                                        setEdit(false);
                                        put(
                                            ECollections.helps,
                                            help.documentId,
                                            help,
                                        );
                                    }}
                                    transparent
                                    small
                                />
                            </View>
                        </>
                    ) : (
                        <>
                            <View style={{ justifyContent: 'center', flex: 1 }}>
                                <CText style={{ color: theme.textMainColor }}>
                                    {help.title}
                                </CText>
                            </View>
                            {isSuperUser(userData) && (
                                <View
                                    style={[
                                        style.horizontal,
                                        style.centeredItems,
                                    ]}
                                >
                                    {help.groups.map((g) =>
                                        g === EUserType.user ? (
                                            <CLabel
                                                checked={true}
                                                title={format(
                                                    generalMessages[g],
                                                )}
                                                color={getUserTypeColor(g)}
                                                smaller
                                            />
                                        ) : (
                                            <CLabel
                                                checked={true}
                                                title={format(
                                                    generalMessages[g],
                                                )}
                                                color={getUserTypeColor(g)}
                                                smaller
                                            />
                                        ),
                                    )}
                                    {myIndex > 0 && (
                                        <CButton
                                            icon={'chevronUp'}
                                            onPress={() => move('up')}
                                            transparent
                                            small
                                        />
                                    )}
                                    {myIndex < sisterHelps.length - 1 && (
                                        <CButton
                                            icon={'chevronDown'}
                                            onPress={() => move('down')}
                                            transparent
                                            small
                                        />
                                    )}
                                    <CButton
                                        icon={'cog'}
                                        onPress={() => setEdit(true)}
                                        transparent
                                        small
                                    />
                                </View>
                            )}
                        </>
                    )
                }
                body={
                    <View style={{ paddingLeft: 10, flex: 1 }}>
                        {edit ? (
                            <>
                                <CPicker
                                    values={Object.values(EUserType)
                                        .filter((v) => v !== EUserType.default)
                                        .map((v) => ({
                                            label: generalMessages[v],
                                            value: v,
                                        }))}
                                    multiValues={help.groups}
                                    onChange={(g: EUserType) => {
                                        if (!g) {
                                            return;
                                        }
                                        if (!help.groups.includes(g))
                                            onChange({
                                                groups: [
                                                    ...help.groups,
                                                    g,
                                                ].sort(),
                                            });
                                    }}
                                    onChangeMulti={(next) => {
                                        onChange({ groups: [...next] });
                                    }}
                                    keepOpen
                                />
                                <RichTextEditor
                                    text={help.message}
                                    onChange={(message) =>
                                        onChange({ message })
                                    }
                                />
                                <CTextInput
                                    label={format(
                                        helpCenterMessages.buttonText,
                                    )}
                                    value={help.buttonText || ''}
                                    onChangeText={(buttonText) =>
                                        onChange({ buttonText })
                                    }
                                    autoExtend
                                />
                                <CTextInput
                                    label={format(helpCenterMessages.buttonUrl)}
                                    placeholder={
                                        '"/job" for internal or "https://app.situsdocs/job" for external'
                                    }
                                    value={help.url || ''}
                                    onChangeText={(url) => onChange({ url })}
                                    autoExtend
                                />
                            </>
                        ) : (
                            <View style={{ flex: 1 }}>
                                <RichTextView html={help.message} />
                                {!!(help.buttonText && help.url) && (
                                    <View style={style.horizontalSpaced}>
                                        <CButton
                                            title={help.buttonText}
                                            onPress={() => {
                                                if (
                                                    help.url.includes(
                                                        'https://',
                                                    )
                                                ) {
                                                    Linking.openURL(help.url);
                                                } else {
                                                    navigate(help.url);
                                                }
                                            }}
                                            minor
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                        {isSuperUser(userData) && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                {!item.parent && (
                                    <CButton
                                        icon="plus"
                                        title={format(actionMessages.create)}
                                        onPress={() => {
                                            post(
                                                ECollections.helps,
                                                new MHelp({
                                                    lang: language,
                                                    environment:
                                                        help.environment,
                                                    parent: help.documentId,
                                                    order:
                                                        helps[helps.length - 1]
                                                            .order - 1,
                                                    groups: help.groups,
                                                }),
                                            ).then(reload);
                                        }}
                                        minor
                                        small
                                    />
                                )}
                            </View>
                        )}
                        {childrenHelps.map((help) => (
                            <HelpRow key={item.documentId} sub item={help} />
                        ))}
                    </View>
                }
                customExpand={location.pathname.includes(item.documentId)}
                customOnClick={() => {
                    if (item.parent) {
                        navigate(
                            `/helpcenter/${item.parent}/${item.documentId}`,
                        );
                    } else {
                        navigate(`/helpcenter/${item.documentId}`);
                    }
                }}
            />
            {sub && <View style={style.thinSeparator} />}
        </>
    );
};
