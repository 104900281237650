import React, { FC, useCallback, useMemo } from 'react';
import { Image, View } from 'react-native';
import { getAsset } from '../../assets';
import {
    ECollections,
    EEnvironment,
    EProfession,
    EUserType,
} from '../../enums';
import { EGender } from '../../enums/EGender';
import {
    MWorkplace,
    MUserData,
    MProfessionalInfo,
    MAgency,
    MProfessionalProfile,
} from '../../models';
import { useFireBase } from '../../utilities/firebase';
import { useStyle } from '../../utilities/styles';
import { FilePicker } from '../FilePicker';
import { TouchableView } from '../TouchableView';
import { useEnvironment } from '../../utilities/contexts';
import { useLock } from '../../utilities/hooks';
import { useDialog } from '../../utilities/dialog';
import { generalMessages } from '../../utilities/messages';

interface IProfilePictureProps {
    large?: boolean;
    small?: boolean;
    smaller?: boolean;
    data: MUserData | MWorkplace | MAgency | MProfessionalInfo;
    isProfProfile?: boolean;
    onPress?: () => void;
    onChange?: (uri: string) => void;
}

const smallerSize = 36;
/**
 * profile picture based on userData or workplace data
 * @param param0
 * @returns
 */
export const ProfilePicture: FC<IProfilePictureProps> = ({
    data,
    large,
    small,
    smaller,
    isProfProfile,
    onChange,
    onPress,
}) => {
    const { uploadToStorage, getFileDownloadUrl, getDataById } = useFireBase();
    const { environment } = useEnvironment();
    const style = useStyle();
    const dialog = useDialog();
    const { lock } = useLock();

    const src = useMemo(() => {
        const picture = (data as MUserData | MWorkplace | MAgency).picture;
        if (picture) {
            return { uri: picture };
        } else if ((data as MWorkplace).name) {
            return getAsset(
                'placeholderH',
                environment === EEnvironment.TimePloyees,
            );
        } else {
            const userData = data as MUserData;
            if (userData.type === EUserType.employer) {
                return getAsset(
                    'placeholderH',
                    environment === EEnvironment.TimePloyees,
                );
            } else if (
                userData.type === EUserType.agency ||
                userData.type === EUserType.talent
            ) {
                if (userData.gender === EGender.male) {
                    return getAsset('agencyMPlaceholder');
                } else if (userData.gender === EGender.female) {
                    return getAsset('agencyFPlaceholder');
                }
            } else if (userData.type === EUserType.admin) {
                if (userData.gender === EGender.male) {
                    return getAsset('placeholderM');
                } else if (userData.gender === EGender.female) {
                    return getAsset('placeholderF');
                }
            } else if (isProfProfile) {
                if (userData.gender === EGender.male) {
                    return getAsset('agencyMPlaceholder');
                } else if (userData.gender === EGender.female) {
                    return getAsset('agencyFPlaceholder');
                }
            } else {
                if (userData.profession === EProfession.doctor) {
                    if (userData.gender === EGender.male) {
                        return getAsset('placeholderM');
                    } else if (userData.gender === EGender.female) {
                        return getAsset('placeholderF');
                    }
                } else if (userData.profession === EProfession.nurse) {
                    if (userData.gender === EGender.male) {
                        return getAsset('placeholderMB');
                    } else if (userData.gender === EGender.female) {
                        return getAsset('placeholderFB');
                    }
                }
            }
            // fallback if nothing is matching
            return getAsset('placeholderD');
        }
    }, [data, isProfProfile, environment]);

    const onFile = useCallback(
        async (fn: string, file: Uint8Array) => {
            if (!onChange) return;
            const unlock = lock();
            try {
                const fnS = fn.split('.');
                const filename = `/${Date.now()}.${fnS[fnS.length - 1]}`;
                const dataAsUserData = data as MUserData;
                const dataAsTalent = data as MProfessionalProfile;
                let path = `/pictures/`;
                if (isProfProfile) {
                    path += `${dataAsTalent.agencyId}/profile/${data.documentId}`;
                } else if (dataAsUserData.talentId) {
                    const talent = new MProfessionalProfile(
                        await getDataById(
                            ECollections.profProfiles,
                            dataAsUserData.talentId,
                        ),
                    );
                    path += `${talent.agencyId}/profile/${talent.documentId}`;
                } else {
                    path += `${data.documentId}`;
                }
                path += `/${filename}`;

                const metaData = await uploadToStorage(path, file);
                const picture = await getFileDownloadUrl(metaData.fullPath);
                unlock();
                onChange(picture);
            } catch (e) {
                unlock();
                dialog({
                    icon: 'error',
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                });
            }
        },
        [isProfProfile, data, onChange],
    );

    return (
        <TouchableView onPress={onPress} nativeId="profile-picture">
            {src ? (
                <Image
                    //@ts-ignore
                    source={src}
                    resizeMethod={'scale'}
                    style={[
                        { width: 70, height: 70, borderRadius: 35 },
                        small && style.listImage,
                        smaller && {
                            width: smallerSize,
                            height: smallerSize,
                            borderRadius: smallerSize / 2,
                        },
                        large && style.bigImage,
                    ]}
                />
            ) : (
                <View
                    style={[
                        { width: 70, height: 70, borderRadius: 35 },
                        small && style.listImage,
                        smaller && {
                            width: smallerSize,
                            height: smallerSize,
                            borderRadius: smallerSize / 2,
                        },
                        large && style.bigImage,
                    ]}
                />
            )}
            {onChange && (
                <View
                    style={{
                        position: 'absolute',
                        alignSelf: 'flex-end',
                        height: '100%',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FilePicker
                        onFile={onFile}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                            margin: 0,
                        }}
                    />
                </View>
            )}
        </TouchableView>
    );
};
