import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useStyle, useTheme } from '../../utilities/styles';
import { CButton, CLabel, CPicker } from '../elements';
import { MenuContent } from './MenuContent';
import { makeMenuStyles } from './menu.style';
import { useFireBase } from '../../utilities/firebase';
import { isSuperUser } from '../../utilities/auth';
import { CText } from '../elements';
import { CImage } from '../elements/CImage';
import { getUserTypeColor, isLocalhost } from '../../utilities/constants';
import { useEnvironment } from '../../utilities/contexts';
import { EEnvironment, ERegion, EUserType } from '../../enums';
import { TouchableView } from '../TouchableView';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDimensions } from '../../utilities/hooks/useDimensions';
import { useSecureNavigate } from '../../utilities/routing';
import { generalMessages } from '../../utilities/messages';
import { useFormat } from '../../utilities/intl';
import { UserTypeLabel } from '../UserTypeLabel';
import GitInfo from 'react-git-info/macro';
import { Version } from './components/Version';

interface IMenuProps {
    closeDrawer?: () => void;
}

export const Menu: FC<IMenuProps> = ({ closeDrawer }) => {
    // global state
    const { theme } = useTheme();
    const format = useFormat();
    const { userData } = useFireBase();
    const { width } = useDimensions();
    const { environment, setEnvironment, setRegion, region } = useEnvironment();
    const { secureNavigate } = useSecureNavigate();
    const style = useStyle();
    const dev = useMemo(isLocalhost, []);
    // local state
    const [isExpanded, setIsExpanded] = useState(true);
    const [prevWidth, setPrevWidth] = useState(width);
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    /**
     * effect to collapse
     */
    useEffect(() => {
        AsyncStorage.getItem('menu-state').then((next) => {
            if (next === 'collapsed') {
                setIsExpanded(false);
            }
        });
    }, []);
    /**
     * effec to collapse menu detail if window gets slimmer smh
     */
    useEffect(() => {
        /**
         * if width was altered noticably
         */
        if (Math.abs(width - prevWidth) > 100) {
            /**
             * if width was lessened close detail
             */
            if (width < prevWidth) {
                setIsExpanded(false);
            }
            setPrevWidth(prevWidth);
        }
    }, [width, prevWidth]);
    /**
     * render
     */
    return (
        <View style={[localStyles.container]} nativeID="menu">
            {!isExpanded ? (
                <View
                    style={[
                        localStyles.sidebar,
                        // environment === EEnvironment.TimePloyees && {
                        //     backgroundColor: theme.darkAccentColor,
                        // },
                    ]}
                >
                    <TouchableView
                        onPress={() => {
                            setIsExpanded(true);
                            AsyncStorage.setItem('menu-state', 'expandet');
                        }}
                        style={style.verticalPadded}
                    >
                        <CImage
                            image={
                                isSuperUser(userData) ? 'adminSigil' : 'sigil'
                            }
                            maxWidth={40}
                        />
                    </TouchableView>
                    <MenuContent
                        isExpanded={isExpanded}
                        onChangeExpanded={setIsExpanded}
                    />
                </View>
            ) : (
                <View
                    style={[
                        localStyles.sidebar,
                        localStyles.sidebarDetail,
                        // environment === EEnvironment.TimePloyees && {
                        //     backgroundColor: theme.darkAccentColor,
                        // },
                    ]}
                >
                    <View style={localStyles.headerContainer}>
                        <TouchableView onPress={() => secureNavigate('/')}>
                            <CImage
                                image={
                                    isSuperUser(userData) ? 'adminLogo' : 'logo'
                                }
                                maxWidth={200}
                            />
                        </TouchableView>
                        {!closeDrawer && (
                            <CButton
                                onPress={() => {
                                    setIsExpanded(false);
                                    AsyncStorage.setItem(
                                        'menu-state',
                                        'collapsed',
                                    );
                                }}
                                icon={'chevronLeft'}
                                small
                                transparent
                                // style={
                                //     environment === EEnvironment.TimePloyees && {
                                //         backgroundColor: theme.darkAccentColor,
                                //     }
                                // }
                            />
                        )}
                        {closeDrawer && (
                            <CButton
                                transparent
                                onPress={() => closeDrawer()}
                                icon={'close'}
                                style={[
                                    localStyles.sidebarButton,
                                    // environment ===
                                    //     EEnvironment.TimePloyees && {
                                    //     backgroundColor: theme.darkAccentColor,
                                    // },
                                ]}
                            />
                        )}
                    </View>
                    {dev && (
                        <View style={[style.horizontal, style.centeredItems]}>
                            <UserTypeLabel data={userData} />
                            <CImage image={userData.region} fixedHeight={16} />
                        </View>
                    )}
                    {dev && (
                        <View style={style.horizontal}>
                            <CPicker
                                values={Object.values(EEnvironment).map(
                                    (v) => ({
                                        value: v,
                                        label: v,
                                    }),
                                )}
                                onChange={(v) => {
                                    setEnvironment(v);
                                }}
                                value={environment}
                            />
                            <CPicker
                                values={Object.values(ERegion).map((v) => ({
                                    value: v,
                                    label: v,
                                }))}
                                onChange={(v) => {
                                    setRegion(v);
                                }}
                                value={region}
                            />
                        </View>
                    )}
                    <MenuContent
                        detail
                        isExpanded={isExpanded}
                        onChangeExpanded={setIsExpanded}
                        closeSidebar={closeDrawer}
                    />
                    <Version />
                </View>
            )}
        </View>
    );
};
