import React, { FC, useCallback, useContext } from 'react';

import { httpsCallable } from 'firebase/functions';

import { IDefaultProps } from '../../IDefaultProps';
import { FireAppContext } from '../app';
import { FireFunctionContext } from './FireFunctionContext';
import { LanguageContext } from '../../intl';
import { useDialog } from '../../dialog';
import { generalMessages } from '../../messages';

export const FireFunctionProvider: FC<IDefaultProps> = ({ children }) => {
    const { functions } = useContext(FireAppContext);
    const { language } = useContext(LanguageContext);
    const dialog = useDialog();
    /**
     * callback to call cloud function
     */
    const callFunction = useCallback(
        async (functionName: string, params: any) => {
            if (functions) {
                try {
                    const instance = httpsCallable(functions, functionName);
                    const result = await instance({
                        ...params,
                        lang: language,
                    });

                    return result.data;
                } catch (e) {
                    dialog({
                        icon: 'error',
                        title: generalMessages.errorOccured,
                        message: `${e}`,
                    });
                }
            }
        },
        [language, functions],
    );
    /**
     * provide context
     */
    return (
        <FireFunctionContext.Provider
            value={{
                callFunction,
            }}
        >
            {children}
        </FireFunctionContext.Provider>
    );
};
